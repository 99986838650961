import { style } from 'typestyle'

import { background, borderRadius, padding } from '../../constants'

export const main = style({
    padding: padding,
    width  : '100%',
})

export const code = style({
    // whiteSpace     : 'pre-wrap',
    wordWrap       : 'break-word',
    fontSize       : '0.8em',
    width          : '100%',
    // maxWidth       : '350px',
    // fontFamily     : 'monospace',
    height         : 'fit-content',
    margin         : 2,
    backgroundColor: background,
    // border         : '1px solid #eee',
    borderRadius   : borderRadius,
    padding        : '1em',
})
