import { style } from 'typestyle'

import { em, hsl } from './helpers'

export const main = style({
    margin: 'auto',
    // fontFamily: 'sans-serif',
    color : '#333',
})


export const table = style({
    width        : '100%',
    position     : 'relative',
    borderSpacing: '0px',
    $nest        : {
        '@media(max-width: 1024px)': {
            $nest: {
                '.hide': {
                    display: 'none',
                },
            },
        },
        thead: {
            fontWeight     : 'bold',
            textAlign      : 'left',
            backgroundColor: '#eee',
            $nest          : {
                td: {
                    paddingBlock : '1em',
                    paddingInline: '0.2em',
                    border       : 0,
                    textTransform: 'uppercase',
                    fontWeight   : 'lighter',
                    color        : '#666',
                    fontSize     : '0.8em',
                },
            },
        },
        td: {
            paddingInline: '0.2em',
            borderBottom : '1px solid gray',
            borderRight  : 0,
            padding      : '0.1em',
        },
    },
})

export const header = style({
    position    : 'relative',
    color       : hsl(213, 20, 20), // '#356FE0'
    fontWeight  : 500,
    lineHeight  : 1.2, // equidistant
    marginBottom: em(0.75),

})