import 'video.js/dist/video-js.css'

// eslint-disable-next-line simple-import-sort/imports
// import 'videojs-contrib-dash'
import { JSX, onCleanup, onMount } from 'solid-js'
import videojs from 'video.js'
import Player from 'video.js/dist/types/player'

const VideoPlayer = (props: { src: string;  hasStream: boolean, loop?: boolean, autoplay?: string | boolean, alwaysMute?: boolean }): JSX.Element => {
    let videoNode: HTMLVideoElement | undefined
    let player: Player | undefined = undefined
    console.log('playing', props.src)

    onMount(() => {
        if(props.src.endsWith('.ts')) {
            // if (mpegts.getFeatureList().mseLivePlayback) {
            //     const player = mpegts.createPlayer({
            //         type                    : 'mse',  // could also be mpegts, m2ts, flv
            //         isLive                  : true,
            //         withCredentials         : false,
            //         //@ts-ignore
            //         liveBufferLatencyChasing: false,
            //         url                     : props.src,
            //     }, {
            //         enableWorker            : true,
            //         lazyLoadMaxDuration     : 3 * 60,
            //         seekType                : 'range',
            //         liveBufferLatencyChasing: false,
            //     })
            //     player.attachMediaElement(videoNode!)
            //     player.load()
            //     setTimeout(() => {
            //         void player.play()
            //     }, 2000)
            // }
        // } else if(props.hasStream) {
        //     new hls.Hls()
        } else {

            player = videojs(videoNode as Element, {
                loop    : props.loop ?? false,
                autoplay: props.autoplay,
            // video.js options
            })
            const vol = localStorage.getItem('igolgi-volume')
            console.log('volume', vol)
            if(vol != undefined && props.alwaysMute != true) {
                player.volume(Number(vol))
            }
            player.on('volumechange', () => {
                // eslint-disable-next-line @typescript-eslint/unbound-method
                const vol = player?.volume?.()
                if(vol != undefined && props.alwaysMute != true) {
                    localStorage.setItem('igolgi-volume', vol.toString())
                }
            })
            let type =  'video/mp4'
            if(props.hasStream == true){
                if(props.src.endsWith('.m3u8')) {
                    type = 'application/x-mpegURL'
                } else if(props.src.endsWith('.mpd')){
                    type = 'application/dash+xml'
                }

            }
            const playerProps = { src: props.src, type }
            console.log(playerProps, player)

            // playerProps = { src : 'https://objectstorage.ca-toronto-1.oraclecloud.com/n/yzwhip2gci7s/b/igolgi-public/o/ab284eb1_91db_40f3_bf74_dd4ef7270f68/video_profile-0.m3u8',
            //                 type: 'application/x-mpegURL',
            // }
            player.src(playerProps)

            void player.play()
            onCleanup(() => {
                if (player != undefined) {
                    player.dispose()
                }
            })
        }
    })

    return (
        <div data-vjs-player>
            <video
                ref={videoNode}
                // class="video-js vjs-default-skin"
                id="my-video"
                class="video-js"
                controls
                preload="auto"
                width="468"
                height="264"
                //   poster="MY_VIDEO_POSTER.jpg"
                data-setup="{}"
                // playsIninlline
            ></video>
        </div>
    )
}

export default VideoPlayer