import { Container, Control, createSelect, List, SelectContext  } from '@thisbeyond/solid-select'
import {
    Component,
    createEffect,
    mergeProps,
    on,
    splitProps,
} from 'solid-js'

type Option = any;

type SingleValue = any;

type Value = SingleValue | SingleValue[];
type ValueType = Value
type OptionType = Option
interface CreateSelectProps {
    options: Option[] | ((inputValue: string) => Option[]);
    initialValue?: Value;
    multiple?: boolean;
    disabled?: boolean;
    optionToValue?: (option: Option) => SingleValue;
    isOptionDisabled?: (option: Option) => boolean;
    onChange?: (value: Value) => void;
    onInput?: (inputValue: string) => void;
}

// import {
//     createSelect,
//     CreateSelectProps,
//     Option as OptionType,
//     Value as ValueType,
// } from './create-select'


interface CommonProps {
    format: (
        data: OptionType | ValueType,
        type: 'option' | 'value'
    ) => string | undefined;
    placeholder?: string;
    id?: string;
    name?: string;
    class?: string;
    autofocus?: boolean;
    readonly?: boolean;
    loading?: boolean;
    loadingPlaceholder?: string;
    emptyPlaceholder?: string;
}

type SelectReturn = ReturnType<typeof createSelect>;

type SelectProps = CreateSelectProps & Partial<CommonProps> & { setValueRef?: (sv: (value: any)=>void)=> void }

export const Select: Component<SelectProps> = (props) => {
    const [selectProps, local] = splitProps(
        mergeProps(
            {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                format            : ((data, type) => data) as CommonProps['format'],
                placeholder       : 'Select...',
                readonly          : typeof props.options !== 'function',
                loading           : false,
                loadingPlaceholder: 'Loading...',
                emptyPlaceholder  : 'No options',
            },
            props,
        ),
        [
            'options',
            'optionToValue',
            'isOptionDisabled',
            'multiple',
            'disabled',
            'onInput',
            'onChange',
        ],
    )
    const select = createSelect(selectProps)

    createEffect(
        on(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            () => local.initialValue,
            (value) => value !== undefined && select.setValue(value),
        ),
    )
    props.setValueRef?.(select.setValue)

    return (
        <SelectContext.Provider value={select}>
            <Container class={local.class}>
                <div style={{ position: 'absolute', bottom: '100%', 'font-size': '0.8em', color: 'gray' }}>Templates</div>
                <Control
                    id={local.id}
                    name={local.name}
                    format={local.format}
                    placeholder={local.placeholder}
                    autofocus={local.autofocus}
                    readonly={local.readonly}
                />
                <List
                    loading={local.loading}
                    loadingPlaceholder={local.loadingPlaceholder}
                    emptyPlaceholder={local.emptyPlaceholder}
                    format={local.format}
                />
            </Container>
        </SelectContext.Provider>
    )
}
