import './EULA.css'

import { JSX } from 'solid-js'


export const EULA_ = (): JSX.Element => {
    return  <div>
        <div class="body s1" style="white-space:pre-wrap; line-break:strict;">
            <p class="p1 p2 c1" style="">TERMS OF SERVICE</p>
            <p class="p1 p3" style=""><span class="c2"> The following Website Use Agreement (the “Agreement”) describes the
            terms under</span></p>
            <p class="p1 p3" style=""><span class="c2"> which Igolgi, Corp., a Delaware Corporation (“Igolgi”), the owner and
            operator of the website streamengine.igolgi.com (“Website”) offers a user (“Customer”) access to the content
            and services at Website (collectively the “Services”).</span></p>
            <p class="p1 p4" style=""><span class="c2"> </span></p>
            <p class="p1 p3" style=""><span class="c2"> WHEREAS, Customer wishes to procure from Igolgi audio/video processing
            services described herein, and Igolgi wishes to provide such services to Customer, each on the terms and
            conditions set forth in this Agreement.</span></p>
            <p class="p1 p3" style="">&nbsp;</p>
            <p class="p1 p3" style=""><span class="c2"> NOW, THEREFORE, in consideration of the mutual covenants, terms and
            conditions set forth herein, and for other good and valuable consideration, the receipt and sufficiency of
            which are hereby acknowledged, the parties agree as follows:</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <ol style="padding:0; margin:0;">
                <li value="1" class="p6 p7 li1 b1" style=""><span class="c3">Definitions </span></li>
            </ol>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Access Credentials</span><span
                class="c2">&quot; means any unique uniform resource locator (URL), username, identification number,
            password, license or security key, security token, PIN or other security code, method, technology or device
            used, alone or in combination, to verify an individual's identity and authorization to access and use the
            Hosted Services.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Action</span><span class="c2">&quot; has
            the meaning set forth in Section 13.1.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> “</span><span class="c3">Account Credit</span><span class="c2">” means
            an advance payment on Fees for Services not yet used by Customer.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Agreement</span><span class="c2">&quot;
            has the meaning set forth in the preamble.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Authorized User</span><span
                class="c2">&quot; means any Person with the Access Credentials needed use Igolgi’s Services.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Availability Requirement</span><span
                class="c2">&quot; has the meaning set forth in Section 5.1.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2">&quot;</span><span class="c3">Available</span><span class="c2">&quot; has
            the meaning set forth in Section 5.1.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Backup Policy</span><span
                class="c2">&quot; has the meaning set forth in Section 6</span></p>
            <p class="p1 p5" style=""><span class="c2">.</span></p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Confidential Information</span><span
                class="c2">&quot; has the meaning set forth in Section 10.1.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Customer</span><span class="c2">&quot; has
            the meaning set forth in the preamble.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Customer Data</span><span
                class="c2">&quot; means information, data and other content, in any form or medium, that is collected,
            downloaded or otherwise received, directly or indirectly from Customer or an Authorized User by or through
            the Services or that incorporates or is derived from the Processing of such information, data or content by
            or through the Services. For the avoidance of doubt, Customer Data includes information reflecting the
            access or use of the Services by or on behalf of Customer or any Authorized User.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Customer Failure</span><span
                class="c2">&quot; has the meaning set forth in Section 4.2.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Customer Indemnitee</span><span
                class="c2">&quot; has the meaning set forth in Section 13.1.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Customer Systems</span><span
                class="c2">&quot; means the Customer's information technology infrastructure, including computers, software,
            hardware, databases, electronic systems (including database management systems) and networks, whether
            operated directly by Customer or through the use of third-party services.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Disclosing Party</span><span
                class="c2">&quot; has the meaning set forth in Section 10.1.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Documentation</span><span
                class="c2">&quot; means any manuals, instructions or other documents or materials listed in Schedule B that
            the Igolgi provides or makes available to Customer in any form or medium and which describe the
            functionality, components, features or requirements of the Services or Igolgi Materials, including any
            aspect of the installation, configuration, integration, operation, use, support or maintenance
            thereof.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Effective Date</span><span
                class="c2">&quot; has the meaning set forth in the preamble.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Exceptions</span><span class="c2">&quot;
            has the meaning set forth in Section 5.1.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Fees</span><span class="c2">&quot; has the
            meaning set forth in Section 8.1.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Force Majeure Event</span><span
                class="c2">&quot; has the meaning set forth in Section 15.1.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Harmful Code</span><span class="c2">&quot;
            means any software, hardware or other technology, device or means, including any virus, worm, malware or
            other malicious computer code, the purpose or effect of which is to (a) permit unauthorized access to, or to
            destroy, disrupt, disable, distort, or otherwise harm or impede in any manner any (i) computer, software,
            firmware, hardware, system or network or (ii) any application or function of any of the foregoing or the
            security, integrity, confidentiality or use of any data Processed thereby, or (b) prevent Customer or any
            Authorized User from accessing or using the Services or Igolgi Systems as intended by this Agreement.
            Harmful Code does not include any Igolgi Disabling Device. </span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2">&quot;</span><span class="c3">Hosted Services</span><span
                class="c2">&quot; has the meaning set forth in Section 2.1.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2">&quot;</span><span class="c3">Igolgi</span><span class="c2">&quot; has
            the meaning set forth in the preamble.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Igolgi Disabling Device</span><span
                class="c2">&quot; means any software, hardware or other technology, device or means (including any back
            door, time bomb, time out, drop dead device, software routine or other disabling device) used by Igolgi or
            its designee to disable Customer's or any Authorized User's access to or use of the Services automatically
            with the passage of time or under the positive control of Igolgi or its designee.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Igolgi Indemnitee</span><span
                class="c2">&quot; has the meaning set forth in Section 13.2.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Igolgi Materials</span><span
                class="c2">&quot; means the Service Software, Specifications, Documentation and Igolgi Systems and any and
            all other information, data, documents, materials, works and other content, devices, methods, processes,
            hardware, software and other technologies and inventions, including any deliverables, technical or
            functional descriptions, requirements, plans or reports, that are provided or used by Igolgi or any
            Subcontractor in connection with the Services or otherwise comprise or relate to the Services or Igolgi
            Systems. For the avoidance of doubt, Igolgi Materials include Resultant Data and any information, data or
            other content derived from Igolgi's monitoring of Customer's access to or use of the Services, but do not
            include Customer Data.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Igolgi Personnel</span><span
                class="c2">&quot; means all individuals involved in the performance of Services as employees, agents or
            independent contractors of Igolgi or any Subcontractor.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Igolgi Systems</span><span
                class="c2">&quot; means the information technology infrastructure used by or on behalf of Igolgi in
            performing the Services, including all computers, software, hardware, databases, electronic systems
            (including database management systems) and networks, whether operated directly by Igolgi or through the use
            of third-party services.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Indemnitee</span><span class="c2">&quot;
            has the meaning set forth in Section 13.3.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Indemnitor</span><span class="c2">&quot;
            has the meaning set forth in Section 13.3.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Initial Term</span><span class="c2">&quot;
            has the meaning set forth in Section 11.1.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Intellectual Property Rights</span><span
                class="c2">&quot; means all registered and unregistered rights granted, applied for or otherwise now or
            hereafter in existence under or related to any patent, copyright, trademark, trade secret, database
            protection or other intellectual property rights laws, and all similar or equivalent rights or forms of
            protection, in any part of the world.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Law</span><span class="c2">&quot; means
            any statute, law, ordinance, regulation, rule, code, order, constitution, treaty, common law, judgment,
            decree or other requirement of any federal, state, local or foreign government or political subdivision
            thereof, or any arbitrator, court or tribunal of competent jurisdiction. </span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2">&quot;</span><span class="c3">Losses</span><span class="c2">&quot; means
            any and all losses, damages, liabilities, deficiencies, claims, actions, judgments, settlements, interest,
            awards, penalties, fines, costs or expenses of whatever kind, including reasonable attorneys' fees and the
            costs of enforcing any right to indemnification hereunder and the cost of pursuing any insurance providers.
            </span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2">&quot;</span><span class="c3">Permitted Use</span><span class="c2">&quot;
            means any use of the Services by an Authorized User for the benefit of Customer solely in or for Customer's
            internal business operations.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Person</span><span class="c2">&quot; means
            an individual, corporation, partnership, joint venture, limited liability entity, governmental authority,
            unincorporated organization, trust, association or other entity.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Privacy and Security Policy</span><span
                class="c2">&quot; has the meaning set forth in Section 7.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Process</span><span class="c2">&quot;
            means to take any action or perform any operation or set of operations that the SaaS Services are capable of
            taking or performing on any data, information or other content, including to collect, receive, input,
            upload, download, record, reproduce, store, organize, compile, combine, log, catalog, cross-reference,
            manage, maintain, copy, adapt, alter, translate or make other derivative works or improvements, process,
            retrieve, output, consult, use, perform, display, disseminate, transmit, submit, post, transfer, disclose or
            otherwise provide or make available, or block, erase or destroy.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2">&quot;</span><span class="c3">Receiving Party</span><span
                class="c2">&quot; has the meaning set forth in Section 10.1.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Reimbursable Expenses</span><span
                class="c2">&quot; has the meaning set forth in Section 8.3.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Renewal Term</span><span class="c2">&quot;
            has the meaning set forth in Section 11.2.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Representatives</span><span
                class="c2">&quot; means, with respect to a party, that party's and its Affiliates' employees, officers,
            directors, consultants, agents, independent contractors, service providers, sublicensees, subcontractors and
            legal advisors. </span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2">&quot;</span><span class="c3">Resultant Data</span><span
                class="c2">&quot; means files, information, data and other content that is derived by or through the
            Services from Processing Customer Data and is sufficiently different from such Customer Data that such
            Customer Data cannot be reverse engineered or otherwise identified from the inspection, analysis or further
            Processing of such information, data or content.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2">&quot;</span><span class="c3">Scheduled Downtime</span><span
                class="c2">&quot; has the meaning set forth in Section 5.3.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Service Period</span><span
                class="c2">&quot; has the meaning set forth in Section 5.1.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Service Software</span><span
                class="c2">&quot; means the Igolgi software application or applications and any third-party or other
            software, and all new versions, updates, revisions, improvements and modifications of the foregoing, that
            Igolgi provides remote access to and use of as part of the Services. </span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2">&quot;</span><span class="c3">Services</span><span class="c2">&quot; has
            the meaning set forth in Section 2.1.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Specifications</span><span
                class="c2">&quot; means the specifications for the Services set forth in Schedule B and, to the extent
            consistent with and not limiting of the foregoing, the Documentation. </span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2">&quot;</span><span class="c3">Subcontractor</span><span class="c2">&quot;
            has the meaning set forth in Section 2.5.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Support Schedule</span><span
                class="c2">&quot; has the meaning set forth in Section 5.4.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Support Services</span><span
                class="c2">&quot; has the meaning set forth in Section 5.4.</span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2"> &quot;</span><span class="c3">Term</span><span class="c2">&quot; has the
            meaning set forth in [Section 11.1/Section 11.2]. </span></p>
            <p class="p1 p5" style="">&nbsp;</p>
            <p class="p1 p5" style=""><span class="c2">&quot;</span><span class="c3">Third Party Materials</span><span
                class="c2">&quot; means materials and information, in any form or medium, including any open-source or other
            software, documents, data, content, specifications, products, equipment or components of or relating to the
            Services that are not proprietary to Igolgi. </span></p>
            <p class="p1" style="">&nbsp;</p>
            <p class="p1" style=""><span class="c2">2</span><span class="c3">. Services</span><span class="c2"> .</span></p>
            <p class="p1" style=""><span class="c2"> 2.1 </span><span class="c3">Services .</span><span class="c2"> Subject to
            and conditioned on Customer's and its Authorized Users' compliance with the terms and conditions of this
            Agreement, during the Term, Igolgi shall use commercially reasonable efforts to provide to Customer and its
            Authorized Users the services described in the attached Schedule A and this Agreement (collectively, the
            &quot;Services&quot;) in accordance with the Specifications and terms and conditions hereof, including to
            host, manage, operate and maintain the Service Software for remote electronic access and use by Customer and
            its Authorized Users (&quot;Hosted Services&quot;) in substantial conformity with the Specifications 24
            hours per day, seven days per week every day of the year, except for:</span></p>
            <p class="p8 p9" style=""><span class="c4"><bdi>a)</bdi></span><span class="c2">Scheduled Downtime in accordance
            with Section 5.3;</span></p>
            <p class="p8 p9" style=""><span class="c4"><bdi>b)</bdi></span><span class="c2">Service downtime or degradation due
            to a Force Majeure Event;</span></p>
            <p class="p8 p9" style=""><span class="c5"><bdi>c)</bdi></span><span class="c2">any other circumstances beyond
            Igolgi's reasonable control, including Customer's or any Authorized User's use of Third-Party Materials,
            misuse of the Hosted Services, or use of the Services other than in compliance with the express terms of
            this Agreement and the Specifications; and (d) any suspension or termination of Customer's or any Authorized
            Users' access to or use of the Hosted Services as permitted by this Agreement.</span></p>
            <p class="p1" style="">&nbsp;</p>
            <p class="p1" style="">&nbsp;</p>
            <p class="p1" style=""><span class="c3">2.2 Service and System Control</span></p>
            <p class="p1" style=""><span class="c2"> Except as otherwise expressly provided in this Agreement, as between the
            parties: </span></p>
            <p class="p1" style=""><span class="c2">(a) Igolgi has and will retain sole control over the operation, provision,
            maintenance and management of the Services and Igolgi Materials, including the: (i) Igolgi Systems; (ii)
            selection, deployment, modification and replacement of the Service Software; and (iii) performance of
            Support Services and Service maintenance, upgrades, corrections and repairs; and</span></p>
            <p class="p1" style=""><span class="c2"> (b) Customer has and will retain sole control over the operation,
            maintenance and management of the Customer Systems, and sole responsibility for all access to and use of the
            Services and Igolgi Materials by any Person by or through the Customer Systems or any other means controlled
            by Customer or any Authorized User, including any: (i) information, instructions or materials provided by
            any of them to the Services or Igolgi; (ii) files uploaded or transcoded files downloaded, or otherwise
            provided or obtained from any use of the Services or Igolgi Materials; and (iii) deliverables, conclusions,
            decisions or actions based on such use. </span></p>
            <p class="p1" style="">&nbsp;</p>
            <p class="p1" style=""><span class="c3">2.3</span><span class="c2"> </span><span class="c3">Service Management
            </span></p>
            <p class="p1" style=""><span class="c2">Each party shall, throughout the Term, maintain within its organization a
            service manager to serve as such party's primary point of contact for day-to-day communications,
            consultation and decision-making regarding the Services. Each service manager shall be responsible for
            providing all day-to-day consents and approvals on behalf of such party under this Agreement. Each party
            shall ensure its service manager has the requisite organizational authority, skill, experience and other
            qualifications to perform in such a capacity. Each party shall use commercially reasonable efforts to
            maintain the same service manager in place throughout the Term. If either party's service manager ceases to
            be employed by such party or such party otherwise wishes to replace its service manager, such party shall
            promptly name a new service manager by written notice to the other party.</span></p>
            <p class="p1" style="">&nbsp;</p>
            <p class="p1" style=""><span class="c3"> 2.4 Changes </span></p>
            <p class="p1" style=""><span class="c2">Igolgi reserves the right, in its sole discretion, to make any changes to
            the Services and Igolgi Materials that it deems necessary or useful to: (a) maintain or enhance (i) the
            quality or delivery of Igolgi's services to its customers, (ii) the competitive strength of or market for
            Igolgi's services or (iii) the Services' cost efficiency or performance; or (b) to comply with applicable
            Law. This includes, without limitation, the addition, removal, or modification of access and usage of
            certain codecs. Without limiting the foregoing, either party may, at any time during the Term, request in
            writing changes to the Services. The parties shall evaluate and, if agreed, implement all such requested
            changes in accordance with the change procedure set forth in Schedule 6. No requested changes will be
            effective unless and until memorialized in a written change order signed by both parties, except that
            Customer may increase or decrease the number of Authorized Users for any Services pursuant to Section 3.4.
            </span></p>
            <p class="p10 c1" style="">2.5 Subcontractors </p>
            <p class="p1" style=""><span class="c2"> Igolgi may from time to time in its discretion engage third parties to
            perform Services (each, a &quot;Subcontractor&quot;). </span></p>
            <p class="p10 c1" style="">2.6 Suspension or Termination of Services</p>
            <p class="p1" style=""><span class="c2"> Igolgi may, directly or indirectly, and by use of a Igolgi Disabling Device
            or any other lawful means, suspend, terminate or otherwise deny Customer's, any Authorized User's or any
            other Person's access to or use of all or any part of the Services or Igolgi Materials, without incurring
            any resulting obligation or liability, if: (a) Igolgi receives a judicial or other governmental demand or
            order, subpoena or law enforcement request that expressly or by reasonable implication requires Igolgi to do
            so; or (b) Igolgi believes, in its good faith and reasonable discretion, that: (i) Customer or any
            Authorized User has failed to comply with, any term of this Agreement, or accessed or used the Services
            beyond the scope of the rights granted or for a purpose not authorized under this Agreement or in any manner
            that does not comply with any instruction or requirement of the Specifications; (ii) Customer or any
            Authorized User is, has been, or is likely to be involved in any fraudulent, misleading or unlawful
            activities; or (iii) this Agreement expires or is terminated. This Section 2.6 does not limit any of
            Igolgi's other rights or remedies, whether at law, in equity or under this Agreement.</span></p>
            <p class="p10 c1" style=""> 3. Authorization and Customer Restrictions</p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">3.1 Authorization </span></p>
            <p class="p1" style=""><span class="c2">Subject to and conditioned on Customer's payment of the Fees and compliance
            and performance in accordance with all other terms and conditions of this Agreement, Igolgi hereby
            authorizes Customer to access and use, solely during the Term, the Services and such Igolgi Materials as
            Igolgi may supply or make available to Customer solely for the Permitted Use by and through Authorized Users
            in accordance with the Specifications, and the conditions and limitations set forth in this Agreement. This
            authorization is non-exclusive and other than as may be expressly set forth in Section 16.8,
            non-transferable.</span></p>
            <p class="p10 c1" style=""> 3.2 Reservation of Rights </p>
            <p class="p1" style=""><span class="c2">Nothing in this Agreement grants any right, title or interest in or to
            (including any license under) any Intellectual Property Rights in or relating to, the Services, Igolgi
            Materials or Third Party Materials, whether expressly, by implication, estoppel or otherwise. All right,
            title and interest in and to the Services, the Igolgi Materials and the Third-Party Materials are and will
            remain with Igolgi and the respective rights holders in the Third-Party Materials.</span></p>
            <p class="p1" style="">&nbsp;</p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">3.3 Authorization Limitations and Restrictions
            </span></p>
            <p class="p1" style=""><span class="c2">Customer shall not, and shall not permit any other Person to, access or use
            the Services or Igolgi Materials except as expressly permitted by this Agreement and, in the case of
            Third-Party Materials, the applicable third-party license agreement. For purposes of clarity and without
            limiting the generality of the foregoing, Customer shall not, except as this Agreement expressly
            permits:</span></p>
            <p class="p8 p11" style=""><span class="c4"><bdi>a)</bdi></span><span class="c2">copy, modify or create derivative
            works or improvements of the Services or Igolgi Materials,</span></p>
            <p class="p8 p11" style=""><span class="c4"><bdi>b)</bdi></span><span class="c2">rent, lease, lend, sell,
            sublicense, assign, distribute, publish, transfer or otherwise make available any Services or Igolgi
            Materials to any Person, including on or in connection with the internet or any time-sharing, service
            bureau, software as a service, cloud or other technology or service,</span></p>
            <p class="p8 p11" style=""><span class="c5"><bdi>c)</bdi></span><span class="c2">copy, reverse engineer,
            disassemble, decompile, decode, adapt or otherwise attempt to derive or gain access to the source code of
            the Services or Igolgi Materials, in whole or in part,</span></p>
            <p class="p8 p11" style=""><span class="c4"><bdi>d)</bdi></span><span class="c2">bypass or breach any security
            device or protection used by the Services or Igolgi Materials or access or use the Services or Igolgi
            Materials other than by an Authorized User through the use of his or her own then valid Access
            Credentials,</span></p>
            <p class="p8 p11" style=""><span class="c4"><bdi>e)</bdi></span><span class="c2">input, upload, transmit or
            otherwise provide to or through the Services or Igolgi Systems, any information or materials that are
            unlawful or injurious, or contain, transmit or activate any Harmful Code,</span></p>
            <p class="p8 p11" style=""><span class="c6"><bdi>f)</bdi></span><span class="c2">damage, destroy, disrupt, disable,
            impair, interfere with or otherwise impede or harm in any manner the Services, Igolgi Systems or Igolgi's
            provision of services to any third party, in whole or in part,</span></p>
            <p class="p8 p11" style=""><span class="c4"><bdi>g)</bdi></span><span class="c2">remove, delete, alter or obscure
            any trademarks, Specifications, Documentation, End-User License Agreement (EULA), warranties or disclaimers,
            or any copyright, trademark, patent or other intellectual property or proprietary rights notices from any
            Services or Igolgi Materials, including any copy thereof,</span></p>
            <p class="p8 p11" style=""><span class="c4"><bdi>h)</bdi></span><span class="c2">access or use the Services or
            Igolgi Materials in any manner or for any purpose that infringes, misappropriates or otherwise violates any
            Intellectual Property Right or other right of any third party (including by any unauthorized access to,
            misappropriation, use, alteration, destruction or disclosure of the data of any other Igolgi customer), or
            that violates any applicable Law,</span></p>
            <p class="p8 p11" style=""><span class="c6"><bdi>i)</bdi></span><span class="c2">access or use the Services or
            Igolgi Materials for purposes of competitive analysis of the Services or Igolgi Materials, the development,
            provision or use of a competing software service or product or any other purpose that is to the Igolgi's
            detriment or commercial disadvantage; or</span></p>
            <p class="p8 p11" style=""><span class="c6"><bdi>j)</bdi></span><span class="c2">otherwise access or use the
            Services or Igolgi Materials beyond the scope of the authorization granted under Section 3.1. </span></p>
            <p class="p10 c1" style="">4. Customer Obligations </p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">4.1 Customer Systems and Cooperation</span><span
                class="c2"> </span></p>
            <p class="p1" style=""><span class="c2">Customer shall always during the Term:</span></p>
            <p class="p8 p11" style=""><span class="c4"><bdi>a)</bdi></span><span class="c2">set up, maintain and operate in
            good repair and in accordance with the Specifications all Customer Systems on or through which the Services
            are accessed or used,</span></p>
            <p class="p8 p11" style=""><span class="c4"><bdi>b)</bdi></span><span class="c2">provide Igolgi Personnel with such
            access to Customer Systems as is necessary for Igolgi to perform the Services in accordance with the
            Availability Requirement and Specifications; and</span></p>
            <p class="p8 p11" style=""><span class="c5"><bdi>c)</bdi></span><span class="c2">provide all cooperation and
            assistance as Igolgi may reasonably request to enable Igolgi to exercise its rights and perform its
            obligations under and in connection with this Agreement.</span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">4.2 Effect of Customer Failure or Delay</span><span
                class="c2"> </span></p>
            <p class="p1" style=""><span class="c2">Igolgi is not responsible or liable for any delay or failure of performance
            caused in whole or in part by the Customer's delay in performing, or failure to perform, any of its
            obligations under this Agreement (each, a &quot;Customer Failure&quot;). </span></p>
            <p class="p1" style=""><span class="c3">4.3 Corrective Action and Notice</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c2"> If Customer becomes aware of any actual or threatened activity prohibited
            by Section 3.3, Customer shall, and shall cause its Authorized Users to, immediately: (a) take all
            reasonable and lawful measures within their respective control that are necessary to stop the activity or
            threatened activity and to mitigate its effects (including, where applicable, by discontinuing and
            preventing any unauthorized access to the Services and Igolgi Materials and permanently erasing from their
            systems and destroying any data to which any of them have gained unauthorized access); and (b) notify Igolgi
            of any such actual or threatened activity. </span></p>
            <p class="p1" style=""><span class="c3">4.4 Non-Solicitation</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c2">During the Term and for 5 years after, Customer shall not, and shall not
            assist any other Person to, directly or indirectly recruit or solicit for employment or engagement as an
            independent contractor any Person then or within the prior twenty-four months employed or engaged by Igolgi
            or any Subcontractor and involved in any respect with the Services or the performance of this Agreement. In
            the event of a violation of this Section 4.4, Igolgi will be entitled to liquidated damages equal to the
            compensation paid by Igolgi to the applicable employee or contractor during the prior twenty-four
            months.</span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">5. Support and Downtime</span></p>
            <p class="p1" style=""><span class="c3">5.1 Scheduled Downtime</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c2">Igolgi will use commercially reasonable efforts to; (a) schedule downtime
            for routine maintenance of the Hosted Services; and (b) give Customer at least 12-24 hours prior notice of
            all scheduled outages of the Hosted Services (&quot;Scheduled Downtime&quot;).</span></p>
            <p class="p1" style=""><span class="c3"> 5.2 Service Support </span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c2">The Services include Igolgi’s standard customer support services
            (&quot;Support Services&quot;) in accordance with the Igolgi service support schedule (the &quot;Support
            Schedule&quot;), and may be requested by contacting support@igolgi.com. Igolgi may amend the Support
            Schedule from time to time in its sole discretion. Customer may purchase enhanced support services
            separately at Igolgi’s then current rates.</span></p>
            <p class="p10 c1" style=""> 6. Data Backup </p>
            <p class="p1" style=""><span class="c2">Igolgi shall delete all Customer’s files related to the Services, including,
            but not limited to, Customer files provided for the Services and the files generated by the Services. Igolgi
            may delete the Customer files after 24-48 hours have elapsed from the generation of the corresponding files.
            Customer has no right to recover, and Igolgi has no means to recover, any deleted data. As such, Customer
            will need to engage in the Services to reconstruct any deleted Resultant Data at Customer’s cost. The
            Services do not replace the need for Customer to maintain regular data backups or redundant data archives.
            PROVIDER HAS NO OBLIGATION OR LIABILITY FOR ANY LOSS, ALTERATION, DESTRUCTION, DAMAGE, CORRUPTION OR
            RECOVERY OF CUSTOMER DATA.</span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">7. Security </span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">7.1 Customer Control and Responsibility</span><span
                class="c2"> </span></p>
            <p class="p1" style=""><span class="c2">Customer has and will retain sole responsibility for: (a) all Customer Data,
            including its content and use; (b) all information, instructions and materials provided by or on behalf of
            Customer or any Authorized User in connection with the Services; (c) Customer's information technology
            infrastructure, including computers, software, databases, electronic systems (including database management
            systems) and networks, whether operated directly by Customer or through the use of third-party services
            (&quot;Customer Systems&quot;); (d) the security and use of Customer's and its Authorized Users' Access
            Credentials; and (e) all access to and use of the Services and Igolgi Materials directly or indirectly by or
            through the Customer Systems or its Authorized Users' Access Credentials, with or without Customer’s
            knowledge or consent, including all results obtained from, and all conclusions, decisions and actions based
            on, such access or use.</span></p>
            <p class="p10 c1" style=""> 7.2 Access and Security </p>
            <p class="p1" style=""><span class="c2">Customer shall employ all physical, administrative and technical controls,
            screening and security procedures and other safeguards necessary to: (a) securely administer the
            distribution and use of all Access Credentials and protect against any unauthorized access to or use of the
            Hosted Services; and (b) control the content and use of Customer Data, including the uploading or other
            provision of Customer Data for Processing by the Hosted Services.</span></p>
            <p class="p1" style=""><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c3">8. Fees; Payment Terms</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c3"> 8.1 Fees</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c2"> Customer shall pay Igolgi the fees set forth in Schedule A
            (&quot;Fees&quot;) in accordance with this Section 8. </span></p>
            <p class="p10 c1" style="">8.2 Fee Increases </p>
            <p class="p1" style=""><span class="c2">Igolgi may increase Fees after the first contract year of the Initial Term,
            by providing written notice to Customer at least 30 calendar days prior to the commencement of that contract
            year, and Schedule A will be deemed amended accordingly. </span></p>
            <p class="p1" style=""><span class="c3">8.3 Reimbursable Expenses</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c2">Subject to Customer approval of Reimbursable Expenses in advance, Customer
            shall reimburse Igolgi for out-of-pocket expenses incurred by Igolgi in connection with performing the
            Services (&quot;Reimbursable Expenses&quot;). </span></p>
            <p class="p10 c1" style="">8.4 Taxes </p>
            <p class="p1" style=""><span class="c2">All Fees and other amounts payable by Customer under this Agreement are
            exclusive of taxes and similar assessments. Customer is responsible for all sales, use and excise taxes, and
            any other similar taxes, duties and charges of any kind imposed by any federal, state or local governmental
            or regulatory authority on any amounts payable by Customer hereunder, other than any taxes imposed on
            Igolgi's income. </span></p>
            <p class="p1" style=""><span class="c3">8.5 Payment</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c2"> Customer shall pay all Fees and Reimbursable Expenses on or prior to the
            due date set forth in Schedule A. Customer shall make all payments hereunder in US dollars. Payments may be
            made to fill out the Customer’s Account Credits. Usage of the Services may cause an auto-deduction from the
            Customer’s Account Credits. In instances where the Customer does not have Account Credits, Customer may
            accumulate an account balance corresponding to accrued usage Fees for the Services. The Customer must pay
            down the Fees prior to replenishing any Account Credits. Customer may further keep bank account or credit
            card account information with the Igolgi, such that Igolgi may draw upon the Customer’s account once a month
            for accrued usage Fees. Payments by credit card may have a corresponding transaction fee, added to the Fees.
            </span></p>
            <p class="p10 c1" style="">8.6 Late Payment</p>
            <p class="p1" style=""><span class="c2">If Customer fails to make any payment when due then, in addition to all
            other remedies that may be available:</span></p>
            <p class="p12 p13" style=""><span class="c7"><bdi>(a)</bdi></span><span class="c2">Igolgi may charge interest on the
            past due amount at the rate of 1.5% per month calculated daily and compounded monthly or, if lower, the
            highest rate permitted under applicable Law;</span></p>
            <p class="p12 p13" style=""><span class="c7"><bdi>(b)</bdi></span><span class="c2">Customer shall reimburse Igolgi
            for all costs incurred by Igolgi in collecting any late payments or interest, including attorneys' fees,
            court costs and collection agency fees; and</span></p>
            <p class="p12 p13" style=""><span class="c8"><bdi>(c)</bdi></span><span class="c2">if such failure continues for 30
            days following written notice thereof, Igolgi may suspend performance of the Services until all past due
            amounts and interest thereon have been paid, without incurring any obligation or liability to Customer or
            any other Person by reason of such suspension.</span></p>
            <p class="p15" style="">&nbsp;</p>
            <p class="p10 c1" style=""> 8.7 No Deductions or Setoffs </p>
            <p class="p1" style=""><span class="c2">All amounts payable to Igolgi under this Agreement shall be paid by Customer
            to Igolgi in full without any setoff, recoupment, counterclaim, deduction, debit or withholding for any
            reason other than Service Credits issued pursuant to Section 5.2 or any deduction or withholding of tax as
            may be required by applicable Law.</span></p>
            <p class="p1" style=""><span class="c3"> 8.8 Unauthorized Use or Unanticipated Use</span><span class="c2"> </span>
            </p>
            <p class="p1" style=""><span class="c2"> Customer agrees to pay the Fees for any usage of the Services, including,
            but not limited to, for usage that was accidental, unintended, in error or through the misappropriation of
            Access Credentials.</span></p>
            <p class="p1" style=""><span class="c3"> 9.Intellectual Property Rights</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c3"> 9.1 Services and Igolgi Materials</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c2">All right, title and interest in and to the Services and Igolgi Materials,
            including all Intellectual Property Rights therein, are and will remain with Igolgi and the respective
            rights holders in the Third-Party Materials. Customer has no right, license or authorization with respect to
            any of the Services or Igolgi Materials (including Third-Party Materials) except as expressly set forth in
            Section 3.1 or the applicable third-party license, in each case subject to Section 3.3 All other rights in
            and to the Services and Igolgi Materials (including Third-Party Materials) are expressly reserved by Igolgi
            and the respective third-party licensors. </span></p>
            <p class="p1" style="">&nbsp;</p>
            <p class="p1" style=""><span class="c3">9.2 Customer Data</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c2">Customer licenses, or sublicenses, as the case may be, to Igolgi all
            necessary rights, including, but not limited to, copyrights and trademarks, with permission to duplicate,
            distribute, publish, store, adapt, transform, and create derivative works of the Customer Data. Such license
            shall be limited to the Term and for the purpose of providing the Services to Customer. As between Customer
            and Igolgi, Customer is and will remain the sole and exclusive owner of all right, title and interest in and
            to all Customer Data, including all Intellectual Property Rights relating thereto, subject to the rights and
            permissions granted in Section 9.3. Customer warrants that it owns or has obtained all necessary license
            rights from third parties, including owners or licensors of certain third- party audio and video formats,
            copyrights, watermarks, or trademarks. Customer is solely responsible for obtaining these licenses and
            paying any necessary royalties or fees. </span></p>
            <p class="p1 p16" style="">&nbsp;</p>
            <p class="p1 p16" style="">&nbsp;</p>
            <p class="p10 c1" style="">9.3 Consent to Use Customer Data </p>
            <p class="p1" style=""><span class="c2"> Customer hereby irrevocably grants all such rights and permissions in or
            relating to Customer Data: (a) to Igolgi, its Subcontractors and the Igolgi Personnel as are necessary or
            useful to perform the Services; and (b) to Igolgi as are necessary or useful to enforce this Agreement and
            exercise its rights and perform its hereunder.</span></p>
            <p class="p1" style=""><span class="c2"> 10. Confidentiality .</span></p>
            <p class="p1" style=""><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c3">10.1. Confidential Information</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c2">In connection with this Agreement each party (as the &quot;Disclosing
            Party&quot;) may disclose or make available Confidential Information to the other party (as the
            &quot;Receiving Party&quot;). Subject to Section 10.2, &quot;Confidential Information&quot; means
            information in any form or medium (whether oral, written, electronic or other) that the Disclosing Party
            considers confidential or proprietary, including information consisting of or relating to the Disclosing
            Party’s technology, trade secrets, know-how, business operations, plans, strategies, customers, and pricing,
            and information with respect to which the Disclosing Party has contractual or other confidentiality
            obligations, in each case whether or not marked, designated or otherwise identified as
            &quot;confidential&quot;. Without limiting the foregoing: all Igolgi Materials are the Confidential
            Information of Igolgi and the financial terms and existence of this Agreement are the Confidential
            Information of each of the parties. </span></p>
            <p class="p10 c1" style="">10.2 Exclusions </p>
            <p class="p1" style=""><span class="c2"> Confidential Information does not include information that the Receiving
            Party can demonstrate by written or other documentary records: (a) was rightfully known to the Receiving
            Party without restriction on use or disclosure prior to such information's being disclosed or made available
            to the Receiving Party in connection with this Agreement; (b) was or becomes generally known by the public
            other than by the Receiving Party's or any of its Representatives' noncompliance with this Agreement; (c)
            was or is received by the Receiving Party on a non-confidential basis from a third party that[, to the
            Receiving Party's knowledge, was not or is not, at the time of such receipt, under any obligation to
            maintain its confidentiality; or (d) the Receiving Party can demonstrate by written or other documentary
            records was or is independently developed by the Receiving Party without reference to or use of any
            Confidential Information.</span></p>
            <p class="p1" style="">&nbsp;</p>
            <p class="p1" style="">&nbsp;</p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">10.3 Protection of Confidential
            Information</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c2">As a condition to being provided with any disclosure of or access to
            Confidential Information, the Receiving Party shall for 5 years: (a)not access or use Confidential
            Information other than as necessary to exercise its rights or perform its obligations under and in
            accordance with this Agreement; (b) except as may be permitted by and subject to its compliance with Section
            10.4, not disclose or permit access to Confidential Information other than to its Representatives who: (i)
            need to know such Confidential Information for purposes of the Receiving Party's exercise of its rights or
            performance of its obligations under and in accordance with this Agreement; (ii) have been informed of the
            confidential nature of the Confidential Information and the Receiving Party's obligations under this Section
            10.3; and (iii) are bound by confidentiality and restricted use obligations at least as protective of the
            Confidential Information as the terms set forth in this Section 10.3; (c) safeguard the Confidential
            Information from unauthorized use, access or disclosure using at least the degree of care it uses to protect
            its sensitive information and in no event less than a reasonable degree of care; and (d) ensure its
            Representatives' compliance with, and be responsible and liable for any of its Representatives'
            non-compliance with, the terms of this Section 10.</span></p>
            <p class="p1" style="">&nbsp;</p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">10.4 Compelled Disclosures </span></p>
            <p class="p1" style=""><span class="c2"> If the Receiving Party or any of its Representatives is compelled by
            applicable Law to disclose any Confidential Information then, to the extent permitted by applicable Law, the
            Receiving Party shall: (a) promptly, and prior to such disclosure, notify the Disclosing Party in writing of
            such requirement so that the Disclosing Party can seek a protective order or other remedy or waive its
            rights under Section 10.3; and (b) provide reasonable assistance to the Disclosing Party, at the Disclosing
            Party's sole cost and expense, in opposing such disclosure or seeking a protective order or other
            limitations on disclosure. If the Disclosing Party waives compliance or, after providing the notice and
            assistance required under this Section 10.4, the Receiving Party remains required by Law to disclose any
            Confidential Information, the Receiving Party shall disclose only that portion of the Confidential
            Information that, on the advice of the Receiving Party's legal counsel, the Receiving Party is legally
            required to disclose and, on the Disclosing Party's request, shall use commercially reasonable efforts to
            obtain assurances from the applicable court or other presiding authority that such Confidential Information
            will be afforded confidential treatment.</span></p>
            <p class="p1" style=""><span class="c2"> </span></p>
            <p class="p1" style="">&nbsp;</p>
            <p class="p1" style=""><span class="c3">11.Term and Termination</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">11.1 Initial Term</span><span class="c2"> </span>
            </p>
            <p class="p1" style=""><span class="c2">The initial term of this Agreement commences as of the Effective Date and,
            unless terminated earlier pursuant any of the Agreement's express provisions, will continue in effect until
            on year from such date (the &quot;[Initial] Term&quot;). </span></p>
            <p class="p1" style="">&nbsp;</p>
            <p class="p1" style=""><span class="c3">11.2 Renewal</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c2">This Agreement will automatically renew for up to 5 additional successive
            one-year terms unless earlier terminated pursuant to this Agreement's express provisions or either party
            gives the other party written notice of non-renewal at least 30 days prior to the expiration of the
            then-current term (each &quot;Renewal Term&quot; and, collectively, together with the Initial Term, the
            &quot;Term&quot;).</span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">11.3 Termination</span><span class="c2"> </span>
            </p>
            <p class="p1" style=""><span class="c2">In addition to any other express termination right set forth elsewhere in
            this Agreement: (a)Igolgi may terminate this Agreement, effective on written notice to Customer, if
            Customer: (i) fails to pay any amount when due hereunder, and such failure continues more than [30] days
            after Igolgi's delivery of written notice thereof; or (ii) breaches any of its obligations under Section 3.3
            (Use Limitations and Restrictions) or Section 10 (Confidentiality); (b) either party may terminate this
            Agreement, effective on written notice to the other party, if the other party breaches this Agreement, and
            such breach: (i) is incapable of cure; or (ii) being capable of cure, remains uncured 30 days after the
            nonbreaching party provides the breaching party with written notice of such breach; (c) either party may
            terminate this Agreement, effective immediately upon written notice to the other party, if the other party:
            (i) becomes insolvent or is generally unable to pay, or fails to pay, its debts as they become due; (ii)
            files or has filed against it, a petition for voluntary or involuntary bankruptcy or otherwise becomes
            subject, voluntarily or involuntarily, to any proceeding under any domestic or foreign bankruptcy or
            insolvency Law; (iii) makes or seeks to make a general assignment for the benefit of its creditors; or (iv)
            applies for or has appointed a receiver, trustee, custodian or similar agent appointed by order of any court
            of competent jurisdiction to take charge of or sell any material portion of its property or business; (d)
            Igolgi may terminate this Agreement, at its sole discretion, when Customer has no Account Credits associated
            with the Customer’s account, without Notice. (e)Customer may terminate this Agreement, at its sole
            discretion, when Customer has no Account Credits, without Notice. (f) Igolgi may terminate this Agreement,
            at its sole discretion, when Customer has Account Credits associated with the Customer’s account by
            providing a 7 days’ notice of the Termination. Within 30 days of Termination, Igolgi must refund the Account
            Credits at a value equivalent to the US Dollar payment received for the Account Credits, (g) Customer may
            terminate this Agreement, at its sole discretion, when Customer has Account Credits by providing a 7 days’
            notice of the Termination. Within 30 days of Termination, Igolgi must refund the Account Credits at a value
            equivalent to the US Dollar payment received for the Account Credits; and (h) Any Service Credits will be
            cancelled upon Termination. Customer shall not be entitled to a cash value for any Service Credits upon any
            Termination event associated with this Agreement.</span></p>
            <p class="p1" style=""><span class="c3"> 11.4 Effect of Expiration or Termination</span><span class="c2"> </span>
            </p>
            <p class="p1" style=""><span class="c2">Upon any expiration or termination of this Agreement, except as expressly
            otherwise provided in this Agreement: (a) all rights, licenses, consents and authorizations granted by
            either party to the other hereunder will immediately terminate; (b) Igolgi shall immediately cease all use
            of any Customer Data or Customer's Confidential Information and (i) with 14 days return to Customer, or at
            Customer's written request destroy, all documents and tangible materials containing, reflecting,
            incorporating or based on Customer Data or Customer's Confidential Information; and (ii) permanently erase
            all Customer Data and Customer's Confidential Information from all systems Igolgi directly or indirectly
            controls; (c) Customer shall immediately cease all use of any Services or Igolgi Materials and (i) within 14
            days return to Igolgi, or at Igolgi's written request destroy, all documents and tangible materials
            containing, reflecting, incorporating or based on any Igolgi Materials or Igolgi's Confidential Information
            and (ii) permanently erase all Igolgi Materials and Igolgi's Confidential Information from all systems
            Customer directly or indirectly controls; and (iii) certify to Igolgi in a signed written instrument that it
            has complied with the requirements of this Section 11.4(c); (d) Igolgi may disable all Customer and
            Authorized User access to the Hosted Services and Igolgi Materials; (e) if Customer terminates this
            Agreement pursuant to Section 11.3(b), Customer will be relieved of any obligation to pay any Fees
            attributable to the period after the effective date of such termination and Igolgi will refund to Customer
            Fees paid in advance for Services that Igolgi has not performed as of the effective date of termination.
            Customer will not be entitled to receive payment for any unused Service Credits; (f) if Igolgi terminates
            this Agreement pursuant to Section 11.3(a) or Section 11.3(b), all Fees that would have become payable had
            the Agreement remained in effect until expiration of the Term will become immediately due and payable, and
            Customer shall pay such Fees, together with all previously-accrued but not yet paid Fees and Reimbursable
            Expenses, on receipt of Igolgi's invoice therefor.</span></p>
            <p class="p1 p16" style="">&nbsp;</p>
            <p class="p1 p16" style="">&nbsp;</p>
            <p class="p10 c1" style=""> 11.5 Surviving Terms </p>
            <p class="p1" style=""><span class="c2">The provisions set forth in the following sections, and any other right or
            obligation of the parties in this Agreement that, by its nature, should survive termination or expiration of
            this Agreement, will survive any expiration or termination of this Agreement: Section 3.3, Section 10,
            Section 11.4, this Section 11.5, Section 12, Section 13, Section 14 and Section 16.</span></p>
            <p class="p1" style=""><span class="c3"> 12 Representations and Warranties</span><span class="c2"> .</span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">12.1 Mutual Representations and Warranties </span>
            </p>
            <p class="p1" style=""><span class="c2">Each party represents and</span></p>
            <p class="p1" style=""><span class="c2"> warrants to the other party that: </span></p>
            <p class="p1" style=""><span class="c2">(a) it is duly organized, validly existing and in good standing as a
            corporation or other entity under the Laws of the jurisdiction of its incorporation or other organization;
            (b) it has the full right, power and authority to enter into and perform its obligations and grant the
            rights, licenses, consents and authorizations it grants or is required to grant under this Agreement; (c)
            the execution of this Agreement by its representative whose signature is set forth at the end of this
            Agreement has been duly authorized by all necessary corporate or organizational action of such party; and
            (d) when executed and delivered by both parties, this Agreement will constitute the legal, valid and binding
            obligation of such party, enforceable against such party in accordance with its terms.</span></p>
            <p class="p1" style=""><span class="c3">12.2 Additional Customer Representations, Warranties and
            Covenants</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c2"> Customer represents, warrants and covenants to Igolgi that Customer owns or
            otherwise has and will have the necessary rights and consents in and relating to the Customer Data so that,
            as received by Igolgi and Processed in accordance with this Agreement, they do not and will not infringe,
            misappropriate or otherwise violate any Intellectual Property Rights, or any privacy or other rights of any
            third party or violate any applicable Law. Notwithstanding the foregoing, to the extent Igolgi processes
            Customer Data protected by Data Protection Laws (as defined in the DPA) as a processor on Customer’s behalf,
            Customer agrees that Customer and Igolgi shall be subject to and comply with the DPA, which is incorporated
            into and forms an integral part of this Agreement. </span></p>
            <p class="p1" style=""><span class="c3">12.3 DISCLAIMER OF WARRANTIES</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c2">EXCEPT FOR THE EXPRESS WARRANTIES SET FORTH IN SECTION 12.1, SECTION 12.2,
            ALL SERVICES AND PROVIDER MATERIALS ARE PROVIDED &quot;AS IS&quot; AND PROVIDER HEREBY DISCLAIMS ALL
            WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHER, AND PROVIDER SPECIFICALLY DISCLAIMS ALL IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT, AND ALL
            WARRANTIES ARISING FROM COURSE OF DEALING, USAGE OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING, PROVIDER
            MAKES NO WARRANTY OF ANY KIND THAT THE SERVICES OR PROVIDER MATERIALS, OR ANY PRODUCTS OR RESULTS OF THE USE
            THEREOF, WILL MEET CUSTOMER'S OR ANY OTHER PERSON'S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY
            INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM OR OTHER SERVICES, OR BE SECURE, ACCURATE,
            COMPLETE, FREE OF HARMFUL CODE OR ERROR FREE. ALL THIRD-PARTY MATERIALS ARE PROVIDED &quot;AS IS&quot; AND
            ANY REPRESENTATION OR WARRANTY OF OR CONCERNING ANY THIRD-PARTY MATERIALS IS STRICTLY BETWEEN CUSTOMER AND
            THE THIRD-PARTY OWNER OR DISTRIBUTOR OF THE THIRD-PARTY MATERIALS.</span></p>
            <p class="p10 c1" style=""> 13 Indemnification .</p>
            <p class="p10 c1" style="">13.1. Customer Indemnification </p>
            <p class="p1" style=""><span class="c2"> Customer shall indemnify, defend and hold harmless Igolgi and its
            Subcontractors and Affiliates, and each of its and their respective officers, directors, employees, agents,
            successors and assigns (each, a &quot;Igolgi Indemnitee&quot;) from and against any and all Losses incurred
            by such Igolgi Indemnitee in connection with any Action by a third to the extent that such Losses,
            including, but not limited to, Intellectual Property Rights infringement, arise out of or relate to any: (a)
            Customer Data, including any Processing of Customer Data by or on behalf of Igolgi in accordance with this
            Agreement; (b) Resultant Data derived from Customer Data; (c) any other materials or information (including
            any documents, data, specifications, software, content or technology) provided by or on behalf of Customer
            or any Authorized User, including Igolgi's compliance with any specifications or directions provided by or
            on behalf of Customer or any Authorized User to the extent prepared without any contribution by Igolgi; (d)
            allegation of facts that, if true, would constitute Customer's breach of any of its representations,
            warranties, covenants or obligations under this Agreement; or (e) negligence or more culpable act or
            omission (including recklessness or willful misconduct) by Customer, any Authorized User, or any third party
            on behalf of Customer or any Authorized User, in connection with this Agreement. </span></p>
            <p class="p10 c1" style="">13.3 Indemnification Procedure </p>
            <p class="p1" style=""><span class="c2">Igolgi shall promptly notify the other party in writing of any Action for
            which such party believes it is entitled to be indemnified pursuant to Section 13.2, as the case may be. The
            party seeking indemnification (the &quot;Indemnitee&quot;) shall cooperate with the other party (the
            &quot;Indemnitor&quot;) at the Indemnitor's sole cost and expense. The Indemnitor shall immediately take
            control of the defense and investigation of such Action and shall employ counsel of its choice to handle and
            defend the same, at the Indemnitor's sole cost and expense. The Indemnitee's failure to perform any
            obligations under this Section 13.3 will not relieve the Indemnitor of its obligations under this Section 13
            except to the extent that the Indemnitor can demonstrate that it has been prejudiced as a result of such
            failure. The Indemnitee may participate in and observe the proceedings at its own cost and expense with
            counsel of its own choosing.</span></p>
            <p class="p10 c1" style=""> 13.4 Mitigation </p>
            <p class="p1" style=""><span class="c2">If any of the Services or Igolgi Materials are, or in Igolgi's opinion are
            likely to be, claimed to infringe, misappropriate or otherwise violate any third-party Intellectual Property
            Right, or if Customer's or any Authorized User's use of the Services or Igolgi Materials is enjoined or
            threatened to be enjoined, Igolgi may, at its option and sole cost and expense:(a)obtain the right for
            Customer to continue to use the Services and Igolgi Materials as contemplated by this Agreement;(b)modify or
            replace the Services and Igolgi Materials, in whole or in part, to seek to make the Services and Igolgi
            Materials (as so modified or replaced) non-infringing, while providing equivalent features and
            functionality, in which case such modifications or replacements will constitute Services and Igolgi
            Materials, as applicable, under this Agreement; or (c) by written notice to Customer, terminate this
            Agreement and require Customer to immediately cease any use of the Services and Igolgi Materials, provided
            that if such termination occurs prior to 1 year after the Effective Date, subject to Customer's compliance
            with its post-termination obligations set forth in Section 11.4, Customer will be entitled to a refund of
            only the Account Credits associated with the Customer’s Account. THIS SECTION 13 SETS FORTH CUSTOMER'S SOLE
            REMEDIES AND PROVIDER'S SOLE LIABILITY AND OBLIGATION FOR ANY ACTUAL, THREATENED OR ALLEGED CLAIMS THAT THIS
            AGREEMENT OR ANY SUBJECT MATTER HEREOF (INCLUDING THE SERVICES AND PROVIDER MATERIALS) INFRINGES,
            MISAPPROPRIATES OR OTHERWISE VIOLATES ANY THIRD-PARTY INTELLECTUAL PROPERTY RIGHT. </span></p>
            <p class="p1" style=""><span class="c3">14.Limitations of Liability</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c3"> 14.1 EXCLUSION OF DAMAGES</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c2">IN NO EVENT WILL PROVIDER OR ANY OF ITS LICENSORS, SERVICE PROVIDERS OR
            SUPPLIERS BE LIABLE UNDER OR IN CONNECTION WITH THIS AGREEMENT OR ITS SUBJECT MATTER UNDER ANY LEGAL OR
            EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY AND OTHERWISE,
            FOR ANY: (a) LOSS OF PRODUCTION, USE, BUSINESS, REVENUE OR PROFIT [OR DIMINUTION IN VALUE; (b) IMPAIRMENT,
            INABILITY TO USE OR LOSS, INTERRUPTION OR DELAY OF THE SERVICES, OTHER THAN FOR THE ISSUANCE OF ANY
            APPLICABLE SERVICE CREDITS PURSUANT TO SECTION 5.2, (c) LOSS, DAMAGE, CORRUPTION OR RECOVERY OF DATA, OR
            BREACH OF DATA OR SYSTEM SECURITY, OR (d) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED
            OR PUNITIVE DAMAGES, REGARDLESS OF WHETHER SUCH PERSONS WERE ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR
            DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED
            OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.</span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">14.2 CAP ON MONETARY LIABILITY</span><span
                class="c2"> </span></p>
            <p class="p1" style=""><span class="c2">IN NO EVENT WILL THE AGGREGATE LIABILITY OF PROVIDER UNDER OR IN CONNECTION
            WITH THIS AGREEMENT OR ITS SUBJECT MATTER, UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF
            CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY AND OTHERWISE, EXCEED THE AMOUNT OF $100. THE
            FOREGOING LIMITATION APPLIES NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL
            PURPOSE. </span></p>
            <p class="p10 c1" style="">15.Force Majeure</p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">15.1 No Breach or Default</span><span class="c2">
            </span></p>
            <p class="p1" style=""><span class="c2">In no event will either party be liable or responsible to the other party,
            or be deemed to have defaulted under or breached this Agreement,for any failure or delay in fulfilling or
            performing any term of this Agreement, (except for any payment obligation), when and to the extent such
            failure or delay is caused by any circumstances beyond such party's reasonable control (a &quot;Force
            Majeure Event&quot;), including acts of God, flood, fire, earthquake or explosion, war, terrorism, invasion,
            riot or other civil unrest, embargoes or blockades in effect on or after the date of this Agreement,
            national or regional emergency, strikes, labor stoppages or slowdowns or other industrial disturbances,
            passage of Law or any action taken by a governmental or public authority, including imposing an embargo,
            export or import restriction, quota or other restriction or prohibition or any complete or partial
            government shutdown, or national or regional shortage of adequate power or telecommunications or
            transportation. Either party may terminate this Agreement if a Force Majeure Event affecting the other party
            continues substantially uninterrupted for a period of 30 days or more.</span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">15.2 Affected Party Obligations</span><span
                class="c2"> </span></p>
            <p class="p1" style=""><span class="c2">In the event of any failure or delay caused by a Force Majeure Event, the
            affected party shall give prompt written notice to the other party stating the period of time the occurrence
            is expected to continue and use diligent efforts to end the failure or delay and minimize the effects of
            such Force Majeure Event. </span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">16. Miscellaneous</span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">16.1 Further Assurances </span></p>
            <p class="p1" style=""><span class="c2">Upon a party's reasonable request, the other party shall, at the requesting
            party's sole cost and expense, execute and deliver all such documents and instruments, and take all such
            further actions, necessary to give full effect to this Agreement.</span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">16.2 Relationship of the Parties</span></p>
            <p class="p1" style=""><span class="c2"> The relationship between the parties is that of independent contractors.
            Nothing contained in this Agreement shall be construed as creating any agency, partnership, joint venture or
            other form of joint enterprise, employment or fiduciary relationship between the parties, and neither party
            shall have authority to contract for or bind the other party in any manner whatsoever.</span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">16.3 Public Announcements </span></p>
            <p class="p1" style=""><span class="c2">Neither party shall issue or release any announcement, statement, press
            release or other publicity or marketing materials relating to this Agreement or otherwise use the other
            party's trademarks, service marks, trade names, logos, domain names or other indicia of source, affiliation
            or sponsorship, in each case, without the prior written consent of the other party, which consent shall not
            be unreasonably withheld, conditioned or delayed, provided, however, that Igolgi may, without Customer's
            consent, include Customer's name in its lists of Igolgi's current or former customers of Igolgi in
            promotional and marketing materials.</span></p>
            <p class="p10 c1" style=""> 16.4 Notices </p>
            <p class="p1" style=""><span class="c2">All notices, requests, consents, claims, demands, waivers and other
            communications under this Agreement have binding legal effect only if in writing and addressed to a party as
            follows (or to such other address or such other person that such party may designate from time to time in
            accordance with this Section 16.4): If to Igolgi: E-mail: </span><a
                href="mailto:file.support@igolgi.com"><span class="c9 underline">file.support@igolgi.com</span></a><span
                class="c2">, If to Customer: Customer’s Email Address on File. Notices sent in accordance with this Section
            16.4 will be deemed effectively given when sent, email, with confirmation of transmission, if sent during
            the addressee's normal business hours, and on the next business day, if sent after the addressee's normal
            business hours.</span></p>
            <p class="p10 c1" style="">16.5 Interpretation </p>
            <p class="p1" style=""><span class="c2">For purposes of this Agreement: (a) the words &quot;include,&quot;
            &quot;includes&quot; and &quot;including&quot; are deemed to be followed by the words &quot;without
            limitation&quot;; (b) the word &quot;or&quot; is not exclusive; (c) the words &quot;herein,&quot;
            &quot;hereof,&quot; &quot;hereby,&quot; &quot;hereto&quot; and &quot;hereunder&quot; refer to this Agreement
            as a whole; (d) words denoting the singular have a comparable meaning when used in the plural, and
            vice-versa; and (e) words denoting any gender include all genders. Unless the context otherwise requires,
            references in this Agreement: (x) to sections, exhibits, schedules, attachments and appendices mean the
            sections of, and exhibits, schedules, attachments and appendices attached to, this Agreement; (y) to an
            agreement, instrument or other document means such agreement, instrument or other document as amended,
            supplemented and modified from time to time to the extent permitted by the provisions thereof; and (z) to a
            statute means such statute as amended from time to time and includes any successor legislation thereto and
            any regulations promulgated thereunder. The parties intend this Agreement to be construed without regard to
            any presumption or rule requiring construction or interpretation against the party drafting an instrument or
            causing any instrument to be drafted. The exhibits, schedules, attachments and appendices referred to herein
            are an integral part of this Agreement to the same extent as if they were set forth verbatim herein.</span>
            </p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">16.6 Headings</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c2">The headings in this Agreement are for reference only and do not affect the
            interpretation of this Agreement.</span></p>
            <p class="p10 c1" style=""> 16.7 Entire Agreement </p>
            <p class="p1" style=""><span class="c2">This Agreement constitutes the sole and entire agreement of the parties with
            respect to the subject matter of this Agreement and supersedes all prior and contemporaneous understandings,
            agreements, representations and warranties, both written and oral, with respect to such subject matter. In
            the event of any inconsistency between the statements made in the body of this Agreement, the related
            exhibits, schedules, attachments and appendices, the following order of precedence governs: (a) first, this
            Agreement, excluding its exhibits, schedules, attachments and appendices; (b) second, the exhibits,
            schedules, attachments and appendices to this Agreement as of the Effective Date; and (c) third, any other
            documents incorporated herein by reference.</span></p>
            <p class="p10 c1" style=""> 16.8 Assignment </p>
            <p class="p1" style=""><span class="c2">Customer shall not assign or otherwise transfer any of its rights, or
            delegate or otherwise transfer any of its obligations or performance, under this Agreement, in each case
            whether voluntarily, involuntarily, by operation of law or otherwise, without Igolgi's prior written
            consent. For purposes of the preceding sentence, and without limiting its generality, any merger,
            consolidation or reorganization involving Customer (regardless of whether Customer is a surviving or
            disappearing entity) will be deemed to be a transfer of rights, obligations or performance under this
            Agreement for which Igolgi's prior written consent is required. No delegation or other transfer will relieve
            Customer of any of its obligations or performance under this Agreement. Any purported assignment, delegation
            or transfer in violation of this Section 16.8 is void. This Agreement is binding upon and inures to the
            benefit of the parties hereto and their respective permitted successors and assigns.</span></p>
            <p class="p10 c1" style=""> 16.9 No Third-party Beneficiaries </p>
            <p class="p1" style=""><span class="c2">This Agreement is for the sole benefit of the parties hereto and their
            respective permitted successors and permitted assigns and nothing herein, express or implied, is intended to
            or shall confer upon any other Person any legal or equitable right, benefit or remedy of any nature
            whatsoever under or by reason of this Agreement.</span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">16.10 Amendment and Modification; Waiver </span>
            </p>
            <p class="p1" style=""><span class="c2"> No amendment to or modification of, or rescission, termination or discharge
            of, this Agreement is effective unless it is in writing, identified as an amendment to, or rescission,
            termination or discharge of, this Agreement and signed by an authorized representative of each party. No
            waiver by any party of any of the provisions hereof shall be effective unless explicitly set forth in
            writing and signed by the party so waiving. Except as otherwise set forth in this Agreement, no failure to
            exercise, or delay in exercising, any rights, remedy, power or privilege arising from this Agreement shall
            operate or be construed as a waiver thereof; nor shall any single or partial exercise of any right, remedy,
            power or privilege hereunder preclude any other or further exercise thereof or the exercise of any other
            right, remedy, power or privilege.</span></p>
            <p class="p10 c1" style=""> 16.11 Severability</p>
            <p class="p1" style=""><span class="c2"> If any provision of this Agreement is invalid, illegal or unenforceable in
            any jurisdiction, such invalidity, illegality or unenforceability shall not affect any other term or
            provision of this Agreement or invalidate or render unenforceable such term or provision in any other
            jurisdiction. Upon such determination that any term or other provision is invalid, illegal or unenforceable,
            the parties hereto shall negotiate in good faith to modify this Agreement so as to effect the original
            intent of the parties as closely as possible in a mutually acceptable manner in order that the transactions
            contemplated hereby be consummated as originally contemplated to the greatest extent possible.</span></p>
            <p class="p1" style=""><span class="c3"> 16.12 Governing Law; Submission to Jurisdiction</span><span class="c2">
            </span></p>
            <p class="p1" style=""><span class="c2">This Agreement is governed by and construed in accordance with the internal
            laws of the State of DELAWARE without giving effect to any choice or conflict of law provision or rule that
            would require or permit the application of the laws of any jurisdiction other than those of the State of
            DELAWARE. Any legal suit, action or proceeding arising out of or related to this Agreement or the licenses
            granted hereunder shall be instituted in the federal courts of the United States or the courts of the State
            of DELAWARE, and each party irrevocably submits to the exclusive jurisdiction of such courts in any such
            suit, action or proceeding. Service of process, summons, notice or other document by mail to such party's
            address set forth herein shall be effective service of process for any suit, action or other proceeding
            brought in any such court. </span></p>
            <p class="p10 c1" style="">16.13 Equitable Relief </p>
            <p class="p1" style=""><span class="c2">Each party acknowledges and agrees that a breach or threatened breach by
            such party of any of its obligations under Section 10 or, in the case of Customer, Section 3.3, or Section
            4.3, would cause the other party irreparable harm for which monetary damages would not be an adequate remedy
            and agrees that, in the event of such breach or threatened breach, the other party will be entitled to
            equitable relief, including a restraining order, an injunction, specific performance and any other relief
            that may be available from any court, without any requirement to post a bond or other security, or to prove
            actual damages or that monetary damages are not an adequate remedy. Such remedies are not exclusive and are
            in addition to all other remedies that may be available at law, in equity or otherwise.</span></p>
            <p class="p10 c1" style="">16.14 Alternative Dispute Resolution </p>
            <p class="p1" style=""><span class="c2">Any disputes, controversies, or claims in connection with or arising out of
            this Agreement, its negotiation, existence, validity or termination, shall be referred to and finally
            determined by arbitration before a single arbitrator who is a member of the American Arbitration
            Association. Such arbitration shall be held in accordance with the Commercial Rules of the American
            Arbitration Association, with the governing law to be that of DELAWARE and the laws of the United States
            applicable therein. </span></p>
            <p class="p10 c1" style="">16.15 Authority </p>
            <p class="p1" style=""><span class="c2">Customer warrants that the user utilizing the services and entering into
            this agreement with the Igolgi on behalf of the Customer has all the necessary authority to enter into this
            agreement on behalf of the Customer.</span></p>
            <p class="p1" style=""><span class="c2">This Agreement (including the Igolgi Data Processing Addendum (the “DPA”)
            and Privacy Policy describing how Igolgi will collect, treat, and Process Customer Data protected by Data
            Protection Laws (as defined in the DPA)) constitutes a binding contract and defines the terms and conditions
            under which Customer is allowed to use the Services. By downloading or uploading any content from or to
            Igolgi’s servers, or otherwise using Services in any manner, you are agreeing to be bound by the terms of
            this Agreement. You must accept and abide by these terms as presented to you; changes, additions, or
            deletions are not acceptable, and Igolgi may refuse access to Services for noncompliance with any part of
            this agreement.</span></p>
            <p class="p1" style="">&nbsp;</p>
            <p class="p1 p17 c10" style="">DATA PROCESSING ADDENDUM</p>
            <p class="p1" style=""><span class="c2"> This Data Processing Addendum (this “DPA”) is incorporated into and
            supplements the Agreement between the Customer and Igolgi, Corp. (“Igolgi”), or other agreement between the
            Customer and Igolgi governing the Customer’s use of the Services (the “Agreement”) and reflects the parties’
            agreement with regard to the Processing of Customer Data. This DPA is an agreement between you and the
            entity you represent (the “Customer”) and Igolgi. In the course of providing the Services to the Customer
            pursuant to the Agreement, Igolgi may Process Customer Data (as defined below) on behalf of the Customer and
            the parties agree to comply with the following provisions with respect to any Customer Data, each acting
            reasonably and in good faith.</span></p>
            <p class="p10 c1" style=""> 1.Definitions</p>
            <p class="p1" style=""><span class="c2"> “</span><span class="c3">Adequacy Decision</span><span class="c2">” means a
            country, territory, or sector within a country which has been subject to a finding, and continues to be
            subject to a finding for the duration of this Agreement, of an adequate level of protection for Personal
            Data under the GDPR or UK GDPR as applicable to the Personal Data Processing activity.</span></p>
            <p class="p1" style=""><span class="c2"> “</span><span class="c3">Affiliate</span><span class="c2">” means an entity
            that directly or indirectly controls, is controlled by or is under common control with an entity, where
            “control” means, for the purposes of this definition, an ownership, voting, or similar interest representing
            fifty percent (50%) or more of the total interests then outstanding of the entity in question.</span></p>
            <p class="p1" style=""><span class="c2"> “</span><span class="c3">Agreement</span><span class="c2">” means Igolgi’s
            Website Use Agreement, or other written electronic agreement, which govern the provision of the Services to
            the Customer, as such terms or agreement may be updated from time to time.</span></p>
            <p class="p1" style=""><span class="c2"> “</span><span class="c3">Customer Data</span><span class="c2">” means any
            Personal Data that Igolgi Processes on behalf of the Customer via the Services, as more particularly
            described in this DPA.</span></p>
            <p class="p1" style=""><span class="c2"> “</span><span class="c3">Data Breach</span><span class="c2">” means a
            breach of security leading to the accidental or unlawful destruction, loss, alteration, or unauthorized
            disclosure of, or access to, Customer Data.</span></p>
            <p class="p1" style=""><span class="c2"> “</span><span class="c3">Data Controller</span><span class="c2">” means the
            natural or legal person, public authority, agency, or any other body which alone or jointly with others
            determines the purposes and means of the Processing of Personal Data.</span></p>
            <p class="p1" style=""><span class="c2"> “</span><span class="c3">Data Processor</span><span class="c2">” means any
            natural or legal person, public authority, agency, or any other body which Processes Personal Data on behalf
            of a Data Controller or on the instruction of another Data Processor acting on behalf of a Data
            Controller.</span></p>
            <p class="p1" style=""><span class="c2"> “</span><span class="c3">Data Protection Laws</span><span class="c2">”
            means all applicable laws and regulations relating to the Processing of Personal Data and privacy that may
            exist in the relevant jurisdictions, including, where applicable, Regulation 2016/679 of the European
            Parliament and of the Council on the protection of natural persons with regard to the processing of personal
            data and on the free movement of such data (General Data Protection Regulation) (the “GDPR”); Directive
            2002/58/EC concerning the processing of personal data and the protection of privacy in the electronic
            communications sector; the Data Protection Act 2018 and the Data Protection, Privacy and Electronic
            Communications (Amendment Etc.) (EU Exit) Regulations 2019 (the “UK GDPR”); the California Consumer Privacy
            Act (“CCPA”); the Canadian Personal Information Protection and Electronic Documents Act (“PIPEDA”); the
            Brazilian General Data Protection Law (“LGPD”), Federal Law no. 13,709/2018; the Privacy Act 1988 (“Cth”) of
            Australia, as amended (“Australian Privacy Law”); and any other privacy laws or regulations applicable to
            the Processing of Customer Data under the Agreement.</span></p>
            <p class="p1" style=""><span class="c2">“</span><span class="c3">Data Subject</span><span class="c2">” means an
            identified or identifiable natural person whom Personal Data relates. “EU Standard Contractual Clauses”
            means: (a) the standard contractual clauses adopted by the European Commission on 4th June 2021 for the
            transfer of Personal Data to third countries pursuant to the EU GDPR and where “MODULE TWO: Transfer
            controller to processor” therein is selected and applies where relevant; or (b) such other standard
            contractual clauses that are approved by the European Commission for Controller to Processor transfers of EU
            Personal Data to a third country which has not received an Adequacy Decision (and are subsequently
            incorporated into this Agreement); “Personal Data” means any information relating to an identified or
            identifiable living individual, including information that can be linked, directly or indirectly, with a
            particular Data Subject.</span></p>
            <p class="p1" style=""><span class="c2"> “</span><span class="c3">Process</span><span class="c2">”, “</span><span
                class="c3">Processing</span><span class="c2">” or “</span><span class="c3">Processed</span><span
                class="c2">” means any operation or set of operations which is performed upon Customer Data whether or not
            by automated means, according to the definitions given to such terms in the GDPR.</span></p>
            <p class="p1" style=""><span class="c2"> “</span><span class="c3">Restricted Transfer</span><span class="c2">” means
            a transfer of Personal Data to a country, a territory, or specified sector within a country that (but for
            the operation of this DPA): (a) has not been granted an Adequacy Decision; or (b) is not subject to any
            safeguards or derogations that would permit the transfer of the Personal Data to the country, territory, or
            sector in accordance with the GDPR or UK GDPR (as applicable to the Personal Data transfer).</span></p>
            <p class="p1" style=""><span class="c2"> “</span><span class="c3">Sensitive Data</span><span class="c2">” means any
            Personal Data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or
            trade union membership, genetic data, or biometric data for the purpose of uniquely identifying a natural
            person, data concerning health or a person’s sex life or sexual orientation, or data relating to criminal
            convictions and offences.</span></p>
            <p class="p1" style=""><span class="c2"> “</span><span class="c3">Services</span><span class="c2">” means all
            services provided by Igolgi in accordance with, and as defined in, the Agreement.</span></p>
            <p class="p1" style=""><span class="c2"> “</span><span class="c3">Standard Contractual Clauses</span><span
                class="c2">” means (a) the EU Standard Contractual Clauses, (b) UK Standard Contractual Clauses, or (c) such
            other standard contractual clauses that are approved under applicable Data Protection Laws and apply to a
            Restricted Transfer of Personal Data under this Agreement.</span></p>
            <p class="p1" style=""><span class="c2"> “</span><span class="c3">Sub-processor</span><span class="c2">” means any
            sub-contractor engaged in the Processing of Customer Data in connection with the Services.</span></p>
            <p class="p1" style=""><span class="c2"> “</span><span class="c3">Supervisory Authority</span><span class="c2">”
            means any regulatory, supervisory, governmental, or other competent authority with jurisdiction or oversight
            over compliance with the Data Protection Laws.</span></p>
            <p class="p1" style=""><span class="c2"> “</span><span class="c3">UK Standard Contractual Clauses</span><span
                class="c2">” means: (a) the standard contractual clauses approved by the European Commission for the
            transfer of Personal Data from a Controller to a Processor (document reference number 2010/87/EU) for which
            references to the Directive 95/46/EC of 24 October 1995 therein shall be deemed to be replaced with
            respective provisions of the UK GDPR; or (b) such other standard contractual clauses that are approved under
            the UK GDPR for the transfer of UK Personal Data to a third country which has not received a UK Adequacy
            Decision (and are subsequently incorporated into this Agreement).</span></p>
            <p class="p1" style=""><span class="c3"> 2. Appointment and Data Processing</span></p>
            <p class="p1" style=""><span class="c2">2.1 Subject to the terms of the Agreement, the Customer is the sole Data
            Controller of the Customer Data or has been instructed by and obtained the authorization of the relevant
            Data Controller(s) to enter into this DPA in the name and on behalf of such Data Controller(s). The Customer
            is responsible for obtaining all of the necessary authorizations and approvals to enter, use, provide,
            store, and Process Customer Data to enable Igolgi to provide the Services.</span></p>
            <p class="p1" style=""><span class="c2"> 2.2 The Customer, as the Data Controller, hereby appoints Igolgi as the
            Data Processor in respect of all Processing operations required to be carried out by Igolgi on Customer Data
            in order to provide the Services in accordance with the terms of the Agreement.</span></p>
            <p class="p1" style=""><span class="c2"> 2.3 Igolgi shall Process Customer Data, as further described in Annex A of
            this DPA, only in accordance with the Customer’s documented lawful instructions as set forth in this DPA, as
            necessary to comply with applicable law, or as otherwise agreed in writing. The parties agree that the
            Agreement, including this DPA, along with the Customer’s configuration of or use of any settings, features,
            or options in the Service (as the Customer may be able to modify from time to time) constitute the
            Customer’s complete and final instructions to Igolgi in relation to the Processing of Customer Data
            (including for the purposes of the Standard Contractual Clauses), and Processing outside the scope of these
            instructions (if any) shall require prior written agreement between the parties.</span></p>
            <p class="p1" style=""><span class="c2"> 2.4 The Customer will ensure that Igolgi’s Processing of the Customer Data
            in accordance with the Customer’s instructions will not cause Igolgi to violate any applicable law,
            regulation, or rule, including, without limitation, Data Protection Laws. Igolgi shall promptly notify the
            Customer in writing, unless prohibited from doing so under Data Protection Laws, if it becomes aware or
            believes that any data Processing instruction from the Customer violates Data Protection Laws.</span></p>
            <p class="p1" style=""><span class="c2"> 2.5 The Customer will not provide (or cause to be provided) any Sensitive
            Data to Igolgi for Processing under the Agreement, and Igolgi will have no liability whatsoever for
            Sensitive Data, whether in connection with a Data Breach or otherwise. For the avoidance of doubt, this DPA
            will not apply to Sensitive Data.</span></p>
            <p class="p1" style=""><span class="c2"> 2.6 The Customer represents and warrants that (a) on an ongoing basis,
            there is, and there will be throughout the term of the Agreement, a legal basis for the Processing by Igolgi
            of the Customer Data on behalf of the Customer in accordance with this DPA and the Agreement (including all
            Documented Instructions issued by the Customer from time to time in respect of such Processing); and (b) it
            will honor the rights of Data Subjects pursuant to Data Protection Laws. The Customer shall have sole
            responsibility for the accuracy, quality, and legality of Customer Data and the means by which the Customer
            acquired Customer Data. </span></p>
            <p class="p1" style=""><span class="c2">2.7 Except as expressly set forth in the Agreement and in this DPA, Igolgi
            will not collect, use, retain, disclose, sell, or otherwise make Customer Data available for Igolgi’s own
            commercial purposes.</span></p>
            <p class="p1" style=""><span class="c2"> 2.8 Igolgi will maintain complete, accurate, and up to date written records
            of all Processing activities carried out on behalf of the Customer containing information required under any
            applicable Data Protection Laws.</span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">3. Sub-processors.</span></p>
            <p class="p1" style=""><span class="c2"> 3.1 The Customer acknowledges and agrees that Igolgi may engage
            Sub-processors to Process Customer Data on the Customer’s behalf. Igolgi has entered into and will maintain
            a written agreement with each Sub-processor containing data protection obligations not less protective than
            those in this DPA with respect to the protection of Customer Data to the extent applicable to the nature of
            the services provided by such Sub-processor.</span></p>
            <p class="p1" style=""><span class="c2">3.2 The Sub-processors currently engaged by Igolgi and authorized by the
            Customer, as of the execution of this DPA, are available here:</span></p>
            <p class="p1" style=""><span class="c2"> </span><a
                href="https://streamengine.igolgi.com/sub-processors-list.pdf"><span
                    class="c9 underline">https://streamengine.igolgi.com/sub-processors-list.pdf</span></a><span
                class="c2">.</span></p>
            <p class="p1" style=""><span class="c2"> Igolgi shall notify the Customer of any proposed amendment(s) to the list
            of the Sub-processors (including any addition or any replacement to the list). The Customer shall notify
            Igolgi within thirty (30) days of the date of its receipt of Igolgi’s notice whether it accepts the
            amendment(s) to the list of Sub-processors or whether it has any objections, in which case, the parties will
            meet to discuss the Customer’s objections, acting reasonably and in good faith. If Igolgi cannot reasonably
            accommodate a solution to the Customer’s objection, then the Customer may terminate the Agreement and this
            DPA, by notice to Igolgi. If the Customer does not object to the proposed change(s) within thirty (30) days
            of the date of its receipt of Igolgi’s, notice, then the amendment(s) proposed in the notice and the use of
            the new Sub-processor will be deemed accepted by the Customer.</span></p>
            <p class="p1" style=""><span class="c2"> 3.3 Igolgi will remain responsible for any acts or omissions of its
            Sub-processors to the same extent that Igolgi would be liable if performing the Services of each
            Sub-processor directly under the terms of this DPA.</span></p>
            <p class="p10 c1" style=""> 4. Authorized Personnel</p>
            <p class="p1" style=""><span class="c2">Igolgi shall ensure that all persons authorized to Process Customer Data are
            made aware of the confidential nature of Customer Data and have committed themselves to confidentiality
            (e.g., by confidentiality agreements) or are under an appropriate statutory obligation of
            confidentiality.</span></p>
            <p class="p10 c1" style=""> 5. Security Responsibilities.</p>
            <p class="p1" style=""><span class="c2"> 5.1 Taking into account the state of the art, the costs of implementation
            and the nature, scope, context and purposes of Processing as well as the risk of varying likelihood and
            severity for the rights and freedoms of natural persons, Igolgi shall maintain appropriate technical and
            organizational measures to ensure a level of security appropriate to the risk, including, but not limited
            to, the security measures attached hereto in Annex B (the “Security Measures”).</span></p>
            <p class="p1" style=""><span class="c2"> 5.2 Igolgi shall implement such measures to ensure a level of security
            appropriate to the risk involved, including as appropriate: (a) the pseudonymization and encryption of
            Customer Data; (b) the ability to ensure the ongoing confidentiality, integrity, availability and resilience
            of Processing systems and services; (c) the ability to restore the availability and access to Customer Data
            in a timely manner in the event of a physical or technical incident; and (d) a Process for regularly
            testing, assessing, and evaluating the effectiveness of security measures.</span></p>
            <p class="p1" style=""><span class="c2"> 5.3 The Customer is responsible for reviewing the information made
            available by Igolgi relating to data security and making an independent determination as to whether the
            Service meets the Customer’s requirements and legal obligations under Data Protection Laws. The Customer
            acknowledges that the Security Measures are subject to changes, from time to time, to reflect technological
            developments and industry best practices; provided, always, that such changes do not result in any objective
            degradation to the security of Customer Data, the manner in which the Services are provided, or which fall
            below the standard of any applicable law.</span></p>
            <p class="p1" style=""><span class="c2"> 5.4 Notwithstanding the above, the Customer agrees that except as provided
            by this DPA, Customer is responsible for its secure use of the Services, including securing its account
            authentication credentials (if and as applicable), protecting the security of Customer Data when in transit
            to and from the Services, and taking any appropriate steps to securely encrypt or backup any Customer Data
            uploaded to Igolgi in connection with the Services.</span></p>
            <p class="p1" style=""><span class="c2"> 6. Data Breach Provisions.</span></p>
            <p class="p1" style=""><span class="c2">6.1 If Igolgi becomes aware of a Data Breach, Igolgi shall, without undue
            delay, (a) notify the Customer of the Data Breach; and (b) take reasonable steps to mitigate the effects and
            to minimize any damage resulting from the Data Breach.</span></p>
            <p class="p1" style=""><span class="c2"> 6.2 In the event of a Data Breach, Igolgi shall provide the Customer with
            all reasonable assistance in dealing with the Data Breach, in particular in relation to making any
            notification to a Supervisory Authority or any communication to Data Subject. In order to provide such
            assistance, and taking into account the nature of the Services and the information available to Igolgi, the
            notification of the Data Breach shall include, at a minimum, the following:</span></p>
            <p class="p1" style=""><span class="c2"> (a) A description of the nature of the Data Breach including the categories
            and</span></p>
            <p class="p1" style=""><span class="c2"> approximate number of data records concerned;</span></p>
            <p class="p1" style=""><span class="c2"> (b) The likely consequences of the Data Breach; and</span></p>
            <p class="p1" style=""><span class="c2"> (c) The measures taken or to be taken by Igolgi to address the Data Breach,
            including</span></p>
            <p class="p1" style=""><span class="c2"> measures to mitigate any possible adverse consequences; and</span></p>
            <p class="p1" style=""><span class="c2"> 6.3 Where, and insofar as, it is not possible for Igolgi to provide such
            information at the time of the notice, then such notice shall nevertheless be made, in as complete a form as
            possible, and the remaining required information may be provided by Igolgi, in phases and as it shall become
            available, without undue delay.</span></p>
            <p class="p1" style=""><span class="c2"> 6.4 The Customer agrees that Igolgi’s obligation to report or respond to a
            Data Breach under this Section is not and will not be construed as an acknowledgement by Igolgi of any fault
            or liability of Igolgi with respect to the Data Breach.</span></p>
            <p class="p1" style=""><span class="c2"> 7. Data Quality, Retrieval, Return, and Deletion.</span></p>
            <p class="p1" style=""><span class="c2"> 7.1 Igolgi will update, correct, or delete Customer Data on the Customer’s
            request. Igolgi will not collect or Process Customer Data beyond what is necessary to comply with the
            Customer’s instructions and perform the Services on the Customer’s behalf.</span></p>
            <p class="p1" style=""><span class="c2"> 7.2 Upon termination of the Agreement (in whole or in part) or earlier upon
            the Customer’s request, and at Customer’s choice, Igolgi will, unless any applicable law, competent court,
            Supervisory Authority, or regulatory body prevents Igolgi from returning or destroying Customer Data: (a)
            destroy all Customer Data Processed and any copies thereof and certify to the Customer on request that
            Igolgi has done so; or (b) if requested by the Customer, return all Customer Data Processed and the copies
            thereof to the Customer or another recipient identified by the Customer. If the Customer does not request
            the return of Customer Data within thirty (30) days following termination of the Agreement, Igolgi shall
            destroy all Customer Data in accordance with Section 7.2(a) above.</span></p>
            <p class="p1" style=""><span class="c2"> 7.3 On request from the Customer, Igolgi will provide a portable copy of
            the Customer Data in accordance with the Data Protection Laws with respect to Personal Data.</span></p>
            <p class="p10 c1" style=""> 8. Audits.</p>
            <p class="p1" style=""><span class="c2"> 8.1 At the Customer’s written request, Igolgi will, not more than once
            annually, allow an audit to verify Igolgi’s compliance with obligations under Data Protection Laws and
            thiDPA, to be carried out either (a) by an independent third party audit firm bound by a duty of
            confidentiality selected by the Customer and approved by Igolgi (which approval will not unreasonably be
            withheld or delayed) and where applicable, in agreement with the competent Supervisory Authority, or (b) by
            a competent government authority. The audit will be carried out in close cooperation with Igolgi’s Data
            Protection Officer or Chief Information Security Officer. The parties will agree on thescope of the audit in
            advance. The Customer will notify Igolgi in writing with a minimum of 15 business days (in the country where
            the audit will be conducted) prior to any audit being carried out.</span></p>
            <p class="p1" style=""><span class="c2"> The Customer will bear the costs of the audit unless the audit uncovers
            compliance deficits that are material, in which case Igolgi will reimburse the Customer for the costs of the
            audit. If the Customer requests Igolgi to incur out-of-pocket costs to assist the Customer in the audit,
            then Igolgi is entitled to a reasonable, pre-approved reimbursement for its costs of the audit incurred by
            Igolgi, to be paid by the Customer only if the audit does not uncover compliance deficits that are
            material.</span></p>
            <p class="p1" style=""><span class="c2"> 8.2 Igolgi will monitor and self-audit its own compliance with its
            obligations under Data Protection Laws and this DPA and will provide the Customer, upon written request,
            with periodic reports, no more than once annually, unless a prior audit revealed non-compliance or more
            frequent audits are required by law or a regulatory body.</span></p>
            <p class="p1" style=""><span class="c2"> 8.3 In addition to the foregoing, Igolgi shall respond to all reasonable
            requests for information made by the Customer to confirm Igolgi’s compliance with this DPA, including
            responses to information security, due diligence, and audit questionnaires, by making additional information
            available regarding its information security program upon the Customer’s written request to Igolgi, provided
            that the Customer shall not exercise this right more than once annually.</span></p>
            <p class="p1" style=""><span class="c3"> 9. Assistance on Data Protection Impact Assessment</span><span class="c2">.
            </span></p>
            <p class="p1" style=""><span class="c2">To the extent required under applicable Data Protection Laws, Igolgi will
            (taking into account the nature of the Processing and the information available to Igolgi) provide all
            reasonably requested information regarding the Services to enable the Customer to carry out data protection
            impact assessments or prior consultations with any Supervisory Authorities, as required by such Data
            Protection Laws. Igolgi shall comply with the foregoing by: (a) complying with Section 8; (b) providing the
            information contained in the Agreement, including this DPA; and (c) if the foregoing clauses (a) and (b) are
            insufficient for the Customer to comply with such obligations, upon request, providing additional reasonable
            assistance (at the Customer’s expense).</span></p>
            <p class="p10 c1" style=""> 10. International Transfers.</p>
            <p class="p1" style=""><span class="c2"> 10.1 The Customer acknowledges that Igolgi may transfer and Process
            Customer Data to and in the United States and anywhere else in the world where Igolgi or its Sub-processors
            maintain Processing operations. Igolgi will, at all times, ensure that such transfers are made in compliance
            with the requirements of all applicable Data Protection Laws.</span></p>
            <p class="p1" style=""><span class="c2"> 10.2 The EU Standard Contractual Clauses shall apply to the transfer of
            Customer Data from the Customer acting the data exporter to Igolgi acting as the data importer where the
            transfer of Customer Data is a Restricted Transfer to which the EU GDPR applies. The information required
            for the purposes of the Annexes of the EU Standard Contractual Clauses is provided in Annex A and Annex B
            respectively. For the purposes of the EU Standard Contractual Clauses, the laws of Ireland shall
            govern.</span></p>
            <p class="p1" style=""><span class="c2"> 10.3 The UK Standard Contractual Clauses shall apply to the transfer of
            Customer Data from the Customer acting the data exporter to Igolgi acting as the data importer where the
            transfer of Customer Data is a Restricted Transfer to which the UK GDPR applies. The information required
            for the purposes of the Appendices of the UK Standard Contractual Clauses is provided in Annex A and Annex B
            respectively. For the purposes of the UK Standard Contractual Clauses, the laws of England and Wales shall
            govern.</span></p>
            <p class="p1" style=""><span class="c2"> 10.4 The parties agree and acknowledge the applicable Standard Contractual
            Clauses are incorporated into this Agreement, without further need for reference, incorporation, or
            attachment and that by accepting or by executing the Agreement the parties are deemed to have executed the
            applicable Standard Contractual Clauses. The parties agree that the applicable Standard Contractual. Clauses
            shall be directly binding between Igolgi as the data importer (as defined therein) and the Customer as the
            data exporter (as defined therein) in relation to such Processing.</span></p>
            <p class="p1" style=""><span class="c2"> 10.5 To the extent Igolgi adopts another lawful mechanism for the transfer
            of Customer Data that is not described in this DPA (“Alternative Transfer Mechanism”), the Alternative
            Transfer Mechanism will apply instead of the transfer mechanisms described in this DPA (but only to the
            extent such Alternative Transfer Mechanism is approved by the appropriate Supervisory Authority). In
            addition, if and to the extent that a court of competent jurisdiction or Supervisory Authority orders (for
            whatever reason) that the measures described in this DPA cannot be relied on to lawfully transfer Customer
            Data (within the meaning of applicable Data Protection Laws), Igolgi may implement any additional measures
            or safeguards that may be reasonably required to enable the lawful transfer of Customer Data.</span></p>
            <p class="p10 c1" style=""> 11. Data Subject Requests and Other Communications.</p>
            <p class="p1" style=""><span class="c2"> 11.1</span></p>
            <p class="p1" style=""><span class="c2"> Igolgi shall, to the extent required by the Data Protection Laws, promptly
            notify the Customer upon receipt of a request by a Data Subject to exercise Data Subject rights under the
            applicable Data Protection Laws. Igolgi will advise the Data Subject to submit their request to the Customer
            and the Customer will be responsible for responding to such request, including, where necessary, by using
            the functionality of the Services.</span></p>
            <p class="p1" style=""><span class="c2"> 11.2 Igolgi shall, taking into account the nature of the Processing, assist
            the Customer fulfilment of the Customer’s obligation to respond to requests for exercising the Data
            Subject’s rights (regarding information, access, rectification and erasure, restriction of Processing,
            notification, data portability, objection and automated decision-making) under the Data Protection
            Laws.</span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">12 Permitted Disclosures of Customer Data.</span>
            </p>
            <p class="p1" style=""><span class="c2"> 12.1 Igolgi may disclose Customer Data to the extent such data is required
            to be disclosed by law, by any government or Supervisory Authority, or by a valid and binding order of a law
            enforcement agency (such as a subpoena or court order), or other authority of competent jurisdiction.</span>
            </p>
            <p class="p1" style=""><span class="c2"> 12.2 If any law enforcement agency government or Supervisory Authority
            sends Igolgi a demand for disclosure of the Customer Data, then Igolgi will attempt to redirect the law
            enforcement agency, government, or Supervisory Authority to request that data directly from the Customer and
            Igolgi is entitled to provide the Customer’s basic contact information to such law enforcement agency,
            government, or Supervisory Authority.</span></p>
            <p class="p1" style=""><span class="c2"> 12.3 If compelled to disclose Customer Data pursuant to Section 12.1, then
            Igolgi will give the Customer reasonable notice of the demand to allow the Customer to seek a protective
            order or other appropriate remedy.</span></p>
            <p class="p1" style=""><span class="c3"> 13. CCPA</span><span class="c2">. </span></p>
            <p class="p1" style=""><span class="c2">For purposes of the CCPA, the definitions of: “Data Controller” includes
            “Business”; “Data Processor” includes “Service Provider”; “Data Subject” includes “Consumer”; “Personal
            Data” includes “Personal Information”; in each case as defined under the CCPA. Igolgi is a Service Provider
            and Customer is a Business. Igolgi, as a Service Provider, will not (a) Sell Customer Data (as the term is
            respectively defined in the CCPA), or (b) retain, use, or disclose Customer Data for any purposes other than
            for the specific purposes set forth in the Agreement and this DPA. For the avoidance of doubt, Igolgi will
            not Process Customer Data outside of the direct business relationship between Igolgi and Customer. Igolgi
            hereby certifies that it understands the restrictions and obligations set forth in this Section and will
            comply with them.</span></p>
            <p class="p10 c1" style="">14. Liability; Limitations</p>
            <p class="p1" style=""><span class="c2"> Each party’s and all of its Affiliates’ liability taken together in the
            aggregate arising out of or related to this DPA shall be subject to the exclusions and limitations of
            liability set forth in the Agreement to the extent permitted by applicable Data Protection Laws. Any claims
            made against Igolgi or its Affiliates under or in connection with this DPA shall be brought solely by the
            Customer entity that is a party to the Agreement. In no event shall any party limit its liability with
            respect to any individual's data protection rights under this DPA or otherwise.</span></p>
            <p class="p1" style=""><span class="c3"> 15. Relationship with the Agreement</span></p>
            <p class="p1" style=""><span class="c2"> 15.1 This DPA shall remain in effect for as long as Igolgi carries out
            Customer Data</span></p>
            <p class="p1" style=""><span class="c2"> Processing operations on behalf of the Customer or until termination of the
            Agreement (and all Customer Data has been returned or deleted in accordance with Section 7 above).</span>
            </p>
            <p class="p1" style=""><span class="c2"> 15.2 This DPA supersedes and replaces all prior representations,
            understandings, communications, and agreements by and between the Parties in relation to Customer Data and
            the matters set forth in this DPA.</span></p>
            <p class="p1" style=""><span class="c2"> 15.3 In the event of any conflict or inconsistency between this DPA and the
            Agreement, the provisions of the following documents (in order of precedence) shall prevail: (a) the
            Standard Contractual Clauses; then (b) this DPA; and then (c) the Agreement.</span></p>
            <p class="p1" style=""><span class="c2"> 15.4 Except for any changes made by this DPA, the Agreement remains
            unchanged and in full force and effect.</span></p>
            <p class="p1" style=""><span class="c2"> 15.5 No one other than a Party to this DPA, its successors, and permitted
            assignees shall have any right to enforce any of its terms.</span></p>
            <p class="p1" style=""><span class="c2"> 15.6 This DPA shall be governed by and construed in accordance with the
            governing law and jurisdiction provisions in the Agreement, unless required otherwise by applicable Data
            Protection Laws.</span></p>
            <p class="p1 p2 c1" style="">ANNEX A</p>
            <p class="p1 p18" style=""><span class="c2">DETAILS OF DATA PROCESSING</span></p>
            <p class="p1" style=""><span class="c2"> Subject Matter: The subject matter of the Processing under this DPA is
            Customer Data. Duration of the Processing: Igolgi will Process Customer Data for the term of the Agreement
            plus the period until Igolgi deletes all Customer Data in accordance with the Agreement or as otherwise
            required by applicable law.</span></p>
            <p class="p1" style=""><span class="c2"> Nature and Purpose of the Processing: The nature and purpose of the
            transfer and Processing of Customer Data is to (a) provide the Services to Customer and fulfil Igolgi’s
            obligations under the Agreement; (b) provide customer support to Customer and its end users; and (c)
            compliance with applicable law.</span></p>
            <p class="p1" style=""><span class="c2"> Categories of Data Subjects: The Customer Data transferred concern
            individuals about whom Personal Data is provided to Igolgi via the Services by (or at the direction of) the
            Customer or the Customer’s end users. </span></p>
            <p class="p1" style=""><span class="c2">Categories of Personal Data: The data transferred is the Customer Data
            uploaded, submitted, or otherwise provided to Igolgi by the Customer in connection with the Services, the
            extent of which is typically determined and controlled by the Customer in its sole discretion.</span></p>
            <p class="p1" style=""><span class="c2"> Sensitive Data: The Customer will not provide (or cause to be provided) any
            Sensitive Data to Igolgi for Processing under the Agreement.</span></p>
            <p class="p1" style=""><span class="c2"> Frequency of the Transfers: The frequency of the transfer of Customer Data
            will be on a continuous basis for the duration of the Agreement. Subject Matter, Nature, and Duration of
            Processing by Sub-processors: The subject matter and nature of the Processing by Sub-processors are
            specified in Annex A. The duration of the Processing carried out by Sub-processors will be until the
            termination or expiration of the Agreement or as requested by Customer.</span></p>
            <p class="p1" style=""><span class="c2"> Competent Supervisory Authority: The Customer’s competent Supervisory
            Authority will be determined in accordance with the GDPR.</span></p>
            <p class="p1 p2 c1" style="">ANNEX B</p>
            <p class="p1 p18" style=""><span class="c2">SECURITY MEASURES</span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">1. Servers and Networking</span><span
                class="c2">.</span></p>
            <p class="p1" style=""><span class="c2"> 1.1 All servers that run Igolgi software in production are recent,
            continuously patched Linux systems. Additional hosted services that we utilize, such as are comprehensively
            hardened.</span></p>
            <p class="p1" style=""><span class="c2"> 1.2 Igolgi’s web servers encrypt data in transit using the strongest grade
            of HTTPS security (TLS 1.2) so that requests are protected from eavesdroppers and man-in-the-middle attacks.
            Our SSL certificates are 2048 bit RSA, signed with SHA256.</span></p>
            <p class="p1" style=""><span class="c2"> 1.3 Internal tier-to-tier requests are signed and authenticated to prevent
            request forgery, tampering, and replay.</span></p>
            <p class="p10 c1" style=""> 2. Storage</p>
            <p class="p1" style=""><span class="c2">All persistent data is encrypted at rest using the AES-128 standards or
            similarly high standards.</span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">3. Employee Equipment and Employee Access</span>
            </p>
            <p class="p1" style=""><span class="c2">3.1 Igolgi employee computers have strong passwords, encrypted disks, and
            firewalls. Igolgi follows the principle of least privilege in how it writes software as well as the level of
            access employees are instructed to utilize in diagnosing and resolving problems in the Igolgi software and
            in response to customer support requests.</span></p>
            <p class="p1" style=""><span class="c2"> 3.3 Access to administrative interfaces additionally enforce administrator
            permissions where applicable, and all administrative access is logged and auditable both in the form of
            traditional web server logs as well as via Igolgi itself to make it easy to find and review any
            administrative activities with full fidelity.</span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">4. Code Reviews and Production Signoff.</span></p>
            <p class="p1" style=""><span class="c2"> 4.1 All changes to source code destined for production systems are subject
            to pre-commit code review by a qualified engineering peer that includes security, performance, and
            potential-for-abuse analysis.</span></p>
            <p class="p1" style=""><span class="c2"> 4.2 Prior to updating production services, all contributors to the updated
            software version are required to approve that their changes are working as intended on staging
            servers.</span></p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">5. Service Levels, Backups, and Recovery</span></p>
            <p class="p1" style=""><span class="c2"> Igolgi’s infrastructure utilizes multiple and layered techniques for
            increasingly reliable uptime, including the use of autoscaling, load balancing, task queues and rolling
            deployments. Due to the very large amount of data that Igolgi stores, Igolgi does not currently make
            point-in-time backups, although Igolgi does use highly redundant data stores and/or rapid recovery
            infrastructure, making unintentional loss of received data due to hardware failures very unlikely.</span>
            </p>
            <p class="p1" style=""><span class="c2"> </span><span class="c3">6. Product Security</span></p>
            <p class="p1" style=""><span class="c2"> 6.1 Product security is of paramount importance at Igolgi. Igolgi uses a
            software development lifecycle in line with general Agile principles. When security effort is applied
            throughout the Agile release cycle, security-oriented software defects are able to be discovered and
            addressed more rapidly than in longer release cycle development methodologies. Software patches are released
            as part of Igolgi’s continuous integration process. Patches that can impact end users will be applied as
            soon as possible but may necessitate end user notification and scheduling a service window.</span></p>
            <p class="p1" style=""><span class="c2"> 6.2 Igolgi performs continuous integration. In this way, Igolgi is able to
            respond rapidly to both functional and security issues. Well defined change management policies and
            procedures determine when and how changes occur. This philosophy is central to DevOps security and the
            development methodologies that have driven Igolgi adoption. In this way, Igolgi is able to achieve extremely
            short mean time to resolution for security vulnerabilities and functional issues alike. Igolgi is
            continuously improving our DevOps practice in an iterative fashion.</span></p>
            <p class="p10 c1" style=""> 7. Corporate Security</p>
            <p class="p1" style=""><span class="c2">All Igolgi personnel undergo regular security and privacy awareness training
            that weaves security into technical and non-technical roles; all employees are encouraged to</span></p>
            <p class="p1" style=""><span class="c2"> participate in helping secure our customer data and company assets.
            Security training materials are developed for individual roles to ensure employees are equipped to handle
            the specific security-oriented challenges of their roles</span></p>
            <p class="p1" style=""><span class="c3"> 8. Client and Server Hardening</span></p>
            <p class="p1" style=""><span class="c2"> 8.1 Exposed server endpoints are recurrently tested for vulnerabilities
            using multiple types of scanning software as well as manual testing. Request-handling code paths have
            frequent user re-authorization checks, payload size restrictions, rate limiting where appropriate, and other
            request verification techniques. All requests are logged and made searchable to operations staff.</span></p>
            <p class="p1" style=""><span class="c2"> 8.2 Client code utilizes multiple techniques to ensure that using the
            Igolgi application is safe and that requests are authentic.</span></p>
            <p class="p1" style=""><span class="c3">10. Customer Payment Information.</span><span class="c2"> </span></p>
            <p class="p1" style=""><span class="c2">Igolgi uses Stripe, Inc. for payment processing and does not store any
            credit card information. Stripe is a trusted, Level 1 PCI Service Provider. 11. Incident Reporting and
            Ongoing Improvements. Igolgi encourages users to submit vulnerability reports. If you have a security
            concern or are aware of an incident, please send an email to file.support@igolgi.com</span></p>
        </div>
    </div>
}