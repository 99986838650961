/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createEffect,  createResource,  createSignal,  Show } from 'solid-js'

import { MainState } from './common'
import { Main } from './Job/Job'
import { Nav } from './Navigation'
import { signalFromPrefix } from './replicache'

const mainState = createMutable<MainState>({
    template   : undefined,
    collapsed  : false,
    canNavigate: true,
    isUploading: false,
    data       : undefined,
    panelStates: {
        outputFormat: createSignal<boolean>(false),
        input       : createSignal<boolean>(false),
        output      : createSignal<boolean>(false),
        audio       : createSignal<boolean>(false),
        video       : createSignal<boolean>(false),
        advanced    : createSignal<boolean>(false),

    },
})
export const App = (p: {}) => {
    const { auth0User: user, rep, auth0, client } = useAuthenticatedContext()
    const us = signalFromPrefix<[id: string, key: { consent: null | string }]>(rep, 'user')

    const getUser = () => {
        const s = us()
        if(s != undefined && s.length > 0) {
            return s[0][1]
        } else {
            return null
        }
    }
    const hasGivenConsent = () => {
        return getUser()?.consent != null
    }
    createEffect(() => {
        const r = rep()
        if(r != undefined) {
            r.subscribe(async (tx: ReadTransaction) => (await tx.get('count')) ?? 0, {
                onData: (count) => console.log('onData', count),
            })
        }
    })


    createEffect(() => {
        console.log('User data', user())
    })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
    const version = import.meta.env.VITE_APP_VERSION.replace(/\n/gi, '')
    console.log('version', JSON.stringify(version))


    return <Show when={getUser() != null} fallback={<div></div>}>
        <Show when={hasGivenConsent()} fallback={<EULA></EULA>}>
            <div class={main}>
                {/* <Show when={user()} fallback={<div>Logging you in...</div>}> */}
                <Toaster position="bottom-left"/>
                <Nav user={user} mainState={mainState}/>
                <div style={{ width: '100%' }}>
                    <Routes>
                        <Route path={['', 'transcoding']} component={() => <Main mainState={mainState}/>} />
                        <Route path="history" component={() => <JobHistory mainState={mainState}/>} />
                        <Route path="keys" component={() => <Keys/>} />
                        <Route path="billing" component={() => <Billing />} />
                        <Route path="dashboard" component={() => <Dashboard mainState={mainState} />} />
                        <Route path="documentation" component={() => <Documentation/>} />
                        <Route path="file-management" component={() => <FileManagement/>} />
                        <Route path="faq" component={() => <FAQ />} />
                        <Route path={paths.payment.client.successfulSetup} component={() => <Success/>} />
                        <Route path={paths.payment.client.cancelSetup} component={() => <div>Your transaction was cancelled.</div>} />
                    </Routes>
                    <div class={hover} style={{ display: 'flex', 'justify-content': 'center', 'align-items': 'center', margin: 'auto', 'font-size': '0.5em', color: 'gray' }}>{version}</div>
                </div>
                {/* </Show> */}
            </div>
        </Show>
    </Show>
}


const hover = style({
    opacity: '0%',
    $nest  : {
        '&:hover': {
            opacity: '1',
        },
    },
})

import { Route, Routes, useNavigate } from '@solidjs/router'
import { paths } from 'common'
import { ReadTransaction } from 'replicache'
import { createMutable } from 'solid-js/store'
import { Toaster } from 'solid-toast'
import { style } from 'typestyle'

import { EULA } from '../EULA'
import { Billing } from './Billing'
import { useAuthenticatedContext } from './context'
import { Dashboard } from './Dashboard'
import { Documentation } from './Documentation'
import { FAQ } from './FAQ'
import { FileManagement } from './FileManagement'
import { JobHistory } from './History'
import { Keys } from './Keys'

export const main = style({
    margin : 'auto',
    display: 'flex',
    $nest  : {
        '@media(max-width: 768px)': {
            flexDirection: 'column',
        },
    },
})



const Success = () => {
    const { client } = useAuthenticatedContext()
    // const session_id = new URLSearchParams(window.location.search).get('session_id')
    const navigate = useNavigate()

    navigate('/app/billing', { replace: true })

    const [info] = createResource(async () => {
        // if(session_id != undefined) {
        const a = await client()!.billingStatus.query()
        return a
        // } else return { name: null, email: null }
    })
    return <Show when={info()} fallback={<div>Credit card successfully saved!</div>}>
        <div>Credit card successfully saved!</div>
    </Show>
}