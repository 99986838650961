import { createSignal, JSX, Show } from 'solid-js'

export const ErrorHint = (p: { children: JSX.Element, error: null | string, cssClass?: string, moreStyle?: JSX.CSSProperties }): JSX.Element => {
    const [errorHintIsVisible, setErrorHintIsVisible] = createSignal(false)
    return <div
        onmouseenter={() => setErrorHintIsVisible(true)}
        onmouseleave={() => setErrorHintIsVisible(false)}
        classList={{ 'row': false, [p.cssClass!]: true }} style={{ margin: '1px', 'border': '2px solid transparent', 'border-radius': '4px', 'border-color': p.error != null ? 'red' : 'transparent', ...(p.moreStyle ?? {}), position: 'relative' }}>
        <Show when={p.error != undefined}>
            <div style={{ position: 'absolute', display: errorHintIsVisible() ? 'block' : 'none', bottom: '100%', 'margin-bottom': '5px', 'z-index': 100, 'max-width': '400px', 'background-color': '#ee5555', color: 'white', border: '1px solid red', padding: '2px', 'border-radius': '3px',     width: 'max-content' }}>{p.error}</div>
        </Show>
        {p.children}
    </div>
}