import { createEffect, JSX, Show } from 'solid-js'
import { style } from 'typestyle'

import { background, borderRadius, padding } from '../constants'
import { css } from '../css'
import { useAuthenticatedContext } from './context'

export const Content = (p: { children: JSX.Element }): JSX.Element => {
    const { auth0User, user, urls, daysRemainingInTrial, userState } = useAuthenticatedContext()


    createEffect(() => {
        console.log('userState', userState())
    })
    const addPayment = () => {
        const url = `${urls.payment.initiate}?sub=${auth0User()!.sub!}`
        window.location.href = url // note, the redirect fails if localhost port is different (i.e. 1234->4000)
    }
    return <div class={content}>
        <Show when={userState() != 'paying' && daysRemainingInTrial() != undefined}>
            <div style={{
                position          : 'relative',
                // top               : '-50px',
                // height            : '10px',
                // width             : '150%',
                'background-color': userState() == 'trial-ok' ? '#d4edda' : '#aa3333',
                color             : userState() == 'trial-ok' ? '#155724' : 'white',
                'border-color'    : '#c3e6cb',
                'margin-bottom'   : padding,
                'align-items'     : 'center',
                'border-radius'   : borderRadius,
                padding           : padding,
                display           : 'flex',
                'justify-content' : 'space-between',
            }}>
                <div style={{ display: 'flex', 'align-items': 'center' }}>
                    <Show when={userState() == 'trial-ok'}>
                        <svg style={{ width: '1em', 'margin-right': '0.5em'  }} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke-width="1.5" height="48" width="48"><path d="M10,21.75a2.087,2.087,0,0,0,4.005,0" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 3L12 0.75" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12,3a7.5,7.5,0,0,1,7.5,7.5c0,7.046,1.5,8.25,1.5,8.25H3s1.5-1.916,1.5-8.25A7.5,7.5,0,0,1,12,3Z" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        <span>You've got {daysRemainingInTrial()} days remaining in your trial period.</span>
                    </Show>
                    <Show when={userState() == 'trial-expired'}>
                        <span>Your trial period has expired.</span>
                    </Show>
                </div>
                <Button style={{ width: 'auto' }} onClick={addPayment}>Upgrade Now</Button>
            </div>
        </Show>


        <div style={{
            padding        : '2em',
            'border-radius': borderRadius,
            background     : background,
            width          : '100%',
            'box-shadow'   : '0 1px 10px 1px rgba(79,79,79,.08)' }}>
            {p.children}
        </div>
    </div>

}

const content = style({
    $nest: {
        'svg path': {
            // stroke     : '#155724',
            strokeWidth: '2px',
        },
    },

})
type ButtonAttrs =  JSX.HTMLElementTags['button']
export const Button = (p: ButtonAttrs & { children: JSX.Element, style?: JSX.CSSProperties, hasErrors?: boolean }): JSX.Element => {
    return <button
        classList={{ [buttonStyle()]: true }} // classlist must come before destructuring so class doesn't get in the way
        {...p}
        style={ p.style }>{p.children}</button>
}

const buttonStyle = css(t =>  ({
    borderRadius   : borderRadius,
    padding        : '0.5em 3em',
    border         : 0,
    color          : 'white',
    backgroundColor: 'hsl(211, 100%, 50%)',
    width          : '100%',
    cursor         : 'pointer',
    '&:hover'      : {
        backgroundColor: 'hsl(211, 100%, 40%)',
    },
    '&:disabled': {
        backgroundColor: 'hsl(211, 30%, 40%)',
        cursor         : 'not-allowed',
    },
}))