import { Decimal } from 'decimal.js'

import { UserPlan } from './common'
// export const STRIPE_PRICE_ID = 'price_1NIwa9GL4Qmh6pVEWXWeTE8Y'
export const STRIPE_PLANS_IGOLGI_TEST = {
    'pro': {
        metered: 'price_1PyEPlHu0OrHjSeqXkVaXj2l',
        monthly: 'price_1PyEOUHu0OrHjSeqQC5TAtz3',
    },
    'starter': {
        'metered': 'price_1PyEGeHu0OrHjSeqDo4x92bU',
    },
}


export const STRIPE_PLANS_IGOLGI_PRODUCTION = {
    'pro': {
        metered: 'price_1PyETFHu0OrHjSeqMdd0Rrrg',
        monthly: 'price_1PyETFHu0OrHjSeqREjRoxwD',
    },
    'starter': {
        'metered': 'price_1PyETJHu0OrHjSeqjydy468C',
    },
}



export const STRIPE_PLANS_ANDREI_TEST = {
    'pro': {
        metered: 'price_1Pfm22GL4Qmh6pVEFJ4CYB5I',
        monthly: 'price_1Pflz1GL4Qmh6pVEWxOP20Nk',
    },
    'starter': {
        'metered': 'price_1Pfm3NGL4Qmh6pVEWUjm76Im',
    },
}
const isIgolgiEmail = (email: string): boolean => email.toLowerCase().endsWith('igolgi.com')
export const getLineItemsForPlan = (plan: string | Extract<UserPlan, 'starter' | 'pro'>, stripeMode: 'production' | 'test', userEmail: string): { price: string, quantity?: number }[] => {
    const data = (stripeMode == 'production' && !isIgolgiEmail(userEmail))
        ? STRIPE_PLANS_IGOLGI_PRODUCTION
        : STRIPE_PLANS_IGOLGI_TEST
    if(plan == 'pro') {
        return [
            { price: data.pro.metered },
            { price: data.pro.monthly, quantity: 1 },
        ]
    } else {
        return [
            { price: data.starter.metered },
        ]
    }
}


export type VideoCodec = 'H.264' | 'MPEG2' | 'HEVC'
export const hourlyPrices = {
    'H.264': {
        30: [
            [720 * 576, 0.25],
            [1920 * 1080, 0.4],
            [3840 * 2160, 2.25],
        ],
        60: [
            [1920 * 1080, 0.8],
            [3840 * 2160, 4.5],
        ],
    },
    'MPEG2': { // == "H.264"
        30: [
            [720 * 576, 0.25],
            [1920 * 1080, 0.4],
            [3840 * 2160, 2.25],
        ],
        60: [
            [1920 * 1080, 0.8],
            [3840 * 2160, 4.5],
        ],
    },
    'HEVC': {
        30: [
            [720 * 576, 0.5],
            [1920 * 1080, 0.8],
            [3840 * 2160, 4.00],
        ],
        60: [
            [1920 * 1080, 1.6],
            [3840 * 2160, 8],
        ],
    },
} as const


export const costInDollars = (codec: VideoCodec, seconds: number, framerate: number, resolutionAsPixelCount: number): number => {
    const hours = Math.max(0, seconds) / 3600
    const s = hourlyPrices[codec][framerate <= 30 ? 30 : 60]
    let i =  0
    for(const row of s){
        if(row[0] >= resolutionAsPixelCount || i == s.length - 1) {
            return row[1] * hours
        }
        i += 1
    }
    throw Error('impossible')
}
export const roundCostUpToNearstCent = (x: number): number => Math.ceil(x * 100) / 100



export const oracleCostMatrixInDollars = {
    storageGBperHour: new Decimal(0.01),
    transferGB      : new Decimal(0.001),
}

// Prices Take 2
type AudioCodecs = 'aac' | 'ac3'/* | 'mp2'*/
export type VideoCodecNEW = 'h.264' | 'mpeg2' | 'hevc'

type VideoProfile = {
    minutes: number,
    // format: 'mp4' | 'ts'
    codec: VideoCodecNEW
    pixelCount: number
    detelecine: boolean
    CABR: boolean
}
export type JobData = {
    plan: 'starter' | 'pro' | 'trial' | 'enterprise'
    inputBitrateKbps: number
    inputFramerate: number
    // CABR: boolean
    videoProfiles: VideoProfile[]
    audioProfiles: {
        minutes: number
        codec: AudioCodecs
    }[]
}

export const perMinute = {
    aacAudioPerTrackPerMinute: 0.0016,
    video                    : [
        [[1280, 720], 0.0038, 'SD'],
        [[2048, 1080], 0.0075, 'HD'],
        [[2560, 1440], 0.0150, '2K'],
        [[3840, 2160], 0.0150, '4K'],
    ],
    highBitrateThresholdKbps: 25000,
    // highFramerateThresholdFPS: 60, //https://igolgi.atlassian.net/jira/software/c/projects/CU/boards/25?assignee=5f0c8ae19e8ba30016b51b31&selectedIssue=CU-320
    extra                   : {
        dolbyAC3PerMinute      : 0.001,
        detelecineMultiplier   : 0.5,
        h265Multiplier         : 0.25,
        highBitrateMultiplier  : 0.25,
        CABRMultiplier         : 0.25,
        upToAndIncluding60FPS  : 0.5,
        beyond60FPS            : 1,
        highFramerateMultiplier: 0.5, // https://igolgi.atlassian.net/jira/software/c/projects/CU/boards/25?assignee=5f0c8ae19e8ba30016b51b31&selectedIssue=CU-320
    },
} as const

const computeVideoPerMinuteCost = (pixelCount: number): number => {
    for(const [res, cost] of perMinute.video){
        if(res[0] * res[1] >= pixelCount || res[0] == perMinute.video[perMinute.video.length - 1][0][0]) return cost
    }
    throw Error('impossible')
}

const audioCost = (data: JobData): number => {
    if(data.plan == 'trial' || data.plan == 'enterprise') return 0
    return data.audioProfiles
        .map(p => {
            const discountMultiplier = data.plan == 'starter' ? 1 : 0.75
            const addonPerMinute = p.codec == 'ac3' ? perMinute.extra.dolbyAC3PerMinute : 0
            const perMinuteCost = (perMinute.aacAudioPerTrackPerMinute + addonPerMinute) * discountMultiplier
            return perMinuteCost * Math.ceil(p.minutes)
        })
        .reduce((acc, c) => acc + c, 0)
}
const computeVideoCost = (data: JobData): number => {
    if(data.plan == 'trial' || data.plan == 'enterprise') return 0
    return data.videoProfiles
        .map(p => {
            const discountMultiplier = data.plan == 'starter' ? 1 : 0.75
            const minuteCost = computeVideoPerMinuteCost(p.pixelCount) * discountMultiplier
            const baseline = minuteCost * Math.ceil(p.minutes)
            const result = baseline * computeVideoAddonMultiplier(p, data.inputBitrateKbps, data.inputFramerate)
            return result
        })
        .reduce((acc, c) => acc + c, 0)
}

const computeVideoAddonMultiplier = (p: VideoProfile, inputBitrateKbps: number, inputFramerate: number): number => {
    let total = 0
    if(true)                                        total += 1
    if(p.codec == 'hevc')                           total += perMinute.extra.h265Multiplier
    if(p.detelecine)                                total += perMinute.extra.detelecineMultiplier
    if(inputBitrateKbps > perMinute.highBitrateThresholdKbps) total += perMinute.extra.highBitrateMultiplier
    if(inputFramerate > 60) total += perMinute.extra.beyond60FPS
    else if(inputFramerate > 30) total += perMinute.extra.upToAndIncluding60FPS
    if(p.CABR)                                      total += perMinute.extra.CABRMultiplier
    return total
}
export const costInCentsNew = (data: JobData): number => {
    if(data.plan == 'trial' || data.plan == 'enterprise') return 0
    console.log('costInCentsNew', data)
    return audioCost(data) + computeVideoCost(data)
}



import { Job as JobModel } from 'api/src/db/schema'

export const convert = (job: JobModel): JobData | 'unknown' => {
    // if(job.status?.duration_sec == undefined) return 'unknown'
    const video_set = (job.streamengine_job_get_response?.input_media?.video_set as ({ width: number, height: number, bitrate_in_kbps: number, frames_per_second: number, duration_in_sec: number, progressive_or_interlaced: number }[])) ??  []
    if(video_set.length == 0) return 'unknown'
    const minutes = video_set[0].duration_in_sec / 60

    const resp: JobData = {
        audioProfiles: job.config.audioProfiles.map(p => ({
            minutes: minutes,
            codec  : p.audio_codec,
        })),
        inputBitrateKbps: video_set[0].bitrate_in_kbps,
        inputFramerate  : video_set[0].frames_per_second,
        plan            : job.user.plan,
        videoProfiles   : job.config.videoProfiles.map((p, i) => {
            const width = p.width > 0 ? p.width : video_set[0].width
            const height = p.height > 0 ? p.height : video_set[0].height
            return {
                minutes   : minutes,
                CABR      : false,
                codec     : job.config.video_codec,
                pixelCount: width * height,
                detelecine: job.config.picture_transform == 'detelecine/deinterlace',
            }
        }),
    }
    return resp
}


