import { useSearchParams } from '@solidjs/router'
import * as commonSchema from 'common/src/schema'
import { createMemo, JSX } from 'solid-js'
import { style } from 'typestyle'

import { borderRadius, padding } from '../constants'
import { header } from '../style'
import { MainState } from './common'
import { useAuthenticatedContext } from './context'
import { JobHistoryTable } from './History'
import { Button, Content } from './Library'
import { signalFromPrefix } from './replicache'

type Props =  {
    mainState: MainState
}
const Check = ({ on }: { on: boolean }): JSX.Element => {
    return <span style={{ 'padding-right': '10px' }}>
        {on
            ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="blue" version="1.1" id="Layer_1" viewBox="0 0 330 330">
                <path id="XMLID_2_" d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M225.606,175.605  l-80,80.002C142.678,258.535,138.839,260,135,260s-7.678-1.464-10.606-4.394c-5.858-5.857-5.858-15.355,0-21.213l69.393-69.396  l-69.393-69.392c-5.858-5.857-5.858-15.355,0-21.213c5.857-5.858,15.355-5.858,21.213,0l80,79.998  c2.814,2.813,4.394,6.628,4.394,10.606C230,168.976,228.42,172.792,225.606,175.605z"/>
            </svg>
            : <svg stroke="transparent" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.00017 0C3.58162 0 0 3.58162 0 8.00017C0 12.4187 3.58162 16.0003 8.00017 16.0003C12.4187 16.0003 16.0003 12.4187 16.0003 8.00017C15.9949 3.584 12.4163 0.00511367 8.00017 0ZM11.4233 6.69618L7.7871 10.3323C7.50312 10.6163 7.04289 10.6163 6.75857 10.3323L4.57674 8.15051C4.28799 7.87131 4.2798 7.41108 4.55901 7.12198C4.83822 6.83323 5.29845 6.82505 5.58754 7.10426C5.59368 7.11005 5.59947 7.11619 5.60527 7.12198L7.27301 8.78972L10.3951 5.66765C10.6838 5.38879 11.1444 5.39663 11.4236 5.68538C11.6957 5.96732 11.6957 6.41425 11.4233 6.69618Z" fill='#BAC0CE'/>
            </svg>}
    </span>
}




export const Dashboard = (p: Props): JSX.Element => {
    const { client, rep } = useAuthenticatedContext()

    const [searchParams, setSearchParams] = useSearchParams()

    // @ts-expect-error
    const jobStatuses_ = signalFromPrefix<[id: string, status:commonSchema.JobModel]>(rep, 'job/')
    // TODO: lift filter, show whole job with reasonable defaults if not sent to se yet
    const jobStatuses = createMemo(() => jobStatuses_().map(j => j[1]).sort((a, b) => b._auto_generated_id_ - a._auto_generated_id_),
        undefined,
        { equals: (prev, next) => JSON.stringify(prev) == JSON.stringify(next) })

    return <div style={{ padding: '1em', width: '100%' }}>
        <Content>
            <h1 class={header}>Dashboard</h1>
            <div style={{ display: 'flex', width: '100%', 'gap': '10px' }}>
                <div style={{ flex: 1 }}>
                    <div class={box}>
                        <h4>Latest Transcoding Jobs</h4>
                        <br></br>
                        <JobHistoryTable mainState={p.mainState} limit={20} jobStatuses={jobStatuses} small={true}></JobHistoryTable>
                    </div>
                </div>
                <div style={{ 'flex-basis': '25%', 'max-width': '25%' }}>
                    <div class={box}>
                        <h4>Getting Started</h4>
                        <ul class={fancyList}>
                            {/* <li><Check on={false}/><a href="#" style={{ color: 'gray' }}>Create account</a></li> */}
                            <li><Check on={true}/><a href="http://docs.streamengine.igolgi.com/books/igolgi-stream-engine/page/videos">Watch get started video</a></li>
                            <li><Check on={true}/><a href="http://docs.streamengine.igolgi.com/books/igolgi-stream-engine/page/quick-start-1-drag-and-drop">Quick Start doc</a></li>
                            <li><Check on={true}/><a href="/app/transcoding">Transcode a Video</a></li>
                            <li><Check on={true}/><a href="/app/keys">Get API Key</a></li>
                        </ul>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div class={box}>
                            <div style={{ 'font-size': '2em', 'text-align': 'center', 'font-weight': '800' }}>{jobStatuses().length}</div>
                            <h4 style={{ 'text-align': 'center', 'font-weight': '400' }}>Videos Transcoded</h4>
                        </div>
                    </div>
                    <div class={box}>
                        <h4>Documentation</h4>
                        <ul class={fancyList}>
                            {/* <li><Check on={false}/><a href="#" style={{ color: 'gray' }}>Create account</a></li> */}
                            <li><Check on={true}/><a href="http://docs.streamengine.igolgi.com/books/igolgi-stream-engine/chapter/api">API Documentation</a></li>
                            <li><Check on={true}/><a href="http://docs.streamengine.igolgi.com/books/igolgi-stream-engine/chapter/tutorials">Tutorials</a></li>
                            <li><Check on={true}/><a href="http://docs.streamengine.igolgi.com/books/igolgi-stream-engine/page/anatomy-of-a-video">Anatomy of Web Videos</a></li>
                            <li><Check on={true}/><a href="http://docs.streamengine.igolgi.com/books/igolgi-stream-engine/page/videos">Web Video Tutorial</a></li>
                        </ul>
                    </div>
                    <div class={box}>
                        <h4>Suggestions</h4>
                        <div style={{ color: '#666', margin: '1em 0em' }}>What features or updates would you like to see more from Streamengine?</div>
                        <textarea style={{ resize: 'none', width: '100%', border: '1px solid #bbb', padding: '0.5em', color: '#666' }}></textarea>
                        <Button style={{ 'background-color': '#aaaadd', color: '#000066' }} onClick={() => {throw Error('test errror')}}>Submit</Button>
                    </div>
                </div>

            </div>

        </Content>
    </div>
}

const box = style({
    'backgroundColor': '#f9f9f9',
    border           : '1px solid #ddd',
    'borderRadius'   : borderRadius,
    padding          : padding,
    marginBottom     : padding,
})

const fancyList = style({
    paddingLeft: '0px',
    paddingTop : '0.5em',
    listStyle  : 'none',
    $nest      : {
        'svg path': {
            stroke     : 'unset',
            strokeWidth: 'unset',
        },
        'li a': {
            color         : 'blue',
            textDecoration: 'none',
            margin        : '3px 0px',
        },
    },
})



