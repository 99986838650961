import { z } from 'zod'

import { UserPlan, userPlan } from './common'

export const id = z.number() // auto generated id
export type Id = z.infer<typeof id>

export const cloudTag = z.union([
    z.literal('aws'),
    z.literal('oracle'),
    z.literal('gcloud'),
    z.literal('http'),
    z.literal('nfs'),
    // z.literal('ftp'),
    z.literal('file-upload'),
    z.literal('igolgi-store'),
]).describe('Tag specifying the cloud service provider on which the job is to be run.')
export const awsRegions = z.union([
    z.literal('us-east-1').describe('US East (N. Virginia)'),
    z.literal('us-east-2').describe('US East (Ohio)'),
    z.literal('us-west-1').describe('US West (N. California)'),
    z.literal('us-west-2').describe('US West (Oregon)'),
    z.literal('af-south-1').describe('Africa (Cape Town)'),
    z.literal('ap-east-1').describe('Asia Pacific (Hong Kong)'),
    z.literal('ap-south-1').describe('Asia Pacific (Mumbai)'),
    z.literal('ap-northeast-1').describe('Asia Pacific (Tokyo)'),
    z.literal('ap-northeast-2').describe('Asia Pacific (Seoul)'),
    z.literal('ap-northeast-3').describe('Asia Pacific (Osaka)'),
    z.literal('ap-southeast-1').describe('Asia Pacific (Singapore)'),
    z.literal('ap-southeast-2').describe('Asia Pacific (Sydney)'),
    z.literal('ca-central-1').describe('Canada (Central)'),
    z.literal('eu-central-1').describe('EU (Frankfurt)'),
    z.literal('eu-west-1').describe('EU (Ireland)'),
    z.literal('eu-west-2').describe('EU (London)'),
    z.literal('eu-west-3').describe('EU (Paris)'),
    z.literal('eu-south-1').describe('EU (Milan)'),
    z.literal('eu-north-1').describe('EU (Stockholm)'),
    z.literal('me-south-1').describe('Middle East (Bahrain)'),
    z.literal('sa-east-1').describe('South America (Sao Paulo)'),
])
export const googleRegions = z.union([
    z.literal('us-central1').describe('US Central (Iowa)'),
    z.literal('us-west1').describe('US West (Oregon)'),
    z.literal('us-west2').describe('US West (Los Angeles)'),
    z.literal('us-west3').describe('US West (Salt Lake City)'),
    z.literal('us-west4').describe('US West (Las Vegas)'),
    z.literal('us-east1').describe('US East (South Carolina)'),
    z.literal('us-east4').describe('US East (Northern Virginia)'),
    z.literal('southamerica-east1').describe('South America (Sao Paulo)'),
    z.literal('northamerica-northeast1').describe('North America (Montreal)'),
    z.literal('northamerica-northeast2').describe('North America (Toronto)'),
    z.literal('europe-west1').describe('Europe West (Belgium)'),
    z.literal('europe-west2').describe('Europe West (London)'),
    z.literal('europe-west3').describe('Europe West (Frankfurt)'),
    z.literal('europe-west4').describe('Europe West (Netherlands)'),
    z.literal('europe-west6').describe('Europe West (Zurich)'),
    z.literal('europe-central2').describe('Europe Central (Warsaw)'),
    z.literal('europe-north1').describe('Europe North (Finland)'),
    z.literal('asia-south1').describe('Asia South (Mumbai)'),
    z.literal('asia-southeast1').describe('Asia Southeast (Singapore)'),
    z.literal('asia-southeast2').describe('Asia Southeast (Jakarta)'),
    z.literal('asia-northeast1').describe('Asia Northeast (Tokyo)'),
    z.literal('asia-northeast2').describe('Asia Northeast (Osaka)'),
    z.literal('asia-northeast3').describe('Asia Northeast (Seoul)'),
    z.literal('asia-east1').describe('Asia East (Taiwan)'),
    z.literal('asia-east2').describe('Asia East (Hong Kong)'),
    z.literal('australia-southeast1').describe('Australia Southeast (Sydney)'),
])
export const ociRegions = z.union([
    z.literal('us-phoenix-1').describe('US Phoenix'),
    z.literal('us-ashburn-1').describe('US Ashburn'),
    z.literal('uk-london-1').describe('UK London'),
    z.literal('ca-toronto-1').describe('Canada Toronto'),
    z.literal('eu-frankfurt-1').describe('Germany Frankfurt'),
    z.literal('ap-tokyo-1').describe('Japan Tokyo'),
    z.literal('ap-seoul-1').describe('South Korea Seoul'),
    z.literal('ap-mumbai-1').describe('India Mumbai'),
    z.literal('ap-sydney-1').describe('Australia Sydney'),
    z.literal('sa-saopaulo-1').describe('Brazil Sao Paulo'),
    z.literal('me-jeddah-1').describe('Saudi Arabia Jeddah'),
])
export const cloudCreds = z.object({
    cloud_provider: cloudTag.default('oracle').describe('The cloud provider to use'),
    access_key    : z.optional(z.string().nullable().describe('Account access key.')),
    secret_key    : z.string().nullable().describe('Account secret key.'),
    region        : z.optional(z.union([ociRegions, awsRegions, googleRegions]))
        .nullable()
        .describe('The region within which your target bucket is located.'),
    // Oracle only
    tenancy_ocid   : z.optional(z.string()).nullable().describe('Tenancy OCID (for oracle only)'),
    user_ocid      : z.optional(z.string()).nullable().describe('User OCID (for oracle only)'),
    oci_fingerprint: z.optional(z.string()).nullable().describe('User Fingerprint (for oracle only)'),
    // Google only
    project_id     : z.optional(z.string()).nullable().describe('Project ID (for google only)'),
    client_email   : z.optional(z.string()).nullable().describe('Client email (for google only)'),
    client_id      : z.optional(z.string()).nullable().describe('Client ID (for google only)'),
    private_key_id : z.optional(z.string()).nullable().describe('Private Key ID (for google only)'),
})
export const cloudCredentials = z.object({
    input : cloudCreds,
    output: cloudCreds,
    // bucket_name   : z.string(),
})
export type CloudTag = z.infer<typeof cloudTag>

export const bitrates_aac_ = [z.literal(32), z.literal(40), z.literal(64), z.literal(96), z.literal(128), z.literal(192), z.literal(256), z.literal(384), z.literal(448), z.literal(768)] as const
export const bitrates_ac3_ = [z.literal(64), z.literal(128), z.literal(192), z.literal(256), z.literal(384), z.literal(448)] as const
export const bitrates_aac = z.union(bitrates_aac_)
export const bitrates_ac3 = z.union(bitrates_ac3_)

export const priority = z.union([z.literal(1), z.literal(2), z.literal(3)])

export const boolean_ints = z.union([z.literal(0), z.literal(1)])

export const simplifiedVideoProfile = z.object({
    // Output
    output: z.string().describe('The location, including the file name, where the output is to be sent.').optional(),

    // Video
    width             : z.number().nonnegative().describe('The output width in pixels.'), // 0 - default to input width
    height            : z.number().nonnegative().describe('The output height in pixels'),
    video_bitrate_mode: z.optional(z.union([z.literal('cbr'), z.literal('vbr')]))
        // .default('cbr')
        .describe('Output video bitrate mode'),
    video_bitrate  : z.number().nonnegative().describe('The video bitrate in kbps'), //video_muxrate
    video_framerate: z.optional(z.union([z.literal('1x'), z.literal('1/2x'), z.literal('2x')]))
        .default('1x') // default added after discussion with Kumar and Jeff Apr 21, 2023
        .describe('the video framerate'), // 0 - default to input framerate
    // video_format: z.optional(z.union([z.literal('progressive'), z.literal('interlaced')]))
    //     .default('progressive') // default added after discussion with Kumar and Jeff Apr 21, 2023
    //     .describe('Target output video format'),
    audio_profiles: z.optional(z.string()),
    // profile_name  : z.optional(z.string()),
})
export const audio_codecs = z.union([z.literal('aac'), z.literal('ac3')/*, z.literal('mp2')*/])
export const simplifiedAudioProfile = z.object({
    audio_codec: z.optional(audio_codecs)
        .default('aac')
        .describe('Audio codec to be used during transcode.'),
    audio_channels: z.optional(z.union([z.literal(2), z.literal(6), z.literal('passthrough')]))
        .default(2)
        .describe('Number of audio channels on the output. Use 2 for stereo and 6 for 5.1.'),
    audio_bitrate: z.optional(z.union([
        ...bitrates_aac_,
        ...bitrates_ac3_] as any as [Zod.ZodLiteral<32>, Zod.ZodLiteral<256>, Zod.ZodLiteral<192>]))
        .default(256) // todo: look into this
        .describe('Output audio bitrate'),
    // primary_audio_downmix_to_stereo: z.boolean().optional().describe('Downmix primary audio from 5.1 to stereo?'),
    source_stream: z.optional(z.union([
        z.literal(1),
        z.literal(2),
        z.literal(3),
        z.literal(4),
        z.literal(5),
        z.literal(6),
        z.literal(7),
        z.literal(8),
    ])).describe('The index of the source stream'),
    dolby_digital_dialnorm: z.number().optional().describe('The dialnorm. Values range from 0 to 31 inclusive. Use 0 for passthrough.'),
})
// .refine((data) =>
//     ((data.audio_codec == 'ac3' || data.audio_codec == 'mp2') && bitrates_ac3.safeParse(data.audio_bitrate).success)
// || ((data.audio_codec == 'aac') && bitrates_aac.safeParse(data.audio_bitrate).success), {
//     message: 'Incorrect bitrate for this codec',
//     path   : ['audio_bitrate'],
// })

export type SimplifiedVideoProfile = z.infer<typeof simplifiedVideoProfile>
export type SimplifiedAudioProfile = z.infer<typeof simplifiedAudioProfile>

export const video_codecs = z.optional(z.union([z.literal('h.264'), z.literal('mpeg2'), z.literal('hevc')])).default('h.264').describe('Output video encoder')
export const simplifiedJobInput = z.object({ // <--
    input        : z.string().describe('The location of the input file. This will be an object key if using a cloud provider, or a regular URL otherwise.'),
    videoProfiles: z.array(simplifiedVideoProfile).describe('An array containing at least one video profile.'),
    audioProfiles: z.array(simplifiedAudioProfile).describe('An array containing zero or more audio profiles.'),

    // Common
    output_container: z.optional(z.union([z.literal('ts'), z.literal('mp4')])).default('mp4')
        .describe('Output container to be used'),
    // output_tag                             : z.string().optional(),
    ts_mode_input_audio_source_stream_count: z.number().int().optional().nullable()
        .describe('Audio stream count (in ts mode only).'),
    audio_output_path: z.string().optional().nullable()
        .describe('The cloud location where audio should be stored.'),
    // output_bucket                          : z.string().optional(),
    bucket_output_path: z.string().optional().nullable()
        .describe('The location, within a cloud bucket, where the output should be stored in segmented mode.'),

    separate_audio       : z.optional(z.boolean()).default(false).describe('Whether or not to use separate audio.'),
    segmented_output_hls : z.boolean().optional().default(false).describe('Whether to use HLS in segmented output'),
    segmented_output_dash: z.boolean().optional().default(false).describe('Whether to use DASH in segmented output'),
    segment_length       : z.number().int().optional().nullable().describe('The segment length in segmented output.'),

    video_codec       : video_codecs,
    ip_distance       : z.number().nonnegative().optional().describe('Target I/P frame distance'),
    gop_length        : z.number().positive().optional().default(1).describe('Target GOP length in seconds'),
    scte35_passthough : z.optional(z.boolean()).default(false).describe('Use SCTE35 passthough?'),
    scte35_pid_remap  : z.number().optional().describe('PID Remap'),
    create_tar_file   : z.boolean().optional().describe('In segmented output, whether to tar the resulting directory and store it in the same bucket specified by the bucket_output_path parameter. Note that this will take up more space in your bucket.'),
    audio_volume      : z.number().optional().default(0).describe('Target audio volume in dB. A value of -100 designates mute.'),
    h264_quality      : z.union([z.literal('good'), z.literal('better'), z.literal('best')]).optional().default('better').describe("Output quality. Accepted values are 'good', 'better', and 'best'."),
    video_aspect_ratio: z.union([
        z.literal('passthrough'),
        z.literal('force_4:3'),
        z.literal('force_16:9'),
        z.literal('invert_aspect_ratio'),
        z.literal('rotate_counterclockwise'),
        z.literal('rotate_clockwise'),
        z.literal('rotate_counterclockwise + invert_aspect_ratio'),
        z.literal('rotate_clockwise + invert_aspect_ratio'),
    ]).optional().default('passthrough').describe('Output video aspect ratio'),
    rotation_blackness: z.number().int().min(0).max(100).optional().nullable().describe('The opacity to use for the side-panels of rotated output.'),
    closed_captions   : z.boolean().optional().describe('Write CC to output?'),

    picture_transform: z.optional(z.union([z.literal('none'), z.literal('deinterlace'), z.literal('detelecine/deinterlace')]))
        .default('none')
        .describe('The picture transform to use.'),
    // force_progressive: z.optional(z.boolean())
    //     .default(false)
    //     .describe('Force progressive?'),
    // keep_fps: z.optional(z.boolean())
    //     // `keep_fps` is dependent on `force_progressive`. Only makes sense to expose it if `force_progressive` is enabled. If `force_progressive` is false then `keep_fps` should be true (but hidden).
    //     .default(true)
    //     .describe('Preserve the framerate?'),
    logo_url: z.optional(z.string()).nullable()
        .describe('URL to an image file to use as a watermark'),
    cloud_credentials: cloudCredentials.describe('See below.'),
    // blazar_mode      : z.optional(z.boolean())
    //     .default(false)
    //     .nullable()
    //     .describe('A flag indicating whether to use blazar acceleration for this job.'),

    // front_write_back_read: z.object({ free_version: z.boolean() }),
})
export type SimplifiedJobInput = z.infer<typeof simplifiedJobInput>

export const status = z.object({
    job_id           : z.union([z.string(), z.number()]),
    tcode_progress   : z.optional(z.union([z.string(), z.number()])),
    state            : z.optional(z.string()),
    tcode_time       : z.optional(z.union([z.string(), z.number()])),
    tcode_speed      : z.optional(z.union([z.string(), z.number()])),
    error_str        : z.optional(z.string()),
    job_completed    : z.optional(z.union([z.string(), z.number(), z.boolean()])),
    xfer_speed       : z.optional(z.union([z.string(), z.number()])),
    xfer_time        : z.optional(z.union([z.string(), z.number()])),
    xfer_progress    : z.optional(z.union([z.string(), z.number()])),
    tcode_start_time : z.optional(z.number()),
    duration_sec     : z.optional(z.number()),
    tcode_finish_time: z.optional(z.number()),
})
export type Status = z.infer<typeof status>
export const statusWithStringId = status.extend({ job_id: z.string() })
export type StatusWithStringId = z.infer<typeof statusWithStringId>


export const userModel = z.object({
    created_at                : z.string(), // not date, date doesn't serialize
    id                        : z.number(),
    stripe_customer_id        : z.nullable(z.string()),
    checkout_session_completed: z.boolean(),
    replicache_space_version  : z.optional(z.number()),
    forced_trial_expiry       : z.boolean(),
    is_admin                  : z.boolean(),
    plan                      : userPlan,
})
type a = UserPlan
export type UserModel = z.infer<typeof userModel>


export const responsePOST = z.object({
    id        : id, //yfJWMGyDiBp6sZ
    created_at: z.string(),
    // completed_at: z.nullable(z.string()),
    status    : z.string(), // job.starting
    progress  : z.string(), // 0%
    // input     : z.object({ status: z.string() }),
    // outputs     : z.array(z.output)
})
export type ResponsePOST = z.infer<typeof responsePOST>


export namespace AudioProfile {
    export const output = z.object({
        id                                  : z.number(),
        name                                : z.string(),
        codec                               : z.string(),
        volume                              : z.number(),
        bitrate                             : z.number(),
        bitrate_backup                      : z.number(),
        primary_audio_downmix_to_stereo_flag: z.boolean().optional().default(false),
        conditional_dolby_digital_flag      : z.boolean(),
        secondary_audio_bitrate             : z.number(),
        channels                            : z.number(),
        base_configurations                 : z.array(z.any()),
        dolby_digital_dialnorm              : z.number().optional(),
    })
    export type Output = z.infer<typeof output>
}
export namespace VideoProfile {
    export const output = z.object({
        id                   : z.number(),
        name                 : z.string(),
        width                : z.number(),
        height               : z.number(),
        video_muxrate        : z.number(),
        video_framerate      : z.number(),
        video_maxrate        : z.number(),
        video_bitrate_mode   : z.number(),
        video_format         : z.string(),
        framerate_720p60_flag: z.boolean(),
        base_configurations  : z.array(z.any()),
    })
    export type Output = z.infer<typeof output>
}
export namespace CommonConfig {
    export const output = z.object({
        id                         : z.number(),
        name                       : z.string(),
        operating_mode             : z.union([z.literal('activitydetection'), z.literal('transcoding')]),
        output_container           : z.string(),
        min_object_size            : z.number(),
        min_object_displacement    : z.number(),
        video_codec                : z.union([z.literal('h.264'), z.literal('mpeg2'), z.literal('hevc')]),
        scte35_passthough          : z.optional(z.boolean()).default(false),
        ip_distance                : z.number(),
        gop_length                 : z.number(),
        h264_profile               : z.string(),
        h264_quality               : z.number(),
        adaptive_spatial_prefilter : z.number(),
        video_aspect_ratio         : z.string(),
        multirate_type             : z.string(),
        multirate_segment_size_sec : z.number(),
        single_package_flag        : z.number(),
        closed_captions_flag       : z.number(),
        vix_output_flag            : z.number(),
        smil_output_flag           : boolean_ints,
        use_muxrate_as_bitrate_flag: z.boolean(),
        use_nullfiller_on_ES_flag  : z.boolean(),
        auto_detelecine_flag       : z.boolean(),
        master_variant_mode        : boolean_ints,
        dai_mode                   : boolean_ints,
        dai_cadent_service_endpoint: z.string(),
        dai_ppaid_mode             : z.number(),
        profileconfig_set          : z.array(VideoProfile.output),
        audioconfig_set            : z.array(AudioProfile.output),
        encryption_type            : z.string(),
        buydrm_userkey             : z.string(),
        buydrm_keyid               : z.string(),
        buydrm_contentid           : z.string(),
        buydrm_mediaid             : z.string(),
        verimatrix_encryptor_url   : z.string(),
        verimatrix_client_url      : z.string(),
        verimatrix_max_segments    : z.string(),
        verimatrix_resource_id     : z.string(),
        verimatrix_asset_type      : z.string(),
        verimatrix_iv_mode         : z.string(),
        // blazar_mode                : z.optional(z.boolean()),
        // blazar_server_url          : z.optional(z.string()),
        // blazar_input_path          : z.optional(z.string()),
    })
    export type Output = z.infer<typeof output>
}


const jobStates = z.union([
    z.literal('enqueued'),
    z.literal('p0scheduled'),
    z.literal('pdiscovered'),
    z.literal('p1scheduled'),
    z.literal('transferred'),
    z.literal('discovered'),
    z.literal('p2scheduled'),
    z.literal('processed'),
    z.literal('aborted'),
    z.literal('p3scheduled'),
    z.literal('ftransferred'),
    z.literal('ftransferpending'),
    z.literal('p4scheduled'),
    z.literal('p5scheduled'),
    z.literal('alldone'),
])

export namespace Job {


    export type CloudCredentials = z.infer<typeof cloudCredentials>

    export const input = z.object({
        job_setup_id                                      : z.number(),
        // is_complete                                       : z.boolean(),
        // has_error                                         : z.boolean(),
        // needs_manual_qc                                   : z.boolean(),
        input_location_id                                 : z.number(),
        input_location_mount_path_includes_subdir         : z.boolean(),
        input_location_delete_even_if_part_of_active_jobs : z.boolean(),
        input_location_mount_parent_path_flag             : z.boolean(),
        output_location_folder_create                     : z.boolean(),
        output_location_match_input_filename              : z.boolean(),
        output_location_copy_companion_files              : z.boolean(),
        output_location_delete_even_if_part_of_active_jobs: z.boolean(),
        output_location_mount_parent_path_flag            : z.boolean(),
        job_type                                          : z.boolean(),
        enhanced_mode                                     : z.boolean(),
        job_priority                                      : priority,
        preset_use_muxrate_as_bitrate_flag                : z.boolean(),
        preset_use_nullfiller_on_ES_flag                  : z.boolean(),
        preset_auto_detelecine_flag                       : z.boolean(),
        compound_job                                      : z.boolean(),
        master_m3u8_created                               : z.boolean(),
        smil_file_created                                 : z.boolean(),
        abort_on_job_timeout                              : z.boolean(),
        debug_skip_checks                                 : z.optional(z.boolean()),
        cloud_credentials                                 : z.optional(z.string()),
        front_write_back_read                             : z.nullable(z.optional(z.string())),
        cur_state                                         : z.nullable(z.optional(jobStates)),
    })
    export type Input = z.infer<typeof input>

    export const output = z.object({
        id                                                : z.union([z.number(), z.array(z.number())]), // array??
        job_start_time                                    : z.string(),
        smil_file_created                                 : z.boolean(),
        output_location_match_input_filename              : z.boolean(),
        job_type                                          : z.string(),
        provider_id                                       : z.string(),
        input_location_delete_even_if_part_of_active_jobs : z.boolean(),
        input_location_dfs_uname                          : z.string(),
        job_setup_id                                      : z.number(),
        preset_auto_detelecine_flag                       : z.boolean(),
        enhanced_mode                                     : z.boolean(),
        preset_use_nullfiller_on_ES_flag                  : z.boolean(),
        preset_width                                      : z.number(),
        input_location_dfs_passwd                         : z.string(),
        input_location_mount_path_includes_subdir         : z.boolean(),
        input_media                                       : z.any(),
        asset_id                                          : z.string(),
        relative_url_to_output_files                      : z.string(),
        job_timeout_time                                  : z.string(),
        output_folder                                     : z.union([z.array(z.string()), z.string()]),
        preset_video_muxrate                              : z.number(),
        job_priority                                      : z.string(),
        output_location_folder_create                     : z.boolean(),
        webserver_ipaddr                                  : z.string(),
        input_storage_location                            : z.string(),
        compound_job                                      : z.boolean(),
        hasError                                          : z.optional(z.boolean()),
        output_location_dfs_uname                         : z.string(),
        preset_use_muxrate_as_bitrate_flag                : z.boolean(),
        input_location_mount_path                         : z.any(),
        job_params                                        : z.optional(CommonConfig.output),
        output_location_delete_even_if_part_of_active_jobs: z.boolean(),
        output_location_mount_parent_path_flag            : z.boolean(),
        errors                                            : z.optional(z.object({
            config   : z.any(),
            locations: z.any(), // map from location id to string error
        })),
        abort_on_job_timeout                 : z.boolean(),
        input_location_id                    : z.union([z.array(z.number()), z.number()]),
        job_timeout_sec                      : z.number(),
        preset_height                        : z.number(),
        num_output_files                     : z.number(),
        input_location_mount_parent_path_flag: z.boolean(),
        output_location_mount_path           : z.any(),
        output_location_dfs_passwd           : z.string(),
        master_m3u8_created                  : z.boolean(),
        cur_state                            : z.string(),
        input_frames_processed_per_sec       : z.number(),
        output_location_copy_companion_files : z.boolean(),
        output_location_nfs_path             : z.any(),
        has_error                            : z.boolean(),
        error_description                    : z.string(),
        input_location_nfs_path              : z.any(),
        needs_manual_qc                      : z.boolean(),
        is_complete                          : z.boolean(),
        simple_job_list                      : z.string(),
        cloud_credentials                    : z.optional(z.string()),
        front_write_back_read                : z.nullable(z.optional(z.string())),
        back_write_front_read                : z.nullable(z.optional(z.string())),
        media_input_duration_in_sec          : z.optional(z.number()),
    })
    export type Output = z.infer<typeof output>
}

// matches the db
export const jobModel = z.object({
    _auto_generated_id_           : z.number(),
    cloud                         : cloudTag,
    cloud_local_id                : z.nullable(z.number()), // maybe null before scheduler gets to it
    config                        : simplifiedJobInput,
    configOriginal                : simplifiedJobInput,
    streamengine_job_get_response : z.nullable(Job.output),
    create_job_response           : z.nullable(Job.output),
    status                        : z.nullable(statusWithStringId),
    version                       : z.number(),
    created_at                    : z.coerce.date(),
    finished_at                   : z.nullable(z.coerce.date()),
    updated_at                    : z.coerce.date(),
    usage_amount_reported_in_cents: z.nullable(z.number()),
})

export type JobModel = z.infer<typeof jobModel>

export type Template = {
    body: string,
    name: string
    id: string
}

export type File = {
    name: string
    id: string
    type: string
    filetype: string
    version: number
    created_at: string | null
    deleted: boolean | null
    size: number | null
}

export type OutputAsset = {
    path: string,
    job: number
    created_at: string
    deleted: boolean
}

export const jobCreateErrors = z.discriminatedUnion('error', [
    z.object({ error: z.literal('job-limit-reached') }),
    z.object({ error: z.literal('time-limit-reached') }),
    z.object({ error: z.literal('validation-error'), message: z.string() }),
])
export const isJobLimitReached = (x: object): x is { error: 'job-limit-reached' } => 'error' in x && x.error == 'job-limit-reached'
export const isTimeLimitReached = (x: object): x is { error: 'time-limit-reached' } => 'error' in x && x.error == 'time-limit-reached'
export const isValidationError = (x: object): x is { error: 'validation-error', message: string } => 'error' in x && x.error == 'validation-error' && 'message' in x

export type JobCreateErrors = z.infer<typeof jobCreateErrors>


export const input_media = z.object({
    video_set: z.array(z.object({
        // codec: z.string(),
        width                    : z.number(),
        height                   : z.number(),
        bitrate_in_kbps          : z.union([z.number(), z.string()]),
        frames_per_second        : z.union([z.string(), z.number()]),
        duration_in_sec          : z.number(),
        num_frames               : z.number(),
        progressive_or_interlaced: z.any() })),
})

export const scenario = (data: Partial<Pick<SimplifiedJobInput, 'segmented_output_dash' | 'separate_audio' | 'segmented_output_hls'>>): 1 | 2 | 3 => {
    if(data.segmented_output_dash == false && data.segmented_output_hls == false && data.separate_audio == false)  {
        return 1
    } else if (data.segmented_output_dash == false && data.segmented_output_hls == false && data.separate_audio == true) {
        return 2
    } else {
        return 3
    }
}
