/* eslint-disable @typescript-eslint/no-misused-promises */
import 'video.js/dist/video-js.css'

import { flip, offset, shift } from '@floating-ui/dom'
import { autoUpdate } from '@floating-ui/dom'
import { ColumnDef } from '@tanstack/solid-table'
import * as commonSchema from 'common/src/schema'
import { useFloating } from 'solid-floating-ui'
import { createEffect, createMemo, createSignal, JSX, Show } from 'solid-js'
import { style } from 'typestyle'

// <- HLS support requires the full bundle
import { em } from '../helpers'
import { header } from '../style'
import { useAuthenticatedContext } from './context'
import { Content } from './Library'
import { signalFromPrefix } from './replicache'
import { makeTable } from './table'


// function setupPlayer(node: Rea.MutableRefObject<null>, src: string, controls: boolean): Promise<videojs> {
//     return new Promise((resolve, _) => {
//         const vjsPlayer = videojs(node.current, getOpts(controls), () => {
//             vjsPlayer.src(getSrc(src))
//             resolve(vjsPlayer)
//         })
//     })
// }

function getOpts(controls: boolean): any {
    return {
        controls: controls || false,

        fluid   : true,
        autoplay: false,
        preload : 'auto',

        // html5: {
        //     hlsjsConfig: {},
        //     hls        : {
        //         enableLowInitialPlaylist: true,
        //         smoothQualityChange     : true,
        //         overrideNative          : true,
        //     },
        // },
    }
}

const defined = <T extends any>(x: T | undefined | null) : x is T => x !== undefined && x !== null
// USE THIS  https://github.com/TanStack/table
export const FileManagement = (): JSX.Element => {
    const { client, rep } = useAuthenticatedContext()

    const getOutputAssets = signalFromPrefix<[id: string, t:commonSchema.OutputAsset]>(rep, 'outputAsset/')

    // TODO: lift filter, show whole job with reasonable defaults if not sent to se yet
    const jobStatuses = createMemo(() => getOutputAssets().filter(x => !x[1].deleted).map(j => j[1]).sort((a, b) => a.job - b.job),
        undefined,
        { equals: (prev, next) => JSON.stringify(prev) == JSON.stringify(next) })

    // const jobStatuses: Accessor<{
    //     fileId: string, fileName: string, fileSize: string
    // }[]> = createMemo(() => [{ fileId: 'd4d34cc31797364b0304a11dde928d6a', fileName: 'uploads/godzilla_720p5994_h264_7Mbps_vbr200_60min.ts', fileSize: '2.2' }],
    //     undefined,
    //     { equals: (prev, next) => JSON.stringify(prev) == JSON.stringify(next) })

    createEffect(() => {
        console.log('jobs', jobStatuses())
    })
    const [selected, setSelected] = createSignal<Set<string>>(new Set())

    const defaultColumns: ColumnDef<commonSchema.OutputAsset>[] = [
        { id  : 'select',
          cell: (info) => {
              return <input type="checkbox" onChange={(e) => {
                  if(e.target.checked) {
                      setSelected(new Set([...Array.from(selected()), info.row.original.path]))
                  } else {
                      setSelected(new Set(Array.from(selected()).filter(x => x != info.row.original.path)))
                  }
                  console.log(Array.from(selected()))
              }}></input>
          },
          header: '',
        },
        // { accessorKey: 'fileId',
        //   cell       : info => <span style="color: #aaa">{info.getValue() as string}</span>,
        //   sortingFn  : (a, b) => Number(a.original.fileId) - Number(b.original.fileId),
        //   header     : 'File ID',
        // },
        { accessorKey: 'path',
          cell       : info => {
              if(info.getValue<commonSchema.OutputAsset>().deleted) {
                  return <></>
              } else {
                  const bits = (info.renderValue() as string).split('/')
                  const fileName = bits[bits.length - 1]
                  const path = `https://objectstorage.ca-toronto-1.oraclecloud.com/n/yzwhip2gci7s/b/igolgi-public/o/${fileName}`
                  return <a href={path} download>{fileName}</a>
              }
          },
            //   sortingFn  : (a, b) => Number(a.original.fileId) - Number(b.original.fileId),
          header: 'File Path',
        },
        // { accessorKey: 'fileSize',
        //   cell       : info => <span>{info.getValue() as string}&nbsp;GB</span>,
        //     //   sortingFn  : (a, b) => Number(a.original.fileId) - Number(b.original.fileId),
        //   header     : 'File Size',
        // },
        // { accessorFn: j => j?.cloud,
        //   header    : 'Cloud',
        // },

    ]

    const Table = makeTable(jobStatuses, defaultColumns)
    const name = (c: ColumnDef<any>): string => {
        return ('accessorKey' in c ? c.accessorKey  as string : c.id) ?? ''
    }

    const [reference, setReference] = createSignal<any>()
    const [floating, setFloating] = createSignal<any>()

    // `position` is a reactive object.
    const position = useFloating(reference, floating, {
        whileElementsMounted: autoUpdate,
        placement           : 'right',
        strategy            : 'fixed',
        middleware          : [offset(10), flip(), shift()],
    })
    // setInterval(() => {
    //     position.update()
    // }, 1000)

    return <div style={{ padding: '1em', width: '100%' }}>
        <Content>
            <div style={{  }}>
                <h1 class={header}>FileManagement</h1>
                {/* <video id="foo1" ref={(e) => {
                    setTimeout(() => {
                        const vjsPlayer = videojs(document.getElementById('foo1'), getOpts(true), () => {
                            vjsPlayer.src({
                                src : 'https://objectstorage.ca-toronto-1.oraclecloud.com/n/yzwhip2gci7s/b/igolgi-public/o/62c14d3e-73b9-4ab8-9297-3c0aeb8b8c12.mp4',
                                type: 'video/mp4',
                            })
                        // resolve(vjsPlayer)
                        })
                    }, 100)
                }}></video> */}
                {/* <div>
                    <button ref={setReference}>Button</button>
                    <div
                        ref={setFloating}
                        style={{
                            position : position.strategy,
                            top      : `${position.y ?? 0}px`,
                            left     : `${position.x ?? 0}px`,
                            'z-index': 1000,
                        }}
                    >
        Tooltip!
                    </div>
                </div> */}
                <br></br>
                {Table}
                <div style={{ 'margin-top': '20px' }}>
                    <button disabled={selected().size == 0} onClick={async () => {
                        for(const path of selected()) {
                            await rep()?.mutate.oracleAssetDelete({ path })
                        }
                    }}>Delete</button>
                </div>
                <div style={{ 'padding-top': em(1) }}>Download as <a href="#" onClick={() => {
                    function downloadCSV(data: string, filename: string) {
                        const blob = new Blob([data], { type: 'text/csv' })
                        const url = URL.createObjectURL(blob)
                        const link = document.createElement('a')
                        link.href = url
                        link.download = filename
                        link.click()
                        URL.revokeObjectURL(url)
                    }
                    const cols = defaultColumns.filter(x => (x.header as string).length > 0)
                    const header = cols.map(x => x.header as string)
                    const props = cols.map(name) as (keyof commonSchema.OutputAsset)[]
                    const data = [
                        header.join(', '),
                        ...jobStatuses().map(x => props.map(c => x[c]).join(', '))]
                        .join('\n')
                    console.log(header, data)
                    // todo: render costs as well!
                    downloadCSV(data, 'data.csv')
                }}>CSV</a>.</div>

            </div>
        </Content>
    </div>
}
const button = style({
    opacity   : 0.3,
    padding   : '0em',
    lineHeight: 1,
    $nest     : {
        '&:hover': {
            opacity: 1,
        },
    },
})

const date = (now: Date, other?: any) => {
    if(isNaN(now.getTime())) return '...'
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const date = String(now.getDate()).padStart(2, '0')
    const hour = String(now.getHours()).padStart(2, '0')
    const minute = String(now.getMinutes()).padStart(2, '0')

    const formattedDate = `${year}-${month}-${date} ${hour}:${minute}`
    return formattedDate
}

type StateValue = 'abort' | 'error' | 'ongoing' | 'success'
const State = (p: { state: StateValue, message?: string }): JSX.Element => {
    const [showMessage, setShowMessage] = createSignal(false)
    const shouldShowMessage = createMemo(() => showMessage() && (p.message ?? '').length > 1)
    const state = p.state ?? 'other'
    let colors = ['black', 'white']
    if(state == 'error') colors = ['white', 'red']
    else if(state == 'abort') colors = ['black', 'gray']
    else if(state == 'ongoing') colors = ['black', 'orange']
    else if(state == 'success') colors = ['white', 'green']
    return <div
        onmouseover={() => setShowMessage(true)}
        onmouseout={() => setShowMessage(false)}
        style={{
            'font-size'       : '0.75em',
            'padding'         : '0em',
            'border-radius'   : '3px',
            'line-height'     : '1.2em',
            'text-align'      : 'center',
            'background-color': colors[1],
            'color'           : colors[0],
            cursor            : 'pointer',
        }}>
        <Show when={shouldShowMessage()}>
            <div style={{
                'margin-top'      : '20px',
                position          : 'absolute',
                'background-color': 'white',
                border            : '1px solid gray',
                'border-radius'   : '3px',
                color             : 'black',
                padding           : '1em',
                width             : '75%',
                'text-align'      : 'left',
                'font-size'       : '1rem',
            }}>{p.message}</div>
        </Show>
        {state}
    </div>
}

const Config = ({ config }: { config: commonSchema.SimplifiedJobInput }): JSX.Element => {
    const [pinned, setPinned] = createSignal(false)
    const show = () => pinned()
    return <span>
        <a onClick={() => setPinned(!pinned()) } href="#">Config</a>
        <Show when={show()}>
            <div style={{
                position          : 'absolute',
                padding           : '1em',
                left              : 0,
                border            : '1px solid gray',
                'z-index'         : 2000,
                width             : '100%', 'background-color': 'white' }}>
                <pre>{JSON.stringify(config, null, 2)}</pre>
            </div>
        </Show></span>
}

