import { escapeOwner } from 'common'
import { For, JSX } from 'solid-js'

import { header, table } from '../style'
import { useAuthenticatedContext } from './context'
import { Button, Content } from './Library'
import { signalFromPrefix } from './replicache'

type Props =  {
}

export const Keys = (p: Props): JSX.Element => {
    const { rep } = useAuthenticatedContext()
    const getKeys = signalFromPrefix<[id: string, key: string]>(rep, 'key/')


    return <div style={{ padding: '1em', width: '100%', height: '100vh' }}>
        <Content>
            <div style={{  }}>
                <h1 class={header}>API Keys</h1>
                <table class={table}>
                    <thead>
                        <tr>
                            <td>#</td>
                            <td>Key</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        <For each={getKeys()}>{(key, i) => {
                            return <tr>
                                <td>{i() + 1}</td>
                                <td>{key[1]}</td>
                                <td><a href="#" style={{ 'text-decoration': 'none' }}
                                    onClick={(e) => escapeOwner(async () => {
                                        e.preventDefault()
                                        if(getKeys().length == 1) {
                                            alert('You must keep at least one key.')
                                        } else {
                                            await rep()?.mutate.keyDelete({ id: key[0] })
                                        }
                                    })}>❌</a></td>
                            </tr>
                        }}</For>
                    </tbody>
                </table>
                <Button onClick={(e) => escapeOwner(async () => {
                    e.preventDefault()
                    await rep()?.mutate.keyAdd({})
                    // const newKey = await client.keysPOST.query()
                    // setKeys([...getKeys(), newKey])
                })}>
                    New Key</Button>
            </div>
        </Content>
    </div>
}

