import { has } from 'api/src/is'
import * as commonSchema from 'common/src/schema'
import { z, ZodIssue, ZodIssueCode } from 'zod'
type PartialExcept<T, K extends keyof T> = Partial<Omit<T, K>> & Pick<T, K>;
export type Data = PartialExcept<commonSchema.SimplifiedJobInput, 'videoProfiles' | 'audioProfiles' | 'cloud_credentials'>
type KSimple =
    |   keyof Data
type KList =
    |  `videoProfiles[*]` | `videoProfiles[*].${keyof (Data['videoProfiles'][number])}`
    |  `audioProfiles[*]` | `audioProfiles[*].${keyof (Data['audioProfiles'][number])}`
type K = KSimple | KList
const isKList = (k: K): k is KList => k.indexOf('[*]') > -1
type Condition = [K | 'scenario' | 'frameratesAvailable' | 'audioProfiles.length' | `cloud_credentials.output.cloud_provider`, '==' | '>' | '<' | '!=' | '!%', string | boolean | number | null | ((data: Data) => number)]
type Condition_ = [Condition[0] | 'videoProfiles.length' | 'audioProfiles.length', Condition[1], Condition[2]]
type Disjunction = ['or', Condition_[][]]
type ContingentFields = { 'show': K, 'if-and-only-if': Condition[], comment?: string }[]
type If = Condition
type Then = [K, '=', boolean | string | number]
type ForcedFields = { 'if': Condition_ [], 'then-set': Then, comment?: string }
type Errors = { 'if': (Condition_ | Disjunction)[], 'errorMessage': string, errorPath: K[], comment?: string }[]
export type Constraints = { 'contingent': ContingentFields, forced: ForcedFields[], errors: Errors }
export const constraints: Constraints = {
    'contingent': [
        { 'show'          : 'ts_mode_input_audio_source_stream_count',
          'if-and-only-if': [['separate_audio', '==', false],
                             ['output_container', '==', 'ts'],
                             ['audioProfiles.length', '>', 0]] },
        { 'show'          : 'audio_output_path',
          'if-and-only-if': [['scenario', '==', 2], ['audioProfiles.length', '>', 0], ['cloud_credentials.output.cloud_provider', '!=', 'igolgi-store']] },
        { 'show'          : 'audio_volume',
          'if-and-only-if': [['audioProfiles.length', '>', 0]],
          'comment'       : 'Audio related fields should be disabled and not required when no audio profile is selected or specified' },
        { 'show'          : 'bucket_output_path',
          'if-and-only-if': [['scenario', '==', 3], ['cloud_credentials.output.cloud_provider', '!=', 'igolgi-store']] },
        { 'show'          : 'segment_length',
          'if-and-only-if': [['scenario', '==', 3]] },
        // { 'show'          : 'videoProfiles[*].profile_name',
        //   'if-and-only-if': [['scenario', '==', 3],
        //                      ['cloud_credentials.output.cloud_provider', '!=', 'igolgi-store']] },
        { 'show'          : 'videoProfiles[*].output',
          'if-and-only-if': [['scenario', '!=', 3],
                             ['cloud_credentials.output.cloud_provider', '!=', 'igolgi-store']] },
        { 'show'          : 'videoProfiles[*].audio_profiles',
          'if-and-only-if': [['separate_audio', '==', false],
                             ['output_container', '==', 'mp4']] },
        { 'show'          : 'audioProfiles[*].source_stream',
          'if-and-only-if': [['output_container', '!=', 'ts']],
        },
        { 'show'          : 'h264_quality',
          'if-and-only-if': [['video_codec', '==', 'h.264']],
          'comment'       : 'Only allow selection of quality settings for H264 output',
        },
        { 'show'          : 'rotation_blackness',
          'if-and-only-if': [['video_aspect_ratio', '!=', '0'], ['video_aspect_ratio', '!=', '1'], ['video_aspect_ratio', '!=', '2']],
          'comment'       : 'rotation_blackness is only visible for some values of video_aspect_ratio',
        },
        { 'show'          : 'audioProfiles[*].dolby_digital_dialnorm',
          'if-and-only-if': [['audioProfiles[*].audio_codec', '==', 'ac3']] },
        { 'show'          : 'scte35_passthough',
          'if-and-only-if': [['output_container', '==', 'ts']] },
        { 'show'          : 'scte35_pid_remap',
          'if-and-only-if': [['output_container', '==', 'ts']] },
        { 'show'          : 'create_tar_file',
          'if-and-only-if': [['scenario', '==', 3]] },

    ],

    'forced': [
        { 'if'      : [['output_container', '==', 'ts']],
          'then-set': ['separate_audio', '=', false] },

        { 'if'      : [['scenario', '==', '3']],
          'then-set': ['separate_audio', '=', true] },
        { 'if'      : [['scenario', '==', 3], ['cloud_credentials.output.cloud_provider', '==', 'igolgi-store']],
          'then-set': ['create_tar_file', '=', true] },

        // { 'if': [['output_container', '==', 'mp4'],
        //          ['video_codec', '==', 'h.264']],
        //   'then-set': ['master_variant_mode', '=', true],
        //   'comment' : 'Guillem (Apr 26, 2024): You can remove it. I think this was the case for hevc. I dont know why we have it for h264 too' },

        // Removing this constraint as per Skype chat: Guillem: You can get rid of this. I can't remember why we set it that way
        // { 'if'      : [['videoProfiles.length', '>', 1]],
        //   'then-set': ['picture_transform', '=', 'detelecine/deinterlace'] },

        { 'if': [['video_codec', '==', 'mpeg2'],
                 ['scte35_passthough', '==', true]],
          'then-set': ['gop_length', '=', 1] },
        // { 'if'      : [['force_progressive', '==', false]],
        //   'then-set': ['keep_fps', '=', true] },
    ],
    'errors': [
        // FORMERLY: “HEVC video codec in combination with MP4 format must only be allowed with separate audio”
        // CHANGED TO: HEVC video codec in combination with MP4 format must now also be allowed with embedded audio
        // So then this rule disappears.
        // { 'if'          : [['video_codec', '==', 'hevc'], ['output_container', '==', 'mp4'], ['master_variant_mode', '==', false]],
        //   'errorMessage': 'HEVC codec is not supported with MP4 without separate audio',
        //   'errorPath'   : ['video_codec'] },
        { 'if'          : [['separate_audio', '==', false], ['audioProfiles.length', '>', 0], ['videoProfiles[*].audio_profiles', '==', '']],
          'errorMessage': 'In embedded audio mode at least one audio profile must be selected',
          //@ts-ignore
          'errorPath'   : ['videoProfiles', '?', 'audio_profiles'] },
        // MPEG2 video codec must not be allowed in combination with MP4 output format
        { 'if'          : [['video_codec', '==', 'mpeg2'], ['output_container', '==', 'mp4']],
          'errorMessage': 'MPEG2 codec is not allowed with MP4, HLS, or DASH output.',
          'errorPath'   : ['video_codec'] },
        //   Only HEVC codec should allow resolutions beyond HD
        // 1280 x 720
        // HD: 1920 * 1080
        { 'if'          : [['videoProfiles[*].width', '!%', 8]],
          'errorMessage': 'The output height must be a multiple of 8.',
            //@ts-ignore
          'errorPath'   : ['videoProfiles', '?', 'width'] },
        { 'if'          : [['videoProfiles[*].height', '!%', 8]],
          'errorMessage': 'The output height must be a multiple of 8.',
            //@ts-ignore
          'errorPath'   : ['videoProfiles', '?', 'height'] },

        // { 'if'          : [['video_codec', '!=', 'hevc'], ['videoProfiles[*].width', '>', 1920]],
        //   'errorMessage': 'Only HEVC allows resolutions beyond HD.',
        //     //@ts-ignore
        //   'errorPath'   : ['videoProfiles', '?', 'width'] },

        // { 'if'          : [['video_codec', '!=', 'hevc'], ['videoProfiles[*].height', '>', 1080]],
        //   'errorMessage': 'Only HEVC allows resolutions beyond HD.',
        //     //@ts-ignore
        //   'errorPath'   : ['videoProfiles', '?', 'height'] },

        { 'if': [
            ['frameratesAvailable', '==', '1x'],
            ['videoProfiles[*].video_framerate', '==', '1/2x']],
          'errorMessage': 'Only 1x framerate is compatible with your settings.',
            //@ts-ignore
          'errorPath'   : ['videoProfiles', '?', 'video_framerate'] },
        { 'if': [
            ['frameratesAvailable', '==', '1x'],
            ['videoProfiles[*].video_framerate', '==', '2x']],
          'errorMessage': 'Only 1x framerate is compatible with your settings.',
            //@ts-ignore
          'errorPath'   : ['videoProfiles', '?', 'video_framerate'] },
        { 'if': [
            ['frameratesAvailable', '==', '1x_1/2x'],
            ['videoProfiles[*].video_framerate', '==', '2x']],
          'errorMessage': 'Only 1x and 1/2x framerates are compatible with 1080+ or 720+ while force_progressive == true and keep_fps == false.',
            //@ts-ignore
          'errorPath'   : ['videoProfiles', '?', 'video_framerate'] },

        // if there exists at least one audio profile and audio stream count = 0 then error
        { 'if'          : [['ts_mode_input_audio_source_stream_count', '==', 0], ['audioProfiles.length', '>', 0], ['ts_mode_input_audio_source_stream_count', '==', null]],
          'errorMessage': 'When audio profiles are present, this value must be greater than 0.',
          //@ts-ignore
          'errorPath'   : ['ts_mode_input_audio_source_stream_count'] },

        { 'if'          : [['audio_output_path', '==', null], ['audioProfiles.length', '>', 0], ['scenario', '==', 2], ['cloud_credentials.output.cloud_provider', '!=', 'igolgi-store']],
          'errorMessage': 'When audio profiles are present, audio_output_path be non-null.',
          //@ts-ignore
          'errorPath'   : ['audio_output_path'] },
        { 'if'          : [['scenario', '==', 3], ['bucket_output_path', '==', null], ['cloud_credentials.output.cloud_provider', '!=', 'igolgi-store']],
          'errorMessage': 'Bucket Output Path must be specified in segmented modes.',
          'errorPath'   : ['bucket_output_path'] },

        { 'if'          : [['ts_mode_input_audio_source_stream_count', '>', 1], ['audioProfiles.length', '>', 1], ['output_container', '==', 'ts']],
          'errorMessage': 'When Audio Source Stream Count > 1, you can have at most one audio profile. Remove some audio profiles or set Audio Source Stream Count to 1.',
          'errorPath'   : ['audioProfiles'],
          'comment'     : 'For TS output format, only one audio profile can be specified.' },
        { 'if'          : [['ts_mode_input_audio_source_stream_count', '==', null], ['audioProfiles.length', '>', 1], ['output_container', '==', 'ts']],
          'errorMessage': 'When Audio Source Stream Count is not set, you can have at most one audio profile. Remove some audio profiles or set Audio Source Stream Count to 1.',
          'errorPath'   : ['audioProfiles'],
          'comment'     : 'Guillem: when this values is not set, we also want to have at maximum one audio profile if we are in .ts mode' },
        { 'if'          : [['ts_mode_input_audio_source_stream_count', '==', 0], ['audioProfiles.length', '>', 0]],
          'errorMessage': 'No audio profiles are allowed because Audio Source Stream Count is set to 0.',
          'errorPath'   : ['audioProfiles'],
        },
        { 'if'          : [['output_container', '==', 'ts'], ['ts_mode_input_audio_source_stream_count', '>', 3]],
          'errorMessage': 'The Audio Source Stream count cannot exceed 3.',
          'errorPath'   : ['ts_mode_input_audio_source_stream_count'],
        },
        { 'if'          : [['audioProfiles.length', '>', 9]],
          'errorMessage': 'At most 9 audio profiles are allowed.',
          'errorPath'   : ['audioProfiles'],
        },
        // https://igolgi.atlassian.net/browse/CU-336
        { 'if'          : [['audioProfiles[*].audio_codec', '==', 'aac'], ['audioProfiles[*].audio_bitrate', '<', 64]],
          'errorMessage': 'The minimum bitrate for aac is 64kbps.',
            //@ts-ignore
          'errorPath'   : ['audioProfiles', '?', 'audio_bitrate'] },
        // https://igolgi.atlassian.net/browse/CU-336
        { 'if'          : [['audioProfiles[*].audio_codec', '==', 'ac3'], ['audioProfiles[*].audio_channels', '==', 2], ['audioProfiles[*].audio_bitrate', '<', 128]],
          'errorMessage': 'The minimum bitrate for ac3 with 2 channels is 128kbps.',
            //@ts-ignore
          'errorPath'   : ['audioProfiles', '?', 'audio_bitrate'] },
        // https://igolgi.atlassian.net/browse/CU-336
        { 'if'          : [['audioProfiles[*].audio_codec', '==', 'ac3'], ['audioProfiles[*].audio_channels', '==', 6], ['audioProfiles[*].audio_bitrate', '<', 256]],
          'errorMessage': 'The minimum bitrate for ac3 with 5.1 channels is 256kbps.',
            //@ts-ignore
          'errorPath'   : ['audioProfiles', '?', 'audio_bitrate'] },
    ],
}

export const changeForcedToErrors = (c: Constraints, data: Data): z.ZodError<Data> => {
    const err = new z.ZodError<Data>([])
    c.forced.forEach(x => {
        const { 'if': if_, 'then-set': then_ } = x

        const condition = evalRHS(data, if_) && !evalCondition(data, [x['then-set'][0], '==', x['then-set'][2]])
        if(condition) {
        // cl.errors.push({ if: x.if, errorMessage: , errorPath:  })
            const newError: ZodIssue = {
                path   : [x.if[0].toString()],
                message: `expecting ${x['then-set'][0]} ${x['then-set'][1]} ${x['then-set'][2].toString()}`,
                code   : ZodIssueCode.custom,
            }
            err.addIssue(newError)
        }
    })
    return err
}

export const get = (data: Data, path: string, i?: number): any => {
    if(path == '') return data
    else if(path == 'scenario') {
        return commonSchema.scenario(data)
    } else if (path == 'frameratesAvailable') {
        return frameratesAvailable(data, i)
    } else {
        const bits = path.split('.')
        let out: any = data
        for(const b of bits) {
            const key = b.replace('[*]', '')
            const requiresKey = b.indexOf('[*]') > -1
            if(out[key] == undefined) {
                // throw Error(`Could not find path: '${path}', missing ${key}`)
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            out = out[key]
            if(requiresKey){
                if(i == undefined) {
                    throw Error(`No key found for ${path}`)
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    out = (out as any[])[i]
                }
            }
        }
        return out
    }
}
export const set = (data: Data, path: string, value: string | boolean | number): boolean => {
    const head = path.split('.')
    const last = head.pop()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const p = get(data, head.join('.'))
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    if(p != undefined && last != undefined && (p)[last] != value) {
        (p)[last] = value
        return true
    }else {
        return false
    }
}

const evalRHSArray = (data: Data, rhs: (Condition_ | Disjunction)[]): boolean[] | null => {
    const m = rhs.find(x => x[0].indexOf('[*]') > -1)
    if(m == null) {
        return null
    } else {
        const p = m[0].split('[*]')[0]
        const list = get(data, p) as any[]
        return list.map((_, i) => evalRHS(data, rhs, i))
    }
}

const evalRHS = (data: Data, rhs: (Condition_ | Disjunction)[], i?: number): boolean => {
    return rhs.map((c) => evalCondition(data, c, i)).filter(x => x == false).length == 0
}
const lhs = (data: Data, s: Condition_[0], i?: number): any => {
    if(s.endsWith('.length')){
        return (get(data, s.replace('.length', ''), i)  as any[]).length
    } else {
        return get(data, s, i)
    }
}
const pred = (s: Condition[1]): ((a: any, b: any) => boolean) => {
    if(s == '!=') return ((a, b) => a != b)
    else if (s == '==') return ((a, b) => a == b)
    else if (s == '>') return ((a, b) => a > b)
    else if (s == '<') return ((a, b) => a < b)
    else if (s == '!%') return ((a, b) => (a % b) != 0)
    else throw Error(`Unsupporrted operator ${s as string}`)
}
const evalCondition = (data: Data, cond: (Condition_ | Disjunction), i?: number): boolean => {
    if(cond[0] == 'or'){
        return any(cond[1].map(x => evalRHS(data, x, i)))
    } else {
        const rhsValue = typeof cond[2] === 'function'
            ? (cond[2] as (data: Data) => number)(data)
            : cond[2]
        return pred(cond[1])(lhs(data, cond[0], i), rhsValue)
    }
}
const any = (t: boolean[]): boolean => t.indexOf(true) > -1
export const enforceConstraintsNew = (data: Data, constraints: Constraints, toast?: (_: string)=> void): z.ZodError<Data> | undefined => {
    // contingent constraints
    for(const { show, 'if-and-only-if': iff } of constraints.contingent){
        if(isKList(show)) {
            const [left, right] = show.split('[*].')
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            for(const [i, _] of Object.entries(get(data, left))) {
                const condition = evalRHS(data, iff, Number(i))
                if(show == 'videoProfiles[*].audio_profiles') {
                    console.log(show, iff, i, condition)
                //     console.log(33)
                //     debugger
                }
                contingent((get(data, left)  as any[])[Number(i)], right, condition)
            }
        } else {
            const condition = evalRHS(data, iff)
            contingent(data, show, condition)
        }
    }
    // forced constraints
    for(const constr of constraints.forced){
        const { 'if': if_, 'then-set': then_ } = constr
        const condition = evalRHS(data, if_)
        if(then_[0] == 'create_tar_file') {
            console.log('>>', if_, condition)
        }
        if(condition) {
            const changeMade = set(data, then_[0], then_[2])
            if(changeMade) {
                toast?.(explain(constr))
            }
        }
    }
    // errors
    const err = new z.ZodError<Data>([])

    let hasErrors = false
    for(const { 'if': if_, errorMessage, errorPath } of constraints.errors) {
        const arrayCondition = evalRHSArray(data, if_)
        if(arrayCondition == null) {
            const condition = evalRHS(data, if_)
            if(condition == true) {
                hasErrors = true
                const newError: ZodIssue = {
                    path   : errorPath,
                    message: errorMessage,
                    code   : ZodIssueCode.custom,
                }
                err.addIssue(newError)
            }
        } else {
            if(arrayCondition.findIndex(x => x) > -1) {
                arrayCondition.forEach((val, i) => {
                    if(val == true) {
                        hasErrors = true
                        const path = [...errorPath]
                        const index = path.indexOf('?' as any as K)
                        if (index !== -1) {
                            path[index] = i as any as K
                        } else {
                            throw Error("could not find '?' placeholder in path")
                        }
                        const newError: ZodIssue = {
                            path   : path,
                            message: errorMessage,
                            code   : ZodIssueCode.custom,
                        }
                        err.addIssue(newError)
                    }
                })
            }
        }
    }

    data.videoProfiles.forEach((vp, i) => {
        const m = minBitrate(data, i)
        if(vp.video_bitrate != 0 && vp.video_bitrate < m) {
            hasErrors = true
            const newError: ZodIssue = {
                path   : ['videoProfiles', i, 'video_bitrate'],
                message: `The video bitrate for this profile and codec should set at a minimum of ${m}.`,
                code   : ZodIssueCode.custom,
            }
            err.addIssue(newError)
        }
        const maxBitrate = minBitrate(data, i) * 10
        if(vp.video_bitrate != 0 && vp.video_bitrate > maxBitrate) {
            hasErrors = true
            const newError: ZodIssue = {
                path   : ['videoProfiles', i, 'video_bitrate'],
                message: `The video bitrate for this profile and codec should set at a maximum of at most ${maxBitrate}.`,
                code   : ZodIssueCode.custom,
            }
            err.addIssue(newError)
        }
        const maxPixelCount = 8294400
        if(vp.width * vp.height > maxPixelCount) {
            hasErrors = true
            const newError: ZodIssue = {
                path   : ['videoProfiles', i, 'width'],
                message: `The product of width and height cannot exceed ${maxPixelCount}.`,
                code   : ZodIssueCode.custom,
            }
            err.addIssue(newError)
        }
        const maxAspectRatio = 7
        if(vp.width != 0 && vp.height != 0 && (vp.width / vp.height > maxAspectRatio || vp.height / vp.width > maxAspectRatio)) {
            hasErrors = true
            const newError: ZodIssue = {
                path   : ['videoProfiles', i, 'width'],
                message: `The maximum aspect ratio cannot exceed ${maxAspectRatio}:1`,
                code   : ZodIssueCode.custom,
            }
            err.addIssue(newError)
        }
        // https://igolgi.atlassian.net/jira/software/c/projects/CU/boards/25?assignee=5f0c8ae19e8ba30016b51b31&selectedIssue=CU-276
        if(data.video_codec == 'mpeg2' && (vp.width > 1920 || vp.height > 1080)) {
            hasErrors = true
            const newError: ZodIssue = {
                path   : ['videoProfiles', i, 'width'],
                message: `mpeg2 jobs cannot exceed a resolution of 1920x1080`,
                code   : ZodIssueCode.custom,
            }
            err.addIssue(newError)
        }
    })


    const bits = (data.input ?? '').split('/')
    if(bits.length > 0 && bits[bits.length - 1].length > 128){
        hasErrors = true

        const newError: ZodIssue = {
            path   : ['input'],
            message: 'File names longer than 128 characters are not allowed',
            code   : ZodIssueCode.custom,
        }
        err.addIssue(newError)
    }


    // switching codec type causes bitrate to become invalid.
    data.audioProfiles.forEach((data, i) => {
        if((data.audio_codec == 'ac3' && (commonSchema?.bitrates_ac3_?.find(x => x._def.value == data.audio_bitrate ?? []) == undefined))) {
            data.audio_bitrate = commonSchema.bitrates_ac3_[commonSchema.bitrates_ac3_.length - 1]._def.value.valueOf() as 32
        } else if((data.audio_codec == 'aac' && (commonSchema?.bitrates_aac_?.find(x => x._def.value == data.audio_bitrate ?? []) == undefined))) {
            data.audio_bitrate = commonSchema.bitrates_aac_[commonSchema.bitrates_aac_.length - 1]._def.value.valueOf() as 32
        }
    })

    const sourceStreamCount: Record<number, number> = {}
    data.audioProfiles.forEach(x => {
        if(x.source_stream != undefined) {
            if(!(x.source_stream in sourceStreamCount)) {
                sourceStreamCount[x.source_stream] = 0
            }
            sourceStreamCount[x.source_stream] += 1
        }
    })
    if(Object.keys(sourceStreamCount).length > 3 || Math.max(...Object.values(sourceStreamCount)) > 3) {
        hasErrors = true

        const newError: ZodIssue = {
            path   : ['audioProfiles'],
            message: 'At most 3 different source streams may be used, and each source stream may appear at most 3 times.',
            code   : ZodIssueCode.custom,
        }
        err.addIssue(newError)
    }
    for(const type of ['input', 'output']) {
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        for(const key of Object.keys(data.cloud_credentials[type])){
        // @ts-expect-error
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const val = data.cloud_credentials[type][key] as string
            if(val != undefined){
                try{
                    console.log('trying', val)
                    JSON.parse(JSON.stringify({ a: val }).replace('\\n', '\\\\'))
                } catch {
                    hasErrors = true
                    const newError: ZodIssue = {
                        path   : ['cloud_credentials', type, key],
                        message: 'The string contents of this field cannot be parsed.',
                        code   : ZodIssueCode.custom,
                    }
                    err.addIssue(newError)
                }
            }
        }
    }

    if((data.audioProfiles.length == 0)){
        data.videoProfiles.forEach((p, i) => {
            if(p.audio_profiles != undefined && p.audio_profiles.length > 0) {
                hasErrors = true

                const newError: ZodIssue = {
                    path   : ['videoProfiles', i, 'audio_profiles'],
                    message: 'Audio profile selection is not allowed if not audio profiles have been provided.',
                    code   : ZodIssueCode.custom,
                }
                err.addIssue(newError)
            }
        })
    }

    return hasErrors ? err : undefined
}
const explain = (f: ForcedFields): string => {
    const { 'if': iff, 'then-set': then_ } = f
    const explainCondition = (cond: Condition_): string => {
        return cond.join(' ')
    }
    return ` ${then_[0]} was forced to '${then_[2].toString()}' because ${iff.map(explainCondition).join(' and ')}`
}

export const contingent = <T extends Record<any, any>, K extends keyof T>(o: T, k: K, condition: boolean, value: T[K] | null = null): void => {
    if(condition) {
        if(!has.key(o, k)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            o[k] = value as any
        }
    } else {
        delete o[k]
    }
}

export type FrameratesAvailable = '1x' | '1x_1/2x' | '1x_1/2x_2x'

export const frameratesAvailable = (data: Data, index?: number): FrameratesAvailable => {
    let out: FrameratesAvailable = undefined!
    if((data.picture_transform == 'detelecine/deinterlace')
        && ((data.videoProfiles[index!].width == 1920 && data.videoProfiles[index!].height == 1080)
        || (data.videoProfiles[index!].width == 1280 && data.videoProfiles[index!].height == 720))){
        out = '1x_1/2x_2x'
    } else if((data.picture_transform == 'deinterlace')) {
        out = '1x'
    } else {
        out = '1x_1/2x'
    }
    console.log('FrameratesAvailable', out)
    return out
}


export const minBitrate = (data: Data, i: number, scalar: number = 1): number => {
    const x = data.videoProfiles[i].width * data.videoProfiles[i].height * scalar
    const isHD = true
    const low_bitrate = 0
    // const round = (x: number): number => Math.ceil(x / 250) * 250

    const limits = getLimits()
    const { min, def } = limits[data.output_container!]![data.video_codec!]

    return min(data.videoProfiles[i])
    // if(data.video_codec == 'mpeg2'){
    //     if(isHD) low_bitrate = round(0.0008680555556 * x + 2200)
    //     else low_bitrate = round(0.001283428478 * x + 559.5559819)
    // } else if (data.video_codec == 'h.264'){
    //     if(isHD) low_bitrate = low_bitrate = round(0.0008680555556 * x + 200)
    //     else low_bitrate =     low_bitrate = round(0 * x + 750)
    // } else{// hevc
    //     if(isHD) low_bitrate = round(0.0002386176337 * x + 518.7007874)
    //     else low_bitrate = round(0 * x + 750)
    // }
    // return low_bitrate // +20% with respect to the minimum
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getLimits = () => {
    const res = {
        mp4: {
            'h.264': {
                def: ({ width, height }: { width: number, height: number }) => {
                    const x = width * height
                    if(x < 307200)       return round(0.004215484509 * x - 1.287)
                    else if(x < 3686400) return round(0.002097434177 * x + 1242.165)
                    else                 return round(0.002940188172 * x - 1259.253)
                },
                min: ({ width, height }: { width: number, height: number }) => {
                    return round(0.40 * res.mp4['h.264'].def({ width, height }))
                },
            },
            'hevc': {
                def: ({ width, height }: { width: number, height: number }) => {
                    const x = width * height
                    if(x < 307200)       return round(0.004301643949 * x - 273.796)
                    else if(x < 3686400) return round(0.001459401954 * x - 968.152)
                    else                 return round(0.002250091374 * x - 507.895)
                },
                min: ({ width, height }: { width: number, height: number }) => {
                    return round(0.40 * res.mp4['hevc'].def({ width, height }))
                },
            },
            mpeg2: {
                def: ({ width, height }: { width: number, height: number }) => {
                    return 0
                },
                min: ({ width, height }: { width: number, height: number }) => {
                    return 0
                },
            },
        },
        ts: {
            'h.264': {
                def: ({ width, height }: { width: number, height: number }) => {
                    return round(Math.max(
                        1.5 * res.ts['h.264'].min({ width, height }),
                        res.mp4['h.264'].def({ width, height })))
                },
                min: ({ width, height }: { width: number, height: number }) => {
                    const x = width * height
                    if(x < 518400)        return round(0.000306066655 * x + 664.904)
                    else if (x < 3686400) return round(0.0008179470855 * x + 292.020)
                    else                  return round(0.0003146517167 * x - 1259.252971)
                },
            },
            'hevc': {
                def: ({ width, height }: { width: number, height: number }) => {
                    return round(Math.max(
                        1.5 * res.ts['h.264'].min({ width, height }),
                        res.mp4['h.264'].def({ width, height })))
                },
                min: ({ width, height }: { width: number, height: number }) => {
                    const x = width * height
                    if(x < 518400)       return round(0 * x + 750)
                    else if(x < 3686400) return round(0.0003036203491 * x  - 507.1101)
                    else                 return round(0.0002232774477 * x - 753.989)
                },
            },
            'mpeg2': {
                def: ({ width, height }: { width: number, height: number }) => {
                    return round(Math.max(
                        1.5 * res.ts['mpeg2'].min({ width, height }),
                        res.mp4['h.264'].def({ width, height })))
                },
                min: ({ width, height }: { width: number, height: number }) => {
                    const x = width * height
                    if(x < 518400) return round(0.001194791012 * x + 576.415)
                    else           return round(0.001569574137 * x + 911.715)
                },
            },
        },
    } as const

    return res
}
const round = (y: number): number => {
    if(y < 1000) return Math.ceil(y / 50) * 50
    else return Math.ceil(y / 100) * 100
}