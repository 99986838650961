import { ReadonlyJSONValue, ReadTransaction, Replicache } from 'replicache'
import { Accessor, createEffect, createSignal, onCleanup, Setter } from 'solid-js'

const subscribeToPrefix = <Value extends ReadonlyJSONValue>(rep: Accessor<Replicache | undefined>, prefix: string, set: Setter<[string, Value][]>): void => {
    createEffect(() => {
        const r = rep()
        if(r != undefined) {
            const cancel = r.subscribe(async (tx: ReadTransaction) => {
                return await tx.scan({ prefix }).entries().toArray() as [string, Value][]
            }, {
                onData: (keys) => {
                    console.log('onData', prefix,  keys.length)
                    set(keys)
                },
            })
            onCleanup(cancel)
        }
    })

}

export const signalFromPrefix = <Value extends ReadonlyJSONValue>(r: Accessor<Replicache | undefined>, prefix: string): Accessor<Value[]> => {
    const [get, set] = createSignal<Value[]>([])
    subscribeToPrefix(r, prefix, set)
    return get
}