import { useSearchParams } from '@solidjs/router'
import { ColumnDef } from '@tanstack/solid-table'
import { createEffect, createResource, createSignal, JSX, onCleanup, Show } from 'solid-js'
import { style } from 'typestyle'

import { INTERVAL } from '../../src/constants'
import { makeClient } from '../client'
import { header } from '../style'
import { useAuthenticatedContext } from './context'
import { Content } from './Library'
import { Plans } from './Plans'
import { signalFromPrefix } from './replicache'
import { makeTable } from './table'

type  APIClient = typeof makeClient
type Props =  {
}
type Month = {
    date: string
    cents: number
    seconds: number
}
const getYearMonthFromDate = (created_at: Date): [number, number] => {
    return [created_at.getFullYear(), created_at.getMonth() + 1]
}


// USE THIS  https://github.com/TanStack/table
export const Billing = (p: Props): JSX.Element => {
    const { urls, client, auth0, auth0User, rep, user } = useAuthenticatedContext()
    const [searchParams, setSearchParams] = useSearchParams()
    // const getKeys = signalFromPrefix<[id: string, key: string]>(rep, 'key/')

    const saved = () => {
        return (searchParams as any)['saved'] == 'true'
    }
    createEffect(() => {

    })

    // @ts-expect-error
    const jobStatuses_ = signalFromPrefix<[id: string, status:commonSchema.JobModel]>(rep, 'job/')
    // const jobUsage = () => {
    //     const out: Month[] = []

    //     for(const j of jobStatuses_()) {
    //         const { created_at, usage_amount_reported_in_cents } = j[1]
    //         if(usage_amount_reported_in_cents != null && usage_amount_reported_in_cents > 0)  {

    //         }
    //     }
    //     return out
    // }

    const [jobUsage, setJobUsage] = createSignal<Month[]>([])

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const timer = setTimeout(async () => {
        const c = await client()!.usage.query()
        console.log('usage', c)
        setJobUsage(c)
    }, INTERVAL)

    const [creditCardOnFile, { mutate, refetch }] = createResource(async () => {
        const status = await client()?.billingStatus.query()
        return status?.checkout_session_completed
    })
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    document.addEventListener('DOMContentLoaded', async (event) => {
        // needed otherwise status won't update on page reload or back button
        await refetch()
    })

    console.log('creditCardOnFile()', creditCardOnFile())

    onCleanup(() => {
        clearInterval(timer)
    })


    const cost = (cents: number): string => (cents / 100).toFixed(2)
    function secondsToHms(d: number): string {
        const h = Math.floor(d / 3600)
        const m = Math.floor(d % 3600 / 60)
        const s = Math.floor(d % 3600 % 60)

        const hDisplay = h > 0 ? (h < 10 ? '0' : '') + h.toString() : '00'
        const mDisplay = m > 0 ? (m < 10 ? '0' : '') + m.toString() : '00'
        const sDisplay = s > 0 ? (s < 10 ? '0' : '') + s.toString() : '00'

        return hDisplay + ':' + mDisplay + ':' + sDisplay
    }
    function getMonthName(monthNumber: number): string {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        return months[monthNumber - 1] ?? 'Invalid month'
    }
    const defaultColumns: ColumnDef<Month>[] = [
        {
            accessorKey: 'date',
            cell       : info => {
                const [y, m] = info.getValue<string>().split('-')
                return `${getMonthName(Number(m))} ${y}`
            },
            header: () => <span>Month</span>,
        },
        {
            accessorKey: 'seconds',
            cell       : info => <i>{secondsToHms(info.getValue<number>())}</i>,
            header     : () => <span>Usage</span>,
        },
        {
            id        : 'cents',
            accessorFn: row => `$${cost(row.cents)}`,
            header    : () => <span>Cost</span>,
        },
    ]

    const Table = makeTable(jobUsage, defaultColumns)
    const addPayment = (type: 'pro' | 'starter') => {
        window.location.href = `${urls.payment.initiate}?sub=${auth0User()!.sub!}&type=${type}`
    }

    return <div style={{ padding: '1em', width: '100%' }}>
        <Content>
            <h1 class={header}>Account</h1>
            <div style={{  }}>
                <Show when={saved()}>Credit card saved!</Show>
                <h2>Billing</h2>
                <Show when={jobUsage()}>
                    {Table}
                </Show>
                <Show when={creditCardOnFile() == false}>
                    {/* <Button onClick={() => addPayment('pro')}>Add a Payment Method</Button> */}
                    <br></br>
                    <div>Your credit card will only be charged for successfully completed jobs.
                    </div>
                </Show>
                <Show when={creditCardOnFile() == false}>
                    <Plans currentPlan={user()?.plan} addPayment={addPayment}/>
                </Show>
                {/* <div>You're currently on the {user()?.plan}</div> */}
                <Show when={creditCardOnFile() == true}>
                    {/* to chagne your plan or cancel your subscription,  */}
                    <div style={{ 'padding-top': '1em' }}>
                        ✅ Your credit card is on file. To cancel, visit the<span> </span>
                        <a style={{ color: 'blue' }} href={`${urls.payment.customerPortal}?sub=${auth0User()!.sub!}`}>customer portal</a>.
                    </div>
                </Show>
            </div>
            <br></br>
            <br></br>
            <br></br>
            {/* <div style={{  }}>
                <h2>API Keys</h2>
                <table class={table}>
                    <thead>
                        <tr>
                            <td></td>
                            <td>Key</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        <For each={getKeys()}>{(key, i) => {
                            return <tr>
                                <td>{i() + 1}</td>
                                <td><pre>{key[1]}</pre></td>
                                <td><a href="#" style={{ 'text-decoration': 'none', filter: 'grayscale(30%)' }}
                                    onClick={(e) => escapeOwner(async () => {
                                        e.preventDefault()
                                        if(getKeys().length == 1) {
                                            alert('You must keep at least one key.')
                                        } else {
                                            await rep()?.mutate.keyDelete({ id: key[0] })
                                        }
                                    })}>❌</a></td>
                            </tr>
                        }}</For>
                    </tbody>
                </table>
                <br></br>
                <Button
                    style={{ display: 'block', margin: 'auto', 'width': 'auto' }}
                    onClick={(e) => escapeOwner(async () => {
                        e.preventDefault()
                        await rep()?.mutate.keyAdd({})
                    // const newKey = await client.keysPOST.query()
                    // setKeys([...getKeys(), newKey])
                    })}>Generate new API key</Button>
            </div> */}

        </Content>
    </div>
}


type Plan = {
    internalName: string,
    name: string
    monthly: number | string
    numConcurrentJobs: number
    contentLength: string
    watermark: boolean
    maxJobs: number
    pricing          : {
        audio: number,
        SD   : number,
        HD   : number,
        '2K': number,
        '4K'  : number,
    },
}





const inner = style({
    borderBottom: '1px solid gray',
    padding     : '0.5em 1em',
})
const heading = style({
    'textTransform': 'uppercase',
    color          : 'gray',
    fontWeight     : '300',
    fontSize       : '1em',
})
const planBox = style({
    border      : '1px solid gray',
    // margin      : '1em',
    borderRadius: '1em',
    overflow    : 'clip',
    flex        : '1 1 0%',
    'flexGrow'  : '1',
    'flexShrink': '1',
    $nest       : {
        '& div': {
            // padding: '0.25em',
        },
    },
})

