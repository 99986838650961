import { UserPlan } from 'common'
import { JSX, Show } from 'solid-js'
import { style } from 'typestyle'

import { css } from '../css'
import { Button } from './Library'

export const Plans = (p: { currentPlan:UserPlan | undefined, addPayment: (p:  'pro' | 'starter') => void }): JSX.Element => {
    return <div
        class={main}
        style={{
        }}>
        <PlanBox plan={plans.trial}         onSubscribe={() => p.addPayment('starter')} hideButton={true} currentPlan={p.currentPlan}/>
        <PlanBox plan={plans.starter}       onSubscribe={() => p.addPayment('starter')} currentPlan={p.currentPlan}/>
        <PlanBox plan={plans.pro}      heading={'25% off'}     onSubscribe={() => p.addPayment('pro')} currentPlan={p.currentPlan}/>
        <PlanBox
            onSubscribe={() => {
                // @ts-expect-error
                window.location = `mailto:support@igolgi.com?subject=Stream Engine Cloud Enterprise Plan&body=
Name (first and last): %0D%0A
Email: %0D%0A
Phone (optional): %0D%0A
Company (optional): %0D%0A
Message: %0D%0A`
            }}
            plan={plans.enterprise} isEnterprise={true} buttonActive={true} buttonText="Contact Us" currentPlan={p.currentPlan} heading={
                <div style={{ 'font-size': '.8em' }}>
                Do you have High Transcoding volume of at least 500 Hours per month? We can create a custom Enterprise plan just for your needs
                </div>
            }/>
    </div>
}


type Plan = {
    internalName: string,
    name: string
    monthly: number | string
    numConcurrentJobs: number
    contentLength: string
    watermark: boolean
    maxJobs: number
    pricing          : {
        audio: number,
        SD   : number,
        HD   : number,
        '2K': number,
        '4K'  : number,
    },
}



type PlanBoxProps = {
    plan: Plan,
    isEnterprise?: boolean,
    onSubscribe?: () => void,
    buttonActive?: boolean
    buttonText?: string
    hideButton?: boolean
    currentPlan: UserPlan | undefined
    heading?: string | JSX.Element
    desc?: string | JSX.Element
}
const PlanBox = (p: PlanBoxProps) => {
    const isCurPlan = ((p.currentPlan ?? '?') == p.plan.internalName)
    return <div class={planBox} style={{
        'background-color': isCurPlan ? 'rgb(244 248 255)' : 'white',
        border            : isCurPlan ? '3px solid rgb(107 140 199)' : '1px solid gray',
    }}>
        <div style={{
            'background-color': 'rgb(218 230 255)',
            'background-image': 'linear-gradient(0deg, rgb(70, 76, 88) 0%, rgb(26, 29, 34) 100%)',
            'min-height'      : '12em',
            position          : 'relative',
            color             : 'white' }} class={inner}>
            <div style={{
                'font-size': '2.5em',
                color      : 'white',
            }}>
                {p.plan.name}
            </div>
            <div style={{ 'min-height': '3em' }}>{p.heading}</div>
            <Price price={p.plan.monthly}/>
        </div>
        <Inner
            top={'Audio'}
            callUs={p.isEnterprise}
            bottom={`$${p.plan.pricing.audio}`}/>
        <Inner
            top={'SD Video'}
            callUs={p.isEnterprise}
            bottom={`$${p.plan.pricing.SD}`}/>
        <Inner
            top={'HD Video'}
            callUs={p.isEnterprise}
            bottom={`$${p.plan.pricing.HD}`}/>
        <Inner
            top={'2K Video'}
            callUs={p.isEnterprise}
            bottom={`$${p.plan.pricing['2K']}`}/>
        <Inner
            top={'4K Video'}
            callUs={p.isEnterprise}
            bottom={`$${p.plan.pricing['4K']}`}/>
        <Inner
            top={'# Concurrent Jobs'}
            bottom={p.plan.numConcurrentJobs == Infinity ? 'Unlimited' : p.plan.numConcurrentJobs}/>
        <Inner
            top={'Max Content Length'}
            bottom={p.plan.contentLength}/>
        <Inner
            top={'Watermark'}
            bottom={p.plan.watermark ? 'Yes' : 'No'}/>
        <Inner
            top={'# Total Jobs'}
            bottom={p.plan.maxJobs == Infinity ? 'Unlimited' : p.plan.maxJobs}/>
        <div style={{ height: '5em' }}></div>
        <div
            style={{
                //  height: '10em'
                position       : 'relative',
                'border-bottom': 'unset'  }}
            class={inner}>
            {/* <Mouseover
                style={{ bottom: '1.5em', left: '0em' }}
                trigger={ */}
            <Show when={!(p.hideButton ?? false)}>
                <Button disabled={!(p.buttonActive ?? true)} onclick={p.onSubscribe}
                // style={{ bottom: '10px', position: 'absolute', width: '90%' }}
                >{p.buttonText ?? 'Subscribe'}</Button>
            </Show>
        </div>
    </div>
}


const Inner = ({ top, bottom, callUs }: { top: JSX.Element, bottom: JSX.Element, callUs?: boolean }) => {
    return <div class={inner}>
        <div class={heading}>{top}</div>
        <div class={value}>{callUs  == true ? 'Contact Us' : bottom}</div>
    </div>
}
const Price = (p: { price: number | string }) => {
    let value: string = ''
    let showMonth = true
    if(typeof p.price == 'string') {
        value = p.price
        showMonth = false
    } else if(p.price == 0) {
        value = '$0'
    } else {
        value = '$' + (p.price / 100).toFixed(2).toString()
    }
    return <div class={value} style={{ color: 'white', position: 'absolute', bottom: 0 }}><span style={{ color: 'white', 'font-weight': '500', 'font-size': '1.5em' }}>{value}</span>{showMonth ? '/month' : ''}</div>
}


const inner = style({
    borderBottom: '1px solid gray',
    padding     : '0.5em 1em',
})
const heading = style({
    'textTransform': 'uppercase',
    color          : 'gray',
    fontWeight     : '300',
    fontSize       : '1em',
})
const planBox = style({
    border      : '1px solid gray',
    // margin      : '1em',
    borderRadius: '1em',
    overflow    : 'clip',
    flex        : '1 1 0%',
    'flexGrow'  : '1',
    'flexShrink': '1',
    $nest       : {
        '& div': {
            // padding: '0.25em',
        },
    },
})


const value = style({
    color     : 'black',
    fontWeight: '500',
    fontSize  : '1.25em',
})
const plans:{ trial: Plan, starter: Plan, pro: Plan, enterprise: Plan } = {
    trial: {
        internalName     : 'trial',
        name             : 'Trial',
        monthly          : 0,
        contentLength    : '1 minute max',
        numConcurrentJobs: 1,
        watermark        : true,
        maxJobs          : 25,
        pricing          : {
            audio: 0,
            SD   : 0,
            HD   : 0,
            '2K' : 0,
            '4K' : 0,
        },
    },
    starter: {
        internalName     : 'starter',
        name             : 'Starter',
        monthly          : 0,
        contentLength    : 'Unlimited',
        numConcurrentJobs: 5,
        watermark        : false,
        maxJobs          : Infinity,
        pricing          : {
            audio: 0.0016,
            SD   : 0.0038,
            HD   : 0.0075,
            '2K' : 0.0150,
            '4K' : 0.0150,
        },
    },
    pro: {
        internalName     : 'pro',
        name             : 'Pro',
        monthly          : 1000,
        contentLength    : 'Unlimited',
        numConcurrentJobs: 10,
        watermark        : false,
        maxJobs          : Infinity,
        pricing          : {
            audio: 0.0012,
            SD   : 0.0028,
            HD   : 0.0056,
            '2K' : 0.0113,
            '4K' : 0.0113,
        },
    },
    enterprise: {
        internalName     : 'enterprise',
        name             : 'Enterprise',
        monthly          : 'Contact Us',
        contentLength    : 'Unlimited',
        numConcurrentJobs: Infinity,
        watermark        : false,
        maxJobs          : Infinity,
        pricing          : {
            audio: 0,
            SD   : 0,
            HD   : 0,
            '2K' : 0,
            '4K' : 0,
        },
    },
}



const main = css({
    display        : 'flex',
    'flexDirection': 'row',
    gap            : '0.5em',
    // @ts-expect-error
    '@bp2'         : {
        'flexDirection': 'column',
    },
})()