import { cloudCreds, simplifiedJobInput } from 'common'
import { createEffect, For, JSX } from 'solid-js'
import { z } from 'zod'

import { getCodeSnippet } from '../../../common'
import { css } from '../css'
import { header } from '../style'
import { urls } from './context'
import { Content } from './Library'

type Type = {
    name: 'string' | 'boolean' | 'number' | 'union' | 'array' | 'object'
    unionDisjuncts: (string | boolean | number)[]
    optional: boolean
    defaultValue: string | boolean | number | undefined
    description: string | undefined
}
const python = `import requests

url = "http://localhost:4000/api/v0/job"

headers = {
    'Content-Type': 'application/json',
}

auth = ('8e6f7a5bf90821a175338ce08be4b7ca', '')

data = {
  'videoProfiles': [
    {
        'output'            : '',
        'video_bitrate_mode': 'cbr',
        'video_format'      : 'progressive',
        'video_framerate'   : '1x',
    }],
    'audioProfiles': [
    {
        'audio_bitrate' : 256,
        'audio_channels': 2,
        'audio_codec'   : 'aac'
    }],
    "master_variant_mode": False,
    "output_container": "mp4",
    "video_codec": "h.264",
    "auto_detelecine_flag": False,
    "input": "oci://yzwhip2gci7s/jey_test_bucket2/test_dir/moana_30_seconds.ts",
    "cloud_credentials": {
        "cloud_provider": "oracle",
        "access_key": "...",
        "secret_key": "...",
        "region": "us-phoenix-1"
    }
}

response = requests.post(url, json=data, headers=headers, auth=auth)

# You can print the response content if needed
print(response.content)
`

const flatten = (type:  z.ZodType<any, any>, optional: boolean = false, defaultValue: Type['defaultValue'] | undefined = undefined, description: string | undefined = undefined): Type => {
    if(type instanceof z.ZodUnion){
        const unionDisjuncts = (type._def.options as z.ZodLiteral<string>[]).map(l => l.value)
        return { name: 'union', unionDisjuncts, optional, defaultValue, description: description ?? type.description }
    } else if(type instanceof z.ZodNumber){
        return { name: 'number', unionDisjuncts: [], optional, defaultValue, description: description ??  type.description }
    } else if(type instanceof z.ZodString){
        return { name: 'string', unionDisjuncts: [], optional, defaultValue, description: description ?? type.description }
    } else if(type instanceof z.ZodBoolean){
        return { name: 'boolean', unionDisjuncts: [], optional, defaultValue, description: description ??  type.description }
    } else if(type instanceof z.ZodOptional){
        return flatten(type.unwrap() as z.ZodType<any, any>, true, defaultValue, description ?? type.description)
    } else if (type instanceof z.ZodNullable) {
        return flatten(type.unwrap() as z.ZodType<any, any>, true, defaultValue, description ?? type.description)
    } else if (type instanceof z.ZodDefault){
        return flatten(type._def.innerType as z.ZodType<any, any>, optional, type._def.defaultValue() as Type['defaultValue'], description ?? type.description)
    } else if (type instanceof z.ZodArray){
        return { name: 'array', unionDisjuncts: [], optional, defaultValue, description: description ?? type.description }
    } else if(type instanceof z.ZodObject){
        return { name: 'object', unionDisjuncts: [], optional, defaultValue, description: description ?? type.description }
    } else {
        console.error(`Zod type not supported`, type)
        return undefined!
    }
}
export const keys: <T extends string | number | symbol>(x: Record<T, any>)=> T[] = Object.keys
const KEY = '8e6f7a5bf90821a175338ce08be4b7ca'

export const Documentation = (): JSX.Element => {
    // const { urls, client } = useAuthenticatedContext()

    return <div style={{ padding: '1em', width: '100%' }}>
        <Content>
            <DocumentationContent></DocumentationContent>
        </Content>
    </div>
}


export const DocumentationContent = (): JSX.Element => {
    createEffect(async () => {
        const url = `${urls.base}/api/v0/job/1`
        const a = await fetch(url)
        console.log(a)
    })

    return             <div style={{  }}>
        <h1 class={header}>Documentation</h1>
        <div style={{ display: 'flex', 'flex-direction': 'column' }}>
            <div class={contents}>
                <h3>Contents</h3>
                <ul style={{ 'margin-left': '2em' }}>
                    <li><a href="#parameters">Parameters</a></li>
                    <li><a href="#cloud-creds">Cloud Credentials</a></li>
                    <li><a href="#creds-input">Cloud Credentials Input/Ouput</a></li>
                    <li><a href="#audio">Audio Profiles</a></li>
                    <li><a href="#video">Video Profiles</a></li>
                    <li><a href="#containers">Supported Containers and Codecs</a></li>
                    <li><a href="#creating">Creating Jobs</a></li>
                    <li><a href="#python">Using the API from within Python</a></li>
                    <li><a href="#retrieve">Retrieving a Sent Job</a></li>
                </ul>
            </div>
            <div>
                <br></br>
                <h3 id="parameters" class={header}>Parameters</h3>
                <Table shape={simplifiedJobInput.shape}/>
                <br/>
                <br/>
                <h3 id="cloud-creds" class={header}>Cloud Credentials</h3>
                <Table shape={simplifiedJobInput.shape['cloud_credentials']._def.shape()}/>
                <br/>
                <br/>
                <h3 id="creds-input" class={header}>Cloud Credentials Input/Output</h3>
                <Table shape={cloudCreds.shape}/>
                <br/>
                <br/>
                <h3 id="audio" class={header}>Audio Profiles</h3>
                <Table shape={simplifiedJobInput.shape['audioProfiles']._def['type'].shape}/>
                <br/>
                <br/>
                <h3 id="video" class={header}>Video Profiles</h3>
                <Table shape={simplifiedJobInput.shape['videoProfiles']._def['type'].shape}/>
                <br/>
                <br/>
                <h2 id="containers">Supported Containers and Codecs</h2>
                <ul>
                    <li>Input Containers: MXF, MPEG2-TS and MP4</li>
                    <li>Output Containers: MPEG2-TS, MP4</li>
                    <li>Input Codecs: MPEG2, ProRes, XDCAM, H.264, HEVC for video; AC3, MPEG2, AAC for audio</li>
                    <li>Output Codecs: MPEG2, H.264 and HEVC for video; Passthrough, AAC-LC and AAC 5.1 for audio, AC3 stereo and 5.1 for audio</li>
                </ul>
                <br/>
                <br/>
                <h3 id="creating">Creating Jobs</h3>
                <p>To create a job, use your API key along with a JSON payload matching the schema described in the tables above, and make an HTTP GET request like the following:</p>
                <Code>{getCodeSnippet({
                    'videoProfiles': [
                        {
                            'output'            : '',
                            width               : 1024,
                            height              : 768,
                            video_bitrate       : 3500,
                            'video_bitrate_mode': 'cbr',
                            // 'video_format'      : 'progressive',
                            'video_framerate'   : '1x',
                        },
                    ],
                    'audioProfiles': [
                        {
                            'audio_bitrate' : 256,
                            'audio_channels': 2,
                            'audio_codec'   : 'aac',
                        },
                    ],
                    'output_container' : 'mp4',
                    // 'blazar_mode'      : false,
                    'video_codec'      : 'h.264',
                    // 'auto_detelecine_flag': false,
                    'input'            : 'oci://yzwhip2gci7s/jey_test_bucket2/test_dir/moana_30_seconds.ts',
                    'cloud_credentials': {
                        input: {
                            cloud_provider: 'oracle',
                            access_key    : '...',
                            secret_key    : '...',
                            region        : 'ca-toronto-1',
                        },
                        output: undefined!,
                    },
                }, urls.base, KEY)}</Code>
                <br/>
                <br/>
                <h3 id="python">Using the API from within Python</h3>
                <Code>{python}
                </Code>
                <br/>
                <br/>
                <h3 id="retrieve">Retrieving a Sent Job</h3>
                <p>To retrieve a job, use your API key to make a GET request to the <span style={{ 'font-family': 'monospace' }}>/api/v0/job/<span style={{ 'text-decoration': 'underline' }}>id</span></span> endpoint, replacing <span style={{ 'text-decoration': 'underline', 'font-family': 'monospace' }}>id</span> with the id of the job you wish to retrieve:</p>
                <Code>{checkStatus(urls.base)}</Code>
                <p>The response will look something like this:</p>
                <Code>{status}</Code>
                <p>This allows you to retrieve the original configuration for your job, as well as check on its progress using the <span style={{ 'font-family': 'monospace' }}>tcode_progress</span> field. </p>
            </div>
        </div>
    </div>
}
const status = `{
    "status": {
        "tcode_progress": "0",
        "state": "ready",
        ... # additional fields
    },
    "config": {
        ... # your job configuration
    },
}`
const checkStatus = (base:string) => `curl ${base}/api/v0/job/1 \\
  -u 8e6f7a5bf90821a175338ce08be4b7ca:
`
const Code = ({ children }: { children: JSX.Element }): JSX.Element => {
    return <pre style={{ 'background-color': '#eee', padding: '1em' }}>{children}</pre>
}

const code = (base: string) => `curl ${base}/api/v0/job \\
  --request POST \\
  --header 'Content-Type: application/json' \\
  -u 8e6f7a5bf90821a175338ce08be4b7ca: \\
  -d '{
  "profiles": [
    {
      "output": "",
      "audio_bitrate": 256,
      "audio_channels": 2,
      "audio_codec": "aac",
      "video_bitrate_mode": "cbr",
      "video_format": "progressive",
      "video_framerate": "1x"
    }
  ],
  "output_container": "mp4",
  "video_codec": "h.264",
  "auto_detelecine_flag": false,
  "cloud": "oracle"
}'`
export const Table = ({ shape }: { shape: Record<string, z.ZodType<any, any>> }): JSX.Element => {
    return <table class={style.table}>
        <thead class={style.thead}>
            <tr class={style.tr}>
                <td class={style.td}>Parameter Key</td>
                <td class={style.td}>Type</td>
                <td class={style.td}>Required</td>

                <td class={style.td}>Default</td>
            </tr>
        </thead>
        <For each={keys(shape)}>{(key, index) => {
            return <Row type={flatten(shape[key])} key={key}/>
        }
        }</For>
    </table>

}
const join = (t: string[]) => {
    const out: JSX.Element[] = []
    for(const a of t){
        if(out.length > 0) {
            out.push(<> </>)
        }
        out.push(<Pre label={a} literal/>)
    }
    return out
}

const Row = (p: { type: Type, key: string }) => {
    const type_ = p.type
    const name = type_.name == 'union'
        ? 'string'
        : `${type_.name}`
    const union = p.key == 'region'
        ?  ''
        : join(type_.unionDisjuncts.map(x => x.toString()))
    return <tr class={style.tr}>
        <td class={style.td}>
            <Pre label={p.key} classList={{ [style.parameterName]: true }}/>
            <br>
            </br>{type_.description}
            {union.length > 0 ? <div>Supported values are {union}.</div> : ''}</td>
        <td class={style.td}><Pre label={name}/></td>
        <td class={style.td}>{(type_.optional) ? 'No' : 'Yes'}</td>
        <td class={style.td}><Pre label={type_.defaultValue} literal/></td>
    </tr>
}

const contents = css({
    // width: '500px',
    '& a': {
        color: 'blue',
    },
})()
const style = {
    table: css({
        borderSpacing : '0px',
        borderCollapse: 'collapse',
        // backgroundColor: 'yellow'

    })(),
    thead: css(t => ({
        backgroundColor: '#eee',
        '& td'         : {
            textTransform: 'uppercase',
            color        : '#888',
        },
    }))(),
    tr: css({
    })(),
    td: css({
        paddingInline: '0.5em',
        paddingBlock : '1em',
        border       : '1px solid #999',
    })(),
    pre: css(t => ({
        fontFamily: t.fonts.mono,
    }))(),
    literal: css(t => ({
        paddingInline  : '0.3em',
        backgroundColor: '#eee',
        borderRadius   : '0.2em',
        border         : '1px solid #ddd',
    }))(),
    parameterName: css(t => ({
        fontWeight: 'bold',
    }))(),
}

const Pre = (p: { label: string | number | boolean | undefined, literal?: boolean, classList?: JSX.HTMLAttributes<any>['classList'] }) => {
    if(p.label === undefined) return <></>
    return <span classList={{ [style.pre]: true, [style.literal]: p.literal, ...p.classList }}>{p.label.toString()}</span>
}
