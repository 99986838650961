import '@thisbeyond/solid-select/style.css'

import { Select } from '@thisbeyond/solid-select'
import { UserPlan } from 'common'
import { costInCentsNew, hourlyPrices, perMinute, VideoCodecNEW } from 'common/src/business-rules-prices'
import { createEffect, createMemo, createSignal, For, JSX, Show } from 'solid-js'
import { createMutable } from 'solid-js/store'

import { css } from '../css'

const intervals: Record<number, number> = {
    1    : 20,
    5    : 100,
    50   : 500,
    100  : 2000,
    1000 : 10000,
    10000: 100000,
}

const makeMap = (): Record<number, number> => {
    const map: Record<number, number> = {}
    let i = 1
    let mins = 1
    let interval: number | string = 1
    while(true){
        map[i] = mins
        if(mins >= intervals[Number(interval)]) {
            if(interval <= 1000) {
                interval = Object.keys(intervals)[Number(Object.keys(intervals).indexOf(`${interval}`)) + 1]
            } else break
        }
        i += 1
        mins += Number(interval)
    }
    return map
}



const map = makeMap()
console.log(map)
const minsToIndex = (mins: number): number => {
    let i = 0
    for(const [key, value] of Object.entries(map)){
        i = Number(key)
        if(value >= mins) break
    }
    return i
}
type WritableArray<T> = {
    -readonly [P in keyof T]: T[P];
}

type Framerate = 25 | 30 | 50 | 60 | 120 | 240
type ResNames = (typeof resolutions)[number]
// const resolutions = [240, 360, 480, 576, 720, 1080, '4K'/*, '5K', '6K', '7K', '8K'*/] as const
const resolutions = perMinute.video.map(x => x[2])

const getPixelCount = (resName: (typeof resolutions)[number]): number => {
    const x = perMinute.video.find(x => x[2] == resName)!
    return x[0][0] * x[0][1]
}
type Data = {
    minutes: number,
    // format: 'MP4' | 'TS'
    inputBitrateKbps: number
    plan: Extract<UserPlan, 'starter' | 'pro'>
    codec: VideoCodecNEW,
    framerate: Framerate
    resolution: (WritableArray<ResNames>)[]
}

const resName = ([w, h]: readonly [number, number]): string => {
    if(w < 1920) return 'SD'
    else if (w < 3840) return 'HD'
    else return 'UHD'
}
export const pixels = {
    [720 * 576]  : [720, 576],
    [1920 * 1080]: [1920, 1080],
    [3840 * 2160]: [3840, 2160],
    [1920 * 1080]: [1920, 1080],
    [3840 * 2160]: [3840, 2160],
} as const


export const Calculator2 = (): JSX.Element => {
    const data = createMutable<Data>({ minutes: 60, inputBitrateKbps: 2000, plan: 'pro', codec: 'h.264', resolution: ['HD'], framerate: 25 })

    const costInDollars_ = createMemo(() => {
        const details: [number | string, number][] = []
        let total = 0
        for(const r of data.resolution) {
            const cost = costInCentsNew({ audioProfiles: [], inputBitrateKbps: data.inputBitrateKbps, plan: data.plan, inputFramerate: data.framerate, videoProfiles: [{ CABR: false, codec: data.codec, detelecine: false, minutes: data.minutes, pixelCount: getPixelCount(r) }] })//data.codec, data.minutes * 60, data.framerate, resolutionDict[r])
            console.log(cost)
            details.push([r, cost])
            total += cost
        }
        return { total, details }
    })
    const [slider, setSlider] = createSignal(minsToIndex(data.minutes))
    const [pricing, setPricing] = createSignal(false)

    createEffect(() => console.log(JSON.stringify(data)))

    return <div class={calculator}>
        <div class={minutes}>
            <div style={{ 'font-size': '1.4em', 'margin-top': 'auto',  'font-weight': 600 }}>Output Content<br></br>Minutes</div>
            <input class={minsInput} min={1} type="number" value={data.minutes}
                onInput={e => {
                    data.minutes = Number(e.target.value)
                    setSlider(minsToIndex(data.minutes))
                }}
            ></input>
        </div>
        <input
            style={{ width: '100%' }}
            type="range"
            min={1}
            max={Object.keys(map)[Object.keys(map).length - 1]}
            value={slider()}
            step={1}
            onInput={e => {
                data.minutes = map[Number(e.target.value)]
            }}
        />
        <div style={{ display: 'grid',  gap: '5px', 'grid-template-columns': '1fr 1fr 1fr', 'overflow': 'visible' }}>
            {/* <Select
                class={format}
                onChange={e => data.format = (e as 'MP4')}
                initialValue={data.format}
                placeholder='Format'
                options={['MP4', 'TS']}/> */}
            <Select
                class={format}
                onChange={e => data.codec = (e as 'mpeg2')}
                initialValue={data.codec}
                placeholder='Codec'
                options={['h.264', 'mpeg2', 'hevc']}/>
            <Select
                multiple
                onChange={e => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    data.resolution = e
                    // data.resolution = [e]/*((e as number[])).sort((x, y) => resolutions.indexOf(x) - resolutions.indexOf(y)) as [720]*/
                }}
                initialValue={data.resolution}
                class={format}
                placeholder='Resolutions'
                options={resolutions }/>
            <Select
                class={format}
                onChange={e => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
                    const match = (e).match(/Framerate: (\d+)/)!

                    data.framerate = 0 + Number(match[1] as 25) as any as Framerate
                }}
                initialValue={`Framerate: ${data.framerate}`}
                placeholder='Framerate'
                options={['Framerate: 25', 'Framerate: 30', 'Framerate: 50', 'Framerate: 60', 'Framerate: 120', 'Framerate: 240']}/>
            <div style={{ 'grid-column': '1 / -1' }}>
            </div>
        </div>
        <div class={minutes}>
            <div style={{ 'font-size': '1.5em', 'margin-bottom': 'auto' }}>Total <span style={{ position: 'relative', 'top': '-.5em', 'font-size': '60%' }}>*</span></div>
            <div style={{ 'font-size': '3em',  'font-variant-numeric': 'tabular-nums', 'font-weight': 600, 'text-align': 'right', color: '#0076FE', 'line-height': '1em' }}>${(costInDollars_()).total.toFixed(2)}</div>
            <div style={{ display: 'flex', gap: '0.15em' }}>
                <div style={{ 'font-size': '0.5em' }}>*</div>
                <div style={{ 'font-size': '0.5em' }}>This calculator assumes 1 audio channel, and no addon features except h265 or higher frame rate if you select it.</div>
            </div>
        </div>
        {/* <div>&nbsp;</div> */}
        {/* <div style={{ display: 'grid',  'grid-template-columns': '1fr 1fr' }}>
            <For each={(costInDollars_()).details}>{(c) => <><span style={{ 'justify-self': 'start' }}>{c[0]}</span> <span style={{ 'justify-self': 'end', 'font-variant-numeric': 'tabular-nums' }}>${c[1].toFixed(2)}</span></>}</For>
        </div> */}
        <div>
            {/* <div style={{  }}>&nbsp</div>
            <div style={{ 'border-top': '1px solid #ccc', 'font-size': '1.5em', 'margin-bottom': 'auto',     'padding-bottom': '0.25em' }}>Pricing Tables
                <a onClick={(e) => {e.preventDefault(); setPricing(!pricing())}} href="#" style={{ float: 'right', 'font-size': '0.8rem', 'padding-top': '0.5em', color: 'blue', 'text-decoration': 'none' }}>Details</a>
            </div> */}
            <Show when={pricing()}>
                <div>
                    <Table codec='H.264'></Table>
                    <div>&nbsp</div>
                    <Table codec='HEVC'></Table>
                </div>
            </Show>
        </div>
    </div>
}

const Table = (p: { codec: keyof typeof hourlyPrices  }): JSX.Element => {
    return <table class={pricingTable}>
        <caption style={{ 'text-align': 'left', color: '#888', 'font-size': '0.9em', 'padding-bottom': '0.2em' }}>{p.codec} In/Out Pricing</caption>
        <thead>
            <tr>
                <th>Resolution</th>
                <th>Max Pixel Format</th>
                <th>Max&nbsp;FPS</th>
                <th>Max Pixel Count</th>
                <th style={{ 'text-align': 'right', padding: 0 }}>$/hour</th>
            </tr>
        </thead>
        <tbody>
            <For each={Object.keys(hourlyPrices[p.codec]) as any[] as 30[]}>{fps =>
                <For each={hourlyPrices[p.codec][fps]}>{e => <tr>
                    <td>{resName(pixels[e[0]])}</td>
                    <td>{pixels[e[0]][0]}×{pixels[e[0]][1]}</td>
                    <td>{fps}</td>
                    <td>{(e[0] * fps).toLocaleString()}</td>
                    <td style={{ 'text-align': 'right' }}>${e[1].toFixed(2)}</td>
                </tr>}
                </For>
            }</For>
        </tbody>
    </table>
}
const pricingTable = css({
    width          : '100%',
    'borderSpacing': 0,
    '& thead th'   : {
        fontWeight: 400,
    },
    '& thead td, & thead th': {
        margin      : '0',
        paddingRight: '1em',
        fontSize    : '0.7em',
        textAlign   : 'left',
        borderBottom: '2px solid gray',
    },
    '& thead tr': {
        backgroundColor: 'white',
    },
    '& tbody tr:hover': {
        backgroundColor: '#f0f0f0',
    },
    '& td': {
        borderBottom: '1px solid #ddd',
    },
    '& tr:nth-child(odd) td': {
        // backgroundColor: '#f0f0f0',
    },
})()
const minutes = css({
    position    : 'relative',
    display     : 'grid',
    gridTemplate: '1fr / 1fr 1fr',
})()
const minsInput = css({
    'fontVariantNumeric'          : 'tabular-nums',
    padding                       : 0,
    lineHeight                    : 0,
    width                         : '100%',
    border                        : 'transparent',
    fontWeight                    : 600,
    textAlign                     : 'right',
    'fontSize'                    : '3em',
    '&::-webkit-inner-spin-button': {
        // @ts-expect-error
        '-webkit-appearance': 'none',
        margin              : 0,
    },
})()
const table = css({
    '& td': {
        width: '150px',
    },
})()

const calculator = css({
    // maxWidth: '500px',
    width: '100%',
})()
const format = css({
    width       : '100%',
    border      : '1px solid #ccc',
    borderRadius: '0.25em',

    '& .solid-select-control': {
        padding: 5,
    },
    '& .solid-select-list': {
        backgroundColor: 'white',
        borderRadius   : '0.25em',
        border         : '1px solid #aaa',
    },
    '& .solid-select-placeholder': {
        color: '#666',
    },
    '& .solid-select-option[data-focused=true]': {
        'backgroundColor': 'unset',
    },
    // doesn't work
    // '& .solid-select-option[data-disabled=true]': {
    //     color          : 'red',
    //     backgroundColor: 'red',
    // },
    '& .solid-select-option[data-focused=true]::before': {
        // content : '✔',f
        position: 'absolute',
        right   : '0.75em',
        // color            : '#fff',
    },
    '& .solid-select-multi-value': {
        backgroundColor: '#DBE9F7',
    },
    '& .solid-select-multi-value button': {
        // textIndent: '-50px',
        // overflow: 'hidden',
        fontSize  : '2em',
        border    : '0px',
        lineHeight: 0,
        transform : 'translateY(-0.2em)',
        color     : '#1A4875',
        cursor    : 'pointer',
    },
    '& .solid-select-multi-value button:hover': {
        textShadow: 'none',
    },
    // '& .solid-select-multi-value button::before': {
    //     content   : '×',
    //     width     : '100px',
    //     display   : 'inline-block',
    //     textIndent: 40,
    //     fontSize  : '1em',
    //     color     : 'black',
    //     border    : '0px',
    //     // fontSize: '2em',
    // },
})()

