import urlJoin from 'url-join'

export const paths = {
    replicache: {
        push: '/push',
        pull: '/pull',
    },
    payment: {
        server: {
            webhook       : '/payment/webhook',
            initiate      : '/payment/initiate',
            customerPortal: '/payment/customer-portal',
            sessionInfo   : '/payment/session-info',
        },
        client: {
            successfulSetup: '/payment/successful-setup',
            cancelSetup    : '/payment/cancel-setup',
        },
    },
}
// DO NOT CHANGE, hashed by auth0
const str = 'http://localhost:1234/app, https://10.1.14.80:1234/app, https://10.1.14.80:8/app, https://localhost:4173/, http://localhost:4173/,http://localhost:4173/app,http://localhost:4000/app,https://localhost:4000/app,https://localhost/app,https://10.1.14.80/app, https://streamengine.igolgi.com/app, https://75.41.113.139/app'
const AUTH0_VERIFIED_CALLBACK_URL = str.split(',').map(x => x.trim())

const stripProtocol = (url: string): string => url.replace(/^https?:\/\//, '')

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const makeURLs = (protocol: string, serverBaseWithoutProtocol: string, clientBaseWithoutProtocol: string) => {
    const serverBase = `${protocol}://${stripProtocol(serverBaseWithoutProtocol)}`
    const clientBase = `${protocol}://${stripProtocol(clientBaseWithoutProtocol)}`
    console.log(serverBase)
    const callback = urlJoin(clientBase, 'app')
    console.log('callback', callback)
    // if(!AUTH0_VERIFIED_CALLBACK_URL.includes(callback))
    // throw Error("auth0 url won't match")

    const out = {

        auth0: {
            CALLBACK: callback,
        },
        base      : serverBase,
        clientBase: clientBase,
        api       : {
            base: urlJoin(serverBase, '/api'),
        },
        replicache: {
            push: urlJoin(serverBase, paths.replicache.push),
            pull: urlJoin(serverBase, paths.replicache.pull),
        },
        payment: {
            // Only these two URLS are needed *in full* by the API.
            // For the rest, the API needs only the path, not the full URL.
            successfulSetup: urlJoin(clientBase, 'app', paths.payment.client.successfulSetup),
            cancelSetup    : urlJoin(clientBase, 'app', paths.payment.client.cancelSetup),

            webhook       : urlJoin(serverBase, paths.payment.server.webhook),
            initiate      : urlJoin(serverBase, paths.payment.server.initiate),
            customerPortal: urlJoin(serverBase, paths.payment.server.customerPortal),
        },
    }
    console.log('urls', out)
    return out
}



export type AppURLs = Exclude<ReturnType<typeof makeURLs>, undefined>

