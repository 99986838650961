import { Data } from 'common'
import * as commonSchema from 'common/src/schema'
import { Signal } from 'solid-js'
import { style } from 'typestyle'

export const JOB_CONFIG_CACHE_KEY = 'job-config-cache'

export type MainState = {
    template: commonSchema.Template | undefined
    collapsed: boolean,
    canNavigate: boolean,
    isUploading: boolean,
    data: Data | undefined,
    panelStates: {
        outputFormat: Signal<boolean>,
        input   : Signal<boolean>,
        output  : Signal<boolean>,
        audio   : Signal<boolean>,
        video   : Signal<boolean>,
        advanced: Signal<boolean>,
    }
}

export const copyToClipboard = style({
    visibility: 'visible',
    opacity   : 1,
    $nest     : {
        '&:active .ack': {
            display   : 'block',
            visibility: 'visible',
            opacity   : 1,
        },
        '&:not(:active) .ack': {
            visibility     : 'hidden',
            opacity        : 0,
            transitionDelay: '2s',
        },
    },
})
