import { FileAddArgs, MutationKeyAddArgs, MutationKeyDeleteArgs, MutationUserConsentArgs, OracleAssetDeleteArgs, TemplateAddArgs, TemplateDeleteArgs } from 'common'
import { customAlphabet } from 'nanoid'
import { WriteTransaction } from 'replicache'

const keyGenerator = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 32)

export const mutators = {
    userConsent: async (tx: WriteTransaction, args: MutationUserConsentArgs): Promise<void> => {
        console.log('user consent')
        await tx.put(`userConsent`, true)
    },

    keyAdd: async (tx: WriteTransaction, args: MutationKeyAddArgs): Promise<void> => {
        console.log('key add')
        await tx.put(`key/${Math.random()}`, 'Requesting...')
    },
    keyDelete: async (tx: WriteTransaction, args: MutationKeyDeleteArgs): Promise<void> => {
        console.log('key delete')
        await tx.del(args.id)
    },
    templateAdd: async (tx: WriteTransaction, args: TemplateAddArgs): Promise<void> => {
        console.log('template add', args)
        await tx.put(`template/${args.id}`, { ...args })
    },
    templateDelete: async (tx: WriteTransaction, args: TemplateDeleteArgs): Promise<void> => {
        console.log('template delete')
        await tx.del(args.id)
    },
    // File
    fileAdd: async (tx: WriteTransaction, args: FileAddArgs): Promise<void> => {
        console.log('file add', args)
        await tx.put(`file/${args.id}`, { ...args })
    },
    oracleAssetDelete: async (tx: WriteTransaction, args: OracleAssetDeleteArgs): Promise<void> => {
        console.log('oracleAsset delete')
        await tx.del(args.path)
    },

    // increment: async (tx: WriteTransaction, delta: number): Promise<number> => {
    //     // Despite 'await' this get almost always responds instantly.
    //     // Same with `put` below.
    //     console.log('increment action')
    //     const prev = (await tx.get('count') as number) ?? 0
    //     const next = prev + delta
    //     await tx.put('count', next)
    //     return next
    // }
}
