import { cssRaw } from 'typestyle'


export const reset = (backgroundColor?: string): void => {
    // Break out into a proper css file
    // HOWEVER make sure that components, however they are tested,
    // are guaranteed to pull in this css.
    //
    // see: https://piccalil.li/blog/a-modern-css-reset/
    // https://elad2412.github.io/the-new-css-reset/
    cssRaw(`
*, *::before, *::after {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    animation-fill-mode: forwards;
}
ul[role='list'],
ol[role='list'] {
    list-style: none;
}
html:focus-within {
  scroll-behavior: smooth;


}
body {
  min-height: 100vh;
  line-height: 1.5;
  overscroll-behavior-y: none;
}

html, body {
  overflow-x: hidden;
}

img,
picture {
  max-width: 100%;
  height: auto;
  display: block;
}

input,
button,
textarea,
select,
::file-selector-button,
::placeholder {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Arial, sans-serif;
/*
san-serif:: Helvetica;
Helvetica Neue slightly different
*/
}

.uppy-StatusBar-actionCircleBtn:focus {
  box-shadow: unset;
}
button[title="Pause"][aria-label="Pause"] {
    display: none;
}
:not(pre) > code[class*="language-"], pre[class*="language-"] {
  background-color: white;
  padding: 5px;
  line-height: 1;
}
`)

    // https://developer.mozilla.org/en-US/docs/Web/CSS/text-decoration-skip
    cssRaw(`
body {
    background-color: ${backgroundColor ?? '#f0f3f6'};
    text-decoration-skip-ink: auto;
    text-decoration-skip: objects edges box-decoration;
}

select:required:invalid {
  color: gray;
}
option[value=""][disabled] {
  display: none;
}
option {
  color: black;
}

select.disabled, input::placeholder {
  color: hsl(210, 15%, 70%);
}

`)
}