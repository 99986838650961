import './Privacy.css'

import { JSX } from 'solid-js'

export const Privacy = (): JSX.Element => {
    return <div>
        <p class="pp1 pp2 cc1" style="">PRIVACY POLICY</p>
        <p class="pp1" style=""><span class="cc2"> </span></p>
        <p class="pp1" style=""><span class="cc2"> Igolgi, Corp. (“Igolgi”, “we”, “us” or “our”) is committed to
            protecting your (“you” or “your”) privacy and empowering you with this privacy policy (this “Privacy
            Policy”). This Privacy Policy explains how we collect, use, disclose, and apply the information
            collected when you use or access online website (https://steramengine.igolgi.com), products, services,
            or applications (collectively, the “Services”). Our Terms of Service (the “Terms”) are expressly
            incorporated herein by reference and are an integral part hereof. The Terms can be found here:
            https://streamengine.igolgi.com/igolgi-terms-ofservice.pdf.</span></p>
        <p class="pp1" style=""><span class="cc2"> By using the Services or interacting with us, you are agreeing to this
            Privacy Policy. Please read the following carefully to understand how we collect, use, disclose, and
            maintain information that can be used to identify you. In addition, this Privacy Policy describes your
            choices for use, access, and correction of your personal information. If you do not agree to be bound by
            this Privacy Policy, please stop using the Services.</span></p>
        <p class="pp1" style="">&nbsp;</p>
        <p class="pp3 cc1" style="">1. Changes to this Privacy Policy</p>
        <p class="pp1" style=""><span class="cc2"> We may change this Privacy Policy from time to time. Laws, regulations,
            and industry standards evolve, which may make those changes necessary, or we may make changes to our
            Services. We will post the changes to this page and encourage you to review this Privacy Policy to stay
            informed. If we make changes that materially alter your privacy rights, we may provide additional
            notice, such as via email or through the Services. If you disagree with the changes to this Privacy
            Policy, you should deactivate your Services account.</span></p>
        <p class="pp1" style=""><span class="cc2"> </span><span class="cc3">2. Information We Collect and Receive</span>
        </p>
        <p class="pp1" style=""><span class="cc2"> We collect your information in a variety of ways when you interact with
            the Services. We collect information about you when you create an account and use the Services and we do
            so in order to deliver the Services to you, to help improve your experience, and to support our business
            functions. More information about the categories and sources of information is provided below.</span>
        </p>
        <p class="pp3 cc1" style=""> 2.1 Information You Provided to Us</p>
        <p class="pp1" style=""><span class="cc2">We collect information that you decide to share with us. At times, we
            may require you to provide certain information in order to use certain parts of our Services, fulfill
            your requests, or provide you with certain Services. </span></p>
        <p class="pp1" style="">&nbsp;</p>
        <p class="pp3 cc1" style="">2.1.1 Registering for an Account</p>
        <p class="pp1" style=""><span class="cc2">If you establish an account with us, we may require you to provide your
            name, email address, physical address, payment information, and a password to create your account. All
            payment data is stored by Stripe, Inc., our third-party payment processor, and you should review its
            privacy policies.</span></p>
        <p class="pp1" style=""><span class="cc2"> </span><span class="cc3">2.1.2 Content</span></p>
        <p class="pp1" style=""><span class="cc2">We collect information from the videos that you choose to upload to the
            Services</span></p>
        <p class="pp1" style=""><span class="cc3"> 2.1.3 Content Information</span></p>
        <p class="pp1" style=""><span class="cc2"> You also may choose to send us information about yourself in an email
            containing inquiries about the Services and we use this information in order to help us respond to your
            inquiry. You may also choose to submit information to us via other methods, including: (i) in response
            to marketing or other communications, (ii) through social media or online forums, or (iii) in connection
            with an actual or potential business relationship with us.</span></p>
        <p class="pp1" style="">&nbsp;</p>
        <p class="pp3 cc1" style="">2.2 Information We Obtain from Others</p>
        <p class="pp1" style=""><span class="cc2">If you choose to log in to the Services through a social network (such
            as GitHub, Google, or Slack) or other third-party platform or otherwise connect your account on a
            third-party platform to your account through the Services, we may collect personal information about you
            from that platform or network. For example, this information may include your username, user ID, email
            address and other contact information, and your affiliations. We may also collect information about you
            from other publicly available sources.</span></p>
        <p class="pp3 cc1" style=""> 2.3 Information We Automatically Collect</p>
        <p class="pp1" style=""><span class="cc2"> We automatically collect certain information from you when you use the
            Services, including internet or other network activity information such as your IP address,
            geolocation-related data (as described below), unique device identifiers, browsing and search history
            (including content you have viewed in the Services), and Cookies (as defined below).</span></p>
        <p class="pp1" style=""><span class="cc2"> </span><span class="cc3">2.3.1Log information</span></p>
        <p class="pp1" style=""><span class="cc2">We retain information about you when you access and use the Services.
            This information can include the following: Internet Protocol (“IP”) address, timestamps, browser
            information, Internet Service Provider “ISP”, webpages visited, and the URL of the webpage you visited
            before navigating to our Services.</span></p>
        <p class="pp1" style=""><span class="cc2"> </span><span class="cc3">2.3.2 Usage Information</span></p>
        <p class="pp1" style=""><span class="cc2">We monitor user activity in connection with the Services and may collect
            information about the applications and features you use, websites you visit, as well as types and amount
            of the Services you use.</span></p>
        <p class="pp3 cc1" style=""> 2.3.3 Crash and Error Information</p>
        <p class="pp1" style=""><span class="cc2">If the Services crash or return an error, we may collect data to
            determine the cause of the error using first-or third-party services. The crash or error information
            collected may include the following: Device IP address, device name, operating system version,
            application configurations(s), the time and date, and other statistics.</span></p>
        <p class="pp1" style=""><span class="cc2"> </span><span class="cc3">2.3.4 Cookies and Other Technologies</span></p>
        <p class="pp1" style=""><span class="cc2">Our Services may use cookies and other technologies (such as “pixel
            tags”, “web beacons”, “clear GIFs”, links in emails, JavaScript, device IDs assigned by Google or Apple,
            or similar technologies) (“Cookies”) to collect information and support certain features of our
            Services. Cookies allow us and third-parties to obtain information about your visits to and use of our
            Services, including analyzing your visiting patterns. Although you are not required to accept Cookies
            when you visit our Services, you may be unable to use all of the functionality of our Services if your
            browser restricts our Cookies. We use this information to process your requests and to deliver online
            and mobile advertisements, messages, and content for us and others that are specific to your interests.
            The information we collect from Cookies does not identify you personally but in some cases, we may link
            it to your personal information. Below, we explain the different types of Cookies that may be used on
            the Services.</span></p>
        <p class="pp1" style=""><span class="cc2"> Necessary Cookies are essential to enable your use of the Services and
            to identify and prevent security risks, such as storing your session information to prevent others from
            changing your password without your username and password.</span></p>
        <p class="pp1" style=""><span class="cc2"> Preference Cookies are used to remember your settings and preferences
            and to improve your experience on our website and in using our Services. For example, we may use these
            Cookies to remember your language preferences.</span></p>
        <p class="pp1" style=""><span class="cc2"> Session Cookies are stored only temporarily during a browsing session
            and are deleted from your device when you close the browser. We use these Cookies to support the
            functionality of the Services and to understand your use of the Services —that is, which pages you
            visit, which links you use, and how long you stay on each page.</span></p>
        <p class="pp1" style=""><span class="cc2">Statistics Cookies help collect information about your use of the
            Services and enable us to improve the way it works. These Cookies give us aggregated information that we
            use to monitor the Service’s performance, count page visits, spot technical errors, see how users reach
            the Services, and measure the effectiveness of marketing (including emails).</span></p>
        <p class="pp1" style=""><span class="cc2"> Marketing Cookies are used to show users display advertisements across
            websites. The intention is to display ads that are relevant and engaging for the individual user and
            thereby more valuable for publishers and third-party advertisers. We do not display ads for any website
            besides our own.</span></p>
        <p class="pp1" style=""><span class="cc2"> Unclassified Cookies are Cookies that we are in the process of
            classifying, together with the providers of individual Cookies.</span></p>
        <p class="pp1" style=""><span class="cc2"> Analytics. We may also use Google Analytics and other service providers
            to collect information regarding visitor behavior and visitor demographics on our Services. </span></p>
        <p class="pp1 pp4" style="">&nbsp;</p>
        <p class="pp1" style=""><span class="cc3">3. How We Use Your Information</span><span class="cc2"> </span></p>
        <p class="pp1" style=""><span class="cc2"> We may use the personal information that we collect for our legitimate
            interests and the limited purpose of providing the Services and related functionality and services, as
            described in the Terms and in this Privacy Policy, and as permitted by applicable laws. These purposes
            include circumstances where it is necessary to provide or fulfill the Services requested by or for you
            or where you have given us your express consent. We may use your personal information to:</span></p>
        <ol style="padding:0; margin:0;">
            <li value="1" class="pp5 pp6 li1 b1" style=""><span class="cc2">Provide the information, products, and services
                you request,</span></li>
        </ol>
        <ol style="padding:0; margin:0;">
            <li value="2" class="pp5 pp6 li1 b1" style=""><span class="cc2">Service your account and provide you, if you
                are a registered user, with effective</span></li>
        </ol>
        <p class="pp7" style=""><span class="cc2">customer service,</span></p>
        <ol style="padding:0; margin:0;">
            <li value="3" class="pp5 pp6 li1 b1" style=""><span class="cc2">Better understand your needs and interests, and
                provide you with a personalized</span></li>
        </ol>
        <p class="pp7" style=""><span class="cc2">experience when you use our Services,</span></p>
        <ol style="padding:0; margin:0;">
            <li value="4" class="pp5 pp6 li1 b1" style=""><span class="cc2">Contact you with special offers and other
                information we believe will be of</span></li>
        </ol>
        <p class="pp7" style=""><span class="cc2">interest to you (in accordance with any privacy preferences you have
            expressed</span></p>
        <p class="pp7" style=""><span class="cc2">to us),</span></p>
        <ol style="padding:0; margin:0;">
            <li value="5" class="pp5 pp6 li1 b1" style=""><span class="cc2">Contact you with information and notices
                related to your use of our Services,</span></li>
        </ol>
        <ol style="padding:0; margin:0;">
            <li value="6" class="pp5 pp6 li1 b1" style=""><span class="cc2">Invite you to participate in surveys and
                provide feedback to us (in accordance</span></li>
        </ol>
        <p class="pp7" style=""><span class="cc2">with any privacy preferences you have expressed to us),</span></p>
        <ol style="padding:0; margin:0;">
            <li value="7" class="pp5 pp6 li1 b1" style=""><span class="cc2">Improve our products and service offerings and
                develop new products and</span></li>
        </ol>
        <p class="pp7" style=""><span class="cc2">service offerings, improve our marketing and promotional efforts, and
            improve</span></p>
        <p class="pp7" style=""><span class="cc2">the content, functionality, and usability of the Services,</span></p>
        <ol style="padding:0; margin:0;">
            <li value="8" class="pp5 pp6 li1 b1" style=""><span class="cc2">Enforce our Terms,</span></li>
        </ol>
        <ol style="padding:0; margin:0;">
            <li value="9" class="pp5 pp6 li1 b1" style=""><span class="cc2">Promote security and protect against and
                prevent fraud, claims, and other liabilities,</span></li>
        </ol>
        <ol style="padding:0; margin:0;">
            <li value="10" class="pp5 pp6 li1 b2" style=""><span class="cc2">Verify the information you provide to us as
                well as the representations and warranties you make to us in the Terms or on the Services,</span>
            </li>
        </ol>
        <ol style="padding:0; margin:0;">
            <li value="11" class="pp5 pp6 li1 b2" style=""><span class="cc2"> Meet our internal and external audit
                obligations,</span></li>
        </ol>
        <ol style="padding:0; margin:0;">
            <li value="12" class="pp5 pp6 li1 b2" style=""><span class="cc2">Evaluate your interest in employment and
                contact you regarding possible employment with us,</span></li>
        </ol>
        <ol style="padding:0; margin:0;">
            <li value="13" class="pp5 pp6 li1 b2" style=""><span class="cc2">Help you, if you are one of our employees,
                perform your job functions and to manage our workforce; and</span></li>
        </ol>
        <ol style="padding:0; margin:0;">
            <li value="14" class="pp5 pp6 li1 b2" style=""><span class="cc2"> Use for any other purpose for which we
                provide notice at the time of collection.</span></li>
        </ol>
        <p class="pp1" style=""><span class="cc2"> We may aggregate and/or de-identify information collected through the
            Services and from other sources so that such information can no longer be linked to you or your device
            (“Aggregate/De-Identified Information”). We may use Aggregate/De-Identified Information for any purpose,
            including, without limitation, for research and marketing purposes</span></p>
        <p class="pp1" style="">&nbsp;</p>
        <p class="pp1" style=""><span class="cc3"> 4.How We Share and Disclose Information</span></p>
        <p class="pp3 cc1" style=""> 4.1 Service Providers</p>
        <p class="pp1" style=""><span class="cc2">We provide access to or share your personal information with select
            third parties who perform services on our behalf. They have access to these services but are prohibited
            from using your personal information for other purposes. They provide a variety of services to us,
            including, for example, billing, sales, marketing, product content and features, advertising, analytics,
            research, customer service, data storage, security, fraud prevention, payment processing, and legal
            services.</span></p>
        <p class="pp3 cc1" style=""> 4.2 Business Partners</p>
        <p class="pp1" style=""><span class="cc2"> Sometimes we may also collaborate with other organizations, and we may
            share your personal information to jointly offer products and services.</span></p>
        <p class="pp1" style=""><span class="cc2"> </span><span class="cc3">4.3 Your Consent</span></p>
        <p class="pp1" style=""><span class="cc2">We may share your personal information for other purposes pursuant to
            your consent or with your further direction. If you access third-party services, such as Google, GitHub,
            or Slack, to login to the Services, these third-party services may be able to collect your personal
            information, including information about your activity on the Services, in accordance with their privacy
            policies.</span></p>
        <p class="pp3 cc1" style=""> 4.4 Aggregate/De-Identified Data</p>
        <p class="pp1" style=""><span class="cc2">From time to time, we may share Aggregate/De-Identified Information
            about use of the Services, such as by publishing a report on usage trends. The sharing of such data is
            unrestricted.</span></p>
        <p class="pp3 cc1" style=""> 4.5 Legal Reasons</p>
        <p class="pp1" style=""><span class="cc2">We may also disclose your personal information when we, in good faith,
            believe disclosure is appropriate to comply with the law, a court order, or a subpoena. We may also
            disclose your personal information to prevent or investigate a possible crime, such as fraud or identity
            theft; to protect the security of our Services; to enforce or apply our policies or other agreements; or
            to protect our own rights or property or the rights, property or safety of our users or others. We will
            attempt to notify our users about legal demands for their personal information when appropriate in our
            judgment unless prohibited by law or court order or when the request is an emergency. We may dispute
            such demands when we believe, in our discretion, that the requests are overbroad, vague, or lack proper
            authority.</span></p>
        <p class="pp3 cc1" style="">4.6 Sale, Merger, or Other Business Transfer</p>
        <p class="pp1" style=""><span class="cc2">As we continue to develop our business, we may buy, merge, or partner
            with other companies. In such transactions (including in contemplation of such transactions), personal
            information may be among the transferred assets. If a portion or all our assets are sold or transferred
            to a third-party, your personal information would likely be one of the transferred business assets. If
            such a transfer is subject to additional mandatory restrictions under applicable laws, we will comply
            with such restrictions.</span></p>
        <p class="pp1 pp4" style="">&nbsp;</p>
        <p class="pp1" style=""><span class="cc3"> 5. Your Choices</span></p>
        <p class="pp1" style=""><span class="cc2"> (a) You can manage Cookies through your web browser. Most browsers will
            tell you how to stop accepting new Cookies, how to be notified when you receive a new Cookie, and how to
            disable existing Cookies. You can find out how to do this for your particular browser by clicking “help”
            on your browser’s menu or by visiting www.allaboutcookies.org. Please note, however, that without
            Cookies you may not be able to take full advantage of all our website features. In addition to the
            browser-based controls, you can manage third party Cookies by visiting www.aboutads.info/choices/. To
            separately make choices for mobile apps on a mobile device, you can download DAA’s AppChoices
            application from your device’s app store. Alternatively, for some devices you may use your device’s
            platform controls in your settings to exercise choice. Please note you must separately opt out in each
            browser and on each device. Advertisements on third party websites that contain the AdChoices link may
            have been directed to you based on information collected by advertising partners over time and across
            websites. These advertisements provide a mechanism to opt out of the advertising partners’ use of this
            information for interest-based advertising purposes.</span></p>
        <p class="pp1" style=""><span class="cc2"> (b) If you have registered for an account, you may access, review, and
            update certain personal information that you have provided to us by logging into your account and using
            available features and functionalities or by contacting us in accordance with the “Contact Us” section
            below.</span></p>
        <p class="pp1" style=""><span class="cc2"> (c) You have a choice at any time to stop us from sending you emails
            for marketing purposes by following the “unsubscribe” link included in these messages. Please note that
            despite any indicated email marketing preferences, we may continue to send you administrative emails
            regarding Igolgi, including, for example, notices of updates to our Terms or this Privacy Policy,
            notifications of new survey opportunities, if we choose to provide such notices to you in this
            manner.</span></p>
        <p class="pp1" style=""><span class="cc2"> (d) If you do not provide the personal information that we need to
            provide the Services, we may not be able to provide the Services or certain functionalities. We will
            tell you what personal information you must provide in order to receive the Services.</span></p>
        <p class="pp1" style=""><span class="cc2"> (e) If you choose to connect to the Services through your social media
            account or another third-party platform, you may be able to use your settings in your account with that
            platform to limit the information we receive from it. If you revoke our ability to access information
            from a third-party platform, that choice will not apply to information that we have already received
            from that third party.</span></p>
        <p class="pp1" style=""><span class="cc2"> (f) Some browsers offer a “do not track” (“DNT”) option. Since no
            common industry or legal standard for DNT has been adopted by industry groups, technology companies, or
            regulators, we do not respond to DNT signals. We will make efforts to continue to monitor developments
            around DNT browser technology and the implementation of a standard.</span></p>
        <p class="pp3 cc1" style=""> 6. Security</p>
        <p class="pp1" style=""><span class="cc2"> We take reasonable precautions intended to help protect your personal
            information that we collect. Unfortunately, no system or online transmission of data is completely
            secure, and we cannot guarantee the security of data transmitted across the Internet. If you believe
            that information you provided to us is no longer secure, please notify us immediately using the contact
            information provided below.</span></p>
        <p class="pp3 cc1" style="">7 European Residents</p>
        <p class="pp1" style=""><span class="cc2"> If you are a resident of the European Economic Area, we rely on our
            legitimate interest as described in this Privacy Policy to process your personal information.
            Additionally, subject to any exemptions as provided by law, you may have certain rights regarding the
            personal information we maintain about you. We offer you certain choices about what personal information
            we collect from you, how we use that information, and how we communicate with you. If at any time you
            wish to exercise your rights, please reach out to us in accordance with the “Contact Us” section
            below.</span></p>
        <p class="pp1" style=""><span class="cc2"> </span><span class="cc3">7.1 Right of Access</span></p>
        <p class="pp1" style=""><span class="cc2"> If you ask us, we will confirm whether we are processing your personal
            information and, if so, provide you with a copy of that personal information along with certain other
            details. If you require additional copies, we may charge a reasonable fee.</span></p>
        <p class="pp3 cc1" style=""> 7.2 Right to Rectification</p>
        <p class="pp1" style=""><span class="cc2"> If your personal information is inaccurate or incomplete, you are
            entitled to ask that we correct or complete it.</span></p>
        <p class="pp3 cc1" style=""> 7.3 Right to Erasure</p>
        <p class="pp1" style=""><span class="cc2">You may ask us to erase your personal information in some circumstances,
            such as where we no longer need it or you withdraw your consent (where applicable) and there is no other
            legal basis for processing.</span></p>
        <p class="pp3 cc1" style=""> 7.4 Right to Restrict Processing</p>
        <p class="pp1" style=""><span class="cc2"> You may ask us to restrict or ‘block’ the processing of your personal
            information in certain circumstances, such as if you contest its accuracy or object to us processing
            it.</span></p>
        <p class="pp3 cc1" style=""> 7.5 Right to Data Portability</p>
        <p class="pp1" style=""><span class="cc2">You have the right to obtain your personal information from us that you
            consented to give us or that was provided to us as necessary in connection with our contract with you,
            and if the processing is carried out by automated means.</span></p>
        <p class="pp1" style=""><span class="cc3"> 7.6 Right to Object</span></p>
        <p class="pp1" style=""><span class="cc2"> You may ask us at any time to stop processing your personal
            information, and we will do so: (a) if we are relying on a legitimate interest to process your personal
            information, unless we demonstrate compelling legitimate grounds for the processing or your data is
            needed to establish, exercise, or defend legal claims; or (b) we are processing your personal
            information for direct marketing and, in such case, we may keep minimum information about you (for
            example, in a suppression list) as necessary for our and your legitimate interest to ensure your opt out
            choices are respected in the future and to comply with data protection laws.</span></p>
        <p class="pp1" style="">&nbsp;</p>
        <p class="pp1" style=""><span class="cc2"> </span><span class="cc3">7.7 Right to Withdraw Consent</span></p>
        <p class="pp1" style=""><span class="cc2"> If we rely on your consent to process your personal information, you
            have the right to withdraw that consent at any time, but this will not affect any processing of your
            data that has already taken place.</span></p>
        <p class="pp1" style=""><span class="cc2"> </span><span class="cc3">7.8 Right to lodge a Complaint</span></p>
        <p class="pp1" style=""><span class="cc2"> If you have a concern about our privacy practices, including the way we
            handled your personal information, you can report it to the data protection authority that is authorized
            to hear those concerns.</span></p>
        <p class="pp1" style=""><span class="cc3"> 8. California Residents</span></p>
        <p class="pp1" style=""><span class="cc2"> (a) If you are a resident of the State of California, you have certain
            rights afforded to you under the California Consumer Privacy Act or the “CCPA”. When we say “Personal
            Information” in this section, we mean information that identifies, relates to, describes, is reasonably
            capable of being associated with, or could reasonably be linked, directly or indirectly, with you.
            Personal Information does not include information that is aggregated or information that cannot be
            reasonably linked to you.</span></p>
        <p class="pp1" style=""><span class="cc2">(b) We collect Personal Information and Non-Personal Information for the
            business and commercial purposes described in the section above titled “How We Use Your Information”,
            and we share Personal Information with the categories of third parties described in the section above
            titled “How We Share and Disclose Information”. </span></p>
        <p class="pp1" style=""><span class="cc2">(c) We do not sell (as such term is defined in the CCPA) the Personal
            Information we collect (and will not sell it without providing a right to opt out right)</span></p>
        <p class="pp1" style=""><span class="cc2"> (d) Subject to certain limitations, the CCPA provides California
            residents the following</span></p>
        <ol style="padding:0; margin:0;">
            <li value="1" class="pp5 pp6 li1 b1" style=""><span class="cc2">You have the right to request that we disclose
                to you the Personal Information we collect, use, or disclose, and information about our data
                practices; </span></li>
        </ol>
        <ol style="padding:0; margin:0;">
            <li value="2" class="pp5 pp6 li1 b1" style=""><span class="cc2">You have the right to request that we delete
                your Personal Information that we have collected from you; </span></li>
        </ol>
        <ol style="padding:0; margin:0;">
            <li value="3" class="pp5 pp6 li1 b1" style=""><span class="cc2">You have the right to request that we no longer
                sell your Personal Information to third parties; </span></li>
        </ol>
        <ol style="padding:0; margin:0;">
            <li value="4" class="pp5 pp6 li1 b1" style=""><span class="cc2">We will not discriminate against you for
                exercising any of these rights. California residents may make a request pursuant to their “right to
                know”, “right to request deletion”, and/or “right to opt-out” under the CCPA by contacting us in
                accordance with the “Contact Us” section below. </span></li>
        </ol>
        <p class="pp1" style=""><span class="cc2">In order to verify your request to know or delete information, we may
            require you to provide us with certain information to be used solely for the purpose of verifying your
            identity.</span></p>
        <p class="pp1" style=""><span class="cc2"> (e) Under the CCPA, you may exercise these rights yourself or you may
            designate an authorized agent to make these requests on your behalf. We may request that your authorized
            agent have written permission from you to make requests on your behalf and may need to verify your
            authorized agent’s identity.</span></p>
        <p class="pp1" style=""><span class="cc2"> </span><span class="cc3">9. Nevada Residents</span></p>
        <p class="pp1" style=""><span class="cc2">Under Nevada law, certain Nevada consumers may opt out of the sale of
            “personally identifiable information” for monetary consideration (as such terms are defined under Nevada
            law) to a person for that person to license or sell such information to additional persons. We do not
            engage in such activity; however, if you are a Nevada resident who has purchased services from us, you
            may submit a request to opt out of any potential future sales under Nevada law by contacting us in
            accordance with the “Contact Us” section below. Please note we will take reasonable steps to verify your
            identity and the authenticity of the request. Once verified, we will maintain your request in the event
            our practices change.</span></p>
        <p class="pp3 cc1" style=""> 10.Information Retention. </p>
        <p class="pp1" style=""><span class="cc2">We will retain personal information required to comply with privacy
            requests, manage active accounts, as required by law, in order to resolve disputes, or enforce our
            agreements. We will retain the personal information we process on behalf of our users as directed by
            them. We may also retain copies of your personal information for disaster recovery purposes, to prevent
            fraud or future abuse, or for legitimate business purposes, such as analysis of Aggregate/De-Identified
            Information, account recovery, and auditing our records.</span></p>
        <p class="pp3 cc1" style=""> 11. Data Processing and Data Transfers</p>
        <p class="pp1" style=""><span class="cc2">Your information, including personal information, may be transferred to
            – and maintained on – computers located outside of your state, province, country or other governmental
            jurisdiction where the data protection laws may differ from those of your jurisdiction.</span></p>
        <p class="pp1" style=""><span class="cc2">If you are located outside the United States and choose to provide
            information to us, please note that we transfer the data, including personal information, to the United
            States and process it there. Your consent to this Privacy Policy followed by your submission of such
            information represents your agreement to that transfer. We will take all the steps reasonably necessary
            to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer
            of your personal information will take place to an organization or a country unless there are adequate
            controls in place including the security of your personal information and other information.</span></p>
        <p class="pp1" style=""><span class="cc2"> </span><span class="cc3">12. Links to Third-Party Websites and Social
            Media Platforms</span></p>
        <p class="pp1" style=""><span class="cc2">The Services may provide the ability to connect to other websites. These
            websites may operate independently from us and may have their own privacy notices or policies, which we
            strongly suggest you review. If any linked website is not owned or controlled by us, we are not
            responsible for its content, any use of the website, or the privacy practices of the operator of the
            website.</span></p>
        <p class="pp1" style=""><span class="cc2"> </span><span class="cc3">13.Children’s Privacy</span></p>
        <p class="pp1" style=""><span class="cc2">The Services are directed to individuals at the age of 18 and over. We
            do not knowingly collect personal information from individuals under the age of 18. If we become aware
            of individuals who have provided us with personal information and are under the age of 18, we will take
            steps to deactivate the account and delete the personal information. If you become aware of information
            from individuals under the age of 18 which has been provided to us, please contact us in accordance with
            the “Contact Us” section below.</span></p>
        <p class="pp3 cc1" style=""> 14. Contact Us</p>
        <p class="pp1" style=""> If you have any questions or concerns about our Privacy Policy, please contact us via
        email at live.support@igolgi.com.</p>
    </div>
}