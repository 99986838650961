import '@uppy/core/dist/style.min.css'
import '@uppy/informer/dist/style.min.css'
import '@uppy/core/dist/style.min.css'
import '@uppy/status-bar/dist/style.min.css'

import Uppy from '@uppy/core'
import DragDrop from '@uppy/drag-drop'
import DropTarget from '@uppy/drop-target'
import Informer from '@uppy/informer'
import StatusBar from '@uppy/status-bar'
import Tus from '@uppy/tus'
import { SUPPORTED_CONTAINERS } from 'common'
import { createEffect, createSignal, JSX, onCleanup } from 'solid-js'
import toast from 'solid-toast'
import { style } from 'typestyle'

import { useAuthenticatedContext } from './context'
type Props = {
    suffix: string,
    showUploadArea?: boolean,
    onFileUpload?: (p: { name: string, id: string }) => Promise<void>
    setUploadingIsInProgress: (x: boolean) => void
}
export const Upload = (props: Props): JSX.Element => {
    const { auth0token, user, urls } = useAuthenticatedContext()
    const [ref, setRef] = createSignal<HTMLElement>()
    const tusEndpoint = `${urls.base}/uploads`
    console.log('tusEndpoint', tusEndpoint)
    createEffect(() => {
        console.log('showUploadArea', props.showUploadArea)
    })
    createEffect(() => {
        const userId = user()?.id

        if(userId == undefined || ref() == undefined) return
        console.log('upload>', userId, ref(), auth0token())
        const uppy = new Uppy({
            debug       : true,
            // onBeforeFileAdded: () => true, // doesn't cause the plugin to accept duplicate files?
            restrictions: {
                // allowedFileTypes: [],
                // maxFileSize: 1,
            },
            meta: { userId }, // key-value pair to attach to file
        })
            .use(DragDrop, { target: `#drop${props.suffix}`, locale: { strings: {
                // Text to show on the droppable area.
                // `%{browse}` is replaced with a link that opens the system file selection dialog.
                dropHereOr: 'Drop here or %{browse}',
                // Used as the label for the link that opens the system file selection dialog.
                browse    : 'browse',
            } } })
            .use(DropTarget, { target: `#drop${props.suffix}` })
            .use(Informer, { target: `#informer${props.suffix}` })
            .use(StatusBar, { target: `#status${props.suffix}` })
            .use(Tus, {

                onBeforeRequest(req): Promise<void> {
                    // https://uppy.io/docs/tus/#onshouldretry-err-retryattempt-options-next
                    req.setHeader('Authorization', `Bearer ${auth0token() ?? '-'}`)
                    return new Promise((resolve) => resolve())
                },
                endpoint: tusEndpoint,
            })

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        uppy.on('cancel-all', () => {
            props.setUploadingIsInProgress(false)
        })

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        uppy.on('file-added', async () => {
            const result = await uppy.upload()
            if('successful' in result && result.successful.length > 0) {
                alert('Your file has been successfully uploaded')
            }
        })
        uppy.on('upload', () => {
            toast('Your file is being uploaded. Navigation has been temporarily disabled.')
        })
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        uppy.on('upload-progress', (result, progress) => {
            props.setUploadingIsInProgress(progress.bytesUploaded < progress.bytesTotal)
        })

        uppy.on('file-added', (file) => {
            const e = SUPPORTED_CONTAINERS
            if(e.find(x => file.name.toLowerCase().endsWith('.' + x)) == undefined) {
                uppy.removeFile(file.id)
                setTimeout(() => {
                    uppy.removeFile(file.id)
                    alert('Your file has an unsupported extension.')
                }, 100)
                return false
            }
            return true
        })

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        uppy.on('complete', async (result) => {
            props.setUploadingIsInProgress(false)
            if (result.failed.length === 0) {
                console.log('Upload successful üòÄ')
            } else {
                console.warn('Upload failed üòû')
            }
            for(const s of result.successful) {
                const id = s.uploadURL.split('/').pop()!
                await props.onFileUpload?.({ id, name: s.name })
            }
            console.log('successful files:', result.successful)
            console.log('failed files:', result.failed)
        })
        onCleanup(() => {
            uppy.removePlugin(uppy.getPlugin('DragDrop')!)
            uppy.removePlugin(uppy.getPlugin('DropTarget')!)
            uppy.removePlugin(uppy.getPlugin('Informer')!)
            uppy.removePlugin(uppy.getPlugin('StatusBar')!)
            uppy.removePlugin(uppy.getPlugin('Tus')!)
        })
    })

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    return <div ref={setRef}
        class={uploadBox}
        style={{ 'background-color': 'auto', 'padding-bottom': '0.5em' }}>
        <div id={`status${props.suffix}`}></div>
        <div id={`informer${props.suffix}`}></div>
        <div id={`target${props.suffix}`}></div>
        {/* Note: don't use display: none, seems to unbind it, set height instead */}
        <div style={{ height: (props.showUploadArea ?? true) ? 'auto' : '0px', overflow: 'hidden' }}>
            <div id={`drop${props.suffix}`} style={{ border: '1px solid gray', padding: '10px', 'border-style': 'dashed' }}></div>
        </div>
    </div>
}

export const uploadBox = style({
    // $nest: {
    //     '& button.uppy-StatusBar-actionCircleBtn path': {
    //         fill: '#ff0000',
    //     },
    // },
})




{/* <button title="Pause" aria-label="Pause" class="uppy-u-reset uppy-StatusBar-actionCircleBtn" type="button" data-uppy-super-focusable="true">
    <svg aria-hidden="true" focusable="false" class="uppy-c-icon" width="16" height="16" viewBox="0 0 16 16">
        <g fill="none" fillRule="evenodd">
            <circle fill="#888" cx="8" cy="8" r="8">
            </circle>
            <path fill="#FFF" d="M5 4.5h2v7H5v-7zm4 0h2v7H9v-7z"></path>
        </g>
    </svg>
</button> */}