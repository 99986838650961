/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// import { Input, input } from 'api/src/domain/types'
import * as commonSchema from 'common/src/schema'
import { Accessor, createEffect, createSignal, For, JSX, Show } from 'solid-js'
import { z } from 'zod'

import question from './../../assets/question.svg'



type PartialExcept<T, K extends keyof T> = Partial<Omit<T, K>> & Pick<T, K>;
type Data = PartialExcept<commonSchema.SimplifiedJobInput, 'videoProfiles' | 'audioProfiles' | 'cloud_credentials'>
type Props<O extends z.ZodObject<any>, K extends keyof (O['shape'])> = {
    obj: O,
    label: string,
    errors: Accessor<z.ZodError<Data> | undefined>
    key: K,
    dict: Partial<Record<K, any>>,
    callbackOnInputType?:() => void,
    disabled?: boolean,
    override?: z.ZodType<any, any>
    narrow?: any[],
    isText?: boolean
    arrayValues?: number[]
    conditional?: boolean,
    path: string,
    moreStyle?: JSX.CSSProperties
    cssClass?: string
    step?: number
    valueMap?: Record<O['shape'][K]['_output'], string>
    rhs?: JSX.Element
    coerceNumber?: (t: any) => any
}
export const Row = <O extends z.ZodObject<any>, K extends keyof (O['shape'])>(p: Props<O, K>) => {
    let value: z.ZodType<any, any> = p.override ?? p.obj.shape[p.key]
    let def: any = ''
    let description: string = ''
    while(true){
        if(description == '' && value.description != undefined) {
            description = value.description
        }
        if(value instanceof z.ZodDefault){
            def = value._def.defaultValue()
            value = value._def.innerType
            p.dict[p.key] = p.dict[p.key] ?? def // set default value
        } else if(value instanceof z.ZodOptional || value instanceof z.ZodNullable){
            value = value.unwrap()
        } else {
            break
        }
    }
    let hasFieldset = false
    let widget = <div></div>
    const [get, set] = createSignal(def == '')

    // console.log(p.label, 'dict[key] ?? def', p.dict[p.key] ?? def, p.dict[p.key] ?? def == '')
    const className = `key_${p.key as string}`
    if(value instanceof z.ZodNumber) {
        let ref: any
        widget = <input
            ref={ref}
            class={className}
            disabled={p.disabled}
            type="number"
            step={p.step ?? 1}
            value={p.dict[p.key] ?? ''}
            onChange={(e) => {
                if(p.coerceNumber != undefined) {
                    const cursorPosition = ref.selectionStart
                    ref.value = p.coerceNumber(e.currentTarget.value)
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                    ;(ref).setSelectionRange(cursorPosition, cursorPosition)
                }
            }}
            onInput={(e) => {
                p.callbackOnInputType?.()
                if(e.currentTarget.value.trim() == ''){
                    p.dict[p.key] = null
                } else {
                    p.dict[p.key] = Number(e.currentTarget.value)
                }
            }}/>
    }else if (value instanceof z.ZodString && p.arrayValues != undefined) {
        hasFieldset = true
        const valueSet = new Set((p.dict[p.key] as string | undefined ?? '').split(',').filter(x => x.length > 0).map(x => Number(x)))
        console.log('valueSet', valueSet)
        widget = <fieldset
            class={className}
            style={{ border: `1px solid ${hsl(213, 20, 90)}`, padding: '0.7em' }}>
            <legend>Audio Profiles</legend>
            <For each={p.arrayValues ?? [] }
            >
                {(item) => (
                    <div>
                        <input
                            checked={valueSet.has(item - 1)}
                            onInput={(e) => {
                                let valueSet = new Set((p.dict[p.key] as string | undefined ?? '').split(',').filter(x => x.length > 0))
                                if(e.currentTarget.checked) {
                                    valueSet = valueSet.add(e.currentTarget.value)
                                } else {
                                    valueSet.delete(e.currentTarget.value)
                                }
                                const newValue = Array.from(valueSet)
                                p.dict[p.key] = newValue.sort((x, y) => x.localeCompare(y)).join(',')
                                console.log(p.key, p.dict[p.key])
                                p.callbackOnInputType?.()
                            } }
                            type='checkbox'
                            id={`${item}`}
                            name='check-select'
                            value={item - 1}
                        />
                        <label class={labelClass} for={`${item}`} style={{ 'margin-left': '0.7em' }}>Audio {item}</label>
                    </div>
                )}
            </For>
        </fieldset>
    } else if (value instanceof z.ZodString) {
        widget = <Show when={p.isText} fallback={
            <input class={className} type="text" disabled={p.disabled}
            // placeholder={p.label}
                value={p.dict[p.key] ?? ''}
                onInput={(e) => {
                    if(e.currentTarget.value.trim() == ''){
                        p.dict[p.key] = null
                    } else {
                        p.dict[p.key] = e.currentTarget.value
                        p.callbackOnInputType?.()
                    }
                }}></input>
        }>
            <textarea
                class={className}
                disabled={p.disabled}
                // placeholder={p.label}
                value={p.dict[p.key] ?? ''}
                onInput={(e) => {
                    if(e.currentTarget.value.trim() == ''){
                        p.dict[p.key] = null
                    } else {
                        p.dict[p.key] = e.currentTarget.value
                        p.callbackOnInputType?.()
                    }
                }}>

            </textarea>
        </Show>
    } else if (value instanceof z.ZodBoolean) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment
        widget = <select
            class={className}
            value={p.dict[p.key] ?? def}
            disabled={p.disabled}
            onInput={(e) => {
                p.dict[p.key] = e.currentTarget.value == 'true'
                set(e.currentTarget.value == '')
                p.callbackOnInputType?.()
            }}
            // classList={{ disabled: get() == true && def != '' }}
        >
            {/* <Show when={def === ''}>
                <option value="" selected>{p.label}?</option>
            </Show> */}
            <option value={'true'}>Yes</option>
            <option value={'false'}>No</option>
        </select>
    } else if(value instanceof z.ZodUnion){
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-assignment
        widget = <select
            class={className}
            value={p.dict[p.key] ?? def}
            disabled={p.disabled}
            onInput={(e) => {
                const actualValue = (value._def.options as any[]).find(x => x.value == e.currentTarget.value)?.value
                // Must save the value as stored in _def.options, otherwise we save strings instead of numbers and validation will
                p.dict[p.key] = actualValue ??  e.currentTarget.value
                set(e.currentTarget.value == '')
            } }
            // classList={{ disabled: get() == true }}
        >
            {/* <Show when={def === ''}>
                <option value="" selected>{p.label}</option>
            </Show> */}
            <For each={(value._def.options as any[]).filter(x => p.narrow == undefined || p.narrow.includes(x.value))}>{(e) => {
                const val = (e as z.ZodLiteral<string>).value
                return <option value={val}>{p.valueMap?.[val as K] ?? val}</option>
            }}</For>
        </select>
    } else if(value instanceof z.ZodArray){
        hasFieldset = true
        widget = <fieldset class={className}>
            <legend>Audio Profiles</legend>
            <For each={p.arrayValues ?? [] }
            >
                {(item) => (
                    <div>
                        <input
                            onInput={(e) => {
                                let valueSet = new Set(p.dict[p.key] as number[])
                                if(e.currentTarget.checked) {
                                    valueSet = valueSet.add(Number(e.currentTarget.value))
                                } else {
                                    valueSet.delete(Number(e.currentTarget.value))
                                }
                                const newValue = Array.from(valueSet)
                                p.dict[p.key] = newValue
                                p.callbackOnInputType?.()
                            } }
                            type='checkbox'
                            id={`${item}`}
                            name='check-select'
                            value={item}
                        />
                        <label class={labelClass} for={`${item}`}>{item}</label>
                    </div>
                )}
            </For>
        </fieldset>
        console.log('audio profiles', widget)
    }
    const [error, setError] = createSignal<null | string>(null)
    createEffect(() => {
        let theError: null | string = null
        //

        for(const e of (p.errors?.()?.errors ?? [])) {
            const separator = (p.path.length == 0 || p.path.endsWith('.')) ? '' : '.'
            if(p.key == 'input') {
                console.log(e.path.join('.'), `${p.path}${separator}${p.key.toString()}`)
            }
            if(e.path.join('.') == `${p.path}${separator}${p.key.toString()}`) {
                theError = e.message
            }
        }
        setError(theError)
    })
    // createEffect(() => {
    //     const a = JSON.stringify(p.dict)
    //     console.log('>>>', 1)
    // })

    const shouldShow = (() => {
        const show = (p.conditional != true) || (p.key in p.dict)
        // console.log('>>>!!', p.key, show, p.key in p.dict, JSON.parse(JSON.stringify(p.dict)))
        // if(p.key == 'audio_output_path') {
        // }
        return show // DON'T USE hasOwnProperty or has.key!! not reactive for some reason.
    })

    const wrap = hasFieldset
        ? widget
        : <Inputs label={p.label} rhs={p.rhs} description={description}>{widget}</Inputs>
    return <Show when={shouldShow()}>
        <ErrorHint error={error()} cssClass={p.cssClass} moreStyle={p.moreStyle}>{wrap}</ErrorHint>
    </Show>
}




// label is now ignored
export const Inputs = (p: { rhs?: JSX.Element, children: JSX.Element, label?: string, description?: string }): JSX.Element => {
    return <div class={main} classList={{ input: true }}>
        <div style={{ float: 'right' }}>{p.rhs}</div>
        <label class={labelClass} style={{ 'padding-left': '0.25em', 'font-size': '0.8em' }}>{p.label}
            <Show when={(p.label ?? '').length > 0 && (p.description?.length ?? 0) > 0}>
                <Mouseover trigger={
                    <img style={{ display: 'inline', 'padding-left': '5px', height: '1em', transform: 'translate(0px, 0.1em)' }} src={question}/>
                }>
                    <div>{p.description}</div>
                </Mouseover>
            </Show>
        </label>

        <div classList={{ widget: true }} class={widgetStyle} style={{ 'margin-top': 0 }}>{p.children}</div>
    </div>
}

import { style } from 'typestyle'

import { borderRadius, color } from '../../constants'
import { em, hsl } from '../../helpers'
import { ErrorHint } from './ErrorHint'
import { Mouseover } from './Mouseover'


export const main = style({

})

export const widgetStyle = style({
    display    : 'inline-block',
    marginBlock: em(0.5),
    width      : '100%',
    $nest      : {
        '*': {
            fontSize    : em(0.8),
            padding     : '0.7em',
            width       : '100%',
            borderWidth : 1,
            borderColor : hsl(213, 20, 90),
            borderStyle : 'solid',
            borderRadius: borderRadius,
        },

        'input': {
            outline: `${em(0)} solid ${color.highlight.medium}`,
            // transition: 'all 0.1s ease-out;',
        },
        'input:focus': {
            boxShadow: `0 0 0 0.5em ${color.highlight.medium}`,
            border   : `1px solid ${color.highlight.dark}`,
        },
    },
})
export const labelClass = style({
    // fontWeight: 'bold',
    // display: 'none',
    // width     : '10em',
    // fontWeight: 400,
    // paddingTop: '1.2em',
    // fontSize  : em(0.8),
})

