import 'video.js/dist/video-js.css'

import { flip, offset, shift } from '@floating-ui/dom'
import { autoUpdate } from '@floating-ui/dom'
import { ColumnDef } from '@tanstack/solid-table'
import { computeProgress, computeState, StateValue } from 'common'
import * as commonSchema from 'common/src/schema'
import dayjs from 'dayjs'
import { useFloating } from 'solid-floating-ui'
import { Accessor, createEffect, createMemo, createSignal, For, JSX, Show } from 'solid-js'
import { style } from 'typestyle'

// <- HLS support requires the full bundle
import { em } from '../helpers'
import { header } from '../style'
import { copyToClipboard, JOB_CONFIG_CACHE_KEY, MainState } from './common'
import { useAuthenticatedContext } from './context'
import { HoverOnClick } from './Job/Mouseover'
import { Content } from './Library'
import { signalFromPrefix } from './replicache'
import { makeTable } from './table'
import VideoPlayer from './VideoPlayer'

type JobDatum = commonSchema.JobModel

const MAX_VIDEO_VIEWS = 5
const httpBase = 'https://yzwhip2gci7s.objectstorage.ca-toronto-1.oci.customer-oci.com/n/yzwhip2gci7s/b/igolgi-public/o/'

const getters = {
    id      : (job: JobDatum) => job._auto_generated_id_,
    state   : (job: JobDatum | null) => computeState(job?.status ?? null, job!._auto_generated_id_),
    progress: (j: JobDatum) => {
        const progress = computeProgress(() => ({ status: j.status, _auto_generated_id_: j._auto_generated_id_ }))
        const state = j.status?.state
        let s: StateValue = 'ongoing'// `${state?.toString() ?? ''}${job_completed?.toString() ?? ''}${progress?.toString() ?? ''}`
        if(state == 'abort') s = 'abort'
        else if(state == 'error') s = 'error'
        else if(progress == 100) s = 'success'
        if(state == 'abort' || s == 'error') return 0
        else return progress
    },
    started : (info: JobDatum) => dayjs(info.created_at).format('MM-DD-YY hh:mm A'),
    finished: (info: JobDatum) => {
        const d = dayjs(info.finished_at)
        return d.isValid() ? d.format('MM-DD-YY hh:mm A') : '...'
    },
    seconds: (j: JobDatum) => {
        const info = j.streamengine_job_get_response?.media_input_duration_in_sec
        return Math.max(0, Math.floor((info) ?? 0))
    },
    charge: (job: JobDatum) => {
        if(getters.state(job) != 'success') return '$0.00'
        const parse = commonSchema.input_media.safeParse(job.streamengine_job_get_response?.input_media ?? {})
        if(job.streamengine_job_get_response?.input_media != undefined && parse.success && parse.data.video_set.length > 0 && 'duration_in_sec' in parse.data.video_set[0]) {
            const { duration_in_sec, frames_per_second, width, height } = parse.data.video_set[0]

            const codec = job.config.video_codec == 'h.264' ? 'H.264' : (job.config.video_codec == 'mpeg2' ? 'MPEG2' : 'HEVC')
            if(job.usage_amount_reported_in_cents == undefined) {
                return '$0.00'
            } else {
                const credits_used = Number(job.usage_amount_reported_in_cents) / 100//costInDollars(codec, duration_in_sec, Number(frames_per_second), width * height)
                //   console.log('credits_used', credits_used)
                return `$${(Math.max(credits_used, .01)).toFixed(2)}`
            }
        }
        return `$0.00`
    },
}as const

function formatSeconds(seconds: number): string {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
}
const defined = <T extends any>(x: T | undefined | null) : x is T => x !== undefined && x !== null
// USE THIS  https://github.com/TanStack/table
export const JobHistoryTable = (p: { mainState: MainState, limit?: number, jobStatuses: Accessor<commonSchema.JobModel[]>,  small: boolean, user?: Accessor<commonSchema.UserModel | undefined> }): JSX.Element => {
    const [watchWindowForJob, setWatchWindowForJob] = createSignal<string | number | undefined>(undefined)

    createEffect(() => {
        console.log('jobs', p.jobStatuses().length)
    })

    let defaultColumns: ColumnDef<JobDatum>[] = [
        { accessorFn: j => j,
          cell      : info => {
              return getters.id(info.getValue<JobDatum>())
          },
          invertSorting: true,
          sortingFn    : (a, b) => Number(a.original._auto_generated_id_) - Number(b.original._auto_generated_id_),
          header       : 'Job ID',
        },
        { accessorFn: j => j,
          header    : 'State',
          cell      : info => {
              const job = info.getValue<JobDatum>()
              const s = getters.state(job)
              let error: string | undefined = info.getValue<JobDatum>().streamengine_job_get_response?.error_description
              if(s == 'error' && job.status?.error_str != undefined) {
                  error = error ?? job.status?.error_str
              }
              return <State state={s} message={error}/>
          },
        },
        { accessorFn: j => j,
          header    : 'Progress',
          cell      : job => {
              const info = getters.progress(job.getValue<JobDatum>())
              return <progress  style={{ width: '100%' }} max="100" value={info}></progress>
          },
        },
        { header    : 'Started',
          accessorFn: j => j,
          cell      : info => {
              return getters.started(info.getValue<JobDatum>())
          },
        },
        { accessorFn: j => j,
          header    : 'Finished',
          cell      : info => {
              return getters.finished(info.getValue<JobDatum>())
          },
        },
        { accessorFn: j => j,
          header    : 'Duration',
          cell      : info => {
              const s = getters.seconds(info.getValue<JobDatum>())
              return formatSeconds(s)
          },
        },
        { id        : 'charge',
          accessorFn: job => {
              return getters.charge(job)
          },
          header: 'Charge',
        },
        {
            header: 'Config',
            cell  : (info) => <Config<commonSchema.SimplifiedJobInput> mainState={p.mainState} config={info.row.original.config} onClick={() => {
                p.mainState.data = info.row.original.config
                localStorage.setItem(JOB_CONFIG_CACHE_KEY, JSON.stringify(info.row.original.config))
                console.log(p.mainState.data)
            }}/>,
        },
        { id        : 'download',
          accessorFn: j => j,
          cell      : (info) => {
              const job = info.getValue<JobDatum>()
              return <Download job={job} index={info.cell.row.index}></Download>
              //   console.log(info.row.id, info.row.original.status?.state)

          },
          header: '',
        },
        { id        : 'watch',
          accessorFn: j => j,
          cell      : (info) => {
              const job = info.getValue<JobDatum>()
              const state = computeState(job?.status, job._auto_generated_id_)
              if (state != 'success') {
                  return <></>
              }

              if((info.row.original.config.videoProfiles ?? []).length > 0 && ((info.row.original.config.videoProfiles[0].output ?? '').indexOf('/yzwhip2gci7s/igolgi-public') > -1)
              && info.row.original.config.cloud_credentials.output.cloud_provider == 'igolgi-store'){
                  const filenames = info.row.original.config.videoProfiles.map(x => x.output == undefined ? undefined : x.output.split('/').pop()!)
                  let hasStream = false
                  const paths: (string | undefined)[] = filenames.map(filename => {
                      if(filename == undefined) return filename
                      let path: string | undefined = undefined
                      if(commonSchema.scenario(info.row.original.config) != 3) {
                          path = `${httpBase}${filename}`
                      } else {
                          const p = info.row.original.config.bucket_output_path?.match('.*/igolgi-public/([^/]*)/?')
                          if(p != undefined) {
                              if(info.row.original.config.segmented_output_hls) {
                                  path = `${httpBase}${p[1]}/playlist.m3u8`
                                  hasStream = true
                              } else if(info.row.original.config.segmented_output_dash) {
                                  path = `${httpBase}${p[1]}/playlist.mpd`
                                  hasStream = true
                              }
                          }
                      }
                      return path
                  })

                  const path = paths[0]
                  if(path == undefined) return <></>
                  if(path.endsWith('.ts')) return <></> // no reliable video player for ts. mpeg.ts is not reliable.

                  const bottom = info.row.index < 5 ? '0px' : '30px'
                  const top = info.row.index < 5 ? '30px' : ''

                  return <div style={{ position: 'relative' }}>
                      <Show when={watchWindowForJob() == job._auto_generated_id_}>
                          <div class="container"
                              style={{ width: '468px', height: '264px', position: 'absolute', bottom, top, right: '0px', 'z-index': 1000000 }}>
                              <div class="close-btn"
                                  onClick={() => {setWatchWindowForJob(undefined)}}
                                  style={{
                                      position          : 'absolute',
                                      top               : 0,
                                      'z-index'         : 1000,
                                      right             : 0,
                                      cursor            : 'pointer',
                                      padding           : '10px',
                                      'font-size'       : '18px',
                                      'background-color': '#f1f1f1',
                                      border            : 'none',
                                  }}>&times;</div>

                              <VideoPlayer src={path} hasStream={hasStream}></VideoPlayer>
                          </div>
                      </Show>
                      <a href="#"
                          style={{ color: 'blue' }}
                          onClick={(e) => {
                              e.preventDefault()
                              const key = `video_${job._auto_generated_id_}`
                              if(localStorage.getItem(key) == undefined) {
                                  localStorage.setItem(key, '0')
                              }
                              const count = Number(localStorage.getItem(key))
                              if(count < MAX_VIDEO_VIEWS || window.location.href.indexOf('localhost') > -1) {
                                  setWatchWindowForJob(job._auto_generated_id_)
                                  localStorage.setItem(key, `${Number(count + 1)}`)
                              } else {
                                  alert('You have reached the maximum number of video views. This video can no longer be viewed.')
                              }
                          }}>Watch</a></div>
              } else {
                  return <></>
              }
          },
          header: '' },
        { id    : 'button',
          cell  : (info) => <a href={mailto(info.row.original._auto_generated_id_, info.row.original)} class={button} >Contact us</a>,
          header: 'Contact',
        },
    ]

    if((p.user?.())?.is_admin == true){
        defaultColumns.splice(1, 0,
            { id    : '_auto_generated_id_',
              cell  : (info) => info.row.original.cloud_local_id,
              header: 'Internal ID†',
            },
        )

        defaultColumns.push(
            { id  : 'job',
              cell: (info) => <Config<commonSchema.JobModel> mainState={p.mainState} config={info.row.original} text={'JSON'} hideRerun={true} onClick={() => {
                  //   p.mainState.data = info.row.original.config
                  //   localStorage.setItem(JOB_CONFIG_CACHE_KEY, JSON.stringify(info.row.original.config))
                  //   console.log(p.mainState.data)
              }}/>,
              header: 'Raw†',
            },
        )
    }


    if(p.small) {
        defaultColumns = defaultColumns.filter(x => x.id != 'button' && x.id != 'watch' && x.id != 'download' && x.header != 'Config')
    }
    {/*
        { id  : 'delete-link',
          cell: (info) => {
              if((info.row.original.config.videoProfiles ?? []).length > 0 && ((info.row.original.config.videoProfiles[0].output ?? '').indexOf('/yzwhip2gci7s/igolgi-public') > -1)){
                  const filename = info.row.original.config.videoProfiles[0].output!.split('/').pop()!
                  const path = `https://objectstorage.ca-toronto-1.oraclecloud.com/n/yzwhip2gci7s/b/igolgi-public/o/${filename}`
                  //   const path = 'https://objectstorage.ca-toronto-1.oraclecloud.com/n/yzwhip2gci7s/b/igolgi-public/o/62c14d3e-73b9-4ab8-9297-3c0aeb8b8c12.mp4'
                  return <div style={{ position: 'relative' }}>
                      <Show when={running()}>
                          <div class="container"
                              style={{ width: '468px', height: '264px', position: 'absolute', bottom: '30px', right: '0px' }}>
                              <div class="close-btn"
                                  onClick={() => setRunning(false)}
                                  style={{
                                      position          : 'absolute',
                                      top               : 0,
                                      'z-index'         : 1000,
                                      right             : 0,
                                      cursor            : 'pointer',
                                      padding           : '10px',
                                      'font-size'       : '18px',
                                      'background-color': '#f1f1f1',
                                      border            : 'none',
                                  //   color             : 'red',
                                  }}>&times;</div>

                              <video
                                  id="my-video"
                                  class="video-js"
                                  controls
                                  preload="auto"
                                  width="468"
                                  height="264"
                                  //   poster="MY_VIDEO_POSTER.jpg"
                                  data-setup="{}"
                              >
                                  <source src={path}/>
                                  <p class="vjs-no-js">
      To view this video please enable JavaScript, and consider upgrading to a
      web browser that
                                      <a href="https://videojs.com/html5-video-support/" target="_blank"
                                      >supports HTML5 video</a
                                      >
                                  </p>
                              </video>
                          </div>
                      </Show>
                      <a href="#" onClick={(e) => {
                          e.preventDefault()
                          setRunning(!running())
                      }}>Watch</a></div>
              } else {
                  return <></>
              }

              const job = info.getValue<JobDatum>()
              return <a href='#' class={button} >Delete</a>
          },
          header: '',
        },
         */}

    const mailto = (id: number, job: JobDatum) => `mailto:support@igolgi.com?cc=andrei.munteanu@igolgi.com&subject=Question about job id ${id}&body=${encodeURIComponent(body(id, job))}}`
    const body = (id: number, job: JobDatum): string => {
        return `Your question here...

------------------------
Job id: ${id}
Config: ${JSON.stringify(job.config, null, 4)}
Status: ${JSON.stringify(job.status, null, 4)}`
    }
    const Table = makeTable(p.jobStatuses, defaultColumns, p.limit, ['Started', 'Finished', 'Raw†', 'Internal ID†', 'Contact'])
    const name = (c: ColumnDef<any>): string => {
        return ('accessorKey' in c ? c.accessorKey  as string : c.id) ?? ''
    }

    const [reference, setReference] = createSignal<any>()
    const [floating, setFloating] = createSignal<any>()

    // `position` is a reactive object.
    const position = useFloating(reference, floating, {
        whileElementsMounted: autoUpdate,
        placement           : 'right',
        strategy            : 'fixed',
        middleware          : [offset(10), flip(), shift()],
    })
    // setInterval(() => {
    //     position.update()
    // }, 1000)
    createEffect(() => console.error('position', position.x, position.y))

    return Table
}


export const JobHistory = (p: { mainState: MainState }): JSX.Element => {
    const CSVColumnNames: Record<keyof typeof getters, string> = {
        id      : 'Job Id',
        started : 'Started At',
        finished: 'Finished At',
        progress: 'Progress',
        seconds : 'Duration (s)',
        state   : 'State',
        charge  : 'Charge',
    }

    const { client, rep, urls, user } = useAuthenticatedContext()


    // @ts-expect-error
    const jobStatuses_ = signalFromPrefix<[id: string, status:commonSchema.JobModel]>(rep, 'job/')
    // TODO: lift filter, show whole job with reasonable defaults if not sent to se yet

    const jobStatuses = createMemo(() => jobStatuses_().map(j => j[1]).sort((a, b) => b._auto_generated_id_ - a._auto_generated_id_),
        undefined,
        { equals: (prev, next) => JSON.stringify(prev) == JSON.stringify(next) })

    return <div
        class={hrefClass}
        style={{ padding: '1em', width: '100%' }}>
        <Content>
            <div style={{  }}>
                <h1 class={header}>Job History</h1>
                <br></br>
                <JobHistoryTable
                    user={user}
                    mainState={p.mainState}
                    jobStatuses={jobStatuses}
                    small={false}
                ></JobHistoryTable>
                <div style={{ 'padding-top': em(1) }}>Download as <a href="#" onClick={(e) => {
                    e.preventDefault()
                    function downloadCSV(data: string, filename: string) {
                        const blob = new Blob([data], { type: 'text/csv' })
                        const url = URL.createObjectURL(blob)
                        const link = document.createElement('a')
                        link.href = url
                        link.download = filename
                        link.click()
                        URL.revokeObjectURL(url)
                    }
                    const props = Object.keys(CSVColumnNames) as (keyof typeof getters)[]
                    const header = Object.values(CSVColumnNames)

                    // const header = defaultColumns.map(x => x.header as string)
                    // const props = defaultColumns.map(name) as (keyof JobDatum)[]
                    const data = [
                        header.join(', '),
                        ...jobStatuses().map(x => props.map(p => getters[p](x)).join(', '))].join('\n')
                    console.log(header, data)
                    // todo: render costs as well!
                    downloadCSV(data, 'data.csv')
                }}>CSV</a>.</div>

            </div>
        </Content>
    </div>
}
const aHover = style({
    padding: '0.3em',
    $nest  : {
        '&:hover': {
            backgroundColor: '#aaccff',
        },
    },
})
const downloadClass = (index: number) => style({
    'zIndex'       : index,
    position       : 'absolute',
    top            : '100%',
    backgroundColor: 'white',
    border         : '1px solid blue',
    padding        : '0.3em',
    width          : 'fit-content',
})
const button = style({
    opacity   : 0.3,
    padding   : '0em',
    lineHeight: 1,
    cursor    : 'pointer',
    $nest     : {
        '&:hover': {
            opacity: 1,
        },
    },
})

const date = (now: Date, other?: any) => {
    if(isNaN(now.getTime())) return '...'
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0')
    const date = String(now.getDate()).padStart(2, '0')
    const hour = String(now.getHours()).padStart(2, '0')
    const minute = String(now.getMinutes()).padStart(2, '0')

    const formattedDate = `${year}-${month}-${date} ${hour}:${minute}`
    return formattedDate
}


const State = (p: { state: StateValue, message?: string }): JSX.Element => {
    const [showMessage, setShowMessage] = createSignal(false)
    const shouldShowMessage = createMemo(() => showMessage() && (p.message ?? '').length > 1)
    const state = p.state ?? 'other'
    let colors = ['black', 'white']
    if(state == 'error') colors = ['white', 'red']
    else if(state == 'abort') colors = ['black', 'gray']
    else if(state == 'ongoing') colors = ['black', 'orange']
    else if(state == 'success') colors = ['white', 'green']
    return <div
        onmouseover={() => setShowMessage(true)}
        onmouseout={() => setShowMessage(false)}
        style={{
            'font-size'       : '0.75em',
            'padding'         : '0em',
            'border-radius'   : '3px',
            'line-height'     : '1.2em',
            'text-align'      : 'center',
            'background-color': colors[1],
            'color'           : colors[0],
            cursor            : 'pointer',
        }}>
        <Show when={shouldShowMessage()}>
            <div style={{
                'margin-top'      : '20px',
                position          : 'absolute',
                'background-color': 'white',
                border            : '1px solid gray',
                'border-radius'   : '3px',
                color             : 'black',
                padding           : '1em',
                width             : '75%',
                'text-align'      : 'left',
                'font-size'       : '1rem',
            }}>{p.message}</div>
        </Show>
        {state}
    </div>
}


const Download = ({ job, index }: { job: JobDatum, index:number }): JSX.Element => {
    const [pinned, setPinned] = createSignal(false)
    const show = () => pinned()
    const { client } = useAuthenticatedContext()

    let audioDownloadLink: undefined | string = undefined
    const audioPathMatch = job.config.audio_output_path?.match('.*/igolgi-public/([^/]*)/?')
    if(job.config.output_container == 'mp4' && commonSchema.scenario(job.config) == 2 && (job.config.audioProfiles?.length ?? 0) > 0 && audioPathMatch != null){
        audioDownloadLink = `${httpBase}${audioPathMatch[1]}`
    }
    console.log(job._auto_generated_id_, audioDownloadLink, job.config.video_codec, commonSchema.scenario(job.config),  job.config.audioProfiles?.length)


    const state = computeState(job?.status, job._auto_generated_id_)
    if (state == 'success'
    && (job.config.videoProfiles ?? []).length > 0
    && ((job.config.videoProfiles[0].output ?? '').indexOf('/yzwhip2gci7s/igolgi-public') > -1
    && job.config.cloud_credentials.output.cloud_provider == 'igolgi-store')){
        const filenames = job.config.videoProfiles.map(x => x.output == undefined ? x.output : x.output.split('/').pop()!)
        let paths: (string | undefined)[] = filenames.map(filename => {
            if(filename == undefined) return filename
            let path: string | undefined = undefined
            if(commonSchema.scenario(job.config) != 3) {
                path = `${httpBase}${filename}`
            } else if(job.config.bucket_output_path != undefined && job.config.create_tar_file == true){
                const p = job.config.bucket_output_path.match('.*/igolgi-public/([^/]*)/?')
                if(p != null) {
                    path = `${httpBase}${p[1]}.tar.gz`
                }
            }
            return path
        }).filter(x => x != undefined) as string[]

        paths = paths.filter(x => x != undefined)

        if(paths.length == 0) {
            return <></>
        } else {
            const segmented = job.config.segmented_output_hls || job.config.segmented_output_dash
            if(segmented) {
                return <a href={paths[0]} style={{ color: 'blue',  cursor: 'pointer', 'z-index': 100000 - index }}>Download</a>
            } else {
                return <HoverOnClick
                    index={100000 - index}
                    className={downloadClass(1000)}
                    trigger={<a style={{ color: 'blue',  cursor: 'pointer', 'z-index': 100000 - index }}>Download</a>}>
                    <For each={paths}>
                        {(path, i) => <a class={aHover} href={path} style={{  display: 'block', width: '100%', 'text-decoration': 'none' }}>Profile {i() + 1}</a>}
                    </For>
                    {audioDownloadLink != undefined ?
                        <a class={aHover} href={audioDownloadLink} style={{  display: 'block', width: '100%', 'text-decoration': 'none' }}>Audio </a>
                        : null}
                </HoverOnClick>
            }
        }
    }else if(state == 'ongoing'){
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        return <><a href="#"
            style={{ color: 'blue' }}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={async (e) => {
                e.preventDefault()
                await client()!.jobAbort.mutate({ id: job._auto_generated_id_ })
            }}>Abort</a></>
    } else {
        const link = job.config.videoProfiles?.[0]?.output ?? (job.config as any as { profiles?: { output?: string }[] }).profiles?.[0].output ?? 'Unknown path'
        console.log(link)

        return <span style={{  }}>
            <a
                style={{ color: 'blue' }}
                // style={{ 'text-decoration': 'underline', color: 'gray', cursor: 'pointer' }}
                class={button}
                onClick={(e) => {
                    e.preventDefault()
                    setPinned(!pinned()) }
                } href="#">{job.config.cloud_credentials.output?.cloud_provider ?? (job.config.cloud_credentials as any as { cloud_provider?: string })?.cloud_provider}</a>
            <Show when={show()}>
                <div style={{
                    position          : 'absolute',
                    'z-index'         : 100000,
                    padding           : '1em',
                    'border-radius'   : '0.5em',
                    'box-shadow'      : '0 5px 10px 1px rgba(79,79,79,.5)',
                    left              : 0,
                    border            : '1px solid gray',
                    width             : '100%', 'background-color': 'white' }}>
                    <a style={{ 'float': 'right', 'text-decoration': 'none' }}href="#" onClick={(e) => {e.preventDefault(); setPinned(false)}}>❌</a>
                    <pre>{link}</pre>
                </div>
            </Show>
        </span>
    }


}


const divStyle: JSX.CSSProperties = {
    position          : 'absolute',
    padding           : '1em',
    'border-radius'   : '0.5em',
    'box-shadow'      : '0 5px 10px 1px rgba(79,79,79,.5)',
    left              : 0,
    border            : '1px solid gray',
    'z-index'         : 200000,
    width             : '100%', 'background-color': 'white' }
const ackStyle: JSX.CSSProperties = {
    position          : 'absolute',
    width             : 'max-content',
    top               : '50%',
    transform         : 'translateY(-50%)',
    'background-color': 'black',
    color             : 'white',
    'border-radius'   : '5px',
    padding           : '1px 5px',
    right             : '110%',
    // 'display'         : 'none',
    // 'transition'      : 'visibility 0s, opacity 0.5s linear',
}


const Config = <T extends Record<any, any>>(p: { hideRerun?: boolean, mainState: MainState, config: T, text?: string, onClick: () => void }): JSX.Element => {
    const [pinned, setPinned] = createSignal(false)
    const show = () => pinned()
    return <span>
        <a style={{ color: 'blue' }}
            onClick={(e) => {
                e.preventDefault()
                setPinned(!pinned()) }
            } href="#">{p.text ?? 'Config'}</a>
        <Show when={show()}>
            <div style={divStyle}>
                <a style={{ 'float': 'right', 'text-decoration': 'none' }}href="#" onClick={(e) => {e.preventDefault(); setPinned(false)}}>❌</a>
                <Show when={(p.hideRerun ?? false) == false}>
                    <span
                        class={copyToClipboard}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onclick={p.onClick}
                        style={{ 'margin-right': '1em', 'float': 'right', position: 'relative', border: '1px solid gray', 'border-radius': '5px', color: 'gray', padding: '3px', 'font-size': '0.7em', cursor: 'pointer' }}>
                        <span class="ack" style={ackStyle}>The "Transcoding" page now reflects this job configuration</span>
                    Rerun
                    </span>
                </Show>
                <pre>{JSON.stringify(p.config, null, 2)}</pre>
            </div>
        </Show>
    </span>
}



// sudo certbot certonly  \
//   --preferred-challenges=dns \
//   --email support@igolgi.com \
//   --server https://acme-v02.api.letsencrypt.org/directory \
//   --agree-tos \
//   -d '*.webdav.igolgi.com'

const hrefClass = style({
    $nest: {
        'a': {
            textDecoration: 'none',
            $nest         : {
                '&:hover': {
                    textDecoration: 'underline',
                },
            },
        },

    },
})