import { createSignal, JSX, Show } from 'solid-js'

export const HoverOnClick = (p: { children: JSX.Element, trigger: JSX.Element, className?: string, index?: number }): JSX.Element => {
    const [showResolutionPopup, setShowResolutionPopup] = createSignal<number>(0)

    return  <div style={{ position: 'relative', 'z-index': p.index ?? 200 }}>
        <Show when={showResolutionPopup() % 2 == 1}>
            <div class={ p.className}
                onmouseover={() => {
                    setShowResolutionPopup(showResolutionPopup() + 2)
                }}
                onmouseout={() => {
                    console.log('>>>', 2)
                    if(showResolutionPopup() % 2 == 1){
                        const cache = showResolutionPopup()
                        setInterval(() => {
                            if(showResolutionPopup() == cache){
                                setShowResolutionPopup(showResolutionPopup() + 1)
                            }
                        }, 500)
                    }
                }}
                onclick={() => setShowResolutionPopup(showResolutionPopup() + 1)}>
                {p.children}
            </div>
        </Show>
        <span
            onClick={() => setShowResolutionPopup(showResolutionPopup() + 1)}
            onmouseout={() => {
                if(showResolutionPopup() % 2 == 1){
                    const cache = showResolutionPopup()
                    console.log('>>> 5')
                    setInterval(() => {
                        if(showResolutionPopup() == cache){
                            setShowResolutionPopup(showResolutionPopup() + 1)
                        }
                    }, 500)
                }
            }}
        >{p.trigger}</span>
    </div>
}

export const Mouseover = (p: { children: JSX.Element, trigger: JSX.Element, className?: string, style?: JSX.CSSProperties }): JSX.Element => {
    const [visible, setVisible] = createSignal<boolean>(false)

    return  <div style={{ position: 'relative', display: 'inline' }}>
        <span
            onMouseOver={() => setVisible(true)}
            onmouseout={() => setVisible(false)}
        >{p.trigger}
            <Show when={visible() == true}>
                <div class={ p.className} style={{
                    'box-shadow'      : '0 5px 10px 1px rgba(79,79,79,.5)',
                    'z-index'         : 200,
                    'max-width'       : '20em',
                    'font-size'       : '1.3em',
                    width             : 'max-content',
                    position          : 'absolute',
                    'background-color': 'white',
                    'border'          : '1px solid #eee',
                    padding           : '0.5em',
                    'border-radius'   : '.25em',
                    bottom            : '1.5em',
                    left              : '-8em', ...(p.style ?? {}) }}>
                    {p.children}
                </div>
            </Show>
        </span>
    </div>
}