export const is = {
    defined     : <T>(x: T | undefined | null)                        : x is T => x !== undefined && x !== null,
    number      : (x: any)                                            : x is number => typeof x == 'number',
    string      : (x: any)                                            : x is string => typeof x == 'string',
    definedArray: <T>(x: (T | undefined | null)[])                    : x is T[] => x.every(is.defined),
    function    : (x: any)                                            : x is (...args: any[])=> any => typeof x == 'function',
    array       : <T>(x: any)                                         : x is T[] => Array.isArray(x),

    defined2tuple: <T>(x: [T | undefined | null, T | undefined | null]): x is [T, T] => x.every(is.defined),
    empty        : <T>(x: T[])                                         : x is [] => {
        return x.length == 0
    },

}
export const has = {
    key: <X extends object, Y extends keyof X>(obj: X | undefined | null, prop: Y): obj is X & Record<Y, unknown> => {
    // eslint-disable-next-line no-prototype-builtins
        return obj?.hasOwnProperty(prop) ?? false
    },
}