import { z } from 'zod'

export const mutation = z.object({
    id       : z.number(),
    name     : z.string(),
    args     : z.any(),
    timestamp: z.number(),
})
export const pushRequest = z.object({
    profileID    : z.string(),
    clientID     : z.string(),
    mutations    : z.array(mutation),
    pushVersion  : z.number(),
    schemaVersion: z.string(),
})
export const pullRequest = z.object({
    profileID     : z.string(),
    clientID      : z.string(),
    cookie        : z.nullable(z.number()),
    lastMutationID: z.number(),
    pullVersion   : z.number(),
    schemaVersion : z.string(),
})
export const patchOperation = z.union([
    z.object({ op: z.literal('put'), key: z.string(), value: z.union([z.any(), z.null(), z.number(), z.string()]) }),
    z.object({ op: z.literal('del'), key: z.string() }),
    z.object({ op: z.literal('clear') }),
])

export const jsonValue = z.union([z.null(), z.string(), z.number(), z.undefined(), z.boolean(), z.object({}), z.array(z.any())])
const pullResponseOK = z.object({
    cookie        : jsonValue,
    lastMutationID: z.number(),
    patch         : z.array(patchOperation),
})
const clientStateNotFoundResponse = z.object({
    error: z.literal('ClientStateNotFound'),
})
export const pullResponse = z.union([pullResponseOK, clientStateNotFoundResponse])
