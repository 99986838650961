import { createSignal, JSX, Signal } from 'solid-js'
import { style } from 'typestyle'

import { makeClient } from '../client'
import { borderRadius, color } from '../constants'
import { header } from '../style'
import { Content } from './Library'

type  APIClient = typeof makeClient
type Props =  {
    // makeClient: APIClient,
    // urls: URLs,
    // key: string
}


// USE THIS  https://github.com/TanStack/table
export const FAQ = (p: Props): JSX.Element => {
    const lorem = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
    return <div style={{ padding: '1em', width: '100%', height: '100vh' }}>
        {/* <div style={{ 'width': '100px' }}>
            <div style={{ 'background-color': '#ddd', 'text-overflow': 'ellipsis', 'white-space': 'nowrap', 'overflow': 'hidden' }} >abcdefadsfadfgsad</div>
        </div> */}
        <Content>
            <div style={{  }}>
                <h1 class={header}>FAQ</h1>
                <Strip title={'Question 1'} body={lorem}></Strip>
                <Strip title={'Question 2'} body={lorem}></Strip>
                <Strip title={'Question 3'} body={lorem}></Strip>
                <Strip title={'Question 4'} body={lorem}></Strip>
            </div>
        </Content>
    </div>
}
const Strip = (p: { title: string, body: string }) => {
    const [open, setOpen] = createSignal(false)
    return <div class={strip} classList={{ 'open': open() }}>
        <div style={{ 'font-size': '1.5em' }}>{p.title} <Arrow open={[open, setOpen]}/></div>
        <div style={{ display: open() ? 'block' : 'none' }}>{p.body}</div>
    </div>
}
const strip = style({
    padding      : '0.75em',
    paddingInline: '1.5em',
    margin       : '0.25em',
    position     : 'relative',
    border       : '1px solid #eee',
    borderRadius : borderRadius,
})
export const Arrow = (p: { open: Signal<boolean> }): JSX.Element => {
    return <span class={circle} onClick={() => p.open[1](!p.open[0]())}>
        <span class={arrow}></span>
    </span>
}
const circle = style({
    width          : 28,
    height         : 28,
    right          : 5,
    top            : 5,
    position       : 'absolute',
    backgroundColor: color.highlight.light,
    borderRadius   : '50%',
    $nest          : {
        '&:hover': {
            cursor         : 'pointer',
            backgroundColor: color.highlight.medium,
        },
    },
})
export const arrow = style({
    width            : 10,
    height           : 10,
    position         : 'absolute',
    border           : `0px solid ${color.highlight.dark}`,
    top              : 7,
    left             : 9,
    transform        : 'rotate(45deg)',
    borderBottomWidth: 3,
    borderRightWidth : 3,
    transition       : 'all 0.1s',
    $nest            : {
        '.open &': {
            cursor   : 'pointer',
            transform: 'rotate(-135deg)',
            top      : 11,
        },
    },
})