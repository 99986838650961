import dayjs from 'dayjs'

export const daysRemainingInTrial = (user?: { created_at: string | Date | undefined }): number | undefined => {
    if(user?.created_at !== undefined) {
        const date = dayjs(user.created_at)
        const expiryDate = date.add(14, 'day')
        return Math.max(0, expiryDate.diff(dayjs(), 'day'))
    } else return undefined
}
export const isInTrial = (user?: { created_at: Date | string | undefined, forced_trial_expiry: boolean | undefined }): boolean => {
    const remaining = daysRemainingInTrial(user)
    console.log('days remaining in trial', remaining)
    return (remaining == undefined || remaining > 0) && (user?.forced_trial_expiry != true)
}
export const isPaid = (user?:{ checkout_session_completed: boolean, is_admin: boolean }): boolean => {
    return (user?.is_admin ?? false) || user?.checkout_session_completed == true
}
export type UserState = 'trial-ok' | 'trial-expired' | 'paying'
export const userState = (user: { created_at: Date | string | undefined, forced_trial_expiry: boolean | undefined, checkout_session_completed: boolean, is_admin: boolean }): UserState => {
    if(isPaid(user)) return 'paying'
    else if(isInTrial(user)) return 'trial-ok'
    else return 'trial-expired'
}

export const userCanSubmitJobs = (user:{ checkout_session_completed: boolean, created_at: Date | string | undefined, forced_trial_expiry: boolean | undefined, is_admin: boolean  }): boolean => {
    console.log('userCanSubmitJobs', user)
    if(isPaid(user)) return true
    else return isInTrial(user)
}


