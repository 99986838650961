import { style } from 'typestyle'

import { em, hsl } from './helpers'

const HUE = 213
export const borderRadius = em(0.5)
export const padding = em(1)
export const background = '#fff'
export const foreground =  hsl(HUE, 40, 96)
export const arrow = { up: '&#f078', right: '›', down: '▼' }

export const h4 = (marginBottom: number, state: boolean): string => style({
    textTransform: 'uppercase',
    fontWeight   : 500,
    marginBottom : state ? `${marginBottom}em` : '0em',
})
export const INTERVAL = 1000
export const color = {
    highlight: {
        dark  : hsl(HUE, 76, 48),
        medium: hsl(HUE, 76, 87),
        light : hsl(HUE, 93, 96),
        muted : hsl(HUE, 17, 40),
    },
}