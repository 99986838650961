/* eslint-disable unused-imports/no-unused-imports */
import { createAuth0Client } from '@auth0/auth0-spa-js'
import { MetaProvider, Title } from '@solidjs/meta'
import { Route, Router, Routes, useLocation, useNavigate } from '@solidjs/router'
import { setupPage } from 'csstips'
import { createEffect, createResource, createSignal, JSX, Show } from 'solid-js'
import { createMutable } from 'solid-js/store'
import { render } from 'solid-js/web'

import { App } from './App/App'
import { env } from './env-site'
import { reset } from './reset'
import { main } from './style'

const a = env
reset()
setupPage('#root')

import { fontFace } from 'typestyle'

import gibsonBoldItalic from './assets/Gibson-BoldItalic.ttf'

fontFace({
    fontFamily: 'gibson',
    src       : `url("${gibsonBoldItalic}")`,
})



import { AuthenticatedContextProvider, UnauthenticatedContextProvider, useAuthenticatedContext, useUnauthenticatedContext } from './App/context'
import { DocumentationContent } from './App/Documentation'
// @ts-ignore
import { Site } from './Site'


const renderer = (element: HTMLElement) =>
    render(
        () =>
            <MetaProvider>
                <UnauthenticatedContextProvider>
                    <div class={main}>
                        <Title>Streamengine</Title>
                        <Router>
                            <Routes>
                                <Route path="/app/*" component={() =>
                                    <AuthenticatedContextProvider>
                                        <App/>
                                    </AuthenticatedContextProvider>} />
                                {/* Leave these at the top, so paths are kept clear */}
                                <Route path="/documentation" element={
                                    <div style={{ padding: '2em' }}>
                                        <DocumentationContent/>
                                    </div>
                                }></Route>
                                <Route path="*" element={<Site/>}/>
                            </Routes>
                        </Router>
                    </div>
                </UnauthenticatedContextProvider>
            </MetaProvider>,
        element,
    )





import * as Sentry from '@sentry/solid'

if(window.location.href.indexOf('localhost') == -1) {
    // sentry gets in the way of relicachePull requests
    Sentry.init({
        dsn         : 'https://981053bebf9d4997273e5d46703faafb@o4507708116697088.ingest.us.sentry.io/4507708118269952',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        tracesSampleRate        : 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets : ['localhost', /^https:\/\/streamengine\.igolgi\.com/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })

}
renderer(document.getElementById('root')!)