/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { z, ZodObject } from 'zod'

export const m = <T extends ZodObject<any>>(name: string, args: T) =>
    z.object({ args, id: z.union([z.number(), z.string()]), name: z.literal(name), timestamp: z.number() })

const mutationUserConsentArgs = z.object({})
export const mutationUserConsent = m('userConsent', mutationUserConsentArgs)
export type MutationUserConsentArgs = z.infer<typeof mutationUserConsentArgs>
export type MutationUserConsent = z.infer<typeof mutationUserConsent>

const mutationKeyAddArgs = z.object({})
export const mutationKeyAdd = m('keyAdd', mutationKeyAddArgs)
export type MutationKeyAddArgs = z.infer<typeof mutationKeyAddArgs>
export type MutationKeyAdd = z.infer<typeof mutationKeyAdd>

const mutationKeyDeleteArgs = z.object({ id: z.string() })
export const mutationKeyDelete = m('keyDelete', mutationKeyDeleteArgs)
export type MutationKeyDeleteArgs = z.infer<typeof mutationKeyDeleteArgs>
export type MutationKeyDelete = z.infer<typeof mutationKeyDeleteArgs>

// Template
const templateAddArgs = z.object({ body: z.string(), name: z.string(), id: z.string() })
export const templateAdd = m('templateAdd', templateAddArgs)
export type TemplateAddArgs = z.infer<typeof templateAddArgs>
export type TemplateAdd = z.infer<typeof templateAdd>

const templateDeleteArgs = z.object({ id: z.string() })
export const templateDelete = m('templateDelete', templateDeleteArgs)
export type TemplateDeleteArgs = z.infer<typeof templateDeleteArgs>
export type TemplateDelete = z.infer<typeof templateDeleteArgs>

// File
const fileAddArgs = z.object({ name: z.string(), id: z.string() })
export const fileAdd = m('fileAdd', fileAddArgs)
export type FileAddArgs = z.infer<typeof fileAddArgs>
export type FileAdd = z.infer<typeof fileAdd>

// OracleAsset
const oracleAssetDeleteArgs = z.object({ path: z.string() })
export const oracleAssetDelete = m('oracleAssetDelete', oracleAssetDeleteArgs)
export type OracleAssetDeleteArgs = z.infer<typeof oracleAssetDeleteArgs>
export type OracleAssetDelete = z.infer<typeof oracleAssetDeleteArgs>
