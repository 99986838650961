import * as commonSchema from 'common/src/schema'


type TT = Omit<commonSchema.SimplifiedJobInput, 'input' | 'videoProfiles' | 'audioProfiles' | 'separate_audio' | 'segmented_output_hls' | 'segmented_output_dash' | 'picture_transform' | 'cloud_credentials'>
type TTT = Pick<commonSchema.CommonConfig.Output, 'h264_profile' | 'smil_output_flag' | 'adaptive_spatial_prefilter' | 'auto_detelecine_flag' | 'operating_mode' | 'dai_mode' | 'encryption_type'>
const common = {
    ts_mode_input_audio_source_stream_count: 1,
    logo_url                               : null,
    h264_quality                           : 'better',
    segment_length                         : 2,
    output_container                       : 'mp4',
    smil_output_flag                       : 0,
    ip_distance                            : 2,
    scte35_passthough                      : false,
    gop_length                             : 2,
    audio_volume                           : 0,
    closed_captions                        : false,
    create_tar_file                        : true,
    h264_profile                           : 'main',
    adaptive_spatial_prefilter             : 0,
    video_aspect_ratio                     : 'passthrough',
    auto_detelecine_flag                   : false,
    blazar_mode                            : false,
    master_variant_mode                    : 0,
    dai_mode                               : 0,
    operating_mode                         : 'transcoding',
    video_codec                            : 'h.264',
    encryption_type                        : 'none',
    rotation_blackness                     : 0,
    scte35_pid_remap                       : -1,
} as TT & TTT
// For defaults: https://docs.google.com/spreadsheets/d/1-XKDSm3ShB0eaZ0HzO2PSYXrrBap_dDIfjkO_6_HsC0/edit#gid=0
export const defaults = {
    video: {
        width             : 0,
        height            : 0,
        video_muxrate     : 3000,
        video_maxrate     : 10, // if -1, error says "Invalid maxrate value (<0) in VBR mode;"
        video_framerate   : 0, // full
        video_bitrate_mode: 0, // cbr
        video_format      : 'progressive',
    },
    audio: {
        audio_codec                         : 'aaclc',
        audio_bitrate                       : 256, // todo : look into this
        secondary_audio_bitrate             : 192,
        bitrate_backup                      : 128, // notes in spreadsheet say: look into this
        primary_audio_downmix_to_stereo_flag: false,
        conditional_dolby_digital_flag      : false,
        channels                            : 2,
        dolby_digital_dialnorm              : 0,
        source_stream                       : 1,
    },
    common: common,
    job   : {
        abort_on_job_timeout: true,
        jobs_priority       : 1,
    },
} as const
