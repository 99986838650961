import { ColumnDef, createSolidTable, flexRender, getCoreRowModel, getSortedRowModel, SortingState } from '@tanstack/solid-table'
import { Accessor, createSignal, For, JSX, Show } from 'solid-js'

import { table as tableStyle } from '../style'
const JOBS_PER_PAGE = 50

export const makeTable = <T extends object>(data: Accessor<T[]>, columns: ColumnDef<T>[],  limit?: number, hiddenCols?: string[]): JSX.Element => {
    const [sorting, setSorting] = createSignal<SortingState>([])
    const [page, setPage] = createSignal(0)
    const table = () => {
        const data_ = data().slice(page() * (limit ?? JOBS_PER_PAGE), (page() + 1) * (limit ?? JOBS_PER_PAGE))
        console.log('table', page(), data_)
        return createSolidTable({
        // initialState: { hiddenColumns: [] },
            state: {
                get sorting() {
                    return sorting()
                },
            },
            onSortingChange  : setSorting,
            get data() { return   data_},
            getCoreRowModel  : getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
            columns,
        })
    }
    const totalPages = () =>  Math.ceil(data().length / (limit ?? JOBS_PER_PAGE))
    return <div>
        <Show when={totalPages() > 1}>
            <div>
                <button
                    style={{ 'margin-inline': '0.2em', 'padding-inline': '0.3em' }}
                    disabled={page() == 0}
                    onClick={() => {  if(page() > 0) setPage(page() - 1)}}>Previous</button>
                <For each={[...Array(totalPages()).keys()]} >{(key, i) => {
                    return <button
                        style={{ 'margin-inline': '0.2em', 'padding-inline': '0.3em' }}
                        disabled={page() == i()}
                        onClick={() => setPage(i())}>{i() + 1}</button>
                }}
                </For>
                <button
                    style={{ 'margin-inline': '0.2em', 'padding-inline': '0.3em' }}
                    disabled={page() == totalPages() - 1}
                    onClick={() => { if(page() < totalPages() - 1) setPage(page() + 1)}}>Next</button>
            </div>
            <br></br>
        </Show>
        <table class={tableStyle}>
            <thead>
                <For each={table().getHeaderGroups()}>
                    {headerGroup => (
                        <tr>
                            <For each={headerGroup.headers}>
                                {header => (
                                    <th classList={{ hide: (hiddenCols?.indexOf(header.column.columnDef.header?.toString() ?? '') ?? -1) > -1 }}>
                                        <Show when={!header.isPlaceholder}>
                                            <td
                                                class={
                                                    header.column.getCanSort()
                                                        ? 'cursor-pointer select-none'
                                                        : undefined
                                                }
                                                onClick={header.column.getToggleSortingHandler()}
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext(),
                                                )}
                                                {{
                                                    asc : ' 🔼',
                                                    desc: ' 🔽',
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </td>
                                        </Show>
                                    </th>
                                )}
                            </For>
                        </tr>
                    )}
                </For>
            </thead>
            <tbody>
                <For each={table().getRowModel().rows}>
                    {row => (
                        <tr>
                            <For each={row.getVisibleCells()}>
                                {cell => (
                                    <td  classList={{ hide: (hiddenCols?.indexOf(cell.column.columnDef.header?.toString() ?? '') ?? -1) > -1 }}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </td>
                                )}
                            </For>
                        </tr>
                    )}
                </For>
            </tbody>
            <tfoot>
                <For each={table().getFooterGroups()}>
                    {footerGroup => (
                        <tr>
                            <For each={footerGroup.headers}>
                                {header => (
                                    <th>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.footer,
                                                header.getContext(),
                                            )}
                                    </th>
                                )}
                            </For>
                        </tr>
                    )}
                </For>
            </tfoot>
        </table>
    </div>
}