import { Auth0Client, createAuth0Client, User } from '@auth0/auth0-spa-js'
import * as Sentry from '@sentry/solid'
// eslint-disable-next-line unused-imports/no-unused-imports
import { TRPCClientError } from '@trpc/client'
import * as trialLogic from 'common/src/business-rules-trial'
import * as commonSchema from 'common/src/schema'
import { deleteAllReplicacheData, PullRequest, PullResponse, PushRequest } from 'replicache'
import { Replicache } from 'replicache'
import { Accessor, Context, createContext, createEffect, createMemo, createResource, createSignal, JSX, onCleanup, Resource, Signal, useContext } from 'solid-js'

import { AppURLs, makeURLs } from '../../../common/src'
// import { env } from '../../env-site_'
import { makeClient } from '../client'
import { env } from '../env-site'
import { mutators } from './mutators'
import { signalFromPrefix } from './replicache'
type UnauthenticatedContextType = {
    urls: AppURLs,
    auth0: Resource<Auth0Client>,
}
const UnauthenticatedContext = createContext<UnauthenticatedContextType>()

type AuthenticatedContextType = {
    auth0User: Resource<User | undefined>,
    user: Accessor<commonSchema.UserModel | undefined>,
    rep: Accessor<ReturnType<typeof makeReplicache> | undefined>
    client: Resource<ReturnType<typeof makeClient>>
    auth0token: Resource<string>
    isInTrial: Accessor<boolean>,
    userState: Accessor<trialLogic.UserState>,
    isPaid: Accessor<boolean>,
    daysRemainingInTrial: Accessor<number | undefined>,
    saveTemplateSignal: Signal<number | undefined>,
} & UnauthenticatedContextType
const AuthenticatedContext = createContext<AuthenticatedContextType>()

// const urls = makeURLs(import.meta.env.SERVER_BASE, import.meta.env.CLIENT_BASE)
export const urls = makeURLs(window.location.protocol.replace(/:?$/, ''), env.VITE_API_URL, window.location.origin.replace(/^https?:\/\//, ''))

const makeReplicache = (name: string, client: ReturnType<typeof makeClient>) => {
    return  new Replicache({
        licenseKey  : 'lee3fe89555204896850d1da4756d9ea3',
        pushURL     : urls.replicache.push,
        pullURL     : urls.replicache.pull,
        pullInterval: 3000,
        name,
        mutators    : mutators,
        puller      : async (r: Request) => {
            // can't non-nullable the zod.any..
            const data = await r.json() as Omit<PullRequest, 'cookie'> & { cookie: number }
            console.log('replicache pull ', data)
            // try{
            // @ts-expect-error
            const response: PullResponse = await client.replicachePull.mutate(data)
            return { response, httpRequestInfo: { httpStatusCode: 200, errorMessage: '' } }
            // } catch(e){
            //     console.error('replicachePull ERROR', e)
            //     throw(e)
            // }

        },
        pusher: async (r) => {
            const data = await r.json() as PushRequest
            try{
                const resp = await client.replicachePush.mutate(data)
            } catch(e: unknown) {
                if(e instanceof TRPCClientError<any>) {
                    return {
                        httpStatusCode: 400,
                        errorMessage  : e.message,
                    }
                } else {
                    return {
                        httpStatusCode: 400,
                        errorMessage  : `${e as string}`,
                    }
                }
            }
            return {
                httpStatusCode: 200,
                errorMessage  : '', // WARNING: Replicache EXPECTS a string here. Use empty string for success.
            }
        },
    })
}


export function UnauthenticatedContextProvider(props: { children: JSX.Element }): JSX.Element {
    const AUTH0_CLIENT_ID = 'IJEl2aYxL9lUtvUik6t6Z31InZONIuGR'
    const AUTH0_DOMAIN = `auth.streamengine.igolgi.com`//`https://dev-g8le8fq1bwf4niy2.us.auth0.com`
    const [auth0] = createResource(async () => {
        console.log(urls.auth0.CALLBACK)
        const auth0 = await createAuth0Client({
            useRefreshTokens   : true, // addresses https://igolgi.atlassian.net/jira/software/c/projects/CU/boards/25?selectedIssue=CU-7 as hinted here https://community.auth0.com/t/how-to-check-if-user-has-logged-out-in-another-tab/56324/4
            cacheLocation      : 'localstorage', // NEEDED for firefox and safari to work see https://community.auth0.com/t/handleredirectcallback-invalid-state/107558
            domain             : AUTH0_DOMAIN,
            clientId           : AUTH0_CLIENT_ID,
            authorizationParams: {
                redirect_uri: urls.auth0.CALLBACK,
                audience    : 'igolgi-cloud-api', // need this for api access!
                scope       : 'openid profile email',
                // ^ https://community.auth0.com/t/auth0-spa-2-x-returning-missing-refresh-token/98999/7
            },
        })
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        setInterval(async () => {
            const isAuthenticated = await auth0.isAuthenticated()
            console.log('auth check', isAuthenticated)
            if (!isAuthenticated && window.location.href.indexOf('/app') > -1 && window.location.href.indexOf('localhost') == -1) {
                console.log('logging out')
                await auth0?.logout({ logoutParams: {
                    returnTo: urls.clientBase,
                    clientId: AUTH0_CLIENT_ID,
                } })
            }
        }, 20000)


        const CHECK_INTERVAL = 1000 * 60 // check every minute
        const TIMEOUT = 30 * 60 * 1000 //
        const LAST_ACTIVE_TIMESTAMP_KEY = 'lastActiveTimestamp'

        const checkActivity = async () => {
            const lastActiveTimestamp = parseInt(localStorage.getItem(LAST_ACTIVE_TIMESTAMP_KEY)!, 10)
            if(isNaN(lastActiveTimestamp) || lastActiveTimestamp == undefined) return

            if ((Date.now() - lastActiveTimestamp > TIMEOUT) &&  window.location.href.indexOf('localhost') == -1) {
                // Timeout should redirect to prod site
                await auth0?.logout({ logoutParams: { returnTo: window.location.href.indexOf('igolgi.com') > -1 ? 'https://streamengine.igolgi.com' : window.location.origin } }) // logout from Auth0 (implements the actual logout logic depending on Auth0 SDK version)
            }
        }

        const setupActivityTracker = () => {
            // Update last active timestamp
            const updateLastActive = () => {
                localStorage.setItem(LAST_ACTIVE_TIMESTAMP_KEY, Date.now().toString())
            }

            // Call on page load, tab focus, and on any interaction
            updateLastActive()
            window.addEventListener('mousemove', updateLastActive)
            window.addEventListener('keydown', updateLastActive)
            window.addEventListener('scroll', updateLastActive)
            window.addEventListener('mousedown', updateLastActive)
            window.addEventListener('touchstart', updateLastActive)
            window.addEventListener('click', updateLastActive)

            // Remove listeners on cleanup
            return () => {
                window.removeEventListener('mousemove', updateLastActive)
                window.removeEventListener('keydown', updateLastActive)
                window.removeEventListener('scroll', updateLastActive)
                window.removeEventListener('mousedown', updateLastActive)
                window.removeEventListener('touchstart', updateLastActive)
                window.removeEventListener('click', updateLastActive)

            }
        }

        // Initialize the activity tracker
        setupActivityTracker()

        // Setup effect to check other tabs every minute
        createEffect(() => {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            const intervalId = setInterval(checkActivity, CHECK_INTERVAL) // Check every minute

            // Cleanup the interval on component unmount
            onCleanup(() => clearInterval(intervalId))
        })





        console.log('!', auth0)
        return auth0
    })

    // const key = '5a4b0c8b8ba9c7ed8c540c1f597f0aa4'

    return <UnauthenticatedContext.Provider value={{ urls: urls, auth0 }}>
        {props.children}
    </UnauthenticatedContext.Provider>
}

export function useUnauthenticatedContext(): UnauthenticatedContextType {
    return useContext<UnauthenticatedContextType>(UnauthenticatedContext as Context<UnauthenticatedContextType>) // not sure why it can be undefined
}

export function AuthenticatedContextProvider(props: { children: JSX.Element }): JSX.Element {
    const { auth0, urls } = useUnauthenticatedContext()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    // deleteAllReplicacheData().then(a => console.log('delete', a))

    const [auth0User] = createResource(auth0, async (auth) => {
        if (!await auth.isAuthenticated()) {
            console.log('auth0 not authenticated', auth)
            const hasCode = new URLSearchParams(window.location.search).get('code') != null
            if(hasCode) {
                await auth.handleRedirectCallback()
                // as per https://stackoverflow.com/questions/62038511/invalid-state-when-i-come-back-on-a-auth0-private-route-on-a-react-application
                const urlParams = new URLSearchParams(window.location.search)
                urlParams.delete('code')
                window.history.replaceState({}, '', `${location.pathname}?${urlParams.toString()}`)
            } else {
                await auth.loginWithRedirect()
            }
            console.log('auth0 authenticated', auth)
        }
        const a = await auth.getUser()
        if(a != undefined) {
            Sentry.setUser({ email: a.email })
        }
        return a
    })

    const [auth0token] = createResource(auth0User, async (user) => {
        // alert("auth0token, getTokenSilently")
        const token = await auth0()?.getTokenSilently()!
        return token
    })

    const [client] = createResource(auth0User, async (user) => {
        // alert("client, getTokenSilently")
        const token = await auth0()?.getTokenSilently()!
        return makeClient(urls.api.base, token)
    })

    const rep = createMemo(() => {
        const [u, c] = [auth0User(), client()]
        if(u !== undefined && c !== undefined) {
            const r = makeReplicache(u.sub ?? u.email ?? '--user--Oct-25-2023--', c)// modify this!
            if(window.location.search.indexOf('delete-replicache') > -1) {
                void deleteAllReplicacheData()
                alert('replicache deleted')
            }
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onCleanup(() => r.close())
            return r
        } else return undefined
    })

    const users = signalFromPrefix<[string, commonSchema.UserModel]>(rep, 'user')
    const user = createMemo(() => users()?.[0]?.[1], {}, { equals: (p, n) => {
        // replicache_space_version causes re-render at every update
        const a = { ...p }
        const b = { ...n }
        delete a.replicache_space_version
        delete b.replicache_space_version
        return JSON.stringify(a) == JSON.stringify(b)
    } })

    const daysRemainingInTrial = createMemo(() => trialLogic.daysRemainingInTrial(user()))
    const isInTrial = createMemo(() => trialLogic.isInTrial(user()))
    const isPaid = createMemo(() => trialLogic.isPaid(user()))
    const userState = createMemo(() => trialLogic.userState(user()))
    const saveTemplateSignal = createSignal<number | undefined>()
    return <AuthenticatedContext.Provider
        value={{  saveTemplateSignal, isInTrial, userState, isPaid, daysRemainingInTrial, auth0User, user, rep, client, auth0token, auth0, urls }}>
        {props.children}
    </AuthenticatedContext.Provider>
}

export function useAuthenticatedContext(): AuthenticatedContextType {
    return useContext<AuthenticatedContextType>(AuthenticatedContext as Context<AuthenticatedContextType>) // not sure why it can be undefined
}
