import { createEffect, createSignal, JSX, Show } from 'solid-js'

import { useUnauthenticatedContext } from './App/context'
import diagram1 from './assets/diagram1.png'
import diagram2 from './assets/diagram2.png'
import screenshot_json from './assets/json_2.jpg'
import judder0 from './assets/judder0.png'
import judder1 from './assets/judder1.png'
import logo from './assets/logo-streamengine.png'
import astound from './assets/logos/astound.png'
import blueridge from './assets/logos/blueridge.png'
import csidigital from './assets/logos/csidigital.png'
import diversified from './assets/logos/diversified.svg'
import fox from './assets/logos/fox.svg'
import kineticiq from './assets/logos/kineticiq.png'
import mediacom from './assets/logos/mediacom.png'
import midco from './assets/logos/midco.png'
import showtime from './assets/logos/showtime.svg'
import tata from './assets/logos/tata.svg'
import screen0 from './assets/screen0.png'
import screen1 from './assets/screen1.png'
import screenshot from './assets/screenshot.png'
// @ts-ignore
import { WarpSpeed } from './effects/warp.js'
export const Warp = (props: { shrink: boolean }): JSX.Element => {
    createEffect(() => {
        new WarpSpeed('warp', { 'speed': 3, 'speedAdjFactor': 0.3, 'density': 2, 'shape': 'square', 'warpEffect': true, 'warpEffectLength': 5, 'depthFade': true, 'starSize': 5, 'backgroundColor': 'hsl(263,45%,7%)', 'starColor': '#FFFFFF' })
    })
    return <canvas id="warp" style={{ 'border-radius': props.shrink ?  '10px' : 0, width: '100%', height: '100%' }}/>
}

const ctaButton = (p: { signup: () => Promise<void> }) => {
    {/* eslint-disable-next-line @typescript-eslint/no-floating-promises, @typescript-eslint/no-misused-promises */}
    return <button class={cta} onClick={async (e) => {e.preventDefault(); await p.signup()}}>Start Your Free Trial</button>

}
const lorem = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
export const Site = (): JSX.Element => {
    const { auth0 } = useUnauthenticatedContext()
    const [rotateY, setRotateY] = createSignal(-40)

    document.addEventListener('mousemove', function(event) {
        setRotateY(event.clientX / window.innerWidth * (-40) - 10)
    })
    // const [shrink, setShrink] = createSignal(false)
    const login = async () =>  {
        await auth0()?.loginWithRedirect({ authorizationParams: { prompt: 'login', 'mode': 'login' }  })
        console.log(await auth0()?.getUser())
    }
    const signup = async () =>  {
        await auth0()?.loginWithRedirect({ authorizationParams: { prompt: 'login', 'mode': 'login', screen_hint: 'signup' }  })
        console.log(await auth0()?.getUser())
    }

    // window.onscroll = () => {
    //     if (document.body.scrollTop > 10 || document.documentElement.scrollTop > 10) {
    //         setShrink(true)
    //     } else {
    //         setShrink(false)
    //     }
    // }
    reset('white')
    return <div class={main}>
        <div class={navLinks} style={{ position: 'sticky', 'z-index': 1000, height: '4em', 'background-image': 'linear-gradient(0deg, #323844 0%, #1A1D22 100%)', 'padding-right': '3em' }}>
            <div style={{ display: 'flex', height: '100%', 'flex-direction': 'column' }}>
                <img class="logo" src={logo} style={{ height: '100%' }}></img>
                {/* <span style={{ 'font-family': 'sans-serif',
                                   'font-weight': '600',
                                   'text-align' : 'center',
                                   'font-size'  : '1em',
                                   'z-index'    : 100,
                                   'margin-top' : '10px' }}>
                        Stream Engine</span> */}
            </div>
            <div style={{ 'flex-grow': 1 }}></div>
            <Dropdown item={<><a href="#features">Features</a>&nbsp;<span style={{ 'font-size': '0.8em' }}>▼</span></>}>
                <div class="dropdown-content">
                    <a href="#features">Video Transcoding</a>
                    <a href="#hlsdash">HLS and DASH</a>
                    <a href="#detelecine">De-Telecine</a>
                    <a href="#dashboard">Web Interface</a>
                    <a href="#api">API</a>
                    {/* <a href="#storage">Storage Integration</a>
                        <a href="#upcoming">Upcoming Features</a> */}
                </div>
            </Dropdown>
            <a href="#pricing">Pricing</a>
            <a href="http://docs.streamengine.igolgi.com/">Documentation</a>
            <a class={aButton} style={{ 'text-decoration': 'none' }}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={async (e) => {e.preventDefault(); await signup()}}
            >Sign&nbsp;Up</a>
            <a class={aButton} style={{ 'text-decoration': 'none' }}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={async (e) => {e.preventDefault(); await login()}}
            >Log&nbsp;In</a>
        </div>
        <div style={{ position: 'relative', 'background-color': 'black',  /* padding: shrink() ? '20px' : 0, */width: '100vw' }}>

            <div class={mainContent}>
                <video
                    muted={true}
                    style={{
                        'z-index'        : 0,
                        'position'       : 'absolute',
                        'height'         : '100%',
                        width            : '100%',
                        'object-fit'     : 'cover',
                        'object-position': 'center center',
                        left             : 0,
                        opacity          : 0.4,
                    }} id="comp-lhwh6pl0_video" class="K8MSra" crossorigin="anonymous" playsinline={true} preload="auto" loop={true} tabindex={-1} autoplay={true} src="https://video.wixstatic.com/video/4afbc1_b9f1a57101044e59904940ea6c346cf6/1080p/mp4/file.mp4" ></video>
                <div style={{     'padding-top': '10vh', 'z-index': 1, 'font-size': '3em', 'text-align': 'center', 'margin-bottom': '3vh' }}>Professional Cloud Video Transcoding<br></br>
                    <div style={{
                        'background-clip'        : 'text',
                        'background-image'       : 'linear-gradient(171deg, rgb(255, 254, 254), rgb(202, 47, 1) 27%, rgb(84 27 9) 94%)',
                        '-webkit-text-fill-color': 'transparent',
                    }}>Affordable and Scalable</div>
                </div>
                <div
                    style={{ display                : 'grid',
                             'grid-template-columns': '1fr 1fr',
                             'font-weight'          : '400',
                             'text-align'           : 'left',
                             'font-size'            : '1.3em',
                             margin                 : 'auto',
                             width                  : '80%',
                             gap                    : '3em' }}>
                    <div>
                        <div style={{ margin: '1em' }}>Stream Engine Transcoder <span style={{ color: ' rgb(202 47 1)' }}>has been processing video assets for the top Content Providers and Cable Companies for over 15 years.</span>  Now this solution is available on the cloud for anyone to use.  </div>
                        <div style={{ margin: '1em' }}>Small or Large Volume, our time tested transcoder can handle your most challenging video assets. </div>
                        <div style={{ margin: '1em' }}>All of this at a low price and works with your existing cloud storage provider, no need to transfer your assets.</div>
                        <p style={{ margin: '1em' }}>Advanced Features such as Telecine Removal that saves 20% bitrate by removing repeated fields.</p>
                        <p style={{ margin: '1em' }}>GPU cloud processing with advanced compression performance.</p>
                    </div>
                    <div>
                        {/* <div style={{ margin: '1em' }}>Single User or Large Organization</div>
                        <div style={{ margin: '1em' }}>Upload files to the User Interface and process files fast</div> */}
                        <div style={{ width               : 'fit-content', position            : 'relative',
                                      perspective         : '1000px',
                                      'perspective-origin': '50% 50%',
                        }}>
                            <img src={screen0} style={{ 'box-shadow': '0px 8px 16px 0px rgba(0,0,0,0.2)',
                                                        transition  : 'all 1s',

                                                        transform: `scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(${rotateY() / 5}deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg)`,
                            }}></img>
                            <img style={{ position    : 'absolute', right       : '-10px', top         : 0, bottom      : 0, height      : '100%', 'box-shadow': '0px 8px 16px 0px rgba(0,0,0,0.2)',
                                          transition  : 'all 1s',
                                          transform   : `scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(${rotateY()}deg) rotateZ(0deg) translateX(0px) translateY(0px) translateZ(0px) skewX(0deg) skewY(0deg)`,

                            }} src={screen1}></img>
                        </div>
                        {/* <div style={{ margin: '1em' }}>Use our robust API</div> */}
                    </div>
                </div>
                <Button
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={async (e) => {e.preventDefault(); await signup()}}
                    style={{
                        width          : 'max-content',
                        'font-weight'  : '400',
                        padding        : '1em 2em',
                        'border-radius': '2em',
                        margin         : '5vh',
                        'z-index'      : 100,
                    }}>Start your free trial</Button>
                <div class={ icons }
                    style={{ display                : 'grid',
                             'grid-template-columns': '1fr 1fr 1fr',
                             'text-align'           : 'center',
                             'font-weight'          : '400',
                             margin                 : 'auto',
                             'padding-bottom'       : '3em',
                             width                  : '80%',
                             gap                    : '3em' }}>

                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="48" width="48"><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M12.25.95c0 2.8 4 5.1 9.6 5.1h1.4"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M23.25 9.05c-9.9 0-17.5-3.4-17.5-7.5"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M20.25 11.95c-12.7 0-19.5-3.4-19.5-7.5"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M12.25 23.05c0-.8.3-1.5.9-2.1"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M12.15 16.35c-3.9 1.3-6.3 3.3-6.3 5.6"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M.75 19.45c0-4.1 6.8-7.5 19.5-7.5h3"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M16.95 22.75c-.3.1-.6.1-.8 0-.2-.1-.4-.4-.4-.7v-5.9c0-.3.2-.6.4-.7.2-.1.6-.2.8 0l5.8 2.9c.3.1.5.4.5.8 0 .3-.2.6-.5.8l-5.8 2.8Z"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M23.25 14.75h-1.7"></path></svg>
                        <div>15 Years of Transcoding Services</div>
                    </div>
                    <div>
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M.504 23.25h23" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4.5 23.25v-2.5a.5.5 0 0 0-.5-.5H2a.5.5 0 0 0-.5.5v2.5" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M10.5 23.25v-6.5a.5.5 0 0 0-.5-.5H8a.5.5 0 0 0-.5.5v6.5" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M16.5 23.25v-10.5a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 0-.5.5v10.5" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M22.5 23.25V8.75a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 0-.5.5v14.5" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="m3.004 13.25 18-12" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="m20.504 5.25.5-4-4-.5" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        <div>Over 5 Million Titles Processed</div>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="48" width="48"><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M2.03 6.565 12 1.143l9.97 5.422"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="m12.002 22.857-9.965-5.422V6.571l9.965 5.422v10.864Z"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="m12.002 22.857 9.965-5.422V6.571l-9.965 5.422v10.864Z"></path></svg>
                        <div>Simple APIs.<br></br>Robust Performance</div>
                    </div>
                </div>
            </div>
        </div>

        <div style={{ padding: '20px' }}>
            {/* <p style={{ 'font-size': '10px' }}>Trusted by industry leaders worldwide</p> */}
            <h1 style={{ 'padding-bottom': '0.5em', color: 'gray' }}>These are just a few of our customers</h1>
            <div class={logos}>
                <div class={logosInner}>
                    <img src={fox}/>
                    <img src={diversified}/>
                    <img src={showtime}/>
                    <img src={tata}/>
                    <img src={astound}/>
                    <img src={kineticiq}/>
                    <img src={mediacom}/>
                    <img src={blueridge}/>
                    <img src={csidigital}/>
                    <img src={midco}/>
                    {/* repeat for the marquee */}
                    <img src={fox}/>
                    <img src={diversified}/>
                    <img src={showtime}/>
                    <img src={tata}/>
                    <img src={astound}/>
                    <img src={kineticiq}/>
                    <img src={mediacom}/>
                    <img src={blueridge}/>
                    <img src={csidigital}/>
                    <img src={midco}/>
                </div>
            </div>
        </div>
        <br id="features"></br>
        <div class={grid} >
            <Panel>
                <h1 style={{ 'font-weight': 600, 'margin-top': '53px', 'text-align': 'center' }}>
                    High Quality Video Compression
                </h1>
                <h4 style={{ 'line-height': '1.2381', 'font-size': '21px', 'font-weight': '400', 'letter-spacing': '.016em', 'margin-top': '4px', 'text-align': 'center' }}>
                    Video Transcoding
                </h4>
                <p class={parag}>Transcode to the lowest bitrates possible while maintaining excellent video quality. Our video algorithms developed over 15 years use multi-pass, scene and object analysis to provide a great picture optimized for the lowest bitrate possible.</p>
                <p class={parag}>Stream Engine runs on GPUs to maximize the compute processing we can apply to video. </p>
            </Panel>
            <Panel black style={{ 'background-color': 'black' }}>
                <h1 style={{ color: 'white', 'font-weight': 600, 'margin-top': '53px', 'text-align': 'center' }}>Secure and Reliable</h1>
                <h4>Rest Assured</h4>
                <p class={parag}>You control your video assets. Use your existing cloud storage provider such as AWS, Google, or others.  Your content is securely transcoded in our cloud and sent to your destination you control.</p>
                <p class={parag}>We do not retain your assets unless you specify to use our temporary storage as your option</p>
            </Panel>
        </div>
        <div classList={{ 'span2': true }} >
            <div>
                <img src={diagram1} style={{ width: '80%', height: 'auto', 'max-width': '1432px', margin: '2em auto' }}></img>
            </div>
        </div>
        <br  id="hlsdash"></br>
        <div class={grid}>
            {/* <Panel span2 classList={{ [col3]: true }}>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="48" width="48"><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M12.25.95c0 2.8 4 5.1 9.6 5.1h1.4"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M23.25 9.05c-9.9 0-17.5-3.4-17.5-7.5"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M20.25 11.95c-12.7 0-19.5-3.4-19.5-7.5"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M12.25 23.05c0-.8.3-1.5.9-2.1"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M12.15 16.35c-3.9 1.3-6.3 3.3-6.3 5.6"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M.75 19.45c0-4.1 6.8-7.5 19.5-7.5h3"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M16.95 22.75c-.3.1-.6.1-.8 0-.2-.1-.4-.4-.4-.7v-5.9c0-.3.2-.6.4-.7.2-.1.6-.2.8 0l5.8 2.9c.3.1.5.4.5.8 0 .3-.2.6-.5.8l-5.8 2.8Z"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M23.25 14.75h-1.7"></path></svg>
                    <span>15 Years of Transcoding Services</span>
                </div>
                <div>
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M.504 23.25h23" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4.5 23.25v-2.5a.5.5 0 0 0-.5-.5H2a.5.5 0 0 0-.5.5v2.5" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M10.5 23.25v-6.5a.5.5 0 0 0-.5-.5H8a.5.5 0 0 0-.5.5v6.5" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M16.5 23.25v-10.5a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 0-.5.5v10.5" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M22.5 23.25V8.75a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 0-.5.5v14.5" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="m3.004 13.25 18-12" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="m20.504 5.25.5-4-4-.5" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                    <span>XX Million Minutes Processed</span>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="48" width="48"><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M2.03 6.565 12 1.143l9.97 5.422"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="m12.002 22.857-9.965-5.422V6.571l9.965 5.422v10.864Z"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="m12.002 22.857 9.965-5.422V6.571l-9.965 5.422v10.864Z"></path></svg>
                    <span>Simple APIs. Robust Performance.</span>
                </div>
            </Panel> */}
            <Panel>
                <h1>ABR Transcoding</h1>
                <h4></h4>
                <p class={parag}>Transcode a single asset into as many ABR resolution/bitrate formats as necessary</p>
                <p class={parag}>Create your own bitrate ladders or use our optimized content based algorithms</p>
                <p class={parag}>Source content can be scaled (up or down), de-interlaced, de-telecined, rotated, etc, and then encoded all in one job</p>
                <br></br>
            </Panel>
            <Panel black>
                <h1>HLS and DASH Packaging</h1>
                <h4></h4>
                <p class={parag}>Stream Engine can package the ABR transcoded segments into HLS, DASH, or both simultaneously</p>
                <p class={parag}>Segments are fmp4 in h264 or h265 codecs</p>
                <p class={parag}>Select the segment length you want to use</p>
                {/* {ctaButton({ login })} */}
                <br></br>
            </Panel>
        </div>
        <div class={grid}>
            <Panel span2={true} classList={{ 'diagram0': true }} style={{ overflow: 'unset' }}>
                <div style={{ width: '100%', display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column' }}>
                    <div style={{ display: 'flex', 'align-items': 'center' }}>
                        <FourK>Source Video</FourK>
                        <div style={{ 'font-size': '2em' }}>⮕</div>
                        <div style={{ 'background-color': 'black', width: '8em', padding: '0.5em', 'border-radius': '0.5em' }}><img src={logo}></img></div>
                        <div style={{ 'display': 'grid', 'grid-template-columns': '3em 3em 3em 3em 3em 3em 3em 3em', 'grid-template-rows': 'repeat(5, 1fr)' }}>
                            <div></div>
                            <div style={{ 'grid-column': 'span 4',  width: 'unset', 'text-align': 'center', display: 'flex', 'justify-content': 'center', 'align-items': 'end' }}>
                                <div>Segments</div>
                            </div>
                            <div style={{ 'grid-column': 'span 3',  width: 'unset', 'text-align': 'center', display: 'flex', 'justify-content': 'center', 'align-items': 'end' }}>
                                <div>Manifests</div>
                            </div>
                            <Arrow/><FourK/><FourK/><FourK/><FourK/><div>&nbsp;</div><FourK/><div></div>
                            <Arrow/><FourK>2K</FourK><FourK>2K</FourK><FourK>2K</FourK><FourK>2K</FourK><div>&nbsp;</div><FourK>2K</FourK><div></div>
                            <Arrow/><FourK>SD</FourK><FourK>SD</FourK><FourK>SD</FourK><FourK>SD</FourK><div>&nbsp;</div><FourK>SD</FourK><div></div>
                            <Arrow/><FourK>HD</FourK><FourK>HD</FourK><FourK>HD</FourK><FourK>HD</FourK><div>&nbsp;</div><FourK>HD</FourK><div></div>
                            <Arrow/><FourK>qc</FourK><FourK>qc</FourK><FourK>qc</FourK><FourK>qc</FourK><div>&nbsp;</div><FourK>qc</FourK><div></div>
                            <div style={{ visibility: 'hidden' }}><Arrow></Arrow></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                        </div>
                    </div>
                </div>
            </Panel>
            <Panel black={true} style={{ 'padding-bottom': '60px' }}>
                <h1>Wide Variety of Supported Formats</h1>
                {/* <h4>Powerful, Robust, Professional</h4> */}
                <ul class={ul} style={{ 'list-style-type': 'none' }} >
                    <li><b>Input Containers</b></li>
                    <div style={{ 'padding-left': '1.5em' }}>mp4, mov, mkv, ts, ps, mxf, asf, mrv, f4v, flv, vob, 3gp, 3g2, mpeg, mpg</div>
                    <li><b>Input Codecs</b></li>
                    <div style={{ 'padding-left': '1.5em' }}>h264(avc), h265(hevc), mpeg2, aac, ac3</div>
                    <li><b>Output Containers</b></li>
                    <div style={{ 'padding-left': '1.5em' }}>ts,mp4, HLS, DASH</div>
                    <li><b>Output Codecs</b></li>
                    <div style={{ 'padding-left': '1.5em' }}>h264(avc), h265(hevc), mpeg2, aac, ac3</div>
                </ul>
                {/* <button class={cta}>Start Your Free Trial</button> */}
            </Panel>
            <Panel
                style={{ 'min-height': '300px' }}>
                <h1>
Fast Processing that Scales
                </h1>
                <h4>Streamline Your Video Transcoding Today</h4>
                <p class={parag}>We’ve optimized the video processing to run as fast as possible on GPUs and with high compression quality</p>
                <p class={parag}>HD transcodes run as fast as 20x real time.</p>
                <p class={parag}>4K transcodes run as fast as 10x real time.</p>
                <p class={parag}>Stream Engine scales dynamically to keep things moving fast.</p>
                <br></br>
                {ctaButton({ signup })}
            </Panel>
            {/* <Panel> */}
            {/* <h1>Professional Video De-Telecine</h1> */}
            {/* <h4>Powerful, Robust, Professional</h4> */}
            {/* <p class={parag}>Telecine based content is often jerky, juddery, and can have tearing video artifacts.  Any transcoding of such content will only look worse if not processed properly. </p>
                <p class={parag}>De-Telecine is the process of detecting and removing repeated video frames (and/or fields).  Performing de-telecine correctly can be very challenging since there are variety of telecine formats,and also mixed formats can exist in content due to content editing.</p>
                <p class={parag}>igolgi’s Stream Engine DeTelecine algorithm has been developed and used on millions of hours of content, including the worst juddery sources.  The algorithm detects repeated fields and frames and removes them leaving pure smooth video.</p> */}
            {/* <p class={parag}>For example, if you content is 29.97 frames per second(fps) but has 3:2 telecine content, the output of the Stream Engine de-telecine will be 23.976 fps composed of just the non repeated frames.  Judder free results.</p> */}
            {/* <button class={cta}>Start Your Free Trial</button> */}
            {/* </Panel> */}
            <br  id="dashboard"></br>
            <h1 class="span2">Transcode Video Files with a convenient User Interface <br></br>or with an API you can integrate into your workflow</h1>
            <Panel>
                <img src={screenshot} style={{ padding: '0px 3em' }}></img>
            </Panel>
            <Panel>
                <h4>User Interface Features</h4>
                <ul class={ul} style={{ 'list-style-type': 'none', 'margin-left': '1em', margin: '2em' }}>
                    <li>Drag and Drop Video Files or use your own cloud storage provider such as AWS, Google, Oracle Cloud, or any https or http URL.</li>
                    <li>Select parameters for detailed work flow results and save your favorite settings for reuse</li>
                    <li>Use parameter defaults : Stream Engine will find optimum settings for every job</li>
                    <li>Run simultaneous jobs and use the job history dashboard to check status and download results</li>
                    <li>Use igolgi temporary storage or push output files to your own cloud storage provider</li>
                    <li  id="api">Copy the JSON for use with an API call</li>
                    <li>Check out our <a href="http://docs.streamengine.igolgi.com/books/igolgi-stream-engine/page/transcoding">User Interface Documentation</a>.</li>
                </ul>
            </Panel>
            <Panel>
                <h4>API Features</h4>
                <ul style={{ 'list-style-type': 'none', 'margin-left': '1em', margin: '2em' }}>
                    <li class={parag}>Integrate transcoding jobs into your workflow with a robust API</li>
                    <li class={parag}>Copy JSON configs from the user interface to help get started</li>
                    <li class={parag}>Run simultaneous jobs</li>
                    <li class={parag}>Use our JSON API with curl or with your favorite scripting language such as Python, Go, Javascript, Ruby, and others.</li>
                    <li class={parag}>In depth documentation and tutorials available </li>
                    <li class={parag}>USA based engineering support</li>
                    <li class={parag}>Check out our <a href="http://docs.streamengine.igolgi.com/books/igolgi-stream-engine/chapter/api">API docs</a>.</li>
                </ul>
            </Panel>
            <Panel>
                <img src={screenshot_json} style={{ padding: '0px 3em' }} ></img>
                {ctaButton({ signup })}
            </Panel>
            {/* <Panel span2 classList={{ [col3]: true }}>
                <div>
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M8.5 23.5h-7a1 1 0 0 1-1-1v-7" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M8.5.5h-7a1 1 0 0 0-1 1v7" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15.5 23.5h7a1 1 0 0 0 1-1v-7" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15.5.5h7a1 1 0 0 1 1 1v7" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M4.5 10v4" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M7 9v6" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9.5 8v8" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M19.5 10v4" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 9v6" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M14.5 8v8" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 5v14" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                    <span>Advanced Encoding Algorithms that reduce file size and preserve video quality</span>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="48" width="48"><path d="M12 6 4 9.5l8 3.5 8-3.5L12 6z" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"/><path d="M7.059 20.338 12 22.5" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"/><path d="M4 9.5V17" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 13v9.5" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"/><path d="m10 2.5 2-2 2 2" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"/><path d="M1 20.5v3h3" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"/><path d="M1 23.5 5.5 19" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.941 20.338 12 22.5" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"/><path d="M20 9.5V17" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"/><path d="M23 20.5v3h-3" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"/><path d="M23 23.5 18.5 19" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 .5V4" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    <span>Professional Video and Audio Features such as De-Telecine</span>
                </div>
                <div>
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="M20.5 13.906A4.311 4.311 0 0 0 23 9.857a4.711 4.711 0 0 0-4.954-4.635A6.706 6.706 0 0 0 12 1.5a6.605 6.605 0 0 0-6.675 6.109A3.561 3.561 0 0 0 1 11.018 3.186 3.186 0 0 0 3.5 14.3" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path d="M17 14.5h-5a1 1 0 0 1-.8-.4l-.9-1.2a1 1 0 0 0-.8-.4H7a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1Z" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                    <span>Watch Folders and Template Creation to save time and produce consistent results</span>
                </div>
            </Panel> */}


            <Panel span2={true}>
                <br></br>
                <h1 id="transcoding" style={{ margin: '0em 18vw 0em 18vw' }}>Advanced Video Processing Features</h1>
                <br></br>
                <div style={{ width: '100%', display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column' }}>
                    <div style={{ width: '100%', display: 'flex', 'justify-content': 'center', 'align-items': 'top', 'flex-direction': 'row' }}>
                        <ul style={{ 'list-style-type': 'none', flex: 1  }}>
                            <li class={parag}>Transcode up to 4Kp60</li>
                            <li class={parag}>Scale down or scale up any video</li>
                            <li class={parag}>Rotate content as necessary</li>
                            <li class={parag}>Portrait to/from Landscape conversions</li>
                            <li class={parag}>Graphic Overlay a logo or any image you can provide</li>
                            <li class={parag}>Stereo and 5.1 Audio support</li>
                        </ul>
                        <ul style={{ 'list-style-type': 'none', flex: 1 }}>
                            <li class={parag}>Multiple Audio Track support</li>
                            <li class={parag}>Audio Loudness Control</li>
                            <li class={parag}>Closed Captioning</li>
                            <li class={parag}>Optical Flow Deinterlacer</li>
                            <li class={parag}>De-Telecine to remove repeated fields found in any pull down cadence</li>
                            <li class={parag}>See De-Telecine example here</li>
                        </ul>
                    </div>
                    <br></br>
                    <div><b>Example</b>: Change Portrait aspect ratio to Landscape and generate outside panels</div>
                    <div class="landscape0" style={{ display: 'flex', 'align-items': 'center', border: '1px solid gray', padding: '0.5em' }}>
                        <div><VideoPlayer alwaysMute={true} autoplay='muted' loop={true} src={'https://yzwhip2gci7s.objectstorage.ca-toronto-1.oci.customer-oci.com/n/yzwhip2gci7s/b/igolgi-public/o/f6be3d73_d03e_4cb4_bb64_fcbee09cd3c3/playlist.m3u8'} hasStream={true}></VideoPlayer></div>
                        <div style={{ 'font-size': '5em' }}>⮕</div>
                        <div><VideoPlayer alwaysMute={true} autoplay='muted'  loop={true} src={'https://yzwhip2gci7s.objectstorage.ca-toronto-1.oci.customer-oci.com/n/yzwhip2gci7s/b/igolgi-public/o/f63f9ae1_f17b_4b90_8aba_6012bac8809f/playlist.m3u8'} hasStream={true}></VideoPlayer></div>
                    </div>
                </div>
            </Panel>
            {/* <Panel span2 classList={{ [col3]: true }}>
                <div>
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" height="48" width="48"><path d="m8 23.498 3-11H7l3-12h8l-5 8h5l-10 15z" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                    <span>Blazar Mode for super-fast transcoding 10X faster than normal</span>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="48" width="48"><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M15.4 8.823v-4.78A3.136 3.136 0 0 0 12.261.907H3.636A3.136 3.136 0 0 0 .5 4.043v4.7a3.136 3.136 0 0 0 3.136 3.137v3.92l3.921-3.92h3.633"></path><path stroke="#000000" d="M4.028 6.775a.375.375 0 1 1 0-.75"></path><path stroke="#000000" d="M4.028 6.775a.375.375 0 1 0 0-.75"></path><path stroke="#000000" d="M7.949 6.775a.375.375 0 1 1 0-.75"></path><path stroke="#000000" d="M7.949 6.775a.375.375 0 0 0 0-.75"></path><path stroke="#000000" d="M11.87 6.775a.375.375 0 0 1 0-.75"></path><path stroke="#000000" d="M11.87 6.775a.375.375 0 0 0 0-.75"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M12.714 20.936h-.432a1.726 1.726 0 0 1-1.726-1.726v-1.726a1.726 1.726 0 0 1 1.726-1.725h.432a.431.431 0 0 1 .431.431v4.315a.43.43 0 0 1-.431.431v0Z"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M21.774 20.936h-.431a.431.431 0 0 1-.432-.431V16.19a.432.432 0 0 1 .432-.431h.431a1.724 1.724 0 0 1 1.726 1.725v1.726a1.726 1.726 0 0 1-1.726 1.726v0Z"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M12.282 15.759a4.746 4.746 0 0 1 4.746-4.746v0a4.746 4.746 0 0 1 4.746 4.746"></path><path stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M18.754 23.093h1.294a1.726 1.726 0 0 0 1.726-1.725v0-.432"></path></svg>
                    <span>USA based technical support needed for any problems with video assets</span>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="48" width="48"><g><path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="m22 .5-1.5 4h3l-7.5 7 2.23-5H15.5l2.5-6h4z"></path><g><path d="M15.5 13.5v6a1 1 0 0 1-1 1h-10a1 1 0 0 1-1-1v-10a1 1 0 0 1 1-1h10" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"></path><path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M9.5 20.5v3"></path><path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M13.5 20.5v3"></path><path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M9.5 5.5v3"></path><path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M5.5 20.5v3"></path><path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M5.5 5.5v3"></path><path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M15.5 18.5h3"></path><path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M3.5 14.5h-3"></path><path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M18.5 14.5h-3"></path><path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M3.5 10.5h-3"></path><path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M3.5 18.5h-3"></path><path fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" d="M7.5 10.5h4s2 0 2 2v4s0 2-2 2h-4s-2 0-2-2v-4s0-2 2-2"></path></g></g></svg>
                    <span>GPU Cloud for maximum processing, performance and Speed</span>
                </div>
            </Panel> */}
            <br id="detelecine"></br>
            <Panel span2 style={{  margin: '0em 8vw', width: 'unset' }}>
                <h1 style={{ 'padding-bottom': '0.5em' }}>De-Telecine (Removal of Judder)</h1>
                <div style={{ width: '100%', display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column' }}>
                    <div class={flexRow} style={{ width: '100%',  'justify-content': 'center', 'align-items': 'normal' }}>
                        <ul style={{ 'list-style-type': 'none', flex: 3 }}>
                            <li class={parag}>Telecine content suffers from visual artifacts referred to as Judder and interlaced tearing.  The effect is a shaking and fuzziness of the content.  Telecine can come in a wide variety of “pull-downs”.  The typical example is a 3:2 pulldown, but many other formats exist.  In many cases content that is telecined is then edited which creates breaks in the cadence of the telecine pattern which causes extra jerkiness in the video.</li>
                            <li class={parag}>Stream Engine has a De-Telecine algorithm which removes the repeated fields in telecine content and also tracks cadence changes.  The algorithm removes repeated fields resulting in a smooth progressive video output.  An added benefit is the reduction in repeated fields reduces the bitrate required by 20% or more, resulting in 20% smaller files</li>
                        </ul>
                        <div style={{ width: '100%', flex: 2 }}>
                            <Slider/>
                            <div class={parag} style={{ 'margin-top': '1em' }}>Here is a single frame example from a video with moving text. The picture on the left is the telecine frame where the letters are tearing into the interlaced fields. The picture on the right is the Stream Engine de-telecined output. The Stream Engine output removes the tearing and creates a smooth progressive frame.</div>
                        </div>
                    </div>
                    {/* <div style={{ display: 'flex', 'align-items': 'center' }}>
                        <div style={{ 'text-align': 'right' }}><img src={judder0} style={{ width: '80%', display: 'inline' }}></img></div>
                        <div style={{ 'font-size': '5em' }}>⮕</div>
                        <div><img src={judder1} style={{ width: '80%' }}></img></div>
                    </div> */}
                    <ul style={{ 'list-style-type': 'none', margin: '1em 0px 0px 0px' }}>
                        <li class={parag}><b>Example</b> You can see the video version of this example with the telecined video on the left and the output of the igolgi deinterlaced output on the right.</li>
                    </ul>
                    <div class="landscape0" style={{ display: 'flex', 'align-items': 'center', border: '1px solid gray', padding: '0.5em' }}>
                        <div ><VideoPlayer autoplay='muted' loop={true} src={'https://yzwhip2gci7s.objectstorage.ca-toronto-1.oci.customer-oci.com/n/yzwhip2gci7s/b/igolgi-public/o/9dc5cce3_d913_48bf_9f89_f1317edbc473/playlist.m3u8'} hasStream={true}></VideoPlayer></div>
                        <div style={{ 'font-size': '5em' }}>⮕</div>
                        <div><VideoPlayer autoplay='muted'  loop={true} src={'https://yzwhip2gci7s.objectstorage.ca-toronto-1.oci.customer-oci.com/n/yzwhip2gci7s/b/igolgi-public/o/27adc1c7_686c_4fc9_aad4_e19049917d42/playlist.m3u8'} hasStream={true}></VideoPlayer></div>
                    </div>
                </div>
            </Panel>
            <div class="span2" style={{ width: 'unset' }}>
                <div style={{
                    'font-weight'  : '800',
                    'line-height'  : 1,
                    'padding-top'  : '10vh',
                    'z-index'      : 1, 'font-size'    : '3em', 'text-align'   : 'center', 'margin-bottom': '3vh' }}>
Professional Cloud Video Transcoding
                    <br></br>
                    <div style={{
                        'background-clip'        : 'text',
                        'background-image'       : 'linear-gradient(171deg, rgb(255, 254, 254), rgb(202, 47, 1) 27%, rgb(84 27 9) 94%)',
                        '-webkit-text-fill-color': 'transparent',
                    }}>Save over 2X versus Competition Costs</div>
                </div>
                <img src={diagram2} style={{ width: '80%', height: 'auto', 'max-width': '1432px', margin: '2em auto' }}></img>
            </div>
            {/* <Panel>
                <div >
                    <div style={{ width: '80%', height: '250px', 'background-color': '#eee', margin: '50px', 'margin-bottom': '10px' }}></div>
                </div>
                <p class={parag}>An added benefit is that with only the original frames , the video compression will require 20% less bits making your files 20% smaller.</p>
                <p class={parag}>Stream Engine supports any form of repeated frames and fields including mixed formats.  It is totally automatic.  Just select detelecine mode in the UI or add it to your API call.  Stream Engine will analyze the incoming video file characteristics, and apply de-telecine on it automatically if it is needed.</p>
                {ctaButton({ login })}
            </Panel> */}
            {/* <Panel>
                <h1>Cloud Video Transcoding</h1>
                <h4>Powerful, Robust, Professional</h4>
                <p class={parag}>Our cloud video service offers robust APIs for Video File Transcoding.  Our solution has been transcoding video assets for the top Content Providers and Cable Companies in the world for over 15 years.  Now this solution is available on the cloud for anyone to use.</p>
                <p class={parag}>Small or Large Volume, our time tested transcoder can handle your most challenging video assets. All of this at a low price and works with your existing cloud storage provider, no need to transfer your assets.</p>
                {ctaButton({ login })}
            </Panel> */}
        </div>
        <div id="pricing"
            style={{
                padding: '0em 3em',
            }}>
            <h1>Pricing</h1>
            <br></br>
            <div style={{
                width : '50vw',
                margin: 'auto',
            }}></div>
            <Plans currentPlan={undefined} addPayment={() => void login()}/>
        </div>
        <div class={grid}>
            <Panel style={{ border: '1px solid #eee', padding: '1em', overflow: 'visible' }}>
                <h1 style={{ 'font-weight': 600, 'margin-top': '53px', 'text-align': 'center' }}>Flexible Pricing</h1>
                <h4 style={{ 'line-height': '1.2381', 'font-size': '21px', 'font-weight': '400', 'letter-spacing': '.016em', 'margin-top': '4px', 'text-align': 'center' }}>
Pay as You Go
                </h4>
                <br></br>
                <Calculator2/>
            </Panel>
            <Panel style={{ border: '1px solid #eee', padding: '1em', overflow: 'visible' }}>
                <h1 style={{ 'font-weight': 600, 'margin-top': '53px', 'text-align': 'center' }}>Addons</h1>
                <br></br>
                <table class={extraTable}>
                    <tbody>
                        <tr><td>Dolby Codecs(AC3, stereo or 5.1)</td><td>+$0.001/minute</td></tr>
                        <tr><td>De-Telecine		</td><td>1.50x</td></tr>
                        <tr><td>H265/AV1		</td><td>1.25x</td></tr>
                        <tr><td>Input files larger than 25 Mbps		</td><td>1.25x</td></tr>
                        {/* <tr><td>CABR encoding 		</td><td>25% delta</td></tr> */}
                        <tr><td>Framerate &gt; 60 fps		</td><td>1.50x</td></tr>
                    </tbody>
                </table>
            </Panel>
            <Panel>
                <h4>Test Plan</h4>
                <div class={parag}>Just sign up with your email, no credit card needed.  You can encode videos or audios up to 1 minute long and process one output at a time.  Videos will be watermarked</div>
                <h4>Starter Plan</h4>
                <div class={parag}>Pay as you go plan with all features available.   With the starter plan you can run up to 5 simultaneous jobs.</div>
                <h4>Pro Plan
                </h4>
                <div class={parag}>$10/month plus pay as you go with 25% discount on all jobs compared to the Starter Plan.  In addition you can run up to 10 simultaneous jobs.
                </div>
                <h4>Enterprise Plan
                </h4>
                <div class={parag}>If you process approximately 500 hours of content per month or more, then please contact us and we can provide a custom pricing plan and features to fit your needs.  In addition, if there is custom work flow processing features you need, we have the engineering team to develop to your requirements.
                </div>
            </Panel>
            <Panel>
                <h4>Billing Process
                </h4>
                <div class={parag}>Your credit card will be charged at the end of every month based on the number of output minutes processed.  We use stripe for purchase processing so everything is secure.</div>
                <div class={parag}>Output minutes are defined as each unique output video file format. For example, a 5 minute HD file (with video and audio) transcoded into 4 output HD formats would count as 20 (5 x 4) output minutes.  This would cost 20 x ($0.0075+$0.0016) = $0.19.</div>
                <div class={parag}>Asset length is rounded up to the minute, so if you have a 20 second clip that is counted as 1 minute for example.</div>
                <div class={parag}>NO OTHER HIDDEN FEES. API calls are free.</div>
                <div class={parag}>If you use Stream Engine temporary storage there is no cost to download your output assets.</div>
                <div class={parag}>We accept Visa, Mastercard, Discover, and American Express.</div>
            </Panel>
        </div>
        <div class={footer}>
            <div class={footerGrid}>
                <div>
                    <h4>About igolgi</h4>
                    <div>
                        <p>For over a decade igolgi has provided innovative, flexible, and high-quality solutions for the broadcast, cable, LPTV and professional, education, and government markets.</p>
                    </div>
                </div>
                <div>
                    <h4>Products</h4>
                    <div>
                        <p>
                        iLux | <a href="https://www.igolgi.com/atsc1">ATSC 1.0</a> | <a href="https://www.igolgi.com/ilux-atsc3">ATSC 3.0</a><br></br>
                    iLux | <a href="https://www.igolgi.com/ilux-mini">Mini</a><br></br>
                    iLux | <a href="https://www.igolgi.com/ilux-file">File</a><br></br>
                    iLux | <a href="https://www.igolgi.com/ilux-live">Live</a><br></br>
                            <a href="https://www.igolgi.com/blazar">Blazar</a>
                        </p>
                    </div>
                </div>
                <div>
                    <h4>Contact Us</h4>
                    <div>
                        <p><a href="https://www.linkedin.com/company/igolgi-inc/">LinkedIn</a></p>
                    </div>
                    <div style={{ display: 'grid', 'grid-template-columns': '1fr 1fr' }}>
                        <div>
                            <p>
                    5 Independence Way<br></br>
                    Suite 300<br></br>
                    Princeton, NJ 08540</p>
                        </div>
                        <div class="logo">
                            <p>Tel: 609-334-5978</p>
                            <Logo onClick={() => window.location.href = 'http://www.igolgi.com'}></Logo>
                        </div>
                    </div>
                </div>
            </div>
            <div class={final}>
                <p>Copyright © 2024 igolgi. All rights reserved.</p>
            </div>
        </div>

    </div>
}

const Logo = (p: { onClick: ()=>void }) => {
    return <svg onClick={p.onClick}
        preserveAspectRatio="xMidYMid meet" data-bbox="1.44 0.479 260.07 62.561" viewBox="0 0 262.95 64.46" xmlns="http://www.w3.org/2000/svg" data-type="ugc" role="presentation" aria-hidden="true" aria-labelledby="svgcid--f2rxr9-xjpkmm">
        <title id="svgcid--f2rxr9-xjpkmm">
        </title>
        <g>
            <path d="M61.4 44.11q0 .6-.66.6h-8.52a.53.53 0 0 1-.6-.6v-20.4a.53.53 0 0 1 .6-.6h8.52q.66 0 .66.6z" fill="#000000"></path>
            <path d="M51.91 12.94c0 4.85 8.69 3 8.69 3V4.26c0-4-8.69-3-8.69-3z" fill="#000000"></path>
            <path d="M224.43 12.27c0 4.85 8.69 3 8.69 3V3.59c0-4-8.69-3-8.69-3z" fill="none" stroke="#000000"></path>
            <path d="M111.44 12.94c0 4.85 8.69 3 8.69 3V4.26c0-4-8.69-3-8.69-3z" fill="none" stroke="#000000"></path>
            <path d="M131.43 12.94c0 4.85 8.69 3 8.69 3V4.26c0-4-8.69-3-8.69-3z" fill="none" stroke="#000000"></path>
            <path d="M71.45 12.94c0 4.85 8.69 3 8.69 3V4.26c0-4-8.69-3-8.69-3z" fill="none" stroke="#000000"></path>
            <path d="M187.1 12.94c0 4.85 8.69 3 8.69 3V4.26c0-4-8.69-3-8.69-3z" fill="none" stroke="#000000"></path>
            <path d="M91.45 12.94c0 4.85 8.68 3 8.68 3V4.26c0-4-8.68-3-8.68-3z" fill="none" stroke="#000000"></path>
            <path d="M36.44 12.94c0 4.85 8.69 3 8.69 3V4.26c0-4-8.69-3-8.69-3z" fill="none" stroke="#000000"></path>
            <path d="M167.75 12.94c0 4.85 8.69 3 8.69 3V4.26c0-4-8.69-3-8.69-3z" fill="none" stroke="#000000"></path>
            <path d="M206.45 12.94c0 4.85 8.68 3 8.68 3V4.26c0-4-8.68-3-8.68-3z" fill="#000000"></path>
            <path d="M101.56 23.28q.66 0 .66.6V49.8a4.64 4.64 0 0 1-1.44 3.45 4.79 4.79 0 0 1-3.48 1.41H70a.53.53 0 0 1-.6-.6V50.4a.53.53 0 0 1 .6-.6h21.78a.58.58 0 0 0 .66-.65v-3.56a.58.58 0 0 0-.66-.65H74.26a4.66 4.66 0 0 1-3.42-1.43A4.72 4.72 0 0 1 69.4 40V28.14a4.92 4.92 0 0 1 4.86-4.86zm-9.12 5.52a.58.58 0 0 0-.66-.66h-12q-.6 0-.6.66v10.62a.53.53 0 0 0 .6.6h12q.66 0 .66-.6z" fill="#000000"></path>
            <path d="M136.24 23.28h.66a4.65 4.65 0 0 1 3 1.64 4.8 4.8 0 0 1 1.2 3.22V40a4.64 4.64 0 0 1-1.44 3.45 4.79 4.79 0 0 1-3.48 1.41h-23a4.72 4.72 0 0 1-3.42-1.41 4.64 4.64 0 0 1-1.48-3.45V28.14a4.92 4.92 0 0 1 4.86-4.86zM130.66 40q.66 0 .66-.6V28.8a.58.58 0 0 0-.66-.66h-12q-.6 0-.6.66v10.62a.53.53 0 0 0 .6.6z" fill="#000000"></path>
            <path d="M148.54 43.47A4.64 4.64 0 0 1 147.1 40V14.1a.53.53 0 0 1 .6-.6h8.52q.66 0 .66.6v25.32a.53.53 0 0 0 .6.6h3.66a.53.53 0 0 1 .6.6v3.66a.53.53 0 0 1-.6.6H152a4.72 4.72 0 0 1-3.46-1.41z" fill="#000000"></path>
            <path d="M199.48 23.28q.66 0 .66.6V49.8a4.64 4.64 0 0 1-1.44 3.45 4.79 4.79 0 0 1-3.48 1.41h-27.3a.53.53 0 0 1-.6-.6V50.4a.53.53 0 0 1 .6-.6h21.78a.58.58 0 0 0 .66-.65v-3.56a.58.58 0 0 0-.66-.65h-17.52a4.66 4.66 0 0 1-3.42-1.43 4.72 4.72 0 0 1-1.44-3.51V28.14a4.92 4.92 0 0 1 4.86-4.86zm-9.12 5.52a.58.58 0 0 0-.66-.66h-12q-.6 0-.6.66v10.62a.53.53 0 0 0 .6.6h12q.66 0 .66-.6z" fill="#000000"></path>
            <path d="M216 44.28q0 .6-.66.6h-8.54a.53.53 0 0 1-.6-.6v-20.4a.53.53 0 0 1 .6-.6h8.52q.66 0 .66.6z" fill="#000000"></path>
            <path d="M28.44 8.47c-17.5 0-27 2.8-27 23.31v31.26h233.07c17.5 0 27-2.81 27-23.32V8.47H238" stroke-width="2.84" fill="none" stroke="#000000"></path>
        </g>
    </svg>
}
export const Panel = (p: { black?: boolean, children: JSX.Element, blush?: boolean, style?: JSX.CSSProperties, span2?: boolean, classList?: {    [k: string]: boolean | undefined } }): JSX.Element => {
    return <div
        classList={{ ...p.classList, 'span2': p.span2, [black]: p.black }}
        style={{
            // 'min-height'   : '400px',
            height         : '100%',
            position       : 'relative',
            // display        : 'grid',
            'width'        : '100%',
            margin         : 'auto',
            // 'height': '200px',
            'border-radius': '10px',
            'overflow'     : 'hidden',
            ...(p.style ?? {})  }}>
        <Show when={p.blush}>
            <Blush style={{ position: 'absolute', 'z-index': -1, 'grid-column': 1, 'grid-row': 1, opacity: 0.9 }}/>
        </Show>
        {p.children}
    </div>
}
const black = css({
    backgroundColor: 'black',
    '& *'          : {
        color: 'white !important',
    },
    '& h1': {
        color: 'white !important',
    },
    '& h4': {
        color: '#aaa !important',
    },

})()
const col3 = css({
    display              : 'grid',
    minHeight            : '150px',
    'gridTemplateColumns': '1fr 1fr 1fr',
    '& div'              : {
        padding        : '30px',
        borderRadius   : '20px',
        backgroundColor: 'hsl(0, 0%, 97%)',
        margin         : '10px',
        display        : 'flex',
        flexDirection  : 'column',
        justifyContent : 'center',
        alignItems     : 'center',
        textAlign      : 'center',
    },
    '& svg': {
        marginBottom: '15px',
        // @ts-expect-error
        '& path'    : {
            'stroke': '#888',
        },
    },
    '@bp1': {
        gridTemplateColumns: 'unset',
    },
})()
const ul = css({
    color      : 'black',
    'fontSize' : '0.8em',
    margin     : 'auto',
    width      : '80%',
    'marginTop': '20px',
    '& li'     : {
        paddingBottom: '0.5em',
    },
})()
const parag = css({
    color        : 'black',
    'fontSize'   : '0.8em',
    margin       : 'auto',
    marginTop    : 0,
    width        : '80%',
    paddingBottom: '0.5em',
    // 'marginTop'  : '20px',
})()
const mainContent = css({
    overflow    : 'clip',
    'lineHeight': 1,
    width       : '100%',
    position    : 'relative',
    cursor      : 'default',
    'fontWeight': '800',
    color       : 'white',
    margin      : 'auto',
    'textAlign' : 'center',
    '& *'       : {
        zIndex  : 1,
        position: 'relative',
    },
})()
const navLinks = css({
    alignItems      : 'center',
    // position        : 'absolute',
    top             : 0,
    right           : 0,
    color           : 'white',
    padding         : '0.5em',
    'fontSize'      : '1.1em',
    'textDecoration': 'none',
    borderBottom    : '1px solid white',
    width           : '100%',
    display         : 'flex',
    'justifyContent': 'flex-end',
    '& a'           : {
        marginLeft      : '2em',
        color           : 'white',
        'textDecoration': 'none',
    },
    //@ts-expect-error
    '@bp2': {
        'a': {
            marginLeft: '1em',
        },
    },
    '& a:hover': {
        'textDecoration': 'underline',
    },
})()
const main = css({
    cursor: 'pointer',
    '& h1': {
        color        : 'black',
        'lineHeight' : '1.1',
        'fontWeight' : 600,
        'marginTop'  : '53px',
        'textAlign'  : 'center',
        paddingInline: '1em',
    },
    '& h4': {
        color          : '#666',
        'lineHeight'   : '1.2',
        'fontSize'     : '21px',
        'fontWeight'   : '400',
        'letterSpacing': '.016em',
        'marginTop'    : '4px',
        'textAlign'    : 'center',
        paddingBottom  : '1em',
    },
    // @ts-expect-error
    '@bp2': {
        '.logo': {
            display: 'none',
        },
    },
})()



const cta = css({
    zIndex         : 100,
    cursor         : 'pointer',
    display        : 'block',
    'fontSize'     : '0.8em',
    margin         : 'auto',
    paddingBlock   : '0.2em',
    paddingInline  : '1em',
    // fontFamily     : '"SF Pro Text", "SF Pro Icons", "Helvetica Neue", Helvetica, Arial, sans-serif',
    'marginTop'    : '20px',
    backgroundColor: 'rgb(0, 118, 254)',
    color          : 'white',
    border         : '0px',
    fontWeight     : '300',
    borderRadius   : '5px',
})()

const marquee = keyframes({
    '0%'  : { transform: 'translate(0, 0)' },
    '50%' : { transform: 'translate(-50%, 0)' },
    '100%': { transform: 'translate(0, 0)' },
})()
const grid = css({
    'fontSize'           : '1.4em',
    display              : 'grid',
    'gridTemplateColumns': '1fr 1fr',
    gap                  : '20px',
    margin               : '1em',
    // 'paddingInline'      : '20px',
    '& .span2'           : {
        gridColumn: 'span 2',
    },
    // @ts-expect-error
    '@bp2': {
        '.span2': {
            gridColumn: 'span 1',
        },
        '.diagram0': {
            fontSize: '0.6em',
        },
        'grid-template-columns': '1fr',
        // display                : 'unset',
        padding                : '1em',
        '.landscape0'          : {
            transform: 'scale(0.5)',
        },
    },
    // '@media(max-width: 1768px)': {
    //     'grid-template-columns': '1fr',
    // },
})()
const logosInner = css({
    display  : 'flex',
    gap      : '50px',
    animation: `${marquee} 30s linear infinite`,
})()
const logos = css({
    overflow     : 'hidden',
    padding      : '10px',
    paddingBottom: '30px',

    // paddingLeft: '100%',
    '& img': {
        height: '30px',
        // filter: saturate(0%);
    },
})()
const final = css({
    borderTop   : '0.5px solid #ddd',
    paddingBlock: 10,
    lineHeight  : 1,
    marginTop   : '2vh',

})()
const footer = css({
    '& svg': {
        // float  : 'right',
        width  : '100px',
        opacity: '20%',
    },
    '& svg:hover': {
        // float  : 'right',
        opacity: '50%',
        cursor : 'pointer',
    },
    backgroundColor: 'hsl(0, 0%, 97%)',
    // height         : '30vh',
    marginTop      : '50px',
    padding        : '3vw 5vw 0vw 5vw',
    color          : 'hsl(0, 0%, 60%)',
    // paddingBottom  : '200px',
    '& a'          : {
        color         : 'unset',
        textDecoration: 'none',
    },
    '& a:hover': {
        color         : 'unset',
        textDecoration: 'underline',
    },
    '& h4': {
        textAlign    : 'left',
        color        : 'hsl(0, 0%, 40%)',
        fontSize     : 12,
        paddingBottom: '5px',
    },
    '& p': {
        fontSize     : 12,
        paddingBottom: 6,
        lineHeight   : 1.3,
    },

})()
const igolgiLink = css({
    color      : 'white',
    '&:visited': {
        color: 'white',
    },
})()
const footerGrid = css({
    'gridTemplateColumns': '1fr 1fr 1fr',
    gap                  : '50px',
    display              : 'grid',
})()

// @ts-ignore
import { keyframes } from '@stitches/core'

import { Calculator2 } from './App/Calculator2'
import { Button } from './App/Library'
import { Plans } from './App/Plans'
import VideoPlayer from './App/VideoPlayer'
import { css } from './css'
// @ts-expect-error
import { blush } from './effects/blush.js'
import { reset } from './reset'
export const Blush = (p: { style?: JSX.CSSProperties }): JSX.Element => {
    const id = `blush${Math.random()}`
    createEffect(() => {
        blush(id, Math.random() * 20)
    })
    // Note: dimensions MUST be in % otherwise it'll will affect parent dimensions
    return <canvas id={id}  width="32" height="32" style={{ width: '100%', height: '100%', ...(p?.style ?? {}) }}/>
}



const dropdown = css({
    zIndex      : 100,
    position    : 'relative',
    display     : 'inline-block',
    '& .content': {
        display: 'none',
        width  : 'max-content',

    },
    '&:hover .content': {
        'display'        : 'block',
        position         : 'absolute',
        'backgroundColor': '#f1f1f1',
        'minWidth'       : '160px',
        'boxShadow'      : '0px 8px 16px 0px rgba(0,0,0,0.2)',
        'zIndex'         : 1,
    },
    '& .content a': {
        color           : 'black',
        padding         : '12px 16px',
        margin          : '0px',
        'textDecoration': 'none',
        display         : 'block',
        // @ts-expect-error
        '&:hover'       : {
            'backgroundColor': '#ddd',
        },
    },
})()

const Dropdown = (p: { item: JSX.Element, children: JSX.Element }): JSX.Element => {
    return <div class={dropdown}>
        {p.item}
        <div class="content">
            {p.children}
        </div>
    </div>
}

const aButton =  css({
    cursor        : 'pointer',
    border        : '1px solid #999',
    padding       : '0.25em 1em',
    'borderRadius': '0.5em',
    margin        : 0,
    '&:hover'     : {
        textDecoration : 'none',
        backgroundColor: '#555',
    },
})()

const icons = css({
    '& svg path': {
        // @ts-expect-error
        stroke: 'white',
    },
    '& svg': {
        'marginBottom': '1.5em',
    },
})()

const extraTable = css({
    '& td': {
        paddingLeft: '1em',
    },
})()


const FourK = (p: { children?: string }) => {
    return <span style={{ 'background-color': 'gray', color: 'white', 'border-radius': '0.5em', border: '1px solid black', padding: '0em 1em', margin: '0.5em 0.2em', 'font-size': '0.8em', 'display': 'flex', 'text-align': 'center',  'align-items': 'center' }}><span>{p.children ?? '4K'}</span></span>
}

const Arrow = () => {
    return <div style={{ 'font-size': '2.5em', margin: '0em 0em' }}>⮕</div>
}


const Slider = () => {
    const [x, setX] = createSignal(50)
    const [isActive, setIsActive] = createSignal(false)
    let parent: HTMLDivElement | undefined = undefined

    return <div class={slider} ref={(e) => parent = e}
        onMouseMove={(e) => {
            // console.log(parent?.getBoundingClientRect().left, window.pageXOffset, e.pageX)
            // console.log(e.pageX - e.currentTarget.getBoundingClientRect().left - window.pageXOffset)
            if(isActive()){
                const perc = (e.pageX - parent!.getBoundingClientRect().left) / parent!.getBoundingClientRect().width
                setX(perc * 100)
            }
            console.log(parent!.offsetLeft, e.currentTarget.offsetLeft)
        }}
    >
        {/* <img src={judder0} style={{ 'pointer-events': 'none', 'user-select': 'none', position: 'relative', width: 'auto', height: 'auto', overflow: 'hidden' }}></img>
        <img src={judder1} style={{ 'pointer-events': 'none', 'user-select': 'none', position: 'absolute', width: 'auto', height: 'auto', overflow: 'hidden', top: 0, left: 0 }}></img> */}
        <div class="img" style={{ 'background-image': `url(${judder1})` }}></div>
        <div class="img" style={{ 'background-image': `url(${judder0})`, width: `${x()}%` }}></div>
        <div
            onMouseDown={() => setIsActive(true)}
            onMouseUp={() => setIsActive(false)}
            onTouchStart={() => setIsActive(true)}
            onTouchEnd={() => setIsActive(false)}

            style={{ 'user-select': 'none', position: 'absolute', 'z-index': 9, cursor: 'ew-resize', width: '9px', left: `${x()}%`, height: '100%', 'background-color': 'red', border: '3px solid black', 'border-top': 0, 'border-bottom': 0,  top: '0px' }}>&nbsp;</div>
    </div>
}
const d = [1005, 247]
const ratio = 3
const slider = css({
    position: 'relative',
    width   : `${d[0] / ratio}px`,
    height  : `${d[1] / ratio}px`,
    border  : '2px solid white',
    '& .img': {
        position         : 'absolute',
        top              : '0',
        left             : '0',
        width            : '100%',
        height           : '100%',
        // @ts-expect-error
        'background-size': `${d[0] / ratio}px 100%`,
    },
})()

const flexRow = css({
    'flexDirection': 'row',
    display        : 'flex',
    // @ts-expect-error
    '@bp2'         : {
        flexDirection: 'column',
    },
})()