
import { createTRPCProxyClient, httpBatchLink } from '@trpc/client'
import type { AppRouter } from 'api/src/trpc/trpc-public'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const makeClient = (base: string, token: string) => createTRPCProxyClient<AppRouter>({
    links: [httpBatchLink({ url: base, headers() {
        return { Authorization: `Bearer ${token}` }
    } }),
    ],
})
export type SEClient = ReturnType<typeof makeClient>
