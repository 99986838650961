
import { createSignal, JSX } from 'solid-js'

import { useAuthenticatedContext } from './App/context'
import { EULA_ } from './EULA_'
import { Privacy } from './Privacy'



export const EULA = (): JSX.Element => {
    const [agree, setAgree] = createSignal(false)
    const { rep, client, urls, saveTemplateSignal, user } = useAuthenticatedContext()
    return <div style={{ width: '100vw', height: '100vh', display: 'flex', 'align-items': 'center', 'justify-content': 'center', 'background-color': '#eee' }}>
        <div style={{ margin: '1em', width: '50vw', height: '75vh', 'background-color': 'white', overflow: 'scroll', padding: '1em' }}>
            <EULA_></EULA_>
            <Privacy></Privacy>
            <div>
                <b><input type="checkbox" checked={agree()} onChange={e => setAgree(e.currentTarget.checked)}></input>&nbsp;I have read, understood, and given consent to the terms and conditions outlined above.</b>
                <div style={{ display: 'flex', 'flex-direction': 'row', 'justify-content': 'space-around' }}>
                    <button style={{ padding: '0.5em 1em' }} disabled={!agree()}
                        onClick={() => {
                            void rep()?.mutate.userConsent({})
                        }}
                    >Continue</button>
                </div>
            </div>
        </div>
    </div>

}