import { z, ZodError } from 'zod'

export const escapeOwner = (p: Promise<any> | undefined | (() => any)): void => {
    if(typeof p == 'function') void p()
}

export class CloudValidationError extends Error {
}

export const SUPPORTED_CONTAINERS = ['mp4',
                                     'avi',
                                     'mov',
                                     'mkv',
                                     'ts',
                                     'ps',
                                     'mxf',
                                     'asf',
                                     'm4v',
                                     'f4v',
                                     'flv',
                                     'vob',
                                     '3gp',
                                     '3g2',
                                     'mpeg',
                                     'mpg']


export const  parse_picture_transform = (keep_fps: boolean, force_progressive: boolean):'deinterlace' | 'detelecine/deinterlace' | 'none' => {
    if(keep_fps == true && force_progressive == true) return 'deinterlace'
    else if (keep_fps == false && force_progressive == true) return 'detelecine/deinterlace'
    else return 'none'
}

export function unifyZodErrors(error1: ZodError | undefined, error2: ZodError | undefined): ZodError | undefined {
    if(error1 == undefined) return error2
    if(error2 == undefined) return error1
    const issuesMap = new Map()

    error1.issues.forEach(issue => {
        issuesMap.set(issue.path.join('.'), issue)
    })

    error2.issues.forEach(issue => {
        const pathKey = issue.path.join('.')
        if (!issuesMap.has(pathKey)) {
            issuesMap.set(pathKey, issue)
        }
    })

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    return new ZodError(Array.from(issuesMap.values()))
}

import { Schema } from 'api/src/streamengine/schema'
import * as commonSchema from 'common/src/schema'

export const computeProgress = (job: () => Pick<commonSchema.JobModel, 'status' | '_auto_generated_id_'> | undefined): number => {
    // console.log(job()?._auto_generated_id_)
    // console.log(job())
    const tcode_progress = Math.min(Number(job()?.status?.tcode_progress ?? 0), 100)
    const xfer_progress = Math.min(Number(job()?.status?.xfer_progress ?? 0), 100)
    // AWS_ACCESS_KEY_ID=AKIAWRWLYDJYXAVHSF52 AWS_SECRET_ACCESS_KEY="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" aws s3 cp s3://testborras/outputs/foo.test .

    const p = (xfer_progress == 0 && tcode_progress > 0)
        ? (100 + tcode_progress) / 2
        : (tcode_progress + xfer_progress) / 2
    if(job() != undefined) return Math.max(p, 5)
    else return 0
}

export type StateValue = 'abort' | 'error' | 'ongoing' | 'success'
export const computeState = (status: Schema.Job.StatusWithStringId | null, _auto_generated_id_: number): StateValue => {
    const progress = computeProgress(() => ({ status, _auto_generated_id_ }))
    const state = status?.state

    let s: StateValue = 'ongoing'// `${state?.toString() ?? ''}${job_completed?.toString() ?? ''}${progress?.toString() ?? ''}`
    if(state == 'abort') s = 'abort'
    else if(state == 'error') s = 'error'
    else if(progress == 100) s = 'success'
    return s
}

export const userPlan = z.union([z.literal('trial'), z.literal('starter'), z.literal('pro'), z.literal('enterprise')])
export type UserPlan = z.infer<typeof userPlan>